import styled from 'styled-components'

import { OverflowTextWithTooltip } from '@oldComponents/OverflowTextWithTooltip'

export const OverflowText = styled(OverflowTextWithTooltip)<{ $maxWidth?: number }>`
  display: block;
  max-width: ${({ $maxWidth }) => ($maxWidth != undefined ? `${$maxWidth}px` : '100%')};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
