import React from 'react'

import { FormattedMessage } from 'react-intl'

export const AlertSuccessDokumentDetachMessage = (
  <FormattedMessage id="alerts.successMessage.detachDokument" defaultMessage="Sikeres dokumentum leválasztás" />
)

export const AlertSuccessDokumentDeleteMessage = (
  <FormattedMessage id="alerts.successMessage.dokumentDelete" defaultMessage="Sikeres dokumentum törlés" />
)
