import React from 'react'

import { CircularProgress, InputAdornment } from '@material-ui/core'
import { useIntl } from 'react-intl'

import { UpdateIcon } from '@oldComponents/ui'

import { exchangeRateDateMessage } from '@messages'
import { RefreshIconButton } from './styles'

interface FieldRefreshAdornmentProps {
  disabled: boolean
  loading: boolean
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export function FieldRefreshAdornment({ disabled, loading, onClick }: FieldRefreshAdornmentProps) {
  const { formatMessage } = useIntl()

  if (disabled) {
    return null
  }

  return (
    <InputAdornment position="end">
      {loading ? (
        <CircularProgress color="secondary" size={16} />
      ) : (
        <RefreshIconButton onClick={onClick} title={formatMessage(exchangeRateDateMessage)}>
          <UpdateIcon size={16} color="currentColor" />
        </RefreshIconButton>
      )}
    </InputAdornment>
  )
}
