import React from 'react'

import { useIntl } from 'react-intl'
import Select, {
  ActionMeta,
  GroupBase,
  InputActionMeta, // eslint-disable-line @typescript-eslint/no-unused-vars
  MenuListProps,
  MultiValue,
  OnChangeValue,
  SelectComponentsConfig,
  SelectInstance,
  SingleValue,
} from 'react-select'
import CreatableSelect from 'react-select/creatable'

import { usePromptTextCreator } from '@hooks'

import { Typography } from '@components/ui/Typography'
import { WindowedMenuList } from '@oldComponents/ui/WindowedMenuList'

import {
  CustomReactSelectOption,
  CustomReactSelectOptionProps, // eslint-disable-line @typescript-eslint/no-unused-vars
} from '../elements'
import { useIsValidNewOption } from '../useIsValidNewOption'
import { BaseSelectInputProps, DefaultSelectInputOption, OptionKeys } from './types'

import { selectFormStyles } from '@styles'
import { formSelectMessages } from '@messages'

/**
 * Make the id compatible with css selector. Remove square brackets ([ ]) and replace with hyphen (-).
 * Since CSS and DOM APIs interpret square brackets as attribute selectors,
 * trying to query an element by an id like custom_fields[0].value using queryByAttribute will not work correctly.
 *
 * @param {string} id - id comes from name and converted to css selector compatible id
 * @returns - css selector compatible id
 */
function makeCssSelectorCompatibleId(id: string) {
  // replace "[0]"" to "-0-"otherwise query in react-testing-library will fail
  return id.replace(/\[(\d+)\]\./g, '-$1-')
}

/**
 * SelectInput component is a customizable select input field that supports both single and multi-select options.
 * It can also handle creatable options, allowing users to add new options dynamically.
 *
 * @template Option - The type of the options used in the select input.
 *
 * @param {BaseSelectInputProps<Option>} props - The properties for the SelectInput component.
 * @param {string} [props.className] - (Optional) Additional class name for the select input.
 * @param {string} [props.inputValue] - (Optional) The current input value of the select input.
 * @param {boolean} [props.isClearable=false] - (Optional) Indicates if the select input is clearable. (Default: false)
 * @param {boolean} [props.isDisabled=false] - (Optional) Indicates if the select input is disabled. (Default: false)
 * @param {boolean} [props.isLoading=false] - (Optional) Indicates if the select input is loading. (Default: false)
 * @param {boolean} [props.isMulti=false] - (Optional) Indicates if the select input supports multiple selections. (Default: false)
 * @param {OptionKeys} [props.labelKey='name'] - (Optional) The key used to display the label of the options. (Default: 'name')
 * @param {boolean} [props.menuShouldBlockScroll=false] - (Optional) Indicates if the menu should block scroll. (Default: false)
 * @param {string} props.name - The name of the select input.
 * @param {string} [props.noResultsText] - (Optional) Custom text to display when no results are found.
 * @param {VoidFunction} [props.onFocus] - (Optional) Callback function to handle focus event.
 * @param {VoidFunction} [props.onBlur] - (Optional) Callback function to handle blur event.
 * @param {(value: any) => void} props.onChange - Callback function to handle change event.
 * @param {(name: string, value: any) => void} [props.onChangeCallback] - (Optional) Additional callback function to handle change event.
 * @param {(payload: Option, selectRef: SelectInstance<Option, boolean, GroupBase<Option>>) => void} [props.onCreate] - (Optional) Callback function to handle creation of new options.
 * @param {string} [props.onEmptyCreateText] - (Optional) Custom text to display when creating an empty option.
 * @param {(newValue: string, actionMeta: InputActionMeta) => void} [props.onInputChange] - (Optional) Callback function to handle input change event.
 * @param {(props: CustomReactSelectOptionProps<Option>) => JSX.Element} [props.OptionComponent=CustomReactSelectOption] - (Optional) Custom component to render options. (Default: CustomReactSelectOption)
 * @param {OptionsOrGroups<Option, GroupBase<Option>>} props.selectOptions - The list of options for the select input.
 * @param {string} [props.placeholder] - (Optional) Custom placeholder text for the select input.
 * @param {boolean} [props.useWindowedMenuList] - (Optional) Indicates if the select input should use a windowed menu list.
 * @param {any} props.value - The current value of the select input.
 * @param {OptionKeys} [props.valueKey='id'] - (Optional) The key used to identify the value of the options. (Default: 'id')
 *
 * @returns {JSX.Element} The rendered SelectInput component.
 */
export function BaseSelectInput<Option extends DefaultSelectInputOption>({
  className,
  inputValue,
  isClearable = false,
  isDisabled = false,
  isLoading = false,
  isMulti = false,
  labelKey = 'name',
  menuShouldBlockScroll = false,
  name,
  noResultsText,
  onBlur,
  onFocus,
  onChange,
  onChangeCallback,
  onCreate,
  onEmptyCreateText,
  onInputChange,
  OptionComponent = CustomReactSelectOption,
  placeholder: placeholderProps,
  selectOptions,
  selectValue,
  useWindowedMenuList = false,
  valueKey = 'id',
}: BaseSelectInputProps<Option>) {
  const { formatMessage } = useIntl()

  const selectRef = React.useRef<SelectInstance<Option, boolean, GroupBase<Option>>>(null)

  const promptTextCreator = usePromptTextCreator()

  const isValidNewOption = useIsValidNewOption(labelKey)

  const isCreatable = Boolean(onCreate)
  const SelectComponent = isCreatable ? CreatableSelect : Select

  //* customize react-select components
  const componentOverridesProp = React.useMemo(() => {
    const components: SelectComponentsConfig<Option, boolean, GroupBase<Option>> = {
      Option: OptionComponent,
    }

    // use react-window when has more than 9 options
    if (useWindowedMenuList) {
      components.MenuList = WindowedMenuList as React.ComponentType<MenuListProps<Option, boolean, GroupBase<Option>>> // still a JS component
    }

    return components
  }, [OptionComponent, useWindowedMenuList])

  //* react-select getters
  const noOptionsMessage = React.useCallback(() => {
    if (isCreatable && !selectOptions.length && onEmptyCreateText) {
      return (
        <Typography size="400-xs" color="inherit" tag="span">
          {onEmptyCreateText}
        </Typography>
      )
    }
    return noResultsText ?? formatMessage(formSelectMessages.selectNoResultsText)
  }, [formatMessage, isCreatable, noResultsText, onEmptyCreateText, selectOptions.length])

  const getOptionLabel = React.useCallback(option => option[labelKey], [labelKey])

  const getOptionValue = React.useCallback(option => option[valueKey], [valueKey])

  const getNewOptionData = React.useCallback(
    (inputValue: string, optionLabel: React.ReactNode) =>
      ({
        [valueKey]: inputValue,
        [labelKey]: optionLabel,
      } as Option),
    [labelKey, valueKey]
  )

  //* custom functions
  const handleCreateOption = React.useCallback(
    newValue => {
      if (selectRef.current) {
        onCreate?.({ [labelKey]: newValue } as Option, selectRef.current)
      }
    },
    [labelKey, onCreate, selectRef]
  )

  const handleChange = React.useCallback(
    onChange =>
      (selectedOption: OnChangeValue<Option, boolean>, { action, option }: ActionMeta<Option>) => {
        if (isMulti) {
          switch (action) {
            case 'select-option': {
              // check "needToCreate" property of option (only exists in multi select-option action):
              // true - new recommendation call onCreate
              // false - existing recommendation call onChange
              // simple options has no "needToCreate" property
              if (option && (option as Option)['needToCreate']) {
                handleCreateOption((option as Option)[valueKey])
              } else {
                const inputValue: MultiValue<Option> = (selectedOption as MultiValue<Option>).map(el => el[valueKey])
                onChange(inputValue)
                onChangeCallback?.(name, inputValue)
              }
              break
            }
            case 'remove-value': {
              const inputValue: MultiValue<Option> = (selectedOption as MultiValue<Option>)
                .map(el => el[valueKey])
                .filter(a => a)
              onChange(inputValue)
              onChangeCallback?.(name, inputValue)
              break
            }
            case 'clear': {
              onChange([])
              onChangeCallback?.(name, [] as MultiValue<Option>)
              break
            }
            default:
              break
          }
        } else {
          switch (action) {
            case 'select-option':
              // check "needToCreate" property of selected option:
              // true - new recommendation call onCreate
              // false - existing recommendation call onChange
              // simple options has no "needToCreate" property
              if (selectedOption && (selectedOption as Option)['needToCreate']) {
                handleCreateOption((selectedOption as Option)[valueKey])
              } else {
                const inputValue: SingleValue<Option[OptionKeys]> = (selectedOption as Option)[valueKey] ?? null
                onChange(inputValue)
                onChangeCallback?.(name, inputValue)
              }
              break
            case 'remove-value': {
              const inputValue: SingleValue<Option[OptionKeys]> = (selectedOption as Option)[valueKey] ?? null
              onChange(inputValue)
              onChangeCallback?.(name, inputValue)
              break
            }
            case 'clear':
              onChange(null)
              onChangeCallback?.(name, null)
              break
            default:
              break
          }
        }
      },
    [handleCreateOption, isMulti, name, onChangeCallback, valueKey]
  )

  const placeholder = React.useMemo(
    () =>
      placeholderProps ??
      (isCreatable
        ? formatMessage(formSelectMessages.creatableSelectPlaceholder)
        : formatMessage(formSelectMessages.selectPlaceholder)),
    [placeholderProps, isCreatable, formatMessage]
  )

  return (
    <SelectComponent
      className={className}
      classNamePrefix="react-select"
      components={componentOverridesProp}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      inputId={makeCssSelectorCompatibleId(name)}
      inputValue={inputValue}
      instanceId={`rs-${name}`}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isMulti={isMulti}
      menuPortalTarget={document.body}
      menuShouldBlockScroll={menuShouldBlockScroll}
      noOptionsMessage={noOptionsMessage}
      onBlur={onBlur}
      onChange={handleChange(onChange)}
      onFocus={onFocus}
      onInputChange={onInputChange}
      options={selectOptions}
      placeholder={placeholder}
      ref={selectRef}
      value={selectValue}
      // only for CreatableSelect
      formatCreateLabel={promptTextCreator}
      getNewOptionData={getNewOptionData}
      isLoading={isLoading}
      isValidNewOption={isValidNewOption}
      onCreateOption={handleCreateOption}
      // custom style
      styles={selectFormStyles}
    />
  )
}
