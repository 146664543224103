import { AcountoCreateIntegrationPayload } from '@oldComponents/settingsBlocks/IntegrationsBlock/acounto/types'
import { BillingoCreateIntegrationPayload } from '@oldComponents/settingsBlocks/IntegrationsBlock/billingo/types'
import { PayeeCreateIntegrationPayload } from '@oldComponents/settingsBlocks/IntegrationsBlock/payee/types'
import { VasfalaCreateIntegrationPayload } from '@oldComponents/settingsBlocks/IntegrationsBlock/vasfala/types'

import onboardingActions from '../onboarding/actions'
import subscriptionActions from '../subscription/actions'
import actions from './actions'

export type BackendAuthMeResponse = {
  companies: BackendCompaniesItem[]
  user: User
}

export type RefreshCompanyPayload = Nullable<Company['id']> | undefined

export type AuthInitialState = Omit<AuthStore, 'user' | 'company'> & {
  user: Nullable<User>
  company: CommonFetchProps & {
    data: Nullable<Company>
  }
}

export type CreateIntegrationProviderPayload =
  | AcountoCreateIntegrationPayload
  | BillingoCreateIntegrationPayload
  | PayeeCreateIntegrationPayload
  | VasfalaCreateIntegrationPayload

// typeguard
export function isAuth2FactorResponse(response: AuthResponse): response is Auth2FactorResponse {
  return (response as Auth2FactorResponse).need_verification === true
}

export type AuthReducerAction =
  | { type: typeof actions.login.SUCCESS | typeof actions.signup.SUCCESS | typeof actions.acceptInvitation.SUCCESS }
  | { type: typeof actions.loadInvitationDetails.SUCCESS; payload: InvitationData }
  | { type: typeof actions.loadInvitationDetails.FAILURE; payload: BackendError }
  | { type: typeof actions.logout.SUCCESS }
  | { type: typeof actions.renewToken.REQUEST }
  | { type: typeof actions.renewToken.SUCCESS }
  | { type: typeof actions.updateBadgeDisplayedAt.SUCCESS | typeof actions.updatePersonalInfo.SUCCESS; payload: User }
  | { type: typeof actions.changePreferences.SUCCESS; payload: UserPreferences }
  | { type: typeof actions.fetchUser.SUCCESS; payload: BackendAuthMeResponse }
  | { type: typeof actions.fetchUserCompanies.SUCCESS; payload: UserCompany[] }
  | { type: typeof actions.createCompany.SUCCESS; payload: BackendCompaniesItem }
  | {
      type: typeof actions.updateCompany.SUCCESS | typeof actions.updateCompanyField.SUCCESS
      payload: BackendCompaniesItem
    }
  | { type: typeof actions.removeCompany.SUCCESS; payload: number }
  | {
      type: typeof subscriptionActions.cancelSubscription.SUCCESS
      payload: { companyId: number; subscription: CompanySubscription }
    }
  | { type: typeof actions.setActiveSubscription.REQUEST; payload: CompanySubscription }
  | { type: typeof actions.selectCompany.REQUEST; payload: Company['id'] }
  | { type: typeof actions.selectCompany.FAILURE; payload: BackendError }
  | { type: typeof actions.selectCompany.SUCCESS; payload: BackendCompaniesItem }
  | { type: typeof actions.fetchCompany.SUCCESS; payload: BackendCompaniesItem }
  | { type: typeof actions.removeCompanyMembership.SUCCESS; payload: number }
  | { type: typeof actions.updateNotificationDisplayedAt.SUCCESS; payload: { id: number } }
  | {
      type: typeof actions.fetchCompanyMembers.SUCCESS
      payload: { company_user_template: CompanyUser; users: CompanyUser[] }
    }
  | { type: typeof onboardingActions.fetchOnboardingInviteApprovals.SUCCESS; payload: CompanyUserInvitation[] }
  | { type: typeof actions.createCompanyMember.SUCCESS; payload: CompanyUser }
  | { type: typeof actions.updateCompanyMember.SUCCESS; payload: CompanyUser }
  | { type: typeof actions.removeCompanyMember.SUCCESS; payload: number }
  | { type: typeof onboardingActions.declineOnboardingInvite.SUCCESS; payload: string } // TODO check is it string or number?
  | { type: typeof actions.fetchCompanyVats.REQUEST }
  | { type: typeof actions.fetchCompanyVats.SUCCESS; payload: CompanyVatType[] }
  | { type: typeof actions.fetchCompanyVats.FAILURE; payload: BackendError }
  | { type: typeof actions.createCompanyVat.SUCCESS; payload: CompanyVatType }
  | { type: typeof actions.updateCompanyVat.SUCCESS; payload: CompanyVatType }
  | { type: typeof actions.removeCompanyVat.SUCCESS; payload: number }
  | { type: typeof actions.createIntegrationProvider.SUCCESS; payload: CompanyIntegration }
  | { type: typeof actions.expiredToken.REQUEST }
  | {
      type: typeof actions.callCustomFieldUrl.SUCCESS | typeof actions.callCustomFieldRemoveUrl.SUCCESS
      payload: ExpenseCustomFieldConfig[]
    }
