import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { isAccountingFetaureEnabledForUser, StrongTextValues } from '@helpers'

import { FormBlock, FormBlockTitle, Typography } from '@components/ui'

import { FeaturePermissons } from '@permissions'

import { FormattedVatPeriodDate } from './FormattedVatPeriodDate'

const StyledTypography = styled(Typography)`
  margin-top: 1rem;
`

interface ExpenseDetailsAccountingBlockProps {
  accountingId: Nullable<string> | undefined
  isAccountingIdEnabled: boolean
  isVatPeriodEnabled: boolean
  vatPeriodStartedAt: Nullable<string> | undefined
  vatPeriodType: Nullable<VatPeriodType> | undefined
}

function PureExpenseDetailsAccountingBlock({
  accountingId,
  isAccountingIdEnabled,
  isVatPeriodEnabled,
  vatPeriodStartedAt,
  vatPeriodType,
}: ExpenseDetailsAccountingBlockProps) {
  if (!isAccountingIdEnabled && !isVatPeriodEnabled) {
    return null
  }

  return (
    <FormBlock>
      <FormBlockTitle>
        <FormattedMessage id="form.expense.accounting.title" defaultMessage="Könyvelés" />
      </FormBlockTitle>
      {isAccountingIdEnabled && (
        <StyledTypography size="400-sm" color="inherit">
          {accountingId ? (
            <FormattedMessage
              id="form.expense.accountingId"
              defaultMessage="A számlához tartozó könyvelési azonosító: <b>{value}</b>."
              values={{ ...StrongTextValues, value: accountingId }}
            />
          ) : (
            <FormattedMessage
              id="form.expense.accountingId.noData"
              defaultMessage="A számlához még nem tartozik könyvelési azonosító."
            />
          )}
        </StyledTypography>
      )}
      {isVatPeriodEnabled && (
        <StyledTypography size="400-sm" color="inherit">
          <FormattedMessage
            id="form.expense.vatPeriod"
            defaultMessage="ÁFA időszak: {value}."
            values={{
              value:
                vatPeriodType && vatPeriodStartedAt ? (
                  <FormattedVatPeriodDate value={vatPeriodStartedAt} type={vatPeriodType} />
                ) : (
                  <FormattedMessage id="form.expense.accountingBlock.vatPeriod.noData" defaultMessage="nincs megadva" />
                ),
            }}
          />
        </StyledTypography>
      )}
    </FormBlock>
  )
}

export const ExpenseDetailsAccountingBlock = connect((state: Store) => ({
  accountingId: state.expense.details.meta.accounting_id,
  isAccountingIdEnabled: isAccountingFetaureEnabledForUser(state, FeaturePermissons.ACCOUNTING_ID),
  isVatPeriodEnabled: isAccountingFetaureEnabledForUser(state, FeaturePermissons.VAT_PERIOD),
  vatPeriodStartedAt: state.expense.details.meta.vat_period_started_at,
  vatPeriodType: state.expense.details.meta.vat_period_type,
}))(PureExpenseDetailsAccountingBlock)

ExpenseDetailsAccountingBlock.displayName = 'ExpenseDetailsAccountingBlock'
