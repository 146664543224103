import { AxiosResponse } from 'axios'
import Qs from 'qs'

import { injectCompanyToURL } from '@helpers'

import Api from '../api'
import { API_MONTHLY_STATEMENT_BACKGROUND_ACTION_URL, V2_API_MONTHLY_STATEMENT_ANALYTICS_URL } from './urls'

export function fetchMonthlyStatementAnalytics(
  companyId: number,
  params: MonthlyStatementStoreFilters
): Promise<AxiosResponse<BackendMonthlyStatementResult>> {
  return Api.get(injectCompanyToURL(V2_API_MONTHLY_STATEMENT_ANALYTICS_URL, companyId), {
    params,
    paramsSerializer: {
      serialize(params) {
        return Qs.stringify(params, { indices: false })
      },
    },
  })
}

export function monthlyStatementBackgroundAction(companyId: number, data: BackgroundActionPayload) {
  return Api.post<BackgroundActionResponse>(
    injectCompanyToURL(API_MONTHLY_STATEMENT_BACKGROUND_ACTION_URL, companyId),
    data
  )
}
