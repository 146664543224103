import { AxiosResponse } from 'axios'
import { call, delay, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { getActiveCompanyId, getErrorMessage, transformWorkoutFiltersToParams } from '@helpers'

import { TYPING_INTERVAL } from '@constants'

import actions from './actions'
import * as api from './api'

function* fetchReportsSaga({ meta: { resolve, reject } }: AsyncSagaAction<void>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse = yield call(api.fetchReports, companyId)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* fetchWorkoutReportSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<{
  partner?: string | undefined
  cluster: {
    value: {
      min: number
      max: number
    }
  }
}>) {
  try {
    yield delay(TYPING_INTERVAL)
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const params = transformWorkoutFiltersToParams(payload)
    const response: AxiosResponse = yield call(api.fetchWorkoutReport, companyId, params)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* fetchWorkoutDetailsReportSaga({
  payload: { partnerId, cluster },
  meta: { resolve, reject },
}: AsyncSagaAction<{ partnerId: number; cluster: { value: { min: number; max: number } } }>) {
  try {
    yield delay(TYPING_INTERVAL)
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const params = transformWorkoutFiltersToParams({ cluster })
    const response: AxiosResponse = yield call(api.fetchWorkoutDetailsReport, companyId, partnerId, params)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* createPayeeWorkflowSaga({
  payload: { partnerId, params },
  meta: { resolve, reject },
}: AsyncSagaAction<{ partnerId: number; params: Record<string, unknown> }>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse = yield call(api.createPayeeWorkflow, companyId, partnerId, params)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* fetchPartnerDetailsSaga({
  payload: { partnerId, params },
  meta: { resolve, reject },
}: AsyncSagaAction<{ partnerId: number; params: Record<string, unknown> }>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse = yield call(api.fetchPartnerDetails, companyId, partnerId, params)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* fetchVatPositionReportSaga({ meta: { resolve, reject } }: AsyncSagaAction<void>) {
  try {
    yield delay(TYPING_INTERVAL)
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse = yield call(api.fetchVatPositionReport, companyId)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* fetchBankTransactionsReportSaga({ meta: { resolve, reject } }: AsyncSagaAction<void>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse = yield call(api.fetchBankTransactionsReport, companyId)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* fetchAiChatDetailsSaga({ meta: { resolve, reject } }: AsyncSagaAction<void>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse = yield call(api.fetchAiChatDetails, companyId)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// watcher Saga
export default function* commonSaga() {
  yield takeLatest(actions.fetchReports.REQUEST, fetchReportsSaga)
  yield takeLatest(actions.fetchWorkoutReport.REQUEST, fetchWorkoutReportSaga)
  yield takeEvery(actions.fetchWorkoutDetailsReport.REQUEST, fetchWorkoutDetailsReportSaga)
  yield takeLatest(actions.createPayeeWorkflow.REQUEST, createPayeeWorkflowSaga)
  yield takeLatest(actions.fetchPartnerDetails.REQUEST, fetchPartnerDetailsSaga)
  yield takeLatest(actions.fetchVatPositionReport.REQUEST, fetchVatPositionReportSaga)
  yield takeLatest(actions.fetchBankTransactionsReport.REQUEST, fetchBankTransactionsReportSaga)
  yield takeLatest(actions.fetchAiChatDetails.REQUEST, fetchAiChatDetailsSaga)
}
