import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'

import { ButtonWithIcon } from './Buttons'
import { RightArrowIcon } from './svgIcons'

interface JumpToDetailsLinkButtonProps {
  children: StringOrMessage
  detailsUrl: string
}

export function JumpToDetailsLinkButton({ children, detailsUrl }: JumpToDetailsLinkButtonProps) {
  const location = useLocation()
  return (
    <ButtonWithIcon
      variant="secondaryContained"
      size="small"
      icon={<RightArrowIcon />}
      forwardedAs={function CustomLink({ children, className }) {
        return (
          <Link className={className} to={detailsUrl} state={{ previousPath: location.pathname }}>
            {children}
          </Link>
        )
      }}
    >
      {children}
    </ButtonWithIcon>
  )
}

JumpToDetailsLinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  detailsUrl: PropTypes.string.isRequired,
}

export function JumpToInvoiceDetailsLinkButton({ detailsUrl }: Pick<JumpToDetailsLinkButtonProps, 'detailsUrl'>) {
  return (
    <JumpToDetailsLinkButton detailsUrl={detailsUrl}>
      <FormattedMessage id="document.preview.linkToInvoiceDetails" defaultMessage="Ugrás a számlarészletekhez" />
    </JumpToDetailsLinkButton>
  )
}
