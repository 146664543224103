import React from 'react'

import { useController } from 'react-hook-form'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import { ReactHookFormCheckboxField, Typography } from '@components/ui'

import { PaidThroughFormValues } from '../types'

import messages from '../messages'
import { CheckboxLabelSpan } from '../styles'

const StyledReactHookFormCheckboxField = styled(ReactHookFormCheckboxField)`
  margin: 0;
  border-radius: 6px;
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.colors.primary[10]};
  width: 214px;

  label {
    margin: 0;
    padding: 0;
    align-items: center;

    .MuiIconButton-root {
      width: 30px;
      height: 30px;
      margin-top: 0;
      margin-left: 0;
      transform: none;
    }
  }
`

const IsAutokasszaCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

function CustomError() {
  const {
    fieldState: { error, isTouched },
  } = useController<PaidThroughFormValues>({
    name: 'is_autokassza',
  })

  return (
    <>
      {error?.message && isTouched && (
        <Typography color="error" size="400-sm">
          {error?.message}
        </Typography>
      )}
    </>
  )
}

export function IsAutokasszaCheckbox() {
  const { formatMessage } = useIntl()

  return (
    <IsAutokasszaCheckboxWrapper>
      <StyledReactHookFormCheckboxField
        hideError
        label={<CheckboxLabelSpan>{formatMessage(messages.autoCashRegisterLabel)}</CheckboxLabelSpan>}
        name="is_autokassza"
      />
      <CustomError />
    </IsAutokasszaCheckboxWrapper>
  )
}
