import { injectCompanyToURL } from '@helpers'

import Api from '../api'
import {
  API_LIQUIDITY_INCOMES_URL,
  API_LIQUIDITY_INVOICE_DETAIL_URL,
  API_LIQUIDITY_INVOICES_URL,
  API_LIQUIDITY_PREDICTED_INCOME_DETAIL_URL,
  API_LIQUIDITY_PREDICTED_INCOMES_URL,
  API_LIQUIDITY_PREDICTED_INVOICE_DETAIL_URL,
  API_LIQUIDITY_PREDICTED_INVOICES_URL,
  API_LIQUIDITY_PREDICTED_SALARIES_URL,
  API_LIQUIDITY_TAXES_URL,
  API_LIQUIDITY_URL,
  API_TAX_DETAILS_URL,
} from './urls'

export function loadLiquidityData(companyId: Company['id']) {
  return Api.get(injectCompanyToURL(API_LIQUIDITY_URL, companyId))
}

export function saveLiquidityData(companyId: Company['id'], data: unknown) {
  return Api.post(injectCompanyToURL(API_LIQUIDITY_URL, companyId), data)
}

// load weekly data
export function loadLiquidityInvoices(companyId: Company['id'], { year, week }: { year: number; week: number }) {
  return Api.get(
    injectCompanyToURL(API_LIQUIDITY_INVOICES_URL, companyId)
      .replace('{year}', String(year))
      .replace('{week}', String(week))
  )
}

export function loadLiquidityPredictedInvoices(
  companyId: Company['id'],
  { year, week }: { year: number; week: number }
) {
  return Api.get(
    injectCompanyToURL(API_LIQUIDITY_PREDICTED_INVOICES_URL, companyId)
      .replace('{year}', String(year))
      .replace('{week}', String(week))
  )
}

export function loadLiquidityPredictedIncomes(
  companyId: Company['id'],
  { year, week, params }: { year: number; week: number; params: unknown }
) {
  return Api.get(
    injectCompanyToURL(API_LIQUIDITY_PREDICTED_INCOMES_URL, companyId)
      .replace('{year}', String(year))
      .replace('{week}', String(week)),
    { params }
  )
}

// update
export function updateInvoice(
  companyId: Company['id'],
  { id, planned_payment_date }: { id: number; planned_payment_date: string }
) {
  return Api.patch(injectCompanyToURL(API_LIQUIDITY_INVOICE_DETAIL_URL, companyId).replace('{id}', String(id)), {
    planned_payment_date,
  })
}

export function updateIncome(
  companyId: Company['id'],
  { id, planned_payment_date }: { id: number; planned_payment_date: string }
) {
  return Api.patch(
    injectCompanyToURL(API_LIQUIDITY_PREDICTED_INCOME_DETAIL_URL, companyId).replace('{id}', String(id)),
    {
      planned_payment_date,
    }
  )
}

// remove
export function removePredictedInvoice(
  companyId: Company['id'],
  { id, year, week }: { id: number; year: number; week: number }
) {
  return Api.delete(
    injectCompanyToURL(API_LIQUIDITY_PREDICTED_INVOICE_DETAIL_URL, companyId)
      .replace('{year}', String(year))
      .replace('{week}', String(week))
      .replace('{id}', String(id))
  )
}

// check: is SzamlazzHU / Billingo connected?
export function fetchLiquidityIncomes(companyId: Company['id']) {
  return Api.get(injectCompanyToURL(API_LIQUIDITY_INCOMES_URL, companyId))
}

// salary
export function loadLiquidityPredictedWages(companyId: Company['id'], { year, week }: { year: number; week: number }) {
  return Api.get(
    injectCompanyToURL(API_LIQUIDITY_PREDICTED_SALARIES_URL, companyId)
      .replace('{year}', String(year))
      .replace('{week}', String(week))
  )
}

export function removePredictedSalary(companyId: Company['id'], { year, week }: { year: number; week: number }) {
  return Api.delete(
    injectCompanyToURL(API_LIQUIDITY_PREDICTED_SALARIES_URL, companyId)
      .replace('{year}', String(year))
      .replace('{week}', String(week))
  )
}

// tax
export function loadLiquidityTaxes(companyId: Company['id'], { year, week }: { year: number; week: number }) {
  return Api.get(
    injectCompanyToURL(API_LIQUIDITY_TAXES_URL, companyId)
      .replace('{year}', String(year))
      .replace('{week}', String(week))
  )
}

export function updateTax(companyId: Company['id'], { id, ...data }: { id: number; [key: string]: number | unknown }) {
  return Api.patch(injectCompanyToURL(API_TAX_DETAILS_URL, companyId).replace('{tax_id}', String(id)), data)
}

// NOTE not used yet
export function removePredictedTax(companyId: Company['id'], { year, week }: { year: number; week: number }) {
  return Api.delete(
    injectCompanyToURL(API_LIQUIDITY_TAXES_URL, companyId)
      .replace('{year}', String(year))
      .replace('{week}', String(week))
  )
}
