import React from 'react'

import { FormControl, FormHelperText, InputLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import __isArray from 'lodash/isArray'
import __isObject from 'lodash/isObject'
import { FieldRenderProps } from 'react-final-form'

import { DefaultSelectInputOption, SelectInput, SelectInputProps } from '@components/ui/FormElements/SelectInputs'

import { formStyles } from '@styles'

const useStyles = makeStyles(formStyles)

interface RenderSelectProps<Option extends DefaultSelectInputOption>
  extends Pick<FieldRenderProps<string | number | string[] | number[] | object>, 'meta' | 'input'>,
    Pick<SelectInputProps<Option>, 'options' | 'placeholder' | 'labelKey' | 'valueKey' | 'isMulti' | 'isDisabled'> {
  required?: boolean
  inputClassName?: string
  label?: React.ReactNode
  labelClassName?: string
}

/**
 * RenderFormFieldSelect component renders a form control with a select input field.
 * It supports both single and multi-select options and handles various states such as errors and disabled state.
 *
 * @template Option - The type of the options used in the select input.
 *
 * @param {RenderSelectProps<Option>} props - The properties for the RenderFormFieldSelect component.
 *
 * @group FieldRenderProps
 * @param {FieldInputProps<any>} props.input - Input properties provided by the form library.
 * @param {string} props.input.name - The name of the select input.
 * @param {VoidFunction} [props.input.onFocus] - (Optional) Callback function to handle focus event.
 * @param {(value: any) => void} props.input.onChange - Callback function to handle change event.
 * @param {any} props.input.value - The current value of the select input.
 * @param {FieldMetaState<any>} props.meta - Meta properties provided by the form library.
 * @param {boolean} [props.meta.dirtySinceLastSubmit] - (Optional) Indicates if the field is dirty since the last submit.
 * @param {string | object} [props.meta.error] - (Optional) Error message or object for the field.
 * @param {string | object} [props.meta.submitError] - (Optional) Submit error message or object for the field.
 * @param {boolean} [props.meta.touched] - (Optional) Indicates if the field has been touched.
 * @param {boolean} [props.meta.modifiedSinceLastSubmit] - (Optional) Indicates if the field has been modified since the last submit.
 *
 * @group SelectInputProps
 * @param {Option[]} props.options - The list of options for the select input.
 * @param {string} [props.placeholder] - (Optional) Custom placeholder text for the select input.
 * @param {Keys} [props.labelKey='name'] - (Optional) The key used to display the label of the options. (Default: 'name')
 * @param {Keys} [props.valueKey='id'] - (Optional) The key used to identify the value of the options. (Default: 'id')
 * @param {boolean} [props.isMulti=false] - (Optional) Indicates if the select input supports multiple selections. (Default: false)
 * @param {boolean} [props.isDisabled=false] - (Optional) Indicates if the select input is disabled. (Default: false)
 *
 * @group OwnProps
 * @param {boolean} [props.required=false] - (Optional) Indicates if the field is required. (Default: false)
 * @param {string} [props.inputClassName] - (Optional) Additional class name for the select input container.
 * @param {React.ReactNode} [props.label] - (Optional) Label text for the select input.
 * @param {string} [props.labelClassName] - (Optional) Additional class name for the label.
 *
 * @returns {JSX.Element} The rendered RenderFormFieldSelect component.
 */
export function RenderFormFieldSelect<Option extends DefaultSelectInputOption>({
  input: { onChange, onFocus, onBlur, name, value },
  inputClassName,
  isDisabled,
  label,
  labelClassName,
  meta: { dirtySinceLastSubmit, error, submitError, touched, modifiedSinceLastSubmit },
  required,
  ...rest
}: RenderSelectProps<Option>) {
  const classes = useStyles()

  const displayedError = touched && (error || (!dirtySinceLastSubmit && !modifiedSinceLastSubmit && submitError))
  const hasError = Boolean(displayedError)

  return (
    <FormControl
      fullWidth
      margin="normal"
      className={cx(classes.selectRoot, 'form-control', { 'form-control-error': hasError })}
      error={hasError}
      disabled={isDisabled}
      required={required}
    >
      {label && (
        <InputLabel htmlFor={name} shrink className={cx(classes.bootstrapFormLabel, labelClassName)}>
          {label}
        </InputLabel>
      )}
      <div className={cx(classes.selectInput, inputClassName)}>
        <SelectInput
          className={cx({ error: hasError })}
          isDisabled={isDisabled}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          value={value}
          {...rest}
        />
      </div>
      {hasError && (
        <>
          {__isObject(displayedError) && !__isArray(displayedError) ? (
            Object.keys(displayedError).map(key => (
              <FormHelperText key={`${name}-${key}`}>
                {key}: {displayedError[key as keyof typeof displayedError]}
              </FormHelperText>
            ))
          ) : (
            <FormHelperText>{displayedError}</FormHelperText>
          )}
        </>
      )}
    </FormControl>
  )
}
