import React from 'react'
import PropTypes from 'prop-types'

import { FormControlLabel, Switch } from '@material-ui/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'

import { postitActions } from '@services'

import { bindActionToPromise, isAdmin, parseApiErrorMessage } from '@helpers'

import { useAlertDispatch } from '@contexts/AlertProvider'

import { AdvancedDeleteConfirmDialog } from '@components/dialogs'
import { AddPostItIcon, IconButton } from '@components/ui'
import { LightTooltip } from '@oldComponents/ui'

import { Note, PostItNote } from './PostItNote'

import { ControlsContainer, PostItViewport, SwitchWrapperDiv } from './styles'

const DEFAULT_X = 150
const DEFAULT_Y = 150
const DEFAULT_WIDTH = 200
const DEFAULT_HEIGHT = 80
const DELTA_OFFSET = 5

interface NoteData {
  created_at: string
  note: string
  pos_x: number
  pos_y: number
  user_email: string
}

type SerializerOptions = {
  user: {
    email: string
  }
  formatDate: (date: string, options: Intl.DateTimeFormatOptions) => string
}

function serializeNoteIntoData({ text, x, y, width, height, page, id }: Partial<Note>) {
  return {
    id,
    width,
    height,
    pos_x: x,
    pos_y: y,
    note: text,
    page,
  }
}

function serializeDataIntoNote(
  { note, pos_x, pos_y, user_email: owner, created_at, ...rest }: NoteData,
  { user, formatDate }: SerializerOptions
): Partial<Note> {
  return {
    ...rest,
    x: pos_x,
    y: pos_y,
    text: note,
    owner,
    created: formatDate(created_at, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }),
    editable: owner === user.email,
  }
}

interface PostItControllerRenderProps {
  renderControlButtons: () => Nullable<JSX.Element>
  renderNotes: (scale: number, page?: number) => Nullable<JSX.Element>
  pageRef: React.MutableRefObject<number>
}

interface PostItProps {
  children: (props: PostItControllerRenderProps) => JSX.Element
  createNote: AsyncFunction<
    { documentId: ItemIdType; data: Partial<Note>; documentType: ArtifactDocumentType },
    NoteData
  >
  documentId?: ItemIdType
  documentType: ArtifactDocumentType
  isAdminUser: boolean
  loadNotes: AsyncFunction<{ documentId: ItemIdType; documentType: ArtifactDocumentType }, NoteData[]>
  removeNote: AsyncFunction<{ documentId: ItemIdType; noteId: number; documentType: ArtifactDocumentType }>
  updateNote: AsyncFunction<
    { documentId: ItemIdType; data: Partial<Note>; documentType: ArtifactDocumentType },
    NoteData
  >
  user: User
  enabled: boolean
}

function PurePostItController({
  children,
  createNote,
  documentId,
  documentType,
  isAdminUser,
  loadNotes,
  removeNote,
  updateNote,
  user,
  user: {
    preferences: { show_post_it: showPostIt },
  },
  enabled,
}: PostItProps) {
  const [notes, setNotes] = React.useState<Note[]>([])
  const [visible, setVisible] = React.useState<boolean>(false)
  const pageRef = React.useRef<number>(1)
  const lastInsertPosition = React.useRef<{ x: number; y: number }>({ x: DEFAULT_X, y: DEFAULT_Y })
  const { formatDate } = useIntl()
  const { setErrorAlert } = useAlertDispatch()
  const serializerRef = React.useRef((d: NoteData) => serializeDataIntoNote(d, { user, formatDate }))
  const [deleteNote, setDeleteNote] = React.useState<Nullable<Note>>(null) // better name for this state?
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    let mounted = true
    async function load() {
      if (documentId) {
        try {
          const results = await loadNotes({ documentId, documentType })
          if (mounted) {
            setNotes(results.map(serializerRef.current) as Note[])
          }
        } catch (error) {
          console.error('Failed to fetch post-it', (error as Error).message)
        }
      }
    }
    if (enabled) {
      load()
    }

    return () => {
      mounted = false
    }
  }, [enabled, documentId, documentType, loadNotes])

  React.useEffect(() => {
    if (showPostIt && notes.length > 0) {
      // only apply showPostIt true if notes are not empty
      setVisible(true)
    }
  }, [showPostIt, notes])

  function toggle() {
    setVisible(value => !value)
  }

  async function handleCreateNote() {
    const { x: xOffset, y: yOffset } = lastInsertPosition.current
    if (documentId) {
      try {
        const data = {
          height: DEFAULT_HEIGHT,
          note: '',
          page: pageRef.current,
          pos_x: xOffset,
          pos_y: yOffset,
          width: DEFAULT_WIDTH,
        }
        const result = await createNote({ documentId, data, documentType })
        const resultNote = serializeDataIntoNote(result, { user, formatDate }) as Note
        setNotes([...notes, resultNote])
        // save position
        lastInsertPosition.current = {
          x: xOffset + DELTA_OFFSET,
          y: yOffset + DELTA_OFFSET,
        }
      } catch (error) {
        const errorMessage = parseApiErrorMessage(error)
        if (errorMessage) {
          setErrorAlert(errorMessage)
        }
      }
    }
  }

  const handleUpdateNote = React.useCallback(
    async (note: Note) => {
      if (documentId) {
        try {
          const data = serializeNoteIntoData(note)
          const result = await updateNote({ documentId, data, documentType })
          const resultNote = serializeDataIntoNote(result, { user, formatDate })
          setNotes(notes.map(n => (n.id === note.id ? resultNote : n)) as Note[])
        } catch (error) {
          const errorMessage = parseApiErrorMessage(error)
          if (errorMessage) {
            setErrorAlert(errorMessage)
          }
        }
      }
    },
    [documentId, documentType, formatDate, notes, setErrorAlert, updateNote, user]
  )

  const onDeleteRequest = React.useCallback((note: Note) => {
    setDeleteNote(note)
  }, [])

  const handleDeleteNote = React.useCallback(async () => {
    if (!deleteNote || !documentId) {
      return
    }

    setLoading(true)
    try {
      await removeNote({ documentId, noteId: deleteNote.id, documentType })
      setNotes(notes.filter(n => n.id !== deleteNote.id))
      setDeleteNote(null)
    } catch (error) {
      const errorMessage = parseApiErrorMessage(error)
      if (errorMessage) {
        setErrorAlert(errorMessage)
      }
    }
    setLoading(false)
  }, [deleteNote, documentId, documentType, notes, removeNote, setErrorAlert])

  const handleDialogClose = React.useCallback(() => {
    setDeleteNote(null)
  }, [])

  function renderControlButtons() {
    if (!enabled) {
      return null
    }

    return (
      <ControlsContainer>
        <LightTooltip
          title={<FormattedMessage id="postIt.buttons.addNew" defaultMessage="Megjegyzés hozzáadása" />}
          PopperProps={{ placement: 'top' }}
        >
          <div>
            <IconButton onClick={handleCreateNote} size="small" variant="primaryText" disabled={!visible}>
              <AddPostItIcon />
            </IconButton>
          </div>
        </LightTooltip>
        <SwitchWrapperDiv>
          <FormControlLabel
            control={<Switch color="primary" checked={visible} onChange={toggle} />}
            label={
              <FormattedMessage
                id="postit.toggleButton.withCount"
                defaultMessage="Megjegyzések ({count} db)"
                values={{ count: notes.length }}
              />
            }
          />
        </SwitchWrapperDiv>
      </ControlsContainer>
    )
  }

  const renderNotes = React.useCallback(
    (scale: number, page = 1) => {
      if (!enabled) {
        return null
      }

      return (
        <PostItViewport $visible={visible} style={{ '--scale-factor': scale }}>
          <div>
            {visible &&
              notes
                .filter((note: Note) => note.page === page)
                .map((note: Note) => (
                  <PostItNote
                    data={note}
                    isAdminUser={isAdminUser}
                    key={note.id}
                    onDelete={onDeleteRequest}
                    onEdit={handleUpdateNote}
                    scale={scale}
                  />
                ))}
          </div>
        </PostItViewport>
      )
    },
    [enabled, handleUpdateNote, isAdminUser, notes, onDeleteRequest, visible]
  )

  const isAdvancedDelete = deleteNote?.editable === false

  return (
    <>
      {children({ renderControlButtons, renderNotes, pageRef })}
      <AdvancedDeleteConfirmDialog
        forceToConfirm={isAdvancedDelete}
        id="note-delete-confirm-dialog"
        infoText={
          <FormattedMessage
            id="dialogs.deleteConfirm.note.infoText"
            defaultMessage="Ha a megjegyzést véglegesen törlöd, akkor később már nem fogod tudni visszaállítani."
          />
        }
        loading={loading}
        onClose={handleDialogClose}
        onConfirm={handleDeleteNote}
        open={Boolean(deleteNote)}
        subjectMessage={
          <FormattedMessage
            id="dialogs.deleteConfirm.note.subjectText"
            defaultMessage="Véglegesen törlődik: 1db megjegyzés"
          />
        }
        warningMessage={
          isAdminUser && isAdvancedDelete ? (
            <FormattedMessage
              id="dialogs.deleteConfirm.note.warningText"
              defaultMessage="A megjegyzést egy másik felhasználó adta hozzá a számlához."
            />
          ) : null
        }
      />
    </>
  )
}

PurePostItController.propTypes = {
  children: PropTypes.func.isRequired,
  createNote: PropTypes.func.isRequired,
  enabled: PropTypes.bool.isRequired,
  documentId: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
  documentType: PropTypes.oneOf(['expense', 'income']).isRequired as React.Validator<ArtifactDocumentType>,
  isAdminUser: PropTypes.bool.isRequired,
  loadNotes: PropTypes.func.isRequired,
  removeNote: PropTypes.func.isRequired,
  updateNote: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    preferences: PropTypes.shape({
      show_post_it: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired as React.Validator<User>,
}

export const PostItController = connect(
  (state: Store) => ({
    isAdminUser: isAdmin(state.auth.company.data.role),
    user: state.auth.user,
  }),
  dispatch => ({
    loadNotes: bindActionToPromise(dispatch, postitActions.fetchPostIt.request),
    createNote: bindActionToPromise(dispatch, postitActions.createPostIt.request),
    updateNote: bindActionToPromise(dispatch, postitActions.updatePostIt.request),
    removeNote: bindActionToPromise(dispatch, postitActions.removePostIt.request),
  })
)(PurePostItController)

PostItController.displayName = 'PostItController'
