import React from 'react'
import PropTypes from 'prop-types'

import { useController, useFormContext } from 'react-hook-form'

import { ReactHookFormAmountInput, ReactHookFormAmountInputProps } from './ReactHookFormAmountInput'

interface ReactHookFormAmountFieldProps
  extends Omit<ReactHookFormAmountInputProps, 'error' | 'helperText' | 'onChange' | 'value' | 'locale'> {
  onChange?: (name: string, value: string) => void
}

export function ReactHookFormAmountField({
  disabled,
  name,
  onChange: onChangeProp,
  ...rest
}: ReactHookFormAmountFieldProps) {
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext()
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control })

  const isFieldDisabled = disabled || isSubmitting

  const handleChange = React.useCallback(
    (inputValue: string) => {
      onChange?.(inputValue)
      onChangeProp?.(name, inputValue)
    },
    [name, onChange, onChangeProp]
  )

  return (
    <ReactHookFormAmountInput
      disabled={isFieldDisabled}
      error={!!error}
      helperText={error?.message ?? ''}
      name={name}
      onChange={handleChange}
      value={value}
      {...rest}
    />
  )
}

ReactHookFormAmountField.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}
