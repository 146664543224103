import { AxiosResponse } from 'axios'

import { callUrl } from '@services/common/api'

// JOB_STATUS_CREATED = 10
// const JOB_STATUS_PROCESSING = 20
export const JOB_STATUS_FINISHED = 30
export const JOB_STATUS_ERROR = 40

const JOB_FINISHED_STATUSES = [JOB_STATUS_FINISHED, JOB_STATUS_ERROR]

interface BackgroundActionProps {
  maxTries?: number
  calculateInterval?: (tries?: number) => number
  api: (companyId: number, payload: BackgroundActionPayload) => Promise<AxiosResponse<BackgroundActionResponse>>
}

const DEFAULT_CONFIG: Required<Omit<BackgroundActionProps, 'api'>> = {
  maxTries: 6,
  calculateInterval() {
    return 5 * 1000
  },
}

//* ################################################################
//* Process listener to handle backend's background job API response
//* Only works with v2 background action API
//* ################################################################
export class BackgroundAction {
  private maxTries
  private calculateInterval
  private api
  private tries = 0
  private resolve: ((value?: unknown) => void) | undefined
  private reject: ((reason?: unknown) => void) | undefined
  private timerRef: number | undefined
  private results: BackgroundActionResponse | undefined
  private sentInEmail = false

  constructor({
    maxTries = DEFAULT_CONFIG.maxTries,
    calculateInterval = DEFAULT_CONFIG.calculateInterval,
    api,
  }: BackgroundActionProps) {
    this.maxTries = maxTries
    this.calculateInterval = calculateInterval
    this.api = api
  }

  //* finish process and call sendInEmail link when needed
  stop = async () => {
    if (this.timerRef) {
      window.clearTimeout(this.timerRef)
    }

    // only call sendInEmail when job is not finished yet
    if (this.results && !JOB_FINISHED_STATUSES.includes(this.results.status)) {
      // last response
      if (this.results.sendEmail === false && !this.sentInEmail) {
        try {
          // url link exists at this point - its call v1 endpoint so keep "send_email" format
          const emailResponse = await callUrl<{ send_email: boolean }>({
            url: this.results.links.sendInEmail as string,
            method: 'post',
          })
          this.sentInEmail = true // to prevent multiple request
          this.resolve?.(emailResponse.data)
        } catch (error) {
          this.reject?.(error)
        }
      } else {
        // backend already sent results in email so do not ask again just resolve promise with the same flag
        this.resolve?.({ send_email: true })
      }
      return
    }

    // fallback only
    this.resolve?.()
  }

  //* check running background-job's status
  private run = async () => {
    // always have results at this point - only need for TS
    if (this.results) {
      try {
        // url link exists at this point
        const response = await callUrl<BackgroundActionResponse>({
          url: this.results.links.status as string,
          method: 'get',
        })
        this.responseHandler(response)
      } catch (error) {
        this.reject?.(error)
      }
    }
  }

  private responseHandler = (response: { data: BackgroundActionResponse }) => {
    this.results = response.data
    // bg job finished
    if (JOB_STATUS_FINISHED === this.results.status) {
      // when download link is filled call it to download file
      if (this.results.links.download) {
        window.location.assign(this.results.links.download) // WARNING: Resource interpreted as Document but transferred with MIME type application/zip
      }

      this.resolve?.(this.results)
    } else if (JOB_STATUS_ERROR === this.results.status) {
      // TODO check it - is it contains "message" key with string message?
      this.reject?.(this.results.errors)
    } else {
      // created and pending statuses
      if (this.tries >= this.maxTries) {
        this.stop()
      } else {
        this.tries++
        this.timerRef = window.setTimeout(this.run, this.calculateInterval(this.tries))
      }
    }
  }

  //* call backend to start the background-job
  private init = async (companyId: number, payload: BackgroundActionPayload) => {
    // init request > bgJob response
    try {
      const response = await this.api(companyId, payload)
      this.responseHandler(response)
    } catch (error) {
      this.reject?.(error)
    }
  }

  //* start process
  start = (companyId: number, payload: BackgroundActionPayload) => {
    this.tries = 0

    return new Promise((pResolve, pReject) => {
      this.resolve = pResolve
      this.reject = pReject

      this.init(companyId, payload)
    })
  }
}
