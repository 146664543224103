import { injectCompanyToURL } from '@helpers'

import { Note } from '@components/ArtifactViewer/elements/PostItNote'

import Api from '../api'
import { API_POST_IT_DETAIL_URL, API_POST_IT_LIST_URL, API_POST_IT_TOOLTIP_URL } from './urls'

export function fetchPostIt(
  companyId: Company['id'],
  { documentId, documentType }: { documentId: ItemIdType; documentType: ArtifactDocumentType }
) {
  return Api.get(
    injectCompanyToURL(API_POST_IT_LIST_URL, companyId)
      .replace('{document_type}', documentType)
      .replace('{document_id}', String(documentId))
  )
}

export function createPostIt(
  companyId: Company['id'],
  {
    documentId,
    data,
    documentType,
  }: { documentId: ItemIdType; data: Partial<Note>; documentType: ArtifactDocumentType }
) {
  return Api.post(
    injectCompanyToURL(API_POST_IT_LIST_URL, companyId)
      .replace('{document_type}', documentType)
      .replace('{document_id}', String(documentId)),
    data
  )
}

export function updatePostIt(
  companyId: Company['id'],
  {
    documentId,
    data,
    documentType,
  }: { documentId: ItemIdType; data: Partial<Note>; documentType: ArtifactDocumentType }
) {
  return Api.patch(
    injectCompanyToURL(API_POST_IT_DETAIL_URL, companyId)
      .replace('{document_type}', documentType)
      .replace('{document_id}', String(documentId))
      .replace('{post_it_id}', String(data.id)),
    data
  )
}

export function removePostIt(
  companyId: Company['id'],
  { documentId, noteId, documentType }: { documentId: ItemIdType; noteId: number; documentType: ArtifactDocumentType }
) {
  return Api.delete(
    injectCompanyToURL(API_POST_IT_DETAIL_URL, companyId)
      .replace('{document_type}', documentType)
      .replace('{document_id}', String(documentId))
      .replace('{post_it_id}', String(noteId))
  )
}

export function fetchInvoicePostIts(
  companyId: Company['id'],
  { documentType, documentId }: { documentType: ArtifactDocumentType; documentId: ItemIdType }
) {
  return Api.get(
    injectCompanyToURL(API_POST_IT_TOOLTIP_URL, companyId)
      .replace('{document_type}', documentType)
      .replace('{document_id}', String(documentId))
  )
}
