import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'

import { Button, FlexRow, TableCell, Typography } from '@components/ui'

import { AnimatedWarningIcon, NotificationRowVariant, NotificationTableRow } from './styles'

export interface SelectionNotificationRowProps {
  colSpan: number
  isAllSelected?: boolean
  notVisibleSelectedNum: number
  onClear: VoidFunction
}

export function SelectionNotificationRow({
  colSpan,
  isAllSelected = false,
  notVisibleSelectedNum,
  onClear,
}: SelectionNotificationRowProps) {
  if (!notVisibleSelectedNum && !isAllSelected) {
    return null
  }

  return (
    <NotificationTableRow data-testid="selection-warning-msg" variant={NotificationRowVariant.WARNING}>
      <TableCell colSpan={colSpan}>
        <FlexRow>
          <AnimatedWarningIcon size={18} />
          {isAllSelected ? (
            <Typography size="400-sm" color="gray-70">
              <FormattedMessage
                id="selections.warning.notVisibleItems.allSelected"
                defaultMessage="Az összes elem kiválasztva a jelenlegi szűrési feltételeknek megfelelően"
              />
            </Typography>
          ) : (
            <Typography size="400-sm" color="gray-70">
              <FormattedMessage
                id="selections.warning.notVisibleItems"
                defaultMessage="{count} db korábban kiválasztott elem nem látszik a jelenlegi szűrési feltételek miatt"
                values={{
                  count: notVisibleSelectedNum,
                }}
              />
            </Typography>
          )}
          <Button variant="secondaryText" onClick={onClear} size="small">
            <FormattedMessage
              id="selections.button.clearSelection"
              defaultMessage="Nem látható kijelölések elhagyása"
            />
          </Button>
        </FlexRow>
      </TableCell>
    </NotificationTableRow>
  )
}

SelectionNotificationRow.propTypes = {
  colSpan: PropTypes.number.isRequired,
  isAllSelected: PropTypes.bool,
  notVisibleSelectedNum: PropTypes.number.isRequired,
  onClear: PropTypes.func.isRequired,
}
