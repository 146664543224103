import React from 'react'
import PropTypes from 'prop-types'

import __uniqueId from 'lodash/uniqueId'
import { Field, Form } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'
import * as Yup from 'yup'

import {
  cancellablePromise,
  createAriaAttributes,
  isPositiveNumericStringValue,
  makeValidate,
  StrongTextValues,
} from '@helpers'

import { useCancellablePromiseRef } from '@hooks'

import { TaxationTaskTypes } from '@components/pages/PulsePage/TaxationTasks/constants'
import {
  AmountInput,
  Button,
  Currency,
  CustomDialogActions,
  CustomDialogBody,
  CustomDialogHeader,
  CustomDialogHeaderProps,
  FirstColumn,
  FormGrid2Column,
  RffSubmitButton,
  SecondColumn,
  Typography,
} from '@components/ui'
import { RenderTextField } from '@oldComponents/ui/form'

import { FIELD_SUBSCRIPTION } from '@constants'

import { ModifyDialogState, SzjaModifyDialogProps, TbjSzochoModifyDialogProps } from '../types'

import { AcceptButtonMessage, formErrorMessages, globalMessages, OptionNoMessage, OptionYesMessage } from '@messages'
import { ModifyTaxForm, TaskCustomDialog, TaxOverwriteDialogSubtitleTypography } from '../../styles'

const SingleFieldPositionerDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  & > * {
    max-width: 268px;
  }
`

const HEADER_TITLES = {
  [ModifyDialogState.confirm]: (
    <FormattedMessage id="taxation.dialog.modifyTax.title.confirm" defaultMessage="Biztosan felülírod az adókat?" />
  ),
  [ModifyDialogState.form]: (
    <FormattedMessage id="taxation.dialog.modifyTax.title.form" defaultMessage="Fizetendő adó felülírása" />
  ),
}

interface ModifyTaxDialogHeaderProps extends Pick<CustomDialogHeaderProps, 'aria' | 'className'> {
  dialogState: Nullable<ModifyDialogState>
}

function ModifyTaxDialogHeader({ aria, className, dialogState }: ModifyTaxDialogHeaderProps) {
  if (!dialogState) {
    return null
  }

  const customProps: Exclude<CustomDialogHeaderProps, 'aria'> = {
    className,
    borderless: true,
    title: HEADER_TITLES[dialogState],
  }

  return <CustomDialogHeader aria={aria} {...customProps} />
}

interface CommonModifyTaxDialogProps {
  dialogState: Nullable<ModifyDialogState>
  onClose: VoidFunction
  onSubmit: AsyncFunction<Record<string, number>, unknown>
  onSubmitSuccess: VoidFunction
  open: boolean
  setDialogState: (state: ModifyDialogState) => void
}

type TbjSzochoModifyTaxDialogProps = CommonModifyTaxDialogProps & TbjSzochoModifyDialogProps

type SzjaModifyTaxDialogProps = CommonModifyTaxDialogProps & SzjaModifyDialogProps

type ModifyTaxDialogProps = TbjSzochoModifyTaxDialogProps | SzjaModifyTaxDialogProps

export function ModifyTaxDialog({
  data,
  dialogState,
  onClose,
  onSubmit,
  onSubmitSuccess,
  open,
  setDialogState,
  variant,
}: ModifyTaxDialogProps) {
  const { formatMessage } = useIntl()

  // ensure to have the same aria attributes and pass down to dialog body
  const ariaIdPrefix = __uniqueId('modify-tax-dialog')
  const aria = createAriaAttributes(ariaIdPrefix)
  const cancellablePromiseRef = useCancellablePromiseRef()

  async function handleFormSubmit(values: Record<string, Decimal>) {
    // we need to change back to form view to display proper error handling
    setDialogState(ModifyDialogState.form)

    const numberValues = Object.keys(values).reduce((numberObject, currentKey) => {
      return { ...numberObject, [currentKey]: Number(values[currentKey]) }
    }, {} as Record<string, number>)

    try {
      cancellablePromiseRef.current = cancellablePromise(onSubmit(numberValues))
      await cancellablePromiseRef.current.promise
      onSubmitSuccess()
    } catch (error) {
      // API error handling already taken care of in saga
      return error
    }
  }

  function confirmSubmit(event: React.FormEvent) {
    event.preventDefault()
    setDialogState(ModifyDialogState.confirm)
  }

  const yupValidatorRef = React.useRef(
    makeValidate(
      Yup.object().shape(
        variant === TaxationTaskTypes.SZJA
          ? {
              szja: Yup.string()
                .nullable()
                .required(formatMessage(formErrorMessages.required))
                .test('szja', formatMessage(formErrorMessages.positiveOnlyValidation), isPositiveNumericStringValue),
            }
          : {
              szocho: Yup.string()
                .nullable()
                .required(formatMessage(formErrorMessages.required))
                .test('szocho', formatMessage(formErrorMessages.positiveOnlyValidation), isPositiveNumericStringValue),
              tbj: Yup.string()
                .nullable()
                .required(formatMessage(formErrorMessages.required))
                .test('tbj', formatMessage(formErrorMessages.positiveOnlyValidation), isPositiveNumericStringValue),
            }
      )
    )
  )

  return (
    <TaskCustomDialog ariaIdPrefix={ariaIdPrefix} open={open} onClose={onClose}>
      <ModifyTaxDialogHeader aria={aria} dialogState={dialogState} />
      <Form
        onSubmit={handleFormSubmit}
        validate={yupValidatorRef.current}
        render={({ handleSubmit, submitting, hasValidationErrors }) => (
          <ModifyTaxForm
            data-testid="modify-tax-form"
            onSubmit={handleSubmit}
            autoComplete="off"
            noValidate
            id={aria.describedby}
            style={{
              // useMeasure can't be used due to having the dialog in the same component, it will initially render with 0 height
              '--form-height': dialogState === ModifyDialogState.confirm ? 140 : variant === 'tbjSzocho' ? 234 : 250,
            }}
          >
            {dialogState === ModifyDialogState.confirm && (
              <>
                <CustomDialogBody>
                  <Typography id={aria.describedby} size="400-sm" color="gray-50" align="center">
                    {variant === TaxationTaskTypes.SZJA && (
                      <FormattedMessage
                        id="taxation.dialogs.modifyTax.confirmation.szja"
                        defaultMessage="A QUiCK által kiszámolt adó felülírásával előírod magadnak az adót."
                      />
                    )}
                    {variant === TaxationTaskTypes.TBJ_SZOCHO && (
                      <FormattedMessage
                        id="taxation.dialogs.modifyTax.confirmation.tbjSzocho"
                        defaultMessage="A QUiCK által kiszámolt adó felülírásával előírod magadnak az adót. Ebben az esetben nem tudsz bevallást letölteni."
                      />
                    )}
                  </Typography>
                </CustomDialogBody>
                <CustomDialogActions borderless>
                  <RffSubmitButton variant="primaryText">{OptionYesMessage}</RffSubmitButton>
                  <Button variant="primaryContained" onClick={onClose}>
                    {OptionNoMessage}
                  </Button>
                </CustomDialogActions>
              </>
            )}
            {dialogState === ModifyDialogState.form && (
              <>
                <CustomDialogBody>
                  <TaxOverwriteDialogSubtitleTypography color="gray-80" align="center" size="400-sm">
                    {variant === TaxationTaskTypes.SZJA && (
                      <FormattedMessage
                        id="taxation.dialogs.modifyTax.subtitle.szja"
                        defaultMessage="A QUiCK által kiszámolt SZJA <b>{szja}</b>. Ha szeretnéd felülírni, akkor add meg alább a helyes összeget, majd kattints az Elfogadás gombra."
                        values={{
                          ...StrongTextValues,
                          szja: <Currency value={Number(data.szja)} />,
                        }}
                      />
                    )}
                    {variant === TaxationTaskTypes.TBJ_SZOCHO && (
                      <FormattedMessage
                        id="taxation.dialogs.modifyTax.subtitle.tbj"
                        defaultMessage="A QUiCK által kiszámolt TBJ <b>{tbj}</b>, a SZOCHO <b>{szocho}</b>. Ha szeretnéd felülírni, akkor add meg alább a helyes összeget, majd kattints az Elfogadás gombra."
                        values={{
                          ...StrongTextValues,
                          tbj: <Currency value={Number(data.tbj)} />,
                          szocho: <Currency value={Number(data.szocho)} />,
                        }}
                      />
                    )}
                  </TaxOverwriteDialogSubtitleTypography>
                  {variant === TaxationTaskTypes.SZJA && (
                    <SingleFieldPositionerDiv>
                      <Field
                        component={RenderTextField}
                        disabled={submitting}
                        InputProps={{
                          inputComponent: AmountInput,
                        }}
                        label={formatMessage(globalMessages.formLabelSzjaAmount)}
                        name="szja"
                        subscription={FIELD_SUBSCRIPTION}
                        required
                      />
                    </SingleFieldPositionerDiv>
                  )}
                  {variant === TaxationTaskTypes.TBJ_SZOCHO && (
                    <FormGrid2Column>
                      <FirstColumn>
                        <Field
                          component={RenderTextField}
                          disabled={submitting}
                          InputProps={{
                            inputComponent: AmountInput,
                          }}
                          label={formatMessage(globalMessages.formLabelTbjAmount)}
                          name="tbj"
                          subscription={FIELD_SUBSCRIPTION}
                          required
                        />
                      </FirstColumn>
                      <SecondColumn>
                        <Field
                          component={RenderTextField}
                          disabled={submitting}
                          InputProps={{
                            inputComponent: AmountInput,
                          }}
                          label={formatMessage(globalMessages.formLabelSzochoAmount)}
                          name="szocho"
                          required
                          subscription={FIELD_SUBSCRIPTION}
                        />
                      </SecondColumn>
                    </FormGrid2Column>
                  )}
                </CustomDialogBody>
                <CustomDialogActions borderless>
                  <RffSubmitButton variant="primaryContained" disabled={hasValidationErrors} onClick={confirmSubmit}>
                    {AcceptButtonMessage}
                  </RffSubmitButton>
                </CustomDialogActions>
              </>
            )}
          </ModifyTaxForm>
        )}
      />
    </TaskCustomDialog>
  )
}

ModifyTaxDialog.propTypes = {
  data: PropTypes.object.isRequired as React.Validator<TbjSzochoModifyTaxDialogProps['data']>,
  dialogState: PropTypes.string as React.Validator<ModifyDialogState>,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setDialogState: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(Object.values(TaxationTaskTypes)).isRequired as React.Validator<
    TbjSzochoModifyTaxDialogProps['variant']
  >,
}
