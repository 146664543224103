import { getDefaultPageSize } from '@helpers'

import { UpdateOrderPayload, UpdateRowsPerPagePayload } from '@hooks/useTableControls'

import { PartnerData } from '@oldComponents/PartnerEditor/types'

import filtersActions from '../filters/actions'
import actions from './actions'

const defaultPageSize = getDefaultPageSize()

// transform partner's details data into list data
function serializePartnerDetailsIntoListData(
  {
    auto_created_type,
    id,
    integration_type,
    is_provider,
    name,
    tax_number,
  }: Pick<PartnerData, 'auto_created_type' | 'id' | 'integration_type' | 'is_provider' | 'name' | 'tax_number'>,
  isCreate = false
): BackendPartnerListResult {
  const defaultPartnerData: BackendPartnerListResult = {
    auto_created_type,
    has_attachment: false,
    id,
    integration_type,
    name,
    tax_number,
  }

  if (isCreate) {
    const extraProps = is_provider
      ? { sum_expense: 0, sum_outstanding: 0 }
      : {
          sum_customer_debt: 0,
          sum_income: 0,
        }

    return {
      ...defaultPartnerData,
      ...extraProps,
    }
  }

  return defaultPartnerData
}

export const initialState: PartnersStore = {
  data: [],
  order: 'asc',
  orderBy: 'name',
  count: 0,
  page: 0,
  pageSize: defaultPageSize,
  // nextPageUrl: null,
  // prevPageUrl: null,
  loading: false,
  error: null,
  fetched: false,
}

type PartnerReducerAction =
  | { type: typeof actions.fetchPartners.REQUEST }
  | { type: typeof actions.fetchPartners.SUCCESS; payload: { count: number; results: BackendPartnerListResult[] } }
  | { type: typeof actions.fetchPartners.FAILURE; payload: BackendError }
  | { type: typeof filtersActions.updatePartnerListFilters.REQUEST }
  | { type: typeof actions.updateOrder.REQUEST; payload: UpdateOrderPayload }
  | { type: typeof actions.updatePage.REQUEST; payload: number }
  | { type: typeof actions.updateRowsPerPage.REQUEST; payload: UpdateRowsPerPagePayload }
  | { type: typeof actions.createPartner.SUCCESS; payload: PartnerData }

export default function partnersReducer(state: PartnersStore = initialState, action: PartnerReducerAction) {
  switch (action.type) {
    case actions.fetchPartners.REQUEST:
      return {
        ...state,
        loading: true,
        fetched: false,
      }

    case actions.fetchPartners.SUCCESS: {
      // partner page list w/ pagination
      if (
        Object.prototype.hasOwnProperty.call(action.payload, 'results') &&
        Object.prototype.hasOwnProperty.call(action.payload, 'count')
      ) {
        return {
          ...state,
          data: action.payload.results,
          count: action.payload.count,
          // nextPageUrl: action.payload.next,
          // prevPageUrl: action.payload.previous,
          loading: false,
          fetched: true,
          error: null,
        }
        // dropdown options - simple list
      }
      return {
        ...state,
        data: action.payload,
        loading: false,
        fetched: true,
        error: null,
      }
    }

    case actions.fetchPartners.FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case filtersActions.updatePartnerListFilters.REQUEST:
      return {
        ...state,
        page: 0,
      }

    case actions.updateOrder.REQUEST:
      return {
        ...state,
        order: action.payload.order,
        orderBy: action.payload.orderBy,
      }

    case actions.updatePage.REQUEST:
      return {
        ...state,
        page: action.payload,
      }

    case actions.updateRowsPerPage.REQUEST:
      return {
        ...state,
        pageSize: action.payload.pageSize,
      }

    case actions.createPartner.SUCCESS:
      return {
        ...state,
        data: [...state.data, serializePartnerDetailsIntoListData(action.payload, true)],
      }

    default:
      return state
  }
}
