import React from 'react'

import { useWatch } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { paymentActions } from '@services'

import { bindActionToPromise, cancellablePromise, getIsAutokasszaEnabled } from '@helpers'

import { CreatePaymentFormData } from '@contexts/PaymentProvider/types'

import { useCancellablePromiseRef } from '@hooks/useCancellablePromiseRef'

import { Currency } from '@components/ui/FormattedTexts'
import { PaidThroughSyncStatus } from '@components/ui/PaidThroughSyncStatus'
import { Typography } from '@components/ui/Typography'

const StyledPaidThroughSyncStatus = styled(PaidThroughSyncStatus)`
  margin-right: 4px;
`

// TODO: when it become a reusable component margins should be removed from here
const PaidThroughBalanceTypography = styled(Typography)<{ $fadeIn: boolean }>`
  height: 36px;
  margin-top: 35px;
  margin-bottom: 8px;
  border-radius: 4px;
  padding: 6px;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.gray[35]};
  opacity: ${({ $fadeIn }) => ($fadeIn ? 1 : 0)};
  transition: all 200ms ease-in-out;

  ${Typography} {
    display: flex;
    align-items: center;
    margin-left: 35px;
  }
`

export const FAKE_OPTION_ID = -1111

type CurrentBalanceState = CurrentBalance & {
  fadeIn: boolean
}

interface PurePaidThroughBalanceProps {
  bankProviders: BankProvider[]
  loadBalance: AsyncFunction<number, CurrentBalance>
}

function PurePaidThroughBalance({ bankProviders, loadBalance }: PurePaidThroughBalanceProps) {
  const [state, setState] = React.useState<CurrentBalanceState>({
    current_balance: null,
    currency: null,
    fadeIn: false,
    is_autokassza: false,
    provider: null,
  })
  const paidThroughValue = useWatch<Pick<CreatePaymentFormData, 'paidThroughId'>>({ name: 'paidThroughId' })
  const cPromiseRef = useCancellablePromiseRef<CurrentBalance>()

  React.useEffect(() => {
    async function updateBalance() {
      if (paidThroughValue && paidThroughValue !== FAKE_OPTION_ID) {
        try {
          cPromiseRef.current = cancellablePromise(loadBalance(paidThroughValue))
          const results = await cPromiseRef.current.promise

          if (results.current_balance != null) {
            setState({
              ...results,
              fadeIn: true,
            })
          } else {
            setState(prevState => ({ ...prevState, fadeIn: false }))
          }
        } catch (error) {
          setState(prevState => ({ ...prevState, fadeIn: false }))
        }
      } else {
        setState(prevState => ({ ...prevState, fadeIn: false }))
      }
    }
    updateBalance()
  }, [cPromiseRef, loadBalance, paidThroughValue])

  return (
    <PaidThroughBalanceTypography size="400-xs" color="gray-80" $fadeIn={state.fadeIn}>
      <StyledPaidThroughSyncStatus
        syncEnabled={state.is_autokassza}
        canBeEnabled={getIsAutokasszaEnabled(state.provider, bankProviders)}
      />
      <FormattedMessage
        id="forms.payment.labels.actualBalance"
        defaultMessage="Aktuális ismert napi egyenleg: {value}"
        values={{
          value: (
            <Typography size="700-sm" tag="span">
              <Currency value={Number(state?.current_balance)} currency={state?.currency?.name} />
            </Typography>
          ),
        }}
      />
    </PaidThroughBalanceTypography>
  )
}

export const PaidThroughBalance = connect(
  (state: Store) => ({
    bankProviders: state.dashboard.common.bank_providers,
  }),
  dispatch => ({
    loadBalance: bindActionToPromise(dispatch, paymentActions.loadPaidThroughProviderBalance.request),
  })
)(PurePaidThroughBalance)

PaidThroughBalance.displayName = 'PaidThroughBalance'
