import { Middleware } from 'redux'

import actions from './auth/actions'
import { checkTokenExpiraiton, getToken } from './helpers'

const checkTokenMiddleware: Middleware<null, Store> =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const result = next(action)

    const {
      auth: { isLoggedIn, renew_token_inprogress, token_expired },
    } = getState()

    if (isLoggedIn && !renew_token_inprogress) {
      const authToken = getToken()

      if (authToken) {
        checkTokenExpiraiton(dispatch, authToken)
      } else if (!token_expired) {
        dispatch(actions.expiredToken.request())
      }
    }

    return result
  }

export default checkTokenMiddleware
