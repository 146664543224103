import { injectCompanyToURL } from '@helpers/url'

import Api from '../api'
import {
  API_ONBOARDING_CONFIRM,
  API_ONBOARDING_INVITATION_MEMBERS_LIST,
  API_ONBOARDING_INVITATION_REJECT,
  API_ONBOARDING_INVITATION_STATUS,
  API_ONBOARDING_KSZI_CONNECT,
  API_ONBOARDING_SETUP,
} from './urls'

export function getOnboardingInstructions() {
  return Api.post(API_ONBOARDING_SETUP)
}

export function confirmOnboarding() {
  return Api.post(API_ONBOARDING_CONFIRM)
}

export function connectKszi(data: { company_id: Company['id'] }) {
  return Api.post(API_ONBOARDING_KSZI_CONNECT, data)
}

export function getOnboardingInvitationStatus(companyId: string) {
  return Api.get(injectCompanyToURL(API_ONBOARDING_INVITATION_STATUS, companyId))
}

export function getOnboardingInvitationMembersList(companyId: Company['id']) {
  return Api.get(injectCompanyToURL(API_ONBOARDING_INVITATION_MEMBERS_LIST, companyId))
}

export function declineOnboardingInvitation(companyId: Company['id'], uuid: string) {
  return Api.delete(injectCompanyToURL(API_ONBOARDING_INVITATION_REJECT, companyId).replace('{uuid}', uuid))
}
