import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { cancellablePromise } from '@helpers'

import { useCancellablePromiseRef } from '@hooks'

import { MINIMUM_RENDERER_SIZE, ZoomOrientation } from '@components/DocViewer/constant'
import { usePostIt } from '@components/DocViewer/hooks'

import { A4_PAPER_SIZE } from '@constants'

import { initImageZoomLevelAndOrientation } from './actions'
import { ImageControlsContext } from './ImageControlsProvider'
import { getDefaultZoomLevel } from './zoom'

const Img = styled.img`
  max-width: 95%;
  max-height: 95%;
`

const ImageWrapper = styled.div`
  display: inline-block;
  position: relative;
`

function useLoadedImage(src: string) {
  const [loaded, setLoaded] = React.useState(false)
  const cPromiseRef = useCancellablePromiseRef()
  const imageRef = React.useRef<HTMLImageElement>()

  React.useEffect(() => {
    async function run() {
      try {
        cPromiseRef.current = cancellablePromise(
          new Promise((resolve, reject) => {
            const img = new Image()
            img.onerror = function () {
              reject()
            }
            img.onload = function () {
              if (img.naturalWidth + img.naturalHeight === 0) {
                reject()
              } else {
                resolve(img)
              }
            }
            img.src = src
          })
        )
        imageRef.current = await cPromiseRef.current.promise
        setLoaded(true)
      } catch (error) {
        // ignore error
      }
    }
    run()
  }, [cPromiseRef, src])
  return { loaded, image: imageRef.current }
}

function ImgWithZoom({ src }: { src: string }) {
  const {
    state: { zoomLevel, mainState },
    dispatch,
  } = React.useContext(ImageControlsContext)
  const canvasRef = React.useRef<HTMLCanvasElement>(null)
  const { image, loaded } = useLoadedImage(src)
  const { renderPostItLayer } = usePostIt()

  const imageWidth = image?.naturalWidth ?? 0
  const imageHeight = image?.naturalHeight ?? 0
  const scaledHeight = Math.max(imageHeight * zoomLevel, MINIMUM_RENDERER_SIZE)
  const scaledWidth = Math.max(imageWidth * zoomLevel, MINIMUM_RENDERER_SIZE)

  function drawImage() {
    if (image) {
      const ctx = canvasRef.current?.getContext('2d')
      const canvasWidth = canvasRef.current?.width ?? 0
      const canvasHeight = canvasRef.current?.height ?? 0

      ctx?.clearRect(0, 0, canvasWidth, canvasHeight)
      ctx?.drawImage(image, 0, 0, imageWidth, imageHeight, 0, 0, scaledWidth, scaledHeight)
    }
  }

  React.useEffect(() => {
    if (loaded) {
      const zoomOrientation = imageWidth > imageHeight ? ZoomOrientation.LANDSCAPE : ZoomOrientation.PORTRAIT
      const initialZoomLevel = getDefaultZoomLevel(zoomOrientation, imageWidth, imageHeight, mainState)

      dispatch(
        initImageZoomLevelAndOrientation({ zoomLevel: initialZoomLevel, zoomOrientation, imageWidth, imageHeight })
      )
    }
  }, [loaded])

  React.useEffect(() => {
    drawImage()
  }, [loaded, zoomLevel])

  // calculate scale for image wrapper based on A4 paper size
  const scale = scaledWidth / A4_PAPER_SIZE.width

  return (
    <ImageWrapper>
      <Img src={src} style={{ display: 'none' }} />
      <canvas ref={canvasRef} width={scaledWidth} height={scaledHeight} />
      {renderPostItLayer?.(scale, 1)}
    </ImageWrapper>
  )
}

ImgWithZoom.propTypes = {
  src: PropTypes.string.isRequired,
}

export function ImageDoc() {
  const {
    state: { mainState },
  } = React.useContext(ImageControlsContext)

  const currentDocument = mainState?.currentDocument || null

  if (!currentDocument || !currentDocument.fileData) return null
  return <ImgWithZoom src={currentDocument.fileData as string} />
}
