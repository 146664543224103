import React from 'react'
import PropTypes from 'prop-types'

import { DialogContentText, DialogProps } from '@material-ui/core'
import styled from 'styled-components'

import { AsyncButton, Button } from '@components/ui/Buttons'
import { WarningText } from '@components/ui/WarningText'

import { Dialog, DialogActions, DialogContent, DialogTitle } from '../DialogElements'

import { CancelButtonMessage } from '@messages'

const StyledWarningText = styled(WarningText)`
  justify-content: center;
  margin-bottom: 1rem;
`

export interface ConfirmDialogProps extends Omit<DialogProps, 'title' | 'classes'> {
  children?: React.ReactChild | React.ReactChild[]
  dialogActions?: React.ReactNode
  dialogContentClassName?: string
  disabled?: boolean
  disabledAll?: boolean
  error?: BackendError
  id: string
  loading: boolean
  onClose: VoidFunction
  onSubmit: VoidFunction
  open: boolean
  submitButtonText: StringOrMessage
  title: StringOrMessage
}

export function ConfirmDialog({
  children,
  dialogActions,
  dialogContentClassName,
  disabled = false,
  disabledAll = false,
  error,
  id,
  loading,
  onClose,
  onSubmit,
  open,
  submitButtonText,
  title,
  ...rest
}: ConfirmDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby={id} {...rest}>
      <DialogTitle id={id}>{title}</DialogTitle>
      <DialogContent className={dialogContentClassName}>
        <DialogContentText component="div">{children}</DialogContentText>
        {error && <StyledWarningText>{error}</StyledWarningText>}
      </DialogContent>
      <DialogActions>
        {dialogActions ?? (
          <>
            <AsyncButton
              disabled={disabledAll || disabled}
              loading={loading}
              onClick={onSubmit}
              variant="secondaryContained"
            >
              {submitButtonText}
            </AsyncButton>
            <Button onClick={onClose} variant="primaryContained" disabled={disabledAll}>
              {CancelButtonMessage}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.arrayOf(PropTypes.node.isRequired).isRequired]),
  dialogActions: PropTypes.node,
  dialogContentClassName: PropTypes.string,
  disabled: PropTypes.bool,
  disabledAll: PropTypes.bool,
  error: PropTypes.node,
  id: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  submitButtonText: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
}
