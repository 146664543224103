import React, { HTMLAttributes } from 'react'
import PropTypes from 'prop-types'

import { LightTooltip } from '@oldComponents/ui/LightTooltip'

interface OverflowTextWithTooltipProps extends HTMLAttributes<HTMLSpanElement> {
  children: React.ReactChild
  className?: string
  disablePortal?: boolean
}

export function OverflowTextWithTooltip({
  children,
  className,
  disablePortal = true,
  ...rest
}: OverflowTextWithTooltipProps) {
  const textNodeRef = React.useRef<HTMLSpanElement>(null)
  const [overflowActive, setOverflowActive] = React.useState(false)
  const [active, setActive] = React.useState(false)

  React.useEffect(() => {
    if (textNodeRef.current) {
      const { offsetWidth, scrollWidth } = textNodeRef.current
      setOverflowActive(offsetWidth < scrollWidth)
    }
  }, [active])

  const textNode = React.useMemo(() => {
    const handleHover = () => setActive(true)
    const handleBlur = () => setActive(false)
    return (
      <span ref={textNodeRef} className={className} {...rest} onMouseEnter={handleHover} onMouseLeave={handleBlur}>
        {children}
      </span>
    )
  }, [children, className, rest, setActive])

  if (overflowActive) {
    return (
      <LightTooltip title={children} PopperProps={{ disablePortal }}>
        {textNode}
      </LightTooltip>
    )
  }

  return textNode
}

OverflowTextWithTooltip.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  disablePortal: PropTypes.bool,
}
