import React from 'react'
import PropTypes from 'prop-types'

import * as Sentry from '@sentry/react'

import { CustomErrorBoundaryView } from '@components/ui'

export class ErrorBoundary extends React.Component {
  static propTypes = { children: PropTypes.node.isRequired }
  state = { hasError: false, eventId: null }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: any) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render() {
    if (this.state.hasError) {
      return <CustomErrorBoundaryView eventId={this.state.eventId} />
    }

    return this.props.children
  }
}

export const RootErrorBoundary = Sentry.withErrorBoundary(ErrorBoundary, {
  fallback: <CustomErrorBoundaryView />,
})
