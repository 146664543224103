import React from 'react'

import { FormattedMessage } from 'react-intl'

export const VAT_PERIOD_MONTHLY = 'monthly'
export const VAT_PERIOD_QUARTERLY = 'quarterly'
export const VAT_PERIOD_YEARLY = 'yearly'

export const INITIAL_VAT_PERIODS: Record<VatPeriodType, StringOrMessage> = {
  [VAT_PERIOD_MONTHLY]: <FormattedMessage id="vatPriod.monthly" defaultMessage="Havi" />,
  [VAT_PERIOD_QUARTERLY]: <FormattedMessage id="vatPriod.quarterly" defaultMessage="Negyedéves" />,
  [VAT_PERIOD_YEARLY]: <FormattedMessage id="vatPriod.yearly" defaultMessage="Éves" />,
}
