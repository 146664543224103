import { getCursorFromUrl } from '@helpers'
import { getDefaultPageSize } from '@helpers/cookie'

import { DokumentConnectionType, DOKUMENTS_DEFAULT_SORTING } from '@constants'

import authActions from '../auth/actions'
import filtersActions from '../filters/actions'
import actions from './actions'
import { DokumentReducerAction } from './types'

const defaultPageSize = getDefaultPageSize()

//* local helper
function getFilingNumberFromResults(
  { id, filingNumber }: Pick<DokumentListData, 'id' | 'filingNumber'>,
  results: Record<ItemIdType, string>
) {
  return results[id] || filingNumber
}

export const initialState: DokumentStore = {
  aiRecognitionCount: 0,
  details: {
    data: {} as BackendDokumentDetailResult,
    meta: {
      attachmentCount: 0,
    },
    error: null,
    fetched: false,
    loading: true,
  },
  dokumentConnections: {
    data: {
      [DokumentConnectionType.EXPENSES]: [],
      [DokumentConnectionType.INCOMES]: [],
      [DokumentConnectionType.PARTNERS]: [],
    },
    error: null,
    fetched: false,
    loading: true,
  },
  dokumentTypes: {
    data: [],
    loading: false,
    error: null,
    fetched: false,
  },
  list: {
    cursor: null,
    data: [],
    error: null,
    fetched: false,
    loading: false,
    nextPageUrl: null,
    pageSize: defaultPageSize,
    prevPageUrl: null,
    ...DOKUMENTS_DEFAULT_SORTING,
  },
}

function reducer(state = initialState, action: DokumentReducerAction) {
  switch (action.type) {
    case authActions.selectCompany.REQUEST:
      return { ...initialState }

    case filtersActions.initDokumentListParamsFromUrl.REQUEST: {
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload,
        },
      }
    }

    case actions.fetchDokuments.REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          fetched: false,
        },
      }

    case actions.fetchDokumentsByPaging.REQUEST: {
      const cursor = getCursorFromUrl(action.payload.url)
      return {
        ...state,
        list: {
          ...state.list,
          cursor,
          loading: true,
          fetched: false,
        },
      }
    }

    case filtersActions.updateDokumentFilters.REQUEST:
    case filtersActions.resetDokumentFilters.REQUEST:
    case filtersActions.toggleDokumentDateFilter.REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          cursor: null,
          loading: true,
        },
      }

    case actions.fetchDokuments.SUCCESS:
    case actions.fetchDokumentsByPaging.SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload.data,
          prevPageUrl: action.payload.previous,
          nextPageUrl: action.payload.next,
          loading: false,
          fetched: true,
          error: null,
        },
      }

    case actions.fetchDokuments.FAILURE:
    case actions.fetchDokumentsByPaging.FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.payload,
        },
      }

    // ordering
    case actions.updateOrder.REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          cursor: null,
          loading: true,
          order: action.payload.order,
          orderBy: action.payload.orderBy,
        },
      }

    // pagination
    case actions.updateRowsPerPage.REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          cursor: null,
          loading: true,
          pageSize: action.payload.pageSize,
        },
      }

    case actions.resetPagination.REQUEST: {
      return {
        ...state,
        list: {
          ...state.list,
          cursor: null,
        },
      }
    }

    // details
    case actions.clearDokumentDetails.REQUEST:
    case actions.bulkFiling.FAILURE: //! used as secondary success action in this case
      return {
        ...state,
        details: { ...initialState.details },
      }

    case actions.fetchDokumentDetails.REQUEST:
      return {
        ...state,
        details: {
          ...initialState.details,
          loading: true,
        },
        dokumentConnections: { ...initialState.dokumentConnections },
      }

    case actions.fetchDokumentDetails.SUCCESS: {
      const { meta, ...data } = action.payload
      return {
        ...state,
        details: {
          ...state.details,
          data,
          meta,
          loading: false,
          fetched: true,
          error: null,
        },
      }
    }

    case actions.fetchDokumentDetails.FAILURE:
      return {
        ...state,
        details: {
          ...state.details,
          loading: false,
          error: action.payload,
        },
      }

    // dokument types
    case actions.fetchDokumentTypes.REQUEST:
      return {
        ...state,
        dokumentTypes: {
          ...state.dokumentTypes,
          loading: true,
          fetched: false,
        },
      }

    case actions.fetchDokumentTypes.SUCCESS:
      return {
        ...state,
        dokumentTypes: {
          ...state.dokumentTypes,
          data: action.payload,
          loading: false,
          fetched: true,
          error: null,
        },
      }

    case actions.fetchDokumentTypes.FAILURE:
      return {
        ...state,
        dokumentTypes: {
          ...state.dokumentTypes,
          loading: false,
          error: action.payload,
        },
      }

    case actions.updateDokumentDetails.SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          data: {
            ...state.details.data,
            ...action.payload,
          },
        },
      }

    case actions.createDokumentType.SUCCESS:
      return {
        ...state,
        dokumentTypes: {
          ...state.dokumentTypes,
          data: [...state.dokumentTypes.data, action.payload],
        },
      }

    case actions.statusCheck.SUCCESS:
      return {
        ...state,
        aiRecognitionCount: action.payload.aiRecognitionCount,
      }

    case actions.dokumentFiling.SUCCESS: {
      const { id, filingNumber } = state.details.data
      return {
        ...state,
        details: {
          ...state.details,
          data: {
            ...state.details.data,
            filingNumber: getFilingNumberFromResults({ id, filingNumber }, action.payload),
          },
        },
      }
    }

    // update filingNumber in list and reset details
    case actions.bulkFiling.SUCCESS:
      return {
        ...state,
        details: { ...initialState.details },
        list: {
          ...state.list,
          data: state.list.data.map((dokument: DokumentListData) => ({
            ...dokument,
            filingNumber: getFilingNumberFromResults(dokument, action.payload),
          })),
        },
      }

    case actions.fetchConnections.REQUEST:
      return {
        ...state,
        dokumentConnections: {
          ...state.dokumentConnections,
          loading: true,
        },
      }

    case actions.fetchConnections.SUCCESS: {
      const attachmentCount =
        action.payload.expenses.length + action.payload.incomes.length + action.payload.partners.length
      return {
        ...state,
        details: {
          ...state.details,
          meta: {
            ...state.details.meta,
            attachmentCount,
          },
        },
        dokumentConnections: {
          ...state.dokumentConnections,
          data: action.payload,
          error: null,
          fetched: true,
          loading: false,
        },
      }
    }

    case actions.fetchConnections.FAILURE:
      return {
        ...state,
        dokumentConnections: {
          ...state.dokumentConnections,
          error: action.payload,
          loading: false,
        },
      }

    case actions.detachFromDokument.SUCCESS: {
      const { documentType, documentId } = action.payload
      let newData = state.dokumentConnections.data

      if (documentType === 'expense') {
        newData = {
          ...newData,
          expenses: newData.expenses.filter(({ id }) => id !== documentId),
        }
      } else if (documentType === 'income') {
        newData = {
          ...newData,
          incomes: newData.incomes.filter(({ id }) => id !== documentId),
        }
      } else if (documentType === 'partner') {
        newData = {
          ...newData,
          partners: newData.partners.filter(({ id }) => id !== documentId),
        }
      }

      return {
        ...state,
        details: {
          ...state.details,
          meta: {
            ...state.details.meta,
            attachmentCount: state.details.meta.attachmentCount - 1,
          },
        },
        dokumentConnections: {
          ...state.dokumentConnections,
          data: newData,
        },
      }
    }

    default:
      return state
  }
}

export default reducer
