import { AxiosResponse } from 'axios'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { getActiveCompanyId, getErrorMessage, getFormErrors } from '@helpers'

import actions from './actions'
import * as api from './api'

export function* loadApiKeysSaga() {
  try {
    const companyId: number = yield select(getActiveCompanyId)
    const response: AxiosResponse<ApiKeysData[]> = yield call(api.loadApiKeys, companyId)
    yield put(actions.loadApiKeys.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.loadApiKeys.failure(errorMsg))
  }
}

export function* createApiKeySaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<{
  company: number
  name: string
}>) {
  try {
    const companyId: number = yield select(getActiveCompanyId)
    const response: AxiosResponse<{
      id: number
      name: string
      token: string
    }> = yield call(api.createApiKey, companyId, payload)
    yield put(actions.createApiKey.success()) // refetch list
    yield call(resolve, response.data)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield call(reject, formErrors)
  }
}

export function* removeApiKeySaga({ payload: { company, data }, meta: { resolve, reject } }: AsyncSagaAction<any>) {
  try {
    yield call(api.removeApiKey, company.id, data)
    yield put(actions.removeApiKey.success(data.id))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// watcher Saga
export default function* commonSaga() {
  yield takeLatest([actions.loadApiKeys.REQUEST, actions.createApiKey.SUCCESS], loadApiKeysSaga)
  yield takeLatest(actions.createApiKey.REQUEST, createApiKeySaga)

  yield takeLatest(actions.removeApiKey.REQUEST, removeApiKeySaga)
}
