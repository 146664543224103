import React from 'react'

import { MINIMUM_RENDERER_SIZE } from '../constant'
import { DocViewerConfig, RendererRect } from '../types'

export function useRendererSizes(config?: DocViewerConfig): RendererRect {
  const [windowSize, setWindowSize] = React.useState<{
    width: number
    height: number
  }>({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  React.useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return React.useMemo(() => {
    const windowWidth = windowSize.width ?? 0
    const windowHeight = windowSize.height ?? 0
    const configMaxHeight = config?.viewerSize?.maxHeight ?? windowHeight
    const configMaxWidth = config?.viewerSize?.maxWidth ?? windowWidth

    const maxWidth = Math.min(windowWidth, configMaxWidth) - (config?.viewerSize?.reducingWidth ?? 0)
    const maxHeight = Math.min(windowHeight, configMaxHeight) - (config?.viewerSize?.reducingHeight ?? 0)

    // Ensure the renderer has a minimum size to prevent rendering issues
    const width = Math.max(maxWidth, MINIMUM_RENDERER_SIZE)
    const height = Math.max(maxHeight, MINIMUM_RENDERER_SIZE)

    return { width, height }
  }, [windowSize, config])
}
