import React from 'react'

import { cancellablePromise, parseApiErrorMessage } from '@helpers'

import { useAlertDispatch } from '@contexts/AlertProvider'

import { useCancellablePromiseRef } from './useCancellablePromiseRef'

export type FilingPayload = {
  ids: ItemIdType[]
  isAllSelected: boolean
}

export interface UseFilingActionProps<FilingResponse> {
  callFiling: AsyncFunction<FilingPayload, FilingResponse>
  callLastFilingNumber: AsyncFunction<void, string>
  handleResponse?: (results: FilingResponse) => void
  payload: FilingPayload
  setFilingInProgress?: (payload: boolean) => void
}

export function useFilingAction<FilingResponse extends BackgroundActionResponse>({
  callFiling,
  callLastFilingNumber,
  handleResponse,
  payload,
  setFilingInProgress,
}: UseFilingActionProps<FilingResponse>) {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [lastKnownFilingNumber, setLastKnownFilingNumber] = React.useState('')
  const cPromise = useCancellablePromiseRef<string>()
  const cPromiseFiling = useCancellablePromiseRef<FilingResponse>()
  const { setErrorAlert } = useAlertDispatch()

  const handleOpen = React.useCallback(async () => {
    setLoading(true)
    try {
      cPromise.current = cancellablePromise(callLastFilingNumber())
      const result = await cPromise.current.promise
      setLastKnownFilingNumber(result)
      setLoading(false)
      setOpen(true)
    } catch (error) {
      const errorMessage = parseApiErrorMessage(error)
      if (errorMessage) {
        setLoading(false)
        setErrorAlert(errorMessage)
      }
    }
  }, [callLastFilingNumber, cPromise, setErrorAlert])

  const handleClose = React.useCallback(() => {
    setOpen(false)
  }, [])

  const handleFiling = React.useCallback(async () => {
    setLoading(true)
    setOpen(false)
    setFilingInProgress?.(true)
    try {
      cPromiseFiling.current = cancellablePromise(callFiling(payload))
      const results = await cPromiseFiling.current.promise
      handleResponse?.(results)
    } catch (error) {
      const errorMessage = parseApiErrorMessage(error)
      if (errorMessage) {
        setErrorAlert(errorMessage)
      }
    }
    setLoading(false)
    setFilingInProgress?.(false)
  }, [setFilingInProgress, cPromiseFiling, callFiling, payload, handleResponse, setErrorAlert])

  return React.useMemo(
    () => ({
      handleClose,
      handleFiling,
      handleOpen,
      lastKnownFilingNumber,
      loading,
      open,
    }),
    [handleClose, handleFiling, handleOpen, lastKnownFilingNumber, loading, open]
  )
}
