import React from 'react'

import moment from 'moment'
import { FormattedMessage } from 'react-intl'

import { getDateQuarter } from '@helpers'

interface FormattedVatPeriodDateProps {
  type: VatPeriodType
  value: string
}

function getValue({ type, value }: FormattedVatPeriodDateProps) {
  if (type === 'monthly') {
    return moment(value).format('YYYY. MMMM')
  }

  if (type === 'quarterly') {
    return (
      <FormattedMessage
        id="form.vatPeriodDateField.quarterValue"
        defaultMessage="{year}. {quarter}. negyedév"
        values={{ year: moment(value).format('YYYY'), quarter: getDateQuarter(value) }}
      />
    )
  }

  return moment(value).format('YYYY')
}

export function FormattedVatPeriodDate(props: FormattedVatPeriodDateProps) {
  return <strong>{getValue(props)}</strong>
}
