import { serviceActionsGenerator } from '../serviceGenerator'

const actionTypes = [
  '!ABORT_DOWNLOAD_EXPENSE_ARTIFACT',
  '!ABORT_UPLOAD_EXPENSE',
  '!CANCEL_UPLOAD_EXPENSE_PROCESS',
  '!CHECK_EXPENSE_DUPLICATION',
  '!CLEAR_EXPENSE_DETAILS',
  '!CLEAR_RESET_FORM_AFTER_UPLOAD',
  '!CREATE_EXPENSE',
  '!CREATE_JOB_NUMBER',
  '!DELETE_EXPENSE_FROM_LIST',
  '!DOWNLOAD_EXPENSE_ARTIFACT',
  '!FETCH_EVENTS_LOG',
  '!FETCH_EXPENSE_DETAILS_BY_PAGING_V2',
  '!FETCH_LAST_FILING_NUMBER',
  '!FETCH_LEDGER_NUMBER_RECOMMENDATIONS',
  '!INIT_DOWNLOAD_V2',
  '!INIT_DUPLICATION',
  '!INIT_EXPENSE_LIST_PAGE_LOAD',
  '!INVOICE_NUMBER_CHECK',
  '!RESET_PAGINATION',
  '!SEARCH_JOB_NUMBERS',
  '!START_BULK_ACCOUNTING_EXPORT_V2',
  '!START_BULK_DOWNLOAD_V2',
  '!START_BULK_EXPORT_V2',
  '!STOP_BULK_ACCOUNTING_EXPORT_V2',
  '!STOP_BULK_DOWNLOAD_V2',
  '!STOP_BULK_EXPORT_V2',
  '!TRIGGER_EXPENSE_LIST_UPDATE',
  '!UPDATE_EXPENSE_WITH_RECOMMENDATION',
  '!UPLOAD_MULTIPLE_EXPENSE',
  '!USER_VIEW_EXPENSE',
  'BULK_ACCOUNTING_V2',
  'BULK_APPROVE',
  'BULK_CATEGORIZATION_V2',
  'BULK_DELETE_V2',
  'BULK_FILING_V2',
  'BULK_SYNC_V2',
  'BULK_TAGGING_V2',
  'CALL_ACCOUNTING_COMPLETE_TOGGLE',
  'CALL_EXCLUDE_FROM_ACCOUNTING_TOGGLE',
  'CALL_UNLOCK_ACCOUNTING',
  'CREATE_EXPENSE_TYPE',
  'DELETE_EXPENSE_DUPLICATION',
  'EXPENSE_APPROVE',
  'EXPENSE_FILING',
  'EXPENSE_STATUS_CHANGE',
  'FETCH_EXPENSE_CHARTS',
  'FETCH_EXPENSE_COUNT_V2',
  'FETCH_EXPENSE_DETAILS',
  'FETCH_EXPENSE_LIST_BY_PAGING_V2',
  'FETCH_EXPENSE_LIST_V2',
  'FETCH_EXPENSE_TYPES',
  'REMOVE_EXPENSE_ARTIFACT',
  'REMOVE_EXPENSE_DUPLICATION',
  'REMOVE_EXPENSE',
  'UPDATE_EXPENSE',
  'UPDATE_ORDER_V2',
  'UPDATE_ROWS_PER_PAGE_V2',
  'UPLOAD_EXPENSE',
] as const

export default serviceActionsGenerator('expense', actionTypes)
