import { AxiosResponse } from 'axios'
import { call, delay, takeLatest } from 'redux-saga/effects'

import { getErrorMessage } from '@helpers'

import { TYPING_INTERVAL } from '@constants'

import actions from './actions'
import * as api from './api'
import { serializer } from './helper'

export function* searchCompaniesSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<string>) {
  try {
    yield delay(TYPING_INTERVAL)
    const response: AxiosResponse<BackendCegjelzoSearchResult[]> = yield call(api.searchCompanies, payload)
    yield call(resolve, serializer(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

export default function* commonSaga() {
  yield takeLatest(actions.searchCompanies.REQUEST, searchCompaniesSaga)
}
