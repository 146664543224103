import __orderBy from 'lodash/orderBy'

import { CompanyUserRoles, QuickUserFeaturePermissions, QuickUserPagePermissions } from '@constants'

import { AuthInitialState, BackendAuthMeResponse } from './types'

const COMPANY_USER_PERMISSIONS_LIST = [
  ...Object.values(QuickUserPagePermissions),
  ...Object.values(QuickUserFeaturePermissions),
  'role',
] as const

const ROLES_ORDER: CompanyUserRole[] = [
  CompanyUserRoles.ADMIN,
  CompanyUserRoles.ACCOUNTANT,
  CompanyUserRoles.EDITOR,
  CompanyUserRoles.VIEWER,
  CompanyUserRoles.UNKNOWN,
]

// order company members by role and restrictions' number
export function orderCompanyMembers(members: CompanyUser[], userId: number, extendedOrder = false) {
  if (extendedOrder) {
    return __orderBy(
      members,
      [
        function (o) {
          return ROLES_ORDER.indexOf(o.role)
        },
        function (o) {
          return (o.not_allowed_expense_types || []).length
        },
        function (o) {
          return (o.not_allowed_revenue_types || []).length
        },
        function (o) {
          return o.user === userId ? 0 : 1
        },
        function (o) {
          return (o.tags_restrictions || []).length
        },
      ],
      ['asc', 'asc', 'asc', 'asc', 'desc']
    )
  }
  return __orderBy(
    members,
    [
      function (o) {
        return ROLES_ORDER.indexOf(o.role)
      },
      function (o) {
        return o.user === userId ? 0 : 1
      },
    ],
    ['asc', 'asc']
  )
}

export function serializeCompanyUser(companyUser: Partial<CompanyUser>) {
  return COMPANY_USER_PERMISSIONS_LIST.reduce((obj, key) => {
    const value = companyUser[key as keyof Partial<CompanyUser>]
    if (value !== undefined) {
      obj[key] = value
    }
    return obj
  }, {} as any)
}

export function serializeCompanyWithUserPermissions({ company_user, ...company }: BackendCompaniesItem) {
  return {
    ...company,
    ...serializeCompanyUser(company_user),
  }
}

export function serializeAuthMeData({ user, companies }: BackendAuthMeResponse) {
  return {
    user,
    companies: companies.map(serializeCompanyWithUserPermissions),
  }
}

// TODO define payload (CompanyIntegration)
export function companyWithTokenAndIntegrations(company: Company, payload: CompanyIntegration): Company {
  return {
    ...company,
    // token: payload.key, // TODO does it need to be added to the company?
    integrations: [...company.integrations, payload],
  }
}

export function updateCompaniesListIfNecessary(state: AuthInitialState, { company_type }: BackendCompaniesItem) {
  // currently we only need to update the list if `company_type` is changing, but we might need more in the future
  if (company_type) {
    const companies = [...state.companies]
    return companies.map(company => {
      if (company.id === state.company.data?.id) {
        company.company_type = company_type
      }
      return company
    })
  } else {
    return state.companies
  }
}
