import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { shouldShowBadgeView } from './helpers'
import { UserBadgeView } from './UserBadgeView'

interface UserBadgeWelcomeContainerProps {
  children: React.ReactNode
  showBadgeView: boolean
}

function PureUserBadgeWelcomeContainer({ children, showBadgeView }: UserBadgeWelcomeContainerProps) {
  const [show, setShow] = React.useState(showBadgeView)

  const closeBadgeView = React.useCallback(() => {
    setShow(false)
  }, [])

  if (show) {
    return <UserBadgeView onClose={closeBadgeView} />
  } else {
    return <>{children}</>
  }
}

PureUserBadgeWelcomeContainer.propTypes = {
  showBadgeView: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}

export const UserBadgeWelcomeContainer = connect((state: Store) => ({
  showBadgeView: shouldShowBadgeView(state),
}))(PureUserBadgeWelcomeContainer)

UserBadgeWelcomeContainer.displayName = 'UserBadgeWelcomeContainer'
