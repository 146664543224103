import React from 'react'

import __uniqueId from 'lodash/uniqueId'

import { createAriaAttributes } from '@helpers'

import { PortalProvider } from '@contexts/PortalProvider'

import {
  DialogOpenState,
  TaskTypes,
  TaxDeclarationMultiClosureDialogState,
} from '@components/pages/PulsePage/TaxationTasks/constants'
import { TaskCustomDialog } from '@components/pages/PulsePage/TaxationTasks/elements'

import { ClosureDialogHeader } from './ClosureDialogHeader'
import { ClosureResponseView } from './ClosureDialogResponseView'
import { ClosureSummaryView } from './ClosureDialogSummaryView'

interface ClosureDialogProps<BackendTaskDetailResponseType> {
  dialogState: Nullable<TaxDeclarationMultiClosureDialogState>
  onClose: VoidFunction
  openState: Nullable<DialogOpenState>
  onCompleteCallback: (payload: BackendTaskDetailResponseType) => void
  setDialogState: (payload: Nullable<TaxDeclarationMultiClosureDialogState>) => void
  supportLink?: BackendCallableLink
  taskType: TaskTypes
}

export function ClosureDialog<BackendTaskDetailResponseType>({
  dialogState,
  onClose,
  onCompleteCallback,
  openState,
  setDialogState,
  supportLink,
  taskType,
}: ClosureDialogProps<BackendTaskDetailResponseType>) {
  const ariaIdPrefix = __uniqueId('closure-dialog')
  const aria = createAriaAttributes(ariaIdPrefix)
  const [cachedOpenState, setCachedOpenState] = React.useState(openState)

  // this useEffect is needed, because otherwise an empty dialog is shown for a short time (without state)
  React.useEffect(() => {
    if (openState) {
      setCachedOpenState(openState)
    }
  }, [openState])

  function onEnterResponseState(payload?: BackendTaskDetailResponseType) {
    setDialogState(TaxDeclarationMultiClosureDialogState.RESPONSE)
    if (payload) {
      onCompleteCallback(payload)
    }
  }

  return (
    <TaskCustomDialog
      ariaIdPrefix={ariaIdPrefix}
      onClose={onClose}
      open={Boolean(openState)}
      stickyBottomOffset={0}
      stickyTopOffset={0}
    >
      {cachedOpenState && <ClosureDialogHeader aria={aria} dialogState={dialogState} openState={cachedOpenState} />}

      {dialogState === TaxDeclarationMultiClosureDialogState.SUMMARY && supportLink && (
        <PortalProvider>
          <ClosureSummaryView supportLink={supportLink} onClose={onClose} onEnterResponseState={onEnterResponseState} />
        </PortalProvider>
      )}

      {dialogState === TaxDeclarationMultiClosureDialogState.RESPONSE && cachedOpenState && (
        <ClosureResponseView aria={aria} onClose={onClose} openState={cachedOpenState} taskType={taskType} />
      )}
    </TaskCustomDialog>
  )
}
