import styled from 'styled-components'

import { CustomDialogActions, FlexColumn, ReactHookFormTextField, WarningTypography } from '@components/ui'

export const DetailsWrapperDiv = styled(FlexColumn)`
  background-color: ${({ theme }) => theme.colors.gray[10]};
  border: 1px solid ${({ theme }) => theme.colors.gray[30]};
  padding: 20px;
  border-radius: 6px;
  gap: 12px;

  ${WarningTypography} + & {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`

export const StyledCustomDialogActions = styled(CustomDialogActions)`
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`

export const StyledReactHookFormTextField = styled(ReactHookFormTextField)`
  margin-left: 34px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0 20px;
  align-items: center;
  width: auto;

  .MuiInputLabel-formControl {
    position: static;
    transform: none;
    font-size: 14px;
  }

  .MuiInput-root {
    margin: 0;
  }

  .MuiFormHelperText-root {
    grid-column: 2 / 3;
  }

  label {
    color: ${({ theme }) => theme.colors.gray[80]};
    font-weight: 700;
  }
  input {
    background-color: white;
  }
`
