import React from 'react'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { StrongTextValues } from '@helpers/translation'

import { OverflowText, Typography } from '@components/ui'

const StyledTypography = styled(Typography)`
  overflow: hidden;
`

const InvoiceHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${({ theme }) => theme.appBarHeight}px;
  padding: 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
  gap: 20px;
`

interface ArtifactViewerProps {
  children?: React.ReactNode
  documentId?: ItemIdType
  fileName?: string
  pagingCounter?: React.ReactNode
}

/**
 * Displays the document ID and filename of the current artifact being viewed.
 * It also renders the `ExpensePagingCounter` component.
 */
export function ArtifactViewerHeader({ children, documentId, fileName, pagingCounter }: ArtifactViewerProps) {
  return (
    <>
      {documentId != null && (
        <InvoiceHeaderContainer>
          <StyledTypography color="gray-80" size="400-sm">
            <OverflowText>
              {fileName != null ? (
                <FormattedMessage
                  id="artifactViewer.header.invoiceIdWithFileName"
                  defaultMessage={'<b>Számla ID:</b> {invoiceId} - {fileName}'}
                  values={{
                    ...StrongTextValues,
                    invoiceId: documentId,
                    fileName,
                  }}
                />
              ) : (
                <FormattedMessage
                  id="artifactViewer.header.invoiceId"
                  defaultMessage={'<b>Számla ID:</b> {invoiceId}'}
                  values={{ ...StrongTextValues, invoiceId: documentId }}
                />
              )}
            </OverflowText>
          </StyledTypography>
          {pagingCounter}
        </InvoiceHeaderContainer>
      )}
      {children}
    </>
  )
}
