import Qs from 'qs'

import { callUrl } from '@services/common/api'

import { injectCompanyToURL } from '@helpers'

import Api from '../api'
import {
  API_BULK_ATTACH_DOKUMENTS_URL,
  API_BULK_DOWNLOAD_DOKUMENTS_URL,
  API_BULK_REMOVE_DOKUMENTS_URL,
  API_BULK_UPLOAD_DOKUMENTS_URL,
  API_CREATE_WEBLINK_URL,
  API_DETACH_DOKUMENT_URL,
  API_DOKUMENT_DETAILS_UPDATE_URL,
  API_DOKUMENT_DETAILS_URL,
  API_DOKUMENT_TYPE_CREATE_URL,
  API_DOKUMENT_TYPE_LIST_URL,
  API_DOKUMENT_USER_SAW_URL,
  API_DOKUMENTS_BACKGROUND_ACTION_URL,
  API_DOKUMENTS_CONNECTIONS_URL,
  API_DOKUMENTS_HAS_CONNECTIONS_URL,
  API_DOKUMENTS_LIST_URL,
  API_DOKUMENTS_STATUS_CHECK_URL,
  API_FILING_NUMBER_URL,
} from './urls'

export function fetchDokuments(companyId: number, params: Partial<DokumentsParams>) {
  return Api.get<{
    next: BackendCallableLink
    prev: BackendCallableLink
    results: BackendDokumentListResult[]
  }>(injectCompanyToURL(API_DOKUMENTS_LIST_URL, companyId), {
    params,
    paramsSerializer: {
      serialize(params) {
        return Qs.stringify(params)
      },
    },
  })
}

export function fetchDokumentsByUrl(url: string) {
  return Api.get(url)
}

export function createWeblink(companyId: number, data: { link?: string; type: 'link'; title: string }) {
  return Api.post(injectCompanyToURL(API_CREATE_WEBLINK_URL, companyId), data)
}

export function bulkRemoveDokuments(companyId: number, data: BulkRemoveActionPayload) {
  return Api.post(injectCompanyToURL(API_BULK_REMOVE_DOKUMENTS_URL, companyId), data)
}

export function downloadDokuments(companyId: number, { data, params }: ApiPayload) {
  return Api.post(injectCompanyToURL(API_BULK_DOWNLOAD_DOKUMENTS_URL, companyId), {
    ...data,
    ...params,
  })
}

export function bulkUploadDokuments(companyId: number, data: { files: File[] }) {
  return Api.post(injectCompanyToURL(API_BULK_UPLOAD_DOKUMENTS_URL, companyId), data, {
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: 5 * 60000, // 5 * 60sec
    transformRequest: [
      function (data) {
        // do it like in Yeri
        const formData = new FormData()
        Object.keys(data.files).forEach((key, index) => {
          formData.append(`files[${index + 1}]`, data.files[key])
        })
        return formData
      },
    ],
  })
}

export function attachDokuments(
  companyId: number,
  { data, params }: ApiPayload,
  payload: AttachDokumentActionPayload['data']
) {
  return Api.post(injectCompanyToURL(API_BULK_ATTACH_DOKUMENTS_URL, companyId), {
    ...data,
    ...params,
    ...payload,
  })
}

export function detachDokument(
  companyId: Company['id'],
  { documentId, attachmentId, documentType }: AttachmentAsyncActionPayload
) {
  return Api.post(injectCompanyToURL(API_DETACH_DOKUMENT_URL, companyId), {
    document_id: documentId,
    document_type: documentType,
    ids: [attachmentId],
  })
}

export function fetchDokumentDetails(companyId: number, dokumentId: ItemIdType) {
  return Api.get(injectCompanyToURL(API_DOKUMENT_DETAILS_URL, companyId).replace('{document_id}', String(dokumentId)))
}

export function fetchDokumentDetailsByPaging(url: string) {
  return callUrl({ method: 'get', url })
}

export function fetchDokumentTypes(companyId: number) {
  return Api.get(injectCompanyToURL(API_DOKUMENT_TYPE_LIST_URL, companyId))
}

export function updateDokumentDetails(companyId: number, { id, ...data }: DokumentDetailsUpdatePayload) {
  return Api.patch(
    injectCompanyToURL(API_DOKUMENT_DETAILS_UPDATE_URL, companyId).replace('{document_id}', String(id)),
    data
  )
}

export function createDokumentType(data: { company: number; name: string }) {
  return Api.post(injectCompanyToURL(API_DOKUMENT_TYPE_CREATE_URL, data.company), data)
}

export function userViewDokument(companyId: number, dokumentId: number) {
  return Api.post(injectCompanyToURL(API_DOKUMENT_USER_SAW_URL, companyId).replace('{document_id}', String(dokumentId)))
}

export function statusCheck(companyId: number) {
  return Api.get<{ aiRecognitionCount: number }>(injectCompanyToURL(API_DOKUMENTS_STATUS_CHECK_URL, companyId))
}

// background actions
export function dokumentBackgroundAction(companyId: number, payload: unknown) {
  return Api.post<BackgroundActionResponse>(injectCompanyToURL(API_DOKUMENTS_BACKGROUND_ACTION_URL, companyId), payload)
}

export function fetchLastFilingNumber(companyId: number) {
  return Api.get(injectCompanyToURL(API_FILING_NUMBER_URL, companyId))
}

export function fetchConnections(companyId: number, dokumentId: ItemIdType) {
  return Api.get(
    injectCompanyToURL(API_DOKUMENTS_CONNECTIONS_URL, companyId).replace('{document_id}', String(dokumentId))
  )
}

export function hasConnections(companyId: number, data: DocumentHasConnectionsRequestPayload) {
  return Api.post(injectCompanyToURL(API_DOKUMENTS_HAS_CONNECTIONS_URL, companyId), data)
}
