import { AxiosResponse } from 'axios'
import FacebookPixel from 'react-facebook-pixel'
import { call, delay, put, select, takeLatest } from 'redux-saga/effects'

import { CommonAxiosPayload, localeActions, onboardingActions } from '@services'

import {
  AMPLITUDE_EVENTS,
  changeLanguage,
  getActiveCompanyId,
  getCompanyType,
  getDefaultUserCompanyId,
  getErrorMessage,
  getFormErrors,
  getRFFFormErrors,
  sendAmplitudeData,
  setAmplitudeUser,
  setUserProperties,
} from '@helpers'

import { CompanyIntegrationListResponse } from '@contexts/CompanyIntegrationsProvider/types'

import { QrCodeData } from '@oldComponents/settingsBlocks/TwoWayAuthBlock/types'

import { CompanyIntegrationProviders } from '@constants'

import { callUrl } from '../common/api'
import { clearToken, setToken } from '../helpers'
import actions from './actions'
import * as api from './api'
import {
  BackendAuthMeResponse,
  CreateIntegrationProviderPayload,
  isAuth2FactorResponse,
  RefreshCompanyPayload,
} from './types'

function* renewTokenSaga() {
  try {
    const response: AxiosResponse<AuthToken> = yield call(api.renewToken)
    yield call(setToken, response.data)
    yield put(actions.renewToken.success())
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.renewToken.failure(errorMsg))
    // console.error('Renew token failed:' + errorMsg)
  }
}

// worker Saga: will be fired on types.vatRatesFetchRequested actions
function* changePasswordSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<ChangePasswordFormValues>) {
  try {
    yield call(api.changePassword, payload)
    yield call(resolve)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield call(reject, formErrors)
  }
}

function* loginHandlerSaga(
  { payload, meta: { resolve, reject } }: AsyncSagaAction<LoginPayload>,
  amplitudeEvent: string
) {
  try {
    const response: AxiosResponse<AuthResponse> = yield call(api.login, payload)
    const { remember_me } = payload

    if (isAuth2FactorResponse(response.data)) {
      // pass remember_me back to 2FactorAuth
      yield call(resolve, { ...response.data, remember_me })
    } else {
      yield call(setToken, response.data)
      const userResponse: AxiosResponse<BackendAuthMeResponse> = yield call(api.fetchUser)
      yield put(actions.fetchUser.success(userResponse.data))
      yield put(localeActions.updateLocale.request(userResponse.data.user.default_language))
      const companyId: Nullable<number> = yield select(getDefaultUserCompanyId)
      if (companyId) {
        yield put(actions.selectCompany.request(companyId))
      }
      // --amplitude tracking start
      yield call(setAmplitudeUser, userResponse.data.user.id ?? null)
      yield call(sendAmplitudeData, amplitudeEvent, { success: true })
      // --amplitude tracking end
      yield put(actions.login.success())
      yield call(resolve)
    }
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield put(actions.login.failure())
    // --amplitude tracking start
    yield call(sendAmplitudeData, amplitudeEvent, { success: false })
    // --amplitude tracking end
    yield call(reject, formErrors)
  }
}

function* regularLoginSaga(data: AsyncSagaAction<LoginPayload>) {
  yield call(loginHandlerSaga, data, AMPLITUDE_EVENTS.LOGIN)
}

function* onboardingManualLoginSaga(data: AsyncSagaAction<LoginPayload>) {
  yield call(loginHandlerSaga, data, AMPLITUDE_EVENTS.ONBOARDING_MANUAL_LOGIN)
}

function* onboardingAutoLoginSaga({
  payload: { auth_token, expires, companyId },
  meta: { resolve, reject },
}: AsyncSagaAction<AuthToken & { companyId?: number }>) {
  try {
    yield call(setToken, { auth_token, expires })
    const userResponse: AxiosResponse<BackendAuthMeResponse> = yield call(api.fetchUser)
    yield put(actions.fetchUser.success(userResponse.data))
    const selectedCompanyId: Nullable<number> = companyId ? companyId : yield select(getDefaultUserCompanyId)
    if (selectedCompanyId) {
      // need to call here so the funcation can be awaited
      const response: AxiosResponse<BackendCompaniesItem> = yield call(api.fetchCompanyDetails, selectedCompanyId)
      yield put(actions.selectCompany.success(response.data))
    }
    // --amplitude tracking start
    yield call(setAmplitudeUser, userResponse.data.user.id)
    yield call(sendAmplitudeData, AMPLITUDE_EVENTS.ONBOARDING_AUTO_LOGIN, { success: true })
    // --amplitude tracking end
    yield put(actions.login.success())
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.login.failure())
    // --amplitude tracking start
    yield call(sendAmplitudeData, AMPLITUDE_EVENTS.ONBOARDING_AUTO_LOGIN, { success: false })
    // --amplitude tracking end
    yield call(reject, errorMsg)
  }
}

function* logoutSaga() {
  try {
    yield call(api.logout)
    yield call(clearToken)
    // --amplitude tracking start
    yield call(setAmplitudeUser, null)
    // --amplitude tracking end
    yield put(actions.logout.success())
  } catch (error) {
    // silent error
  }
}

// same as logout but do not call api.logout (change password success callback)
function* forceToLoginSaga() {
  try {
    yield call(clearToken)
    // --amplitude tracking start
    yield call(setAmplitudeUser, null)
    // --amplitude tracking end
    yield put(actions.logout.success())
  } catch (error) {
    // silent error
  }
}

function* resetPasswordSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<ResetPasswordFormValues>) {
  try {
    yield call(api.resetPassword, payload)
    yield put(actions.resetPassword.success())
    yield call(resolve)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield put(actions.resetPassword.failure())
    yield call(reject, formErrors)
  }
}

function* resetPasswordConfirmSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<ResetPasswordConfirmFormValues>) {
  try {
    yield call(api.resetPasswordConfirm, payload)
    yield put(actions.resetPasswordConfirm.success())
    yield call(resolve)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield put(actions.resetPasswordConfirm.failure())
    yield call(reject, formErrors)
  }
}

function* signupSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<SignupFormValues>) {
  try {
    const response: AxiosResponse<AuthToken> = yield call(api.signup, payload)
    yield call(setToken, response.data)
    const userResponse: AxiosResponse<BackendAuthMeResponse> = yield call(api.fetchUser)
    yield put(actions.fetchUser.success(userResponse.data))
    yield put(localeActions.updateLocale.request(userResponse.data.user.default_language))
    const companyId: Nullable<number> = yield select(getDefaultUserCompanyId)
    if (companyId) {
      yield put(actions.selectCompany.request(companyId))
    }
    // --amplitude tracking start
    yield call(setAmplitudeUser, userResponse.data.user.id)
    const company_type: Nullable<string> = yield select(getCompanyType, userResponse.data.companies[0]?.company_type)
    yield call(setUserProperties, { company_type })
    yield call(sendAmplitudeData, AMPLITUDE_EVENTS.REGISTRATION_SUCCESS)
    // --amplitude tracking end
    // -- Facebook Pixel track CompleteRegistration
    yield call(FacebookPixel.track, 'CompleteRegistration')
    yield put(actions.signup.success())
    yield call(resolve)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield put(actions.signup.failure())
    yield call(reject, formErrors)
  }
}

// INVITE
function* loadInvitationDetailsSaga({ payload }: SagaAction<string>) {
  try {
    const response: AxiosResponse<InvitationData> = yield call(api.loadInvitationDetails, payload)
    yield put(actions.loadInvitationDetails.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.loadInvitationDetails.failure(errorMsg))
  }
}
// similar as sign up
function* acceptInvitationSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<Required<SignupFormValues>>) {
  try {
    const response: AxiosResponse<AuthToken> = yield call(api.acceptInvitation, payload)
    yield call(setToken, response.data)
    const userResponse: AxiosResponse<BackendAuthMeResponse> = yield call(api.fetchUser)
    yield put(actions.fetchUser.success(userResponse.data))
    const companyId: Nullable<number> = yield select(getDefaultUserCompanyId)
    if (companyId) {
      yield put(actions.selectCompany.request(companyId))
    }
    // --amplitude tracking start
    yield call(setAmplitudeUser, userResponse.data.user.id)
    const company_type: Nullable<string> = yield select(getCompanyType, userResponse.data.companies[0]?.company_type)
    yield call(setUserProperties, { company_type })
    yield call(sendAmplitudeData, AMPLITUDE_EVENTS.INVITATION_SUCCESS)
    // --amplitude tracking end
    // -- Facebook Pixel track CompleteRegistration
    yield call(FacebookPixel.track, 'CompleteRegistration')
    yield put(actions.acceptInvitation.success())
    yield call(resolve)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield put(actions.acceptInvitation.failure())
    yield call(reject, formErrors)
  }
}

// USER
function* userSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<{ companyId?: Company['id'] }>) {
  try {
    const userResponse: AxiosResponse<BackendAuthMeResponse> = yield call(api.fetchUser)
    yield put(localeActions.setLocale.request(userResponse.data.user.default_language))
    // --amplitude tracking start
    yield call(setAmplitudeUser, userResponse.data.user.id)
    // --amplitude tracking end
    yield delay(1000) // wait 1 sec before call api (avoid flashing screen)
    yield put(actions.fetchUser.success(userResponse.data))
    if (payload?.companyId) {
      yield put(actions.selectCompany.request(payload.companyId))
    }
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// USER BADGE
function* updateBadgeDisplayedAtSaga({ payload }: SagaAction<string>) {
  try {
    const response: AxiosResponse<User> = yield call(api.updateBadgeDisplayedAt, payload)
    yield put(actions.updateBadgeDisplayedAt.success(response.data))
  } catch (error) {
    // ignore error
  }
}

// USER NOTIFICATIONS
function* updateNotificationDisplayedAtSaga({ payload }: SagaAction<{ id: number; displayed_at: string }>) {
  try {
    yield call(api.updateNotificationDisplayedAt, payload)
    yield put(actions.updateNotificationDisplayedAt.success(payload))
  } catch (error) {
    // ignore error
  }
}

// USER COMPANIES
function* userCompaniesSaga() {
  try {
    const response: AxiosResponse<UserCompany[]> = yield call(api.fetchUserCompanies)
    yield put(actions.fetchUserCompanies.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchUserCompanies.failure(errorMsg))
  }
}

// data is a User object
function* removeAccountSaga({ payload: { data }, meta: { resolve, reject } }: AsyncSagaAction<{ data: User }>) {
  try {
    yield call(callUrl, { url: data.links.delete as string, method: 'delete' })
    yield call(clearToken)
    // --amplitude tracking start
    yield call(setAmplitudeUser, null)
    // --amplitude tracking end
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// change and set language when change user profile
function* updatePersonalInfoSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<PersonalInfoFormValues>) {
  try {
    const response: AxiosResponse<User> = yield call(api.updatePersonalInfo, payload)
    yield put(actions.updatePersonalInfo.success(response.data))
    yield call(changeLanguage, response.data.default_language)
    yield call(resolve)
    yield delay(1000)
    yield put(localeActions.updateLocale.request(response.data.default_language))
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield call(reject, formErrors)
  }
}

// change user preferences
function* changePreferencesSaga({ payload }: SagaAction<Partial<UserPreferences>>) {
  try {
    const response: AxiosResponse<UserPreferences> = yield call(api.changeUserPreferences, payload)
    yield put(actions.changePreferences.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.changePreferences.failure(errorMsg))
  }
}
function* restorePreferencesSaga({ meta: { resolve } }: AsyncSagaAction) {
  try {
    const response: AxiosResponse<UserPreferences> = yield call(api.restoreUserPreferences)
    yield put(actions.changePreferences.success(response.data))
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.changePreferences.failure(errorMsg))
  }
}

// COMPANY
function* refreshCompanySaga({ payload }: SagaAction<RefreshCompanyPayload>) {
  let companyId = payload
  if (!companyId) {
    companyId = (yield select(getActiveCompanyId)) as Company['id']
  }
  const response: AxiosResponse<BackendCompaniesItem> = yield call(api.fetchCompanyDetails, companyId)
  yield put(actions.fetchCompany.success(response.data))
}

function* refreshCompanyAsyncSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<RefreshCompanyPayload>) {
  try {
    yield put(actions.refreshCompany.request(payload))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchCompany.failure())
    yield call(reject, errorMsg)
  }
}

function* selectCompanySaga({ payload }: SagaAction<Company['id']>) {
  try {
    const response: AxiosResponse<BackendCompaniesItem> = yield call(api.fetchCompanyDetails, payload)
    yield put(actions.selectCompany.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.selectCompany.failure(errorMsg))
  }
}

// MULTI COMPANY
function* createCompanySaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<CreateCompanyFormValues>) {
  try {
    const response: AxiosResponse<BackendCompaniesItem> = yield call(api.createCompany, payload)
    yield put(actions.createCompany.success(response.data))
    yield call(resolve, response.data)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield call(reject, formErrors)
  }
}

function* updateCompanySaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<Partial<Company>>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<BackendCompaniesItem> = yield call(api.updateCompany, { id: companyId, ...payload })
    yield put(actions.updateCompany.success(response.data))
    yield call(resolve)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield call(reject, formErrors)
  }
}
// RFF version of updateCompany
function* updateCompanyRffSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<Partial<Company>>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<BackendCompaniesItem> = yield call(api.updateCompany, { id: companyId, ...payload })
    yield put(actions.updateCompany.success(response.data))
    yield call(resolve)
  } catch (error) {
    const formErrors = getRFFFormErrors(error)
    yield call(reject, formErrors)
  }
}

function* updateCompanyFieldSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<Partial<Company>>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<BackendCompaniesItem> = yield call(api.updateCompany, { id: companyId, ...payload })
    yield put(actions.updateCompanyField.success(response.data))
    yield call(resolve)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield call(reject, formErrors)
  }
}

function* removeCompanySaga({
  payload: { company },
  meta: { resolve, reject },
}: AsyncSagaAction<{ company: Company }>) {
  try {
    yield call(callUrl, { url: company.links.delete as string, method: 'delete' })
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// COMPANY MEMBER
function* fetchCompanyMembersSaga() {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<{ company_user_template: CompanyUser; users: CompanyUser[] }> = yield call(
      api.fetchCompanyMembers,
      companyId
    )
    yield put(actions.fetchCompanyMembers.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchCompanyMembers.failure(errorMsg))
  }
}
function* createCompanyMemberSaga({
  payload: { isInvited, ...restPayload },
  meta: { resolve, reject },
}: AsyncSagaAction<Omit<CompanyUser, 'id'> & { isInvited: boolean }>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<CompanyUser> = yield call(api.createCompanyMember, companyId, restPayload)
    yield put(actions.createCompanyMember.success(response.data))
    // if it is an invite approve, refetch invites as well
    // isInvited is created as initialValue in `<InvitesTable />`
    if (isInvited) {
      yield put(onboardingActions.fetchOnboardingInviteApprovals.request())
    }
    yield call(resolve)
  } catch (error) {
    const formErrors = getRFFFormErrors(error)
    yield call(reject, formErrors)
  }
}

function* updateCompanyMemberSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<CompanyUser>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<CompanyUser> = yield call(api.updateCompanyMember, companyId, payload)
    yield put(actions.updateCompanyMember.success(response.data))
    yield call(resolve)
  } catch (error) {
    const formErrors = getRFFFormErrors(error)
    yield call(reject, formErrors)
  }
}

function* removeCompanyMemberSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<{ company: Company | UserCompany; data: CompanyUser }>) {
  try {
    const companyId = payload.company.id
    const companyUserId = payload.data.id
    yield call(api.removeCompanyMember, companyId, companyUserId)
    yield put(actions.removeCompanyMember.success(companyUserId))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.removeCompanyMember.failure())
    yield call(reject, errorMsg)
  }
}

function* removeCompanyMembershipSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<{ company: Company | UserCompany; data: CompanyUser }>) {
  try {
    const companyId = payload.company.id
    const companyUserId = payload.data.id
    yield call(api.removeCompanyMember, companyId, companyUserId)
    yield put(actions.removeCompanyMembership.success(companyUserId))
    yield call(resolve, companyId)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// COMPANY VAT
function* fetchCompanyVatsSaga() {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<CompanyVatType[]> = yield call(api.fetchCompanyVats, companyId)
    yield put(actions.fetchCompanyVats.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchCompanyVats.failure(errorMsg))
  }
}
function* createCompanyVatSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<CompanyVatFormValues>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<CompanyVatType> = yield call(api.createCompanyVat, companyId, payload)
    yield put(actions.createCompanyVat.success(response.data))
    yield call(resolve)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield put(actions.createCompanyVat.failure())
    yield call(reject, formErrors)
  }
}

function* updateCompanyVatSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<Required<CompanyVatFormValues>>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<CompanyVatType> = yield call(api.updateCompanyVat, companyId, payload)
    yield put(actions.updateCompanyVat.success(response.data))
    yield call(resolve)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield put(actions.updateCompanyVat.failure())
    yield call(reject, formErrors)
  }
}

function* removeCompanyVatSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<{ company: Company | UserCompany; data: CompanyVatType }>) {
  try {
    const companyId = payload.company.id
    const companyVatId = payload.data.id
    yield call(api.removeCompanyVat, companyId, companyVatId)
    yield put(actions.removeCompanyVat.success(companyVatId))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.removeCompanyVat.failure())
    yield call(reject, errorMsg)
  }
}
function* createIntegrationProviderSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<CreateIntegrationProviderPayload>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<CompanyIntegration> = yield call(api.createIntegrationProvider, companyId, payload)
    yield put(actions.createIntegrationProvider.success(response.data))
    // refresh company details
    yield put(actions.refreshCompany.request(companyId))
    yield call(resolve, response.data) // need for local state update
  } catch (error) {
    if (payload.integration === CompanyIntegrationProviders.szamlazz) {
      // no form in SzamlazzHu flow
      const errorMsg = getErrorMessage(error)
      yield call(reject, errorMsg)
    } else {
      const formErrors = getRFFFormErrors(error)
      yield call(reject, formErrors)
    }
  }
}

function* updateIntegrationProviderSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<CreateIntegrationProviderPayload & { id: number }>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<CompanyIntegration> = yield call(api.updateIntegrationProvider, companyId, payload)
    yield put(actions.updateIntegrationProvider.success(response.data))
    yield call(resolve, response.data) // need for local state update
  } catch (error) {
    const formErrors = getRFFFormErrors(error)
    yield call(reject, formErrors)
  }
}

function* loadIntegrationProvidersSaga({ meta: { resolve, reject } }: AsyncSagaAction) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<CompanyIntegrationListResponse> = yield call(api.loadIntegrationProviders, companyId)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// 2 FACTOR AUTH
function* getAuthStatusSaga({ meta: { resolve, reject } }: AsyncSagaAction) {
  try {
    const response: AxiosResponse<{ has_auth: boolean }> = yield call(api.getAuthStatus)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* getAuthDataSaga({ meta: { resolve, reject } }: AsyncSagaAction) {
  try {
    const response: AxiosResponse<QrCodeData> = yield call(api.getAuthData)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* validateDeviceSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<{ token: string }>) {
  try {
    const response: AxiosResponse<{ backup_tokens: string[] }> = yield call(api.validateDevice, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* updateBackupTokensSaga({ meta: { resolve, reject } }: AsyncSagaAction) {
  try {
    const response: AxiosResponse<{ backup_tokens: string[] }> = yield call(api.updateBackupTokens)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* clearTwoFactorAuthSaga({ meta: { resolve, reject } }: AsyncSagaAction) {
  try {
    yield call(api.clearTwoFactorAuth)
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

//* TAXATION
function* setTaxationTaxFormSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<SetTaxFormPayload>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    yield call(api.taxationSetTaxForm, companyId, payload)
    yield call(resolve)
  } catch (error) {
    const formErrors = getRFFFormErrors(error)
    yield call(reject, formErrors)
  }
}

function* callCustomFieldUrlSaga<Payload>({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<CommonAxiosPayload<Payload>>) {
  try {
    const response: AxiosResponse<ExpenseCustomFieldConfig[]> = yield call(callUrl, payload)
    yield put(actions.callCustomFieldUrl.success(response.data))
    yield call(resolve)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield call(reject, formErrors)
  }
}

function* callCustomFieldRemoveUrlSaga<Payload>({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<CommonAxiosPayload<Payload>>) {
  try {
    const response: AxiosResponse<ExpenseCustomFieldConfig[]> = yield call(callUrl, payload)
    yield put(actions.callCustomFieldUrl.success(response.data))
    yield call(resolve)
  } catch (error) {
    const errorMessage = getErrorMessage(error)
    yield call(reject, errorMessage)
  }
}

// watcher Saga
export default function* commonSaga() {
  // RENEW TOKEN
  yield takeLatest(actions.renewToken.REQUEST, renewTokenSaga)
  // AUTH
  yield takeLatest(actions.changePassword.REQUEST, changePasswordSaga)
  yield takeLatest(actions.login.REQUEST, regularLoginSaga)
  yield takeLatest(actions.onboardingManualLogin.REQUEST, onboardingManualLoginSaga)
  yield takeLatest(actions.onboardingAutoLogin.REQUEST, onboardingAutoLoginSaga)
  yield takeLatest(actions.logout.REQUEST, logoutSaga)
  yield takeLatest(actions.forceToLogin.REQUEST, forceToLoginSaga)
  yield takeLatest(actions.resetPassword.REQUEST, resetPasswordSaga)
  yield takeLatest(actions.resetPasswordConfirm.REQUEST, resetPasswordConfirmSaga)
  yield takeLatest(actions.signup.REQUEST, signupSaga)
  yield takeLatest(actions.updatePersonalInfo.REQUEST, updatePersonalInfoSaga)
  // 2 FACTOR AUTH
  yield takeLatest(actions.getAuthStatus.REQUEST, getAuthStatusSaga)
  yield takeLatest(actions.getAuthData.REQUEST, getAuthDataSaga)
  yield takeLatest(actions.validateDevice.REQUEST, validateDeviceSaga)
  yield takeLatest(actions.updateBackupTokens.REQUEST, updateBackupTokensSaga)
  yield takeLatest(actions.clearTwoFactorAuth.REQUEST, clearTwoFactorAuthSaga)

  // USER
  yield takeLatest(actions.fetchUser.REQUEST, userSaga)
  yield takeLatest(actions.fetchUserCompanies.REQUEST, userCompaniesSaga)
  // USER BADGE
  yield takeLatest(actions.updateBadgeDisplayedAt.REQUEST, updateBadgeDisplayedAtSaga)
  yield takeLatest(actions.removeAccount.REQUEST, removeAccountSaga)
  // COMPANY
  yield takeLatest(actions.fetchCompany.REQUEST, refreshCompanyAsyncSaga)
  yield takeLatest(actions.refreshCompany.REQUEST, refreshCompanySaga)
  yield takeLatest(actions.selectCompany.REQUEST, selectCompanySaga)
  yield takeLatest(actions.createCompany.REQUEST, createCompanySaga)
  yield takeLatest(actions.updateCompany.REQUEST, updateCompanySaga)
  yield takeLatest(actions.updateCompanyRff.REQUEST, updateCompanyRffSaga)
  yield takeLatest(actions.updateCompanyField.REQUEST, updateCompanyFieldSaga)
  yield takeLatest(actions.removeCompany.REQUEST, removeCompanySaga)
  // COMPANY MEMBERS
  yield takeLatest(actions.fetchCompanyMembers.REQUEST, fetchCompanyMembersSaga)
  yield takeLatest(actions.createCompanyMember.REQUEST, createCompanyMemberSaga)
  yield takeLatest(actions.updateCompanyMember.REQUEST, updateCompanyMemberSaga)
  yield takeLatest(actions.removeCompanyMember.REQUEST, removeCompanyMemberSaga)
  yield takeLatest(actions.removeCompanyMembership.REQUEST, removeCompanyMembershipSaga)
  // INVITE
  yield takeLatest(actions.loadInvitationDetails.REQUEST, loadInvitationDetailsSaga)
  yield takeLatest(actions.acceptInvitation.REQUEST, acceptInvitationSaga)
  // COMPANY VAT
  yield takeLatest(actions.fetchCompanyVats.REQUEST, fetchCompanyVatsSaga)
  yield takeLatest(actions.createCompanyVat.REQUEST, createCompanyVatSaga)
  yield takeLatest(actions.updateCompanyVat.REQUEST, updateCompanyVatSaga)
  yield takeLatest(actions.removeCompanyVat.REQUEST, removeCompanyVatSaga)
  // INTEGRATION
  yield takeLatest(actions.createIntegrationProvider.REQUEST, createIntegrationProviderSaga)
  yield takeLatest(actions.updateIntegrationProvider.REQUEST, updateIntegrationProviderSaga)
  yield takeLatest(actions.loadIntegrationProviders.REQUEST, loadIntegrationProvidersSaga)
  // USER NOTIFICATIONS
  yield takeLatest(actions.updateNotificationDisplayedAt.REQUEST, updateNotificationDisplayedAtSaga)
  // PREFERENCES
  yield takeLatest(actions.changePreferences.REQUEST, changePreferencesSaga)
  yield takeLatest(actions.restorePreferences.REQUEST, restorePreferencesSaga)
  // TAXATION
  yield takeLatest(actions.taxationSetTaxForm.REQUEST, setTaxationTaxFormSaga)
  yield takeLatest(actions.callCustomFieldUrl.REQUEST, callCustomFieldUrlSaga)
  yield takeLatest(actions.callCustomFieldRemoveUrl.REQUEST, callCustomFieldRemoveUrlSaga)
}
