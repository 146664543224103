import React from 'react'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { IntercomLauncherButton } from '@components/Intercom'

import invoiceNotFoundIconSrc from '@assets/img/not_found_icon.svg'

import { Button } from './Buttons'
import { InheritLink } from './Links'
import { Typography } from './Typography'
import { FlexColumn } from './Wrappers'

import { CustomerServiceButtonOrLinkMessage, ReloadPageButtonMessage, UnexpectedErrorMessage } from '@messages'

const Wrapper = styled(FlexColumn)`
  gap: 30px;
  max-width: 730px;
  text-align: center;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
`

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;

  & button {
    min-width: 110px;
  }

  &:first-child {
    grid-column: 1/2;
  }
  &:last-child {
    grid-column: 1/2;
  }
`

interface CustomErrorBoundaryViewProps {
  eventId?: Nullable<string>
  withIntercom?: boolean
}

export function CustomErrorBoundaryView({ eventId, withIntercom = false }: CustomErrorBoundaryViewProps) {
  function handleReloadWindow() {
    window.location.reload()
  }

  return (
    <Wrapper>
      <img role="presentation" alt="" src={invoiceNotFoundIconSrc} />
      <Typography size="heading-1" color="gray-80">
        {UnexpectedErrorMessage}
      </Typography>
      <Typography size="400-md" color="gray-80">
        {withIntercom ? (
          <FormattedMessage
            id="views.errorBoundary.withIntercom.text"
            defaultMessage="Sajnáljuk az esetleges kellemetlenségeket. Ezt a hibát automatikusan rögzítettük, kijavításán dolgozunk. Kérjük, próbáld meg az oldal újratöltését. Ha a hiba továbbra is fennáll, fordulj ügyfélszolgálatunkhoz."
          />
        ) : (
          <FormattedMessage
            id="views.errorBoundary.withoutIntercom.text"
            defaultMessage="Sajnáljuk az esetleges kellemetlenségeket. Ezt a hibát automatikusan rögzítettük, kijavításán dolgozunk. Kérjük, próbáld meg az oldal újratöltését. Ha a hiba továbbra is fennáll, <mailto>írj nekünk a hello@riport.app címre</mailto>."
            values={{
              mailto: function renderInheritLink(chunks: string) {
                return <InheritLink href={`mailto:hello@riport.app`}>{chunks}</InheritLink>
              },
            }}
          />
        )}
      </Typography>
      {eventId && (
        <Typography size="700-sm" color="gray-80">
          <FormattedMessage
            id="views.errorBoundary.errorId"
            defaultMessage="Hibaazonosító: {eventId}"
            values={{ eventId }}
          />
        </Typography>
      )}
      <ButtonsContainer>
        <Button variant="primaryContained" onClick={handleReloadWindow}>
          {ReloadPageButtonMessage}
        </Button>
        {withIntercom && (
          <IntercomLauncherButton variant="secondaryContained">
            {CustomerServiceButtonOrLinkMessage}
          </IntercomLauncherButton>
        )}
      </ButtonsContainer>
    </Wrapper>
  )
}
