import PropTypes from 'prop-types'

import { Yearly22KataCompanyOwnerFormValues } from './KataTasks'
import { Yearly23KataCompanyOwnerFormValues } from './KataTasks/KataClosureDialogControls/types'
import { TbjSzochoCompanyOwnerFormValues } from './TbjSzochoTasks'
import { TaxationTaskData } from './types'

export enum TaskTypes {
  SZJA_ELOLEG_FIZETES = 'szja-eloleg-fizetes',
  BEVALLAS_2258_TB_SZOCHO = 'tb-szocho-bevallas',
  BEVALLAS_2358_TB_SZOCHO = 'bevallas-2358-tbj-szocho',
  BEVALLAS_2458_TB_SZOCHO = 'bevallas-2458-tbj-szocho',
  BEVALLAS_2558_TB_SZOCHO = 'bevallas-2558-tbj-szocho',
  KATA_ADO_FIZETES = 'kata-ado-fizetes',
  BEVALLAS_KATA = 'kata-bevallas', // TODO remove it when BE replace it with new format
  BEVALLAS_22_KATA = 'bevallas-22-kata',
  BEVALLAS_23_KATA = 'bevallas-23-kata',
  BEVALLAS_24_KATA = 'bevallas-24-kata',
  BEVALLAS_22_SZJA = 'bevallas-22-szja',
  BEVALLAS_23_SZJA = 'bevallas-23-szja',
  BEVALLAS_24_SZJA = 'bevallas-24-szja',
  UNSUPPORTED = 'unsupported',
}

export const ACCORDION_PROP_TYPES = {
  refetchReports: PropTypes.func.isRequired,
  report: PropTypes.shape({
    name: PropTypes.string.isRequired,
    data: PropTypes.shape({
      due_at: PropTypes.string.isRequired,
      headline: PropTypes.string,
      status: PropTypes.oneOf(['todo', 'completed']).isRequired,
      task_type: PropTypes.oneOf(Object.values(TaskTypes)).isRequired,
      year: PropTypes.number.isRequired,
    }).isRequired as React.Validator<TaxationTaskData>,
    links: PropTypes.shape({
      details: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export const COMPLETED_STATUS = 'completed'

export const YEARLY_22_KATA_COMPANY_OWNER_EMPTY_VALUES: Yearly22KataCompanyOwnerFormValues = {
  adozoTitulus: null,
  adozoCsaladinev: '',
  adozoUtonev: '',
  adoazonosito: '',
  szekhelyIrszam: '',
  szekhelyVaros: '',
}

export const YEARLY_23_KATA_COMPANY_OWNER_EMPTY_VALUES: Yearly23KataCompanyOwnerFormValues = {
  adozoTitulus: null,
  adozoCsaladinev: '',
  adozoUtonev: '',
  adoazonosito: '',
}

export const TBJ_SZOCHO_COMPANY_OWNER_EMPTY_VALUES: TbjSzochoCompanyOwnerFormValues = {
  ...YEARLY_22_KATA_COMPANY_OWNER_EMPTY_VALUES,
  szuletesiCsaladinev: '',
  szuletesiUtonev: '',
  adozoNeme: null,
  allampolgar: '',
  taj: '',
  anyjaCsaladinev: '',
  anyjaUtonev: '',
  szuletesiHely: '',
  szuletesiIdo: '',
  szekhelyKozterNev: '',
  szekhelyKozterJelleg: '',
  szekhelyHsz: '',
  szekhelyEp: null,
  szekhelyLcs: null,
  szekhelyEm: null,
  szekhelyAjto: null,
}

export const YEARLY_22_KATA_BEVALLAS_DATA_PROP_TYPES = PropTypes.shape({
  companyOwnerData: PropTypes.shape({
    adozoTitulus: PropTypes.string,
    adozoCsaladinev: PropTypes.string.isRequired,
    adozoUtonev: PropTypes.string.isRequired,
    adoazonosito: PropTypes.string.isRequired,
    szekhelyIrszam: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    szekhelyVaros: PropTypes.string.isRequired,
  }).isRequired,
  options: PropTypes.shape({
    adozoTitulus: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
  links: PropTypes.shape({
    update: PropTypes.string.isRequired,
  }).isRequired,
})

export const YEARLY_23_KATA_BEVALLAS_DATA_PROP_TYPES = PropTypes.shape({
  companyOwnerData: PropTypes.shape({
    adozoTitulus: PropTypes.string,
    adozoCsaladinev: PropTypes.string.isRequired,
    adozoUtonev: PropTypes.string.isRequired,
    adoazonosito: PropTypes.string.isRequired,
  }).isRequired,
  options: PropTypes.shape({
    adozoTitulus: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
  links: PropTypes.shape({
    update: PropTypes.string.isRequired,
  }).isRequired,
})

export const TBJ_SZOCHO_BEVALLAS_DATA_PROP_TYPES = PropTypes.shape({
  companyOwnerData: PropTypes.shape({
    adozoTitulus: PropTypes.string,
    adozoCsaladinev: PropTypes.string.isRequired,
    adozoUtonev: PropTypes.string.isRequired,
    szuletesiCsaladinev: PropTypes.string.isRequired,
    szuletesiUtonev: PropTypes.string.isRequired,
    adozoNeme: PropTypes.number,
    allampolgar: PropTypes.string.isRequired,
    adoazonosito: PropTypes.string.isRequired,
    taj: PropTypes.string.isRequired,
    anyjaCsaladinev: PropTypes.string.isRequired,
    anyjaUtonev: PropTypes.string.isRequired,
    szuletesiHely: PropTypes.string,
    szuletesiIdo: PropTypes.string.isRequired,
    szekhelyIrszam: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    szekhelyVaros: PropTypes.string.isRequired,
    szekhelyKozterNev: PropTypes.string.isRequired,
    szekhelyKozterJelleg: PropTypes.string.isRequired,
    szekhelyHsz: PropTypes.string.isRequired,
    szekhelyEp: PropTypes.string,
    szekhelyLcs: PropTypes.string,
    szekhelyEm: PropTypes.string,
    szekhelyAjto: PropTypes.string,
  }).isRequired,
  options: PropTypes.shape({
    adozoNeme: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired,
      }).isRequired
    ).isRequired,
    adozoTitulus: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
    allampolgar: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
    szekhelyKozterJelleg: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
  links: PropTypes.shape({
    update: PropTypes.string.isRequired,
  }).isRequired,
})

export enum CompanyOwnerFormFieldNames {
  TITLE = 'adozoTitulus',
  SURNAME = 'adozoCsaladinev',
  FIRST_NAME = 'adozoUtonev',
  BIRTH_SURNAME = 'szuletesiCsaladinev',
  BIRTH_FIRST_NAME = 'szuletesiUtonev',
  GENDER = 'adozoNeme',
  TAX_NUMBER = 'adoazonosito',
  TAJ_NUMBER = 'taj',
  NATIONALITY = 'allampolgar',
  MOTHER_SURNAME = 'anyjaCsaladinev',
  MOTHER_FIRST_NAME = 'anyjaUtonev',
  BIRTH_PLACE = 'szuletesiHely',
  BIRTH_DATE = 'szuletesiIdo',
  COMPANY_ZIP_CODE = 'szekhelyIrszam',
  COMPANY_CITY = 'szekhelyVaros',
  COMPANY_ADDRESS_1 = 'szekhelyKozterNev',
  COMPANY_ADDRESS_2 = 'szekhelyKozterJelleg',
  COMPANY_ADDRESS_3 = 'szekhelyHsz',
  COMPANY_ADDRESS_4 = 'szekhelyEp',
  COMPANY_ADDRESS_5 = 'szekhelyLcs',
  COMPANY_ADDRESS_6 = 'szekhelyEm',
  COMPANY_ADDRESS_7 = 'szekhelyAjto',
}

export enum DialogLoadingSource {
  DOWNLOAD_BUTTON = 'downloadButton',
  ACCEPT_RESPONSE_BUTTON = 'acceptResponseButton',
}

// use every states here
export enum DialogOpenState {
  SUPPORT = 'support',
  XML = 'xml',
  KATA_NO_TASK_RESPONSE = 'kata_skip_bevallas',
  CONTINUE = 'continue',
  TAX_OVERWRITE_FORM = 'taxOverwriteForm',
  CONFIRM = 'confirm',
}

export enum TaxDeclarationMultiClosureDialogState {
  ACCEPT = 'accept',
  TAX_OVERWRITE = 'taxOverwrite',
  CONTINUE = 'continue',
  FORM = 'form',
  SUMMARY = 'summary',
  RESPONSE = 'response',
}

export enum TaxationTaskTypes {
  SZJA = 'szja',
  TBJ_SZOCHO = 'tbjSzocho',
}
