import { serviceActionsGenerator } from '../serviceGenerator'

const actionTypes = [
  '!FETCH_EXPENSE_DETAILS',
  '!FETCH_QUARANTINE_DETAILS_BY_PAGING_V2',
  '!INIT_QUARANTINE_LIST_PAGE_LOAD',
  '!MERGE_INVOICES_V2',
  '!RESET_PAGINATION',
  '!START_BULK_EXPORT_V2',
  '!STOP_BULK_EXPORT_V2',
  '!TRIGGER_EXPENSE_UPDATE_V2',
  '!TRIGGER_QUARANTINE_LIST_UPDATE',
  '!TRIGGER_QUARANTINE_UPDATE_V2',
  'BULK_APPROVE_QUARANTINE_INVOICES_V2',
  'BULK_REJECT_QUARANTINE_INVOICES_V2',
  'CHECK_QUARANTINE_EXPENSE_EXISTS',
  'CLEAR_QUARANTINE_INVOICE_DETAILS',
  'FETCH_QUARANTINE_CHARTS',
  'FETCH_QUARANTINE_INVOICE_DETAILS',
  'FETCH_QUARANTINE_LIST_BY_PAGING_V2',
  'FETCH_QUARANTINE_LIST_V2',
  'UPDATE_ORDER_V2',
  'UPDATE_QUARANTINE_INVOICE',
  'UPDATE_ROWS_PER_PAGE_V2',
  'UPLOAD_ACCEPTED_QUARANTINE_INVOICE',
  'UPLOAD_QUARANTINE_INVOICE',
] as const

export default serviceActionsGenerator('quarantine', actionTypes)
