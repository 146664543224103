import { AxiosResponse } from 'axios'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { CommonAxiosPayload } from '@services/types'

import { AMPLITUDE_EVENTS, getActiveCompanyId, getErrorMessage, getFormErrors, sendAmplitudeData } from '@helpers'

import { BRAINTREE_PAYMENT_ERROR_CODE } from '@constants'

import authActions from '../auth/actions'
import { callUrl } from '../common/api'
import actions from './actions'
import * as api from './api'
import { BackendSubscriptionPlanResults } from './types'

export function* fetchSubscriptionPlansSaga() {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<BackendSubscriptionPlanResults> = yield call(api.fetchSubscriptionPlans, companyId)
    yield put(actions.fetchSubscriptionPlans.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchSubscriptionPlans.failure(errorMsg))
  }
}

export function* applyPromocodeSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<{ promo_code: string }>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<Pick<BackendSubscriptionPlanResults, 'plans' | 'promo'>> = yield call(
      api.applyPromocode,
      companyId,
      payload
    )
    yield put(actions.applyPromocode.success(response.data))
    yield call(resolve)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield call(reject, formErrors)
  }
}

export function* createSubscriptionSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<{
  plan_id: number
  promo_code: string | undefined
  nonce: unknown
}>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<CompanySubscription> = yield call(api.createSubscription, companyId, payload)
    yield call(sendAmplitudeData, AMPLITUDE_EVENTS.SUBSCRIPTION_SUCCESS)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

export function* updateSubscriptionSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<{
  id: string
  new_plan_id: number
  new_plan_price: Decimal
  trial: boolean
}>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<CompanySubscription> = yield call(api.updateSubscription, companyId, payload)
    yield call(sendAmplitudeData, AMPLITUDE_EVENTS.SUBSCRIPTION_CHANGE, {
      subscription_id: payload.id,
      to_plan_id: payload.new_plan_id,
    })
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

export function* cancelSubscriptionSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<CommonAxiosPayload<{ id: number }>>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<CompanySubscription> = yield call(callUrl, payload)
    yield put(actions.cancelSubscription.success({ companyId, subscription: response.data }))
    yield put(authActions.setActiveSubscription.request(response.data))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

export function* fetchSubscriptionHistorySaga() {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<SubscriptionHistoryData[]> = yield call(api.fetchSubscriptionHistory, companyId)
    yield put(actions.fetchSubscriptionHistory.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchSubscriptionHistory.failure(errorMsg))
  }
}

// braintree
export function* createPaymentMethodNonceSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<{ nonce: unknown }>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    // TODO define "nonce" type later
    const response: AxiosResponse<unknown> = yield call(api.createPaymentMethodNonce, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, { code: BRAINTREE_PAYMENT_ERROR_CODE, message: errorMsg }) // must return BraintreeCustomError shape
  }
}

// watcher Saga
export default function* commonSaga() {
  yield takeLatest(
    [actions.fetchSubscriptionPlans.REQUEST, actions.cancelSubscription.SUCCESS],
    fetchSubscriptionPlansSaga
  )
  yield takeLatest(actions.applyPromocode.REQUEST, applyPromocodeSaga)
  yield takeLatest(actions.createSubscription.REQUEST, createSubscriptionSaga)
  yield takeLatest(actions.updateSubscription.REQUEST, updateSubscriptionSaga)
  yield takeLatest(actions.cancelSubscription.REQUEST, cancelSubscriptionSaga)
  yield takeLatest(actions.fetchSubscriptionHistory.REQUEST, fetchSubscriptionHistorySaga)
  yield takeLatest(actions.createPaymentMethodNonce.REQUEST, createPaymentMethodNonceSaga)
}
