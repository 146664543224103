import { QuickUserFeaturePermissions } from '@constants'

import { FeaturePermissons, isPlanPermissionEnabled, permissionDeniedForUser } from '@permissions'

import { isAdminOrAccountant } from './authorization'

type AvailableFeatures =
  | FeaturePermissons.ACCOUNTING_ID
  | FeaturePermissons.ADVANCED_ACCOUNTING
  | FeaturePermissons.ADVANCED_APPROVAL
  | FeaturePermissons.REQUIRE_APPROVAL_FOR_PAYMENT
  | FeaturePermissons.VAT_PERIOD

//* Company advanced settings
function isCompanySettingEnabled(state: Store | Company, feature: AvailableFeatures, isCompanyState = false) {
  const isEnabled = isPlanPermissionEnabled(state, feature, isCompanyState)

  if (isEnabled) {
    if (isCompanyState) return (state as Company)?.[feature] ?? false
    return (state as Store).auth.company.data?.[feature] ?? false
  }

  return false
}

//* available in all tiers
export function isAdvancedAccountingAvailableForUser(state: Store) {
  return (
    isCompanySettingEnabled(state, FeaturePermissons.ADVANCED_ACCOUNTING) &&
    !permissionDeniedForUser(state, QuickUserFeaturePermissions.denyAccountingPermission)
  )
}

//* only available in pro tier
export function isRequireApprovalForPaymentEnabled(state: Store) {
  return isCompanySettingEnabled(state, FeaturePermissons.REQUIRE_APPROVAL_FOR_PAYMENT)
}

export function isAdvancedApprovalEnabled(state: Store) {
  return isCompanySettingEnabled(state, FeaturePermissons.ADVANCED_APPROVAL)
}

export function userHasAccountantRole(state: Store) {
  return (
    isAdminOrAccountant(state.auth.company.data.role) &&
    !permissionDeniedForUser(state, QuickUserFeaturePermissions.denyAccountingPermission)
  )
}

//* available in all tiers for users with accounting permission
export function isAccountingFetaureEnabledForUser(
  state: Store,
  feature: FeaturePermissons.ACCOUNTING_ID | FeaturePermissons.VAT_PERIOD
) {
  return userHasAccountantRole(state) && isCompanySettingEnabled(state, feature)
}
