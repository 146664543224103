import React from 'react'
import PropTypes from 'prop-types'

import { CircularProgress, IconButton, IconButtonProps, TooltipProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import styled from 'styled-components'

import { LightTooltip } from '../LightTooltip'

import { iconButtonStyles } from '@styles'

const useIconButtonStyles = makeStyles(iconButtonStyles)

const InlineBlockDiv = styled.div`
  display: inline-block;
`

type FunctionWithEvent = (event: React.MouseEvent<HTMLButtonElement>) => void

export interface QuickIconButtonProps extends Omit<TooltipProps, 'title' | 'PopperProps' | 'children' | 'onClick'> {
  disabled?: boolean
  icon: JSX.Element
  loading?: boolean
  minimal?: boolean
  onClick?: VoidFunction | FunctionWithEvent
  size?: 'small' | 'medium'
  tooltipText?: React.ReactNode
  testId?: string
}

export function QuickIconButton({
  disabled = false,
  icon,
  loading = false,
  minimal = false,
  onClick,
  size = 'small',
  tooltipText,
  testId,
  ...rest
}: QuickIconButtonProps) {
  const classes = useIconButtonStyles()
  const iconButtonProps: IconButtonProps = {
    className: cx(classes.root, {
      [classes.disabled]: disabled,
      [classes.minimal]: minimal,
    }),
    size: size,
    disableRipple: true,
    disabled: disabled || loading,
  }

  if (!disabled) {
    iconButtonProps.onClick = onClick
  }

  if (tooltipText) {
    return (
      <LightTooltip title={tooltipText} PopperProps={{ disablePortal: true }} {...rest}>
        <InlineBlockDiv>
          <IconButton {...iconButtonProps} data-testid={testId}>
            {loading ? <CircularProgress color="inherit" size={14} /> : icon}
          </IconButton>
        </InlineBlockDiv>
      </LightTooltip>
    )
  }
  return (
    <IconButton {...iconButtonProps} data-testid={testId}>
      {loading ? <CircularProgress color="inherit" size={14} /> : icon}
    </IconButton>
  )
}

QuickIconButton.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  minimal: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium']),
  tooltipText: PropTypes.node,
}
