import React from 'react'

import { FormattedMessage } from 'react-intl'

export const CreatedAtOptionMessage = (
  <FormattedMessage id="filterBar.dateFilter.createdAt" defaultMessage="Feltöltés ideje" />
)

export const ValueDateOptionMessage = <FormattedMessage id="filterBar.dateFilter.valueDate" defaultMessage="Értéknap" />

export const PaymentDateOptionMessage = (
  <FormattedMessage id="filterBar.dateFilter.paymentDate" defaultMessage="Kifizetés napja" />
)

export const CashFlowDateOptionMessage = (
  <FormattedMessage id="filterBar.dateFilter.cashFlow" defaultMessage="Cash Flow (kifizetés napja)" />
)

export const AccountingPeriodOptionMessage = (
  <FormattedMessage id="filterBar.dateFilter.accountingPeriod" defaultMessage="Számviteli időszak" />
)

export const FulfilledAtOptionMessage = (
  <FormattedMessage id="filterBar.dateFilter.fulfilledAt" defaultMessage="Teljesítés ideje" />
)

export const IssuedAtOptionMessage = (
  <FormattedMessage id="filterBar.dateFilter.issuedAt" defaultMessage="Számla kelte" />
)

export const DueAtOptionMessage = <FormattedMessage id="filterBar.dateFilter.dueAt" defaultMessage="Esedékesség" />

export const CurrentMonthOptionMessage = (
  <FormattedMessage id="filterBar.dateFilter.currentMonth" defaultMessage="Tárgyhó" />
)

export const ExportedAtOptionMessage = (
  <FormattedMessage id="filterBar.dateFilter.exportedAt" defaultMessage="Exportálás dátuma" />
)

export const VatPeriodOptionMessage = (
  <FormattedMessage id="filterBar.dateFilter.vatPeriod" defaultMessage="ÁFA időszak" />
)
