import React from 'react'

import { useDispatch } from 'react-redux'

import { commonActions, CommonAxiosPayload } from '@services'

import { bindActionToPromise } from '@helpers'

import { DebounceLoadConfig, useDebouncedLoadData } from './useDebouncedLoadData'

/**
 * Custom hook to call a URL with debouncing.
 *
 * This hook uses `useDebouncedLoadData` to debounce the call to a URL.
 * It dispatches an action to call the URL using Redux and returns the debounced result.
 *
 * @template Results - The type of the results returned by the URL call.
 *
 * @param {string} methodUrl - The URL to call.
 * @param {DebounceLoadConfig} [config] - Optional configuration for debouncing.
 *
 * @returns {ReturnType<typeof useDebouncedLoadData>} The debounced result of the URL call.
 */
export function useDebouncedCallUrl<Results>(methodUrl: string, config?: DebounceLoadConfig) {
  const dispatch = useDispatch()

  const asyncLoadData = React.useCallback(async () => {
    const callUrl = bindActionToPromise<CommonAxiosPayload, Results>(dispatch, commonActions.callUrl.request)
    return await callUrl({ url: methodUrl, method: 'get' })
  }, [dispatch, methodUrl])

  return useDebouncedLoadData(asyncLoadData, config)
}
