import React from 'react'
import PropTypes from 'prop-types'

import { Field, Form } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import * as yup from 'yup'

import { authActions } from '@services'

import { bindActionToPromise, getPulseRedirectPreferredForCompanyType, getRouteUrl, makeValidate } from '@helpers'

import { PulseDialogs } from '@components/pages/PulsePage'
import {
  CustomDialog,
  CustomDialogActions,
  CustomDialogBody,
  CustomDialogHeader,
  RffSubmitButton,
  Typography,
} from '@components/ui'
import { RenderFormFieldSelect } from '@oldComponents/ui/form'

import { RouteKeys } from '@constants/routes'

import { formErrorMessages, SimpleNextMessage } from '@messages'
import fieldMessages from '@oldComponents/forms/fields/ReduxFormFields/messages'

const StyledDialog = styled(CustomDialog)`
  width: 596px;

  ${CustomDialogBody} {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > div {
      margin-top: 20px;
      margin-bottom: 0;
      width: 356px;
    }
  }
`

interface SetCompanyTypeFormValues {
  company_type: string
}

interface SetCompanyTypeDialogProps {
  companyId: number | undefined
  companyTypes: CompanyType[]
  isOpen: boolean
  onSuccess: VoidFunction
  updateCompany: AsyncFunction<SetCompanyTypeFormValues>
}

function PureSetCompanyTypeDialog({
  companyId,
  companyTypes,
  isOpen,
  onSuccess,
  updateCompany,
}: SetCompanyTypeDialogProps) {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()

  async function onSubmit(values: SetCompanyTypeFormValues) {
    try {
      await updateCompany(values)
      onSuccess()
      if (getPulseRedirectPreferredForCompanyType(values.company_type)) {
        navigate(getRouteUrl(RouteKeys.PULSE, companyId), {
          state: {
            showDialog: PulseDialogs.WELCOME_TO_TASK_LIST,
          },
        })
      }
    } catch (error) {
      return error
    }
  }

  const validateRef = React.useRef(
    makeValidate(
      yup.object({
        company_type: yup.string().required(formatMessage(formErrorMessages.required)),
      })
    )
  )

  return (
    <StyledDialog open={isOpen} shouldCloseOnEsc={false} shouldCloseOnOverlayClick={false}>
      <CustomDialogHeader
        borderless
        title={<FormattedMessage id="setCompanyTypeDialog.text.title" defaultMessage="Üdvözlünk a QUiCK fiókodban!" />}
      />
      <Form
        onSubmit={onSubmit}
        validate={validateRef.current}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} noValidate>
            <CustomDialogBody>
              <Typography size="400-sm" align="center">
                <FormattedMessage
                  id="setCompanyTypeDialog.text.subtitle"
                  defaultMessage="Még egy utolsó simítás, hogy személyre szabottan tudjunk segíteni!"
                />
              </Typography>
              <Typography size="400-sm" align="center">
                <FormattedMessage
                  id="setCompanyTypeDialog.text.description"
                  defaultMessage="Kérjük, add meg, milyen formában működik a vállalkozásod!"
                />
              </Typography>
              <Field
                name="company_type"
                component={RenderFormFieldSelect}
                options={companyTypes}
                label={formatMessage(fieldMessages.formLabelBusinessType)}
                required
              />
            </CustomDialogBody>
            <CustomDialogActions borderless>
              <RffSubmitButton variant="primaryContained">{SimpleNextMessage}</RffSubmitButton>
            </CustomDialogActions>
          </form>
        )}
      />
    </StyledDialog>
  )
}

PureSetCompanyTypeDialog.propTypes = {
  companyId: PropTypes.number,
  companyTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired as React.Validator<CompanyType[]>,
  isOpen: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  updateCompany: PropTypes.func.isRequired,
}

const SetCompanyTypeDialog = connect(
  ({
    auth: {
      company: { data: company },
    },
    dashboard: {
      common: { company_types },
    },
  }: Store) => ({
    companyTypes: company_types,
    companyId: company?.id,
  }),
  dispatch => ({
    updateCompany: bindActionToPromise(dispatch, authActions.updateCompanyRff.request),
  })
)(PureSetCompanyTypeDialog)

SetCompanyTypeDialog.displayName = 'SetCompanyTypeDialog'

export default SetCompanyTypeDialog
