import React from 'react'

import { InfoText, MultilineInfoText, Typography, WarningTypography } from '@components/ui'

import { DeleteConfirmDialogBodyProps } from '../types'

import { ListActionsDialogBody } from '@components/dialogs/styles'
import { DetailsWrapperDiv } from './styles'

export function DeleteConfirmDialogBody({
  additionalInfoText,
  children,
  infoText,
  subjectMessage,
  warningMessage,
}: DeleteConfirmDialogBodyProps) {
  return (
    <ListActionsDialogBody>
      {warningMessage && (
        <WarningTypography color="gray-80" size="400-md">
          {warningMessage}
        </WarningTypography>
      )}
      <DetailsWrapperDiv>
        <Typography color="gray-80" size="700-md">
          {subjectMessage}
        </Typography>
        {additionalInfoText ? (
          <MultilineInfoText iconColor="gray-40" size="400-md">
            {infoText}
            {additionalInfoText}
          </MultilineInfoText>
        ) : (
          <InfoText iconColor="gray-40" size="400-md">
            {infoText}
          </InfoText>
        )}
        {children}
      </DetailsWrapperDiv>
    </ListActionsDialogBody>
  )
}
