import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import ErrorIcon from '@material-ui/icons/Error'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    display: 'inline-flex',
    alignItems: 'center',
  },
  text: {
    display: 'inline-block',
    fontWeight: 700,
    marginRight: 2,
    fontSize: 14,
  },
}))

interface WarningBadgeProps {
  isDuplicated?: boolean
  isNav?: boolean
}

function WarningBadge({ isDuplicated = false, isNav = false }: WarningBadgeProps) {
  const classes = useStyles()

  return (
    <div className={cx('missing-warning', classes.root)}>
      <span className={classes.text}>
        {isDuplicated ? (
          isNav ? (
            <FormattedMessage id="badge.navDuplicated" defaultMessage="NAV duplikáció" />
          ) : (
            <FormattedMessage id="badge.duplicated" defaultMessage="Duplikáció" />
          )
        ) : (
          <FormattedMessage id="badge.deficient.data" defaultMessage="Adathiányos" />
        )}
      </span>
      {isDuplicated && <ErrorIcon style={{ fontSize: 14 }} />}
    </div>
  )
}

WarningBadge.propTypes = { isDuplicated: PropTypes.bool, isNav: PropTypes.bool }

export function DeficientBadge() {
  return <WarningBadge />
}

export function DuplicatedBadge({ isNav }: Pick<WarningBadgeProps, 'isNav'>) {
  return <WarningBadge isDuplicated isNav={isNav} />
}

DuplicatedBadge.propTypes = { isNav: PropTypes.bool }
