import styled, { css, DefaultTheme, keyframes } from 'styled-components'

import { applyOpacity } from '@helpers/styled-helpers'

import { Button } from '@components/ui/Buttons'
import { PlugCircleBoltRegularIcon, WarningIcon } from '@components/ui/svgIcons'
import { TableRow } from '@components/ui/TableElements'
import { Typography } from '@components/ui/Typography'
import { FlexRow } from '@components/ui/Wrappers'

export enum NotificationRowVariant {
  DEFAULT = 'default',
  WARNING = 'warning',
}

function getVariantStyles({ theme, variant }: { theme: DefaultTheme; variant?: NotificationRowVariant }) {
  switch (variant) {
    case NotificationRowVariant.WARNING:
      return css`
        background-color: ${applyOpacity(theme.colors.common.warningMessageBackground, 60)};

        & > td {
          padding-top: 5px;
          padding-bottom: 5px;
        }

        ${Button},${Typography} {
          color: ${({ theme }) => theme.colors.gray[80]};
        }
      `
    default:
      return css`
        background-color: ${theme.colors.gray[20]};
      `
  }
}

export const NotificationTableRow = styled(TableRow)<{ variant?: NotificationRowVariant }>`
  ${getVariantStyles}

  height: 42px;

  &:hover {
    box-shadow: none;
  }

  ${FlexRow} {
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  ${Button} {
    --css-hover-background-color: ${({ theme }) => applyOpacity(theme.colors.gray[40], 15)};
  }
`

export const StyledFlexRow = styled(FlexRow)`
  color: ${({ theme }) => theme.colors.blue[30]};
`

const scaleAnimation = keyframes`
0% {
  transform: scale(1);
}
50% {
  transform: scale(1.2);
}
100% {
  transform: scale(1);
}
`

export const AnimatedPlugCircleBoltRegularIcon = styled(PlugCircleBoltRegularIcon)`
  animation: ${scaleAnimation} infinite 1.5s;
`

export const AnimatedWarningIcon = styled(WarningIcon)`
  color: ${({ theme }) => theme.colors.gray[80]};
  animation: ${scaleAnimation} infinite 1.5s;
`
