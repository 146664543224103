import React from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import { getRouteUrl, parseApiErrorMessage, parseIfExists } from '@helpers'

import { useAlertDispatch } from '@contexts/AlertProvider'

import { RouteKeys } from '@constants'

interface DetailsPagingProps extends PagePaginationProps {
  company: number
}

export function useDetailsPaging(
  { company, detailsPagingConfig, fetchByPaging, nextPageUrl, prevPageUrl }: DetailsPagingProps,
  paramId: string | undefined
) {
  const navigate = useNavigate()
  const location = useLocation<PreviousPathHistoryState>()
  const { setErrorAlert } = useAlertDispatch()

  return React.useMemo(() => {
    if (!detailsPagingConfig) {
      return
    }

    const { detailsPageRouteKey, itemsCount, listMap } = detailsPagingConfig
    let displayPaging = false
    let item: Nullable<DetailsPagingMapItem> = null

    const itemId = parseIfExists(paramId)
    if (itemId) {
      item = listMap[itemId]
      displayPaging = Boolean(itemsCount > 0 && item)
    }

    const disabledPrev = !item?.prevItemId && !prevPageUrl
    const disabledNext = !item?.nextItemId && !nextPageUrl

    async function callDataFetch(url: string, isNext: boolean) {
      try {
        const newId = await fetchByPaging({ url, isNext })
        navigate(`${getRouteUrl(detailsPageRouteKey as unknown as RouteKeys, company)}/${newId}`, {
          state: location.state,
        })
      } catch (error) {
        const errorMessage = parseApiErrorMessage(error)
        if (errorMessage) {
          setErrorAlert(errorMessage)
        }
      }
    }

    function onPrev() {
      const prevItemId = item?.prevItemId
      if (prevItemId) {
        navigate(`${getRouteUrl(detailsPageRouteKey as unknown as RouteKeys, company)}/${prevItemId}`, {
          state: location.state,
        })
      } else if (prevPageUrl) {
        callDataFetch(prevPageUrl, false)
      }
    }

    function onNext() {
      const nextItemId = item?.nextItemId
      if (nextItemId) {
        navigate(`${getRouteUrl(detailsPageRouteKey as unknown as RouteKeys, company)}/${nextItemId}`, {
          state: location.state,
        })
      } else if (nextPageUrl) {
        callDataFetch(nextPageUrl, true)
      }
    }

    return {
      disabledNext,
      disabledPrev,
      displayPaging,
      onNext,
      onPrev,
    }
  }, [
    company,
    detailsPagingConfig,
    fetchByPaging,
    location.state,
    navigate,
    nextPageUrl,
    paramId,
    prevPageUrl,
    setErrorAlert,
  ])
}
