import React from 'react'

import { NavigateFunction, useNavigate } from 'react-router-dom'
import { SortingRule } from 'react-table'

import { PageChangeDirection } from '@constants'

type UseTableReturnType = Partial<{
  sortBy: Array<SortingRule<Record<string, unknown>>>
  onSortByChange: (payload: Array<SortingRule<Record<string, unknown>>>) => void
  onRowsPerPageChange: React.ChangeEventHandler<{
    name?: string
    value: unknown
  }>
  onPageChange: PageChangeFunction
  onPageReset: VoidFunction
}>

export type PageChangePayload = { url: string; direction?: PageChangeDirection }
export type PageChangeFunction = (payload: PageChangePayload) => void

export type UpdateOrderPayload = { navigate: NavigateFunction; order: OrderOptions; orderBy: string }
export type UpdateOrderFunction = (payload: UpdateOrderPayload) => void

export type UpdateRowsPerPagePayload = { navigate: NavigateFunction; pageSize: string | number }
export type UpdateRowsPerPageFunction = (payload: UpdateRowsPerPagePayload) => void

type UseTableControlsProps = Partial<{
  defaultSortBy: DefaultSorting
  updateOrder: UpdateOrderFunction
  updateRowsPerPage: UpdateRowsPerPageFunction
  handlePageChange: PageChangeFunction
  handlePageReset: (payload: { navigate: NavigateFunction }) => void
}>

/**
 * Hook for creating table control functions and configs. It will only return with valid values if you provide necessary input parameters.
 *
 * For sorting you need to provide both `defaultSortBy` and `updateOrder`
 *
 * @param {UseTableControlsProps} props
 */
export function useTableControls({
  defaultSortBy,
  updateOrder,
  updateRowsPerPage,
  handlePageChange,
  handlePageReset,
}: UseTableControlsProps): UseTableReturnType {
  const navigate = useNavigate()
  return React.useMemo(() => {
    // declare variables for return values
    let sortBy, onSortByChange, onRowsPerPageChange, onPageChange, onPageReset

    if (defaultSortBy && updateOrder) {
      // default sortBy as react-table expects based on passed in sortBy constant
      sortBy = [{ id: defaultSortBy.orderBy, desc: defaultSortBy.order === 'desc' }]

      // sorting function for react-table tables
      onSortByChange = (payload: SortingRule<Record<string, unknown>>[]) => {
        // there is no multi sorting so we only need the first item
        const { id, desc } = payload[0]
        updateOrder({ navigate, order: desc ? 'desc' : 'asc', orderBy: id })
      }
    }

    if (updateRowsPerPage) {
      // change rows per page for TableCursorPagination component
      onRowsPerPageChange = (
        event: React.ChangeEvent<{
          name?: string | undefined
          value: string | number
        }>
      ) => {
        updateRowsPerPage({ navigate, pageSize: event.target.value })
      }
    }

    if (handlePageChange) {
      // change page for TableCursorPagination component
      onPageChange = (payload: PageChangePayload) => {
        handlePageChange(payload)
      }
    }

    if (handlePageReset) {
      onPageReset = () => {
        handlePageReset({ navigate })
      }
    }

    return {
      sortBy,
      onSortByChange,
      onRowsPerPageChange,
      onPageChange,
      onPageReset,
    } as UseTableReturnType
  }, [defaultSortBy, updateOrder, updateRowsPerPage, handlePageChange, handlePageReset, navigate])
}
