import { serviceActionsGenerator } from '../serviceGenerator'

const actionTypes = [
  '!CREATE_PAYMENT_METHOD_NONCE',
  '!CREATE_SUBSCRIPTION',
  'APPLY_PROMOCODE',
  'CANCEL_SUBSCRIPTION',
  'FETCH_SUBSCRIPTION_HISTORY',
  'FETCH_SUBSCRIPTION_PLANS',
  'UPDATE_SUBSCRIPTION',
] as const

export default serviceActionsGenerator('subscription', actionTypes)
