import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { Location, NavigateFunction } from 'react-router-dom'

import { SyncFiltersConfig } from '@components/filters/types'

import { FiltersStateKey } from '@constants/store'

export type CommonAxiosPayload<Data = never> = {
  method: 'post' | 'get' | 'delete' | 'patch'
  url: string
  data?: Data
  config?: AxiosRequestConfig<Data>
  isFormError?: boolean // react-final-form support
  isHookFormError?: boolean // react-hook-form support
}

export type InitPageLoadSagaPayload = {
  config: SyncFiltersConfig
  filtersStateKey: FiltersStateKey
  location: Location<unknown>
  navigate: NavigateFunction
}

// this is v1 version of the response
export type BackgroundJobResponse<Results = unknown> = {
  errors: Partial<{ message: string }>
  id: number
  path: BackendCallableLink
  results: Results
  send_email: boolean
  status: 10 | 20 | 30 | 40 // background job status { 10 - created, 20 - pending, 30 - finished, 40 - error }
}

// typeguard
export function isBackgroundJobResponse(
  response: AxiosResponse<{ path: string } | BackgroundJobResponse>
): response is AxiosResponse<BackgroundJobResponse> {
  return (response as AxiosResponse<BackgroundJobResponse>).data.status !== undefined
}
