import React from 'react'

import __uniqueId from 'lodash/uniqueId'
import { Props as ModalProps } from 'react-modal'
import styled from 'styled-components'

import { AsyncButton, Button } from '@components/ui/Buttons'
import { Typography } from '@components/ui/Typography'

import { DIALOG_CLASS_NAMES, DIALOG_PADDING, SIMPLE_DIALOG_MAX_WIDTH } from '@constants/dialog'

import { BaseDialog } from './BaseDialog'

import { CancelButtonMessage, OkButtonMessage } from '@messages'

const StyledBaseDialog = styled(BaseDialog)`
  --gap: 10px;
  --vertical-padding: ${DIALOG_PADDING}px;
  --horizontal-padding: ${DIALOG_PADDING}px;

  &.${DIALOG_CLASS_NAMES.content.base} {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    max-width: ${SIMPLE_DIALOG_MAX_WIDTH}px;
    padding: var(--vertical-padding) var(--horizontal-padding);

    & > *:last-child {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: var(--gap);
    }
  }
  &.gap-3x {
    --gap: 30px;
  }
`

interface CommonDialogProps extends Omit<ModalProps, 'isOpen' | 'onRequestClose' | 'children' | 'className'> {
  children?: React.ReactChild
  className?: string
  description?: React.ReactChild
  loading?: boolean
  onClose: (event: React.MouseEvent | React.KeyboardEvent) => void
  onPrimaryAction: (event: React.MouseEvent | React.KeyboardEvent) => void
  open: boolean
  primaryActionText?: React.ReactChild
  title: React.ReactChild
}

interface WithoutSecondaryActionSimpleDialogProps extends CommonDialogProps {
  onSecondaryAction?: never
  secondaryActionText?: never
}

interface WithSecondaryActionSimpleDialogProps extends CommonDialogProps {
  onSecondaryAction: (event: React.MouseEvent | React.KeyboardEvent) => void
  secondaryActionText?: React.ReactChild
}

export type SimpleDialogProps = WithoutSecondaryActionSimpleDialogProps | WithSecondaryActionSimpleDialogProps

const ID_PREFIX = __uniqueId('simple-dialog')

/**
 * Simple dialog for quick confirm and information based modals.
 *
 * You can pass almost all react-modal props if needed, but check `BaseDialog` component first (Usage documentation: https://reactcommunity.org/react-modal/)
 *
 * @param {SimpleDialogProps} props - The props for the SimpleDialog component
 * @param {React.ReactChild} props.children - Optional content to replace the description
 * @param {React.ReactChild} props.description - Optional text in the content area
 * @param {boolean} props.loading - Loading state for the primary action button (Default: false)
 * @param {function} props.onClose - Callback function when the dialog is closed
 * @param {function} props.onPrimaryAction - Callback function for the primary action button
 * @param {function} props.onSecondaryAction - Optional callback function for the secondary action button
 * @param {boolean} props.open - Whether the dialog is open or not
 * @param {React.ReactChild} props.primaryActionText - Text for the primary action button (default is "OK")
 * @param {React.ReactChild} props.secondaryActionText - Text for the secondary action button (default is "Mégse")
 * @param {React.ReactChild} props.title - Title of the dialog
 * @param {object} dialogProps - Additional props to pass to the BaseDialog component
 */
export function SimpleDialog({
  children,
  description,
  loading = false,
  onClose,
  onPrimaryAction,
  onSecondaryAction,
  open,
  primaryActionText,
  secondaryActionText,
  title,
  ...dialogProps
}: SimpleDialogProps) {
  const aria = {
    labelledby: `${ID_PREFIX}-heading`,
    describedby: `${ID_PREFIX}-description`,
  }
  return (
    <StyledBaseDialog isOpen={open} onRequestClose={onClose} aria={aria} {...dialogProps}>
      <Typography id={aria.labelledby} tag="h2" size="heading-4" align="center">
        {title}
      </Typography>
      {children}
      {!children && description && (
        <Typography id={aria.describedby} size="400-sm" color="gray-50" align="center">
          {description}
        </Typography>
      )}
      <div>
        {onSecondaryAction && (
          <AsyncButton loading={loading} onClick={onSecondaryAction} variant="secondaryContained">
            {secondaryActionText || CancelButtonMessage}
          </AsyncButton>
        )}
        <Button onClick={onPrimaryAction} variant="primaryContained">
          {primaryActionText || OkButtonMessage}
        </Button>
      </div>
    </StyledBaseDialog>
  )
}
