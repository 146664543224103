import React from 'react'

import __uniqueId from 'lodash/uniqueId'
import { Props as ModalProps } from 'react-modal'

import { createAriaAttributes } from '@helpers'

import { Typography, TypographyProps } from '@components/ui/Typography'

import { DialogHeader, ScrollableBaseDialog } from './styles'

export interface CustomDialogHeaderProps {
  align?: TypographyProps['align']
  aria?: ModalProps['aria']
  borderless?: boolean
  children?: React.ReactNode
  className?: string
  title: React.ReactChild
}

/**
 * Building block for `CustomDialog`. Sticks to the top and has a separator at the bottom.
 *
 * You can pass an optional `children` to extend the header portion
 *
 * @param {CustomDialogHeaderProps} { align, title, children, aria, className }
 */
export function CustomDialogHeader({
  align = 'center',
  aria,
  borderless,
  children,
  className,
  title,
}: CustomDialogHeaderProps) {
  return (
    <DialogHeader className={className} $borderless={borderless}>
      <Typography id={aria?.labelledby} tag="h2" size="heading-4" align={align}>
        {title}
      </Typography>
      {children}
    </DialogHeader>
  )
}

interface CustomDialogRenderProps {
  aria: ReturnType<typeof createAriaAttributes>
  contentRef: HTMLDivElement | undefined
}

export interface CustomDialogProps extends Omit<ModalProps, 'isOpen' | 'onRequestClose' | 'children' | 'className'> {
  ariaIdPrefix?: string
  children: React.ReactNode | React.ReactNode[] | ((props: CustomDialogRenderProps) => React.ReactElement)
  className?: string
  fullScreen?: boolean
  fullWidth?: boolean
  onClose?: (event?: React.MouseEvent | React.KeyboardEvent) => void
  open: boolean
  stickyBottomOffset?: number
  stickyTopOffset?: number
}

/**
 * Custom dialog for special purpose dialogs.
 *
 * An `aria` prop is automatically passed on to each child to be used for accessibility. One for a title, one for a description.
 * Check `CustomDialogHeader` for usage example.
 *
 * A `contentRef` prop is automatically passed on to each child to be used.
 *
 * You can pass almost all react-modal props if needed, but check `BaseDialog` component first (Usage documentation: https://reactcommunity.org/react-modal/)
 *
 * Use the following building blocks as children: `CustomDialogHeader`, `CustomDialogBody`, `CustomDialogActions`
 *
 * Example:
 * ```
 * <CustomDialog open={open} onClose={onClose}>
 *  <CustomDialogHeader title="Title" />
 *  <CustomDialogBody>
 *    <p>body</p>
 *  </CustomDialogBody>
 *  <CustomDialogActions>
 *    <button onClick={onClose}>OK</button>
 *  </CustomDialogActions> />
 * </CustomDialog>
 * ```
 *
 * For advanced use cases you can uses the children as a render function getting the same `contentRef` and `aria` props
 * @param {CustomDialogProps} { onClose, children, open, ...dialogProps }
 */
export function CustomDialog({
  ariaIdPrefix,
  onClose,
  children,
  open,
  fullScreen,
  fullWidth,
  ...dialogProps
}: CustomDialogProps) {
  const [isScrollable, setScrollable] = React.useState(false)
  const isScrollableRef = React.useRef(isScrollable)
  const idPrefix = __uniqueId('custom-dialog')
  const aria = createAriaAttributes(ariaIdPrefix ?? idPrefix)

  const wrapperRef = React.useRef<HTMLDivElement>()
  const observerRef = React.useRef<ResizeObserver>()
  const extraProps = { aria, contentRef: wrapperRef.current }

  // apply aria and contentRef as extra props for each children to use
  const childrenWithExtraProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, extraProps)
    } else {
      return child
    }
  })

  function contentRefCallback(node: HTMLDivElement | null) {
    if (node) {
      // If the node changes, update the observer to observe the new node
      if (observerRef.current) {
        if (wrapperRef.current) {
          observerRef.current.unobserve(wrapperRef.current)
        }
        observerRef.current.observe(node)
      }
    } else {
      // If the node is null, stop observing the current node
      if (observerRef.current && wrapperRef.current) {
        observerRef.current.unobserve(wrapperRef.current)
      }
    }

    // Update the wrapper reference
    wrapperRef.current = node ?? undefined
  }

  React.useEffect(() => {
    // Initialize the ResizeObserver only once
    observerRef.current = new ResizeObserver(([entry]) => {
      const hasScroll = entry.target.scrollHeight > entry.target.getBoundingClientRect().height

      // Only update if `hasScroll` changes
      if (isScrollableRef.current !== hasScroll) {
        isScrollableRef.current = hasScroll
        // use timeout to avoid ResizeObserver loop completed with undelivered notifications error
        window.setTimeout(() => {
          setScrollable(hasScroll)
        })
      }
    })

    return () => {
      // Clean up on unmount
      if (wrapperRef.current && observerRef.current) {
        observerRef.current.unobserve(wrapperRef.current)
      }
      observerRef.current?.disconnect()
    }
  }, [])

  return (
    <ScrollableBaseDialog
      $isFullScreen={fullScreen}
      $isFullWidth={fullWidth}
      $isScrollable={isScrollable}
      aria={aria}
      contentRef={contentRefCallback}
      isOpen={open}
      onRequestClose={onClose}
      {...dialogProps}
    >
      {typeof children === 'function' ? children(extraProps) : childrenWithExtraProps}
    </ScrollableBaseDialog>
  )
}
