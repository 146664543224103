import { injectCompanyToURL } from '@helpers'

import Api from '../api'
import { CreateIntegrationProviderPayload } from './types'
import {
  API_2FACTOR_AUTH_URL,
  API_2FACTOR_BACKUP_TOKENS_URL,
  API_2FACTOR_DELETE_URL,
  API_2FACTOR_QR_CODE_URL,
  API_2FACTOR_VALIDATE_DEVICE_URL,
  API_CHANGE_PASSWORD_URL,
  API_COMPANY_DETAIL_URL,
  API_COMPANY_MEMBER_DETAILS_URL,
  API_COMPANY_MEMBERS_URL,
  API_COMPANY_URL,
  API_COMPANY_VAT_DETAILS_URL,
  API_COMPANY_VATS_URL,
  API_INTEGRATION_PROVIDER_URL,
  API_INVITATION_URL,
  API_LOGIN_URL,
  API_LOGOUT_URL,
  API_PREFERENCES_URL,
  API_RENEW_TOKEN_URL,
  API_RESET_PASSWORD_CONFIRM_URL,
  API_RESET_PASSWORD_URL,
  API_SIGNUP_URL,
  API_TAXATION_TAX_FORM_URL,
  API_USER_COMPANIES_URL,
  API_USER_NOTIFICATION_URL,
  API_USER_URL,
} from './urls'

function injectToUrl(url: string, key: string, value: string | number) {
  return url.replace(key, String(value))
}

function injectHashToUrl(url: string, hash: string) {
  return injectToUrl(url, '{hash}', hash)
}

function injectCompanyUserToUrl(url: string, id: ItemIdType) {
  return injectToUrl(url, '{company_user_id}', id)
}

function injectCompanyVatToUrl(url: string, id: ItemIdType) {
  return injectToUrl(url, '{company_vat_id}', id)
}

// AUTH API-s
export function renewToken() {
  return Api.post(API_RENEW_TOKEN_URL)
}

export function changePassword(data: ChangePasswordFormValues) {
  return Api.post(API_CHANGE_PASSWORD_URL, data)
}

export function login(data: LoginPayload) {
  return Api.post(API_LOGIN_URL, data)
}

export function logout() {
  return Api.post(API_LOGOUT_URL)
}

export function resetPassword(data: ResetPasswordFormValues) {
  return Api.post(API_RESET_PASSWORD_URL, data)
}

export function resetPasswordConfirm(data: ResetPasswordConfirmFormValues) {
  return Api.post(API_RESET_PASSWORD_CONFIRM_URL, data)
}

export function signup(data: SignupFormValues) {
  return Api.post(API_SIGNUP_URL, data)
}

export function fetchUser() {
  return Api.get(API_USER_URL)
}

export function fetchUserCompanies() {
  return Api.get(API_USER_COMPANIES_URL)
}
// 2 FACTOR AUTH
export function getAuthStatus() {
  return Api.get(API_2FACTOR_AUTH_URL)
}

export function getAuthData() {
  return Api.post(API_2FACTOR_QR_CODE_URL)
}

export function validateDevice(data: { token: string }) {
  return Api.post(API_2FACTOR_VALIDATE_DEVICE_URL, data)
}

export function updateBackupTokens() {
  return Api.post(API_2FACTOR_BACKUP_TOKENS_URL)
}

export function clearTwoFactorAuth() {
  return Api.post(API_2FACTOR_DELETE_URL)
}

// INVITE
export function loadInvitationDetails(hash: string) {
  return Api.get(injectHashToUrl(API_INVITATION_URL, hash))
}

export function acceptInvitation({ hash, password, newsletter_status }: Required<SignupFormValues>) {
  return Api.post(injectHashToUrl(API_INVITATION_URL, hash), { password, newsletter_status })
}

// badge
export function updateBadgeDisplayedAt(badge_displayed_at: string) {
  return Api.patch(API_USER_URL, { badge_displayed_at })
}

// notifications
export function updateNotificationDisplayedAt({ id, displayed_at }: { id: number; displayed_at: string }) {
  return Api.patch(API_USER_NOTIFICATION_URL.replace('{notification_id}', String(id)), { displayed_at })
}

// user personal info: { default_language, first_name, last_name }

export function updatePersonalInfo(payload: PersonalInfoFormValues) {
  return Api.patch(API_USER_URL, payload)
}

// user preferences
export function changeUserPreferences(partialPreferences: Partial<UserPreferences>) {
  return Api.patch(API_PREFERENCES_URL, partialPreferences)
}

export function restoreUserPreferences() {
  return Api.put(API_PREFERENCES_URL, { default: true })
}

// COMPANY
export function fetchCompanyDetails(companyId: Company['id']) {
  // TODO need refact after Yeri shutdown
  return Api.get(injectCompanyToURL(API_COMPANY_DETAIL_URL, companyId), { params: { fields: 'vat_categories' } })
}

export function createCompany(data: CreateCompanyFormValues) {
  return Api.post(API_COMPANY_URL, data)
}

export function updateCompany({ id, ...data }: Partial<Company> & { id: Company['id'] }) {
  return Api.patch(injectCompanyToURL(API_COMPANY_DETAIL_URL, id), data)
}

// COMPANY MEMBER
export function fetchCompanyMembers(companyId: Company['id']) {
  return Api.get(injectCompanyToURL(API_COMPANY_MEMBERS_URL, companyId))
}

export function fetchCompanyAdmins(companyId: Company['id']) {
  return Api.get(injectCompanyToURL(API_COMPANY_DETAIL_URL, companyId), {
    params: { fields: 'admins' },
  })
}

export function createCompanyMember(companyId: Company['id'], data: Omit<CompanyUser, 'id'>) {
  return Api.post(injectCompanyToURL(API_COMPANY_MEMBERS_URL, companyId), data)
}

export function updateCompanyMember(companyId: Company['id'], data: NonNullableObject<CompanyUserTemplate>) {
  return Api.patch(injectCompanyUserToUrl(injectCompanyToURL(API_COMPANY_MEMBER_DETAILS_URL, companyId), data.id), data)
}

export function removeCompanyMember(companyId: Company['id'], companyUserId: ItemIdType) {
  return Api.delete(
    injectCompanyUserToUrl(injectCompanyToURL(API_COMPANY_MEMBER_DETAILS_URL, companyId), companyUserId)
  )
}

// COMPANY VAT
export function fetchCompanyVats(companyId: Company['id']) {
  return Api.get(injectCompanyToURL(API_COMPANY_VATS_URL, companyId))
}

export function createCompanyVat(companyId: Company['id'], data: CompanyVatFormValues) {
  return Api.post(injectCompanyToURL(API_COMPANY_VATS_URL, companyId), data)
}

export function updateCompanyVat(companyId: Company['id'], data: Required<CompanyVatFormValues>) {
  return Api.patch(injectCompanyVatToUrl(injectCompanyToURL(API_COMPANY_VAT_DETAILS_URL, companyId), data.id), data)
}

export function removeCompanyVat(companyId: Company['id'], companyVatId: ItemIdType) {
  return Api.delete(injectCompanyVatToUrl(injectCompanyToURL(API_COMPANY_VAT_DETAILS_URL, companyId), companyVatId))
}

// COMPANY INTEGRATIONS
export function createIntegrationProvider(companyId: Company['id'], data: CreateIntegrationProviderPayload) {
  return Api.post(injectCompanyToURL(API_INTEGRATION_PROVIDER_URL, companyId), data)
}

export function updateIntegrationProvider(
  companyId: Company['id'],
  { id, ...data }: CreateIntegrationProviderPayload & { id: number }
) {
  return Api.patch(injectCompanyToURL(`${API_INTEGRATION_PROVIDER_URL}${id}/`, companyId), data)
}

export function loadIntegrationProviders(companyId: Company['id']) {
  return Api.get(injectCompanyToURL(API_INTEGRATION_PROVIDER_URL, companyId))
}

//* TAXATION
export function taxationSetTaxForm(companyId: Company['id'], data: SetTaxFormPayload) {
  return Api.post(injectCompanyToURL(API_TAXATION_TAX_FORM_URL, companyId), data)
}
