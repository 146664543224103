import { AxiosResponse } from 'axios'
import { call, select, takeLatest } from 'redux-saga/effects'

import { getActiveCompanyId, getErrorMessage } from '@helpers'

import { Note } from '@components/ArtifactViewer/elements/PostItNote'

import actions from './actions'
import * as api from './api'

function* fetchPostItSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<{ documentId: ItemIdType; documentType: ArtifactDocumentType }>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse = yield call(api.fetchPostIt, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* createPostItSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<{ documentId: ItemIdType; data: Partial<Note>; documentType: ArtifactDocumentType }>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse = yield call(api.createPostIt, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* updatePostItSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<{ documentId: ItemIdType; data: Partial<Note>; documentType: ArtifactDocumentType }>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse = yield call(api.updatePostIt, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* removePostItSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<{ documentId: ItemIdType; noteId: number; documentType: ArtifactDocumentType }>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    yield call(api.removePostIt, companyId, payload)
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

//! POST-IT on dashboard
function* fetchInvoicePostItsSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<{ documentType: ArtifactDocumentType; documentId: ItemIdType }>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse = yield call(api.fetchInvoicePostIts, companyId, payload)

    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// watcher Saga
export default function* commonSaga() {
  yield takeLatest(actions.fetchPostIt.REQUEST, fetchPostItSaga)
  yield takeLatest(actions.createPostIt.REQUEST, createPostItSaga)
  yield takeLatest(actions.updatePostIt.REQUEST, updatePostItSaga)
  yield takeLatest(actions.removePostIt.REQUEST, removePostItSaga)
  yield takeLatest(actions.fetchInvoicePostIts.REQUEST, fetchInvoicePostItsSaga)
}
