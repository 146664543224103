import React from 'react'

import __uniqueId from 'lodash/uniqueId'
import { Props as ModalProps } from 'react-modal'
import styled from 'styled-components'

import { LinkButton } from '@components/ui/Buttons'
import { Typography } from '@components/ui/Typography'

import { DIALOG_CLASS_NAMES, DIALOG_PADDING, SIMPLE_DIALOG_MAX_WIDTH } from '@constants/dialog'

import { BaseDialog } from './BaseDialog'

import { AttentionDialogCloseButtonMessage, CloseAndNavigatteButtonMessage } from '@messages'

const StyledBaseDialog = styled(BaseDialog)`
  &.${DIALOG_CLASS_NAMES.content.base} {
    --gap: 40px;
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    max-width: ${SIMPLE_DIALOG_MAX_WIDTH}px;
    padding: ${DIALOG_PADDING}px;

    & > *:last-child {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: calc(var(--gap) / 2);
    }
  }
`

interface ResponseDialogWithLinkProps extends Omit<ModalProps, 'isOpen' | 'onRequestClose' | 'children' | 'className'> {
  className?: string
  description: React.ReactChild
  onClose: (event: React.MouseEvent | React.KeyboardEvent) => void
  open: boolean
  primaryActionText?: React.ReactChild
  primaryLink: string
  secondaryActionText?: React.ReactChild
  secondaryLink: string
  title: React.ReactChild
}

export function ResponseDialogWithLinks({
  description,
  onClose,
  open,
  primaryActionText,
  primaryLink,
  secondaryActionText,
  secondaryLink,
  title,
  ...dialogProps
}: ResponseDialogWithLinkProps) {
  const idPrefix = __uniqueId('response-dialog')
  const aria = {
    labelledby: `${idPrefix}-heading`,
    describedby: `${idPrefix}-description`,
  }

  return (
    <StyledBaseDialog isOpen={open} onRequestClose={onClose} aria={aria} {...dialogProps}>
      <Typography id={aria.labelledby} tag="h2" size="heading-4" align="center">
        {title}
      </Typography>
      <Typography id={aria.describedby} size="400-sm" color="gray-50" align="center">
        {description}
      </Typography>
      <div>
        <LinkButton to={secondaryLink} variant="secondaryContained" withoutIcon>
          {secondaryActionText || CloseAndNavigatteButtonMessage}
        </LinkButton>
        <LinkButton to={primaryLink} variant="primaryContained" withoutIcon>
          {primaryActionText || AttentionDialogCloseButtonMessage}
        </LinkButton>
      </div>
    </StyledBaseDialog>
  )
}
