import styled, { DefaultTheme } from 'styled-components'

import { applyOpacity } from '@helpers/styled-helpers'

export enum BadgeVariant {
  DEFAULT = 'default',
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info',
  ERROR = 'error',
  NAV = 'nav',
}

interface StyleOnlyProps {
  variant?: BadgeVariant
}

function getBadgeBackgroundColor(theme: DefaultTheme, variant: BadgeVariant) {
  switch (variant) {
    case BadgeVariant.SUCCESS:
      return theme.colors.success[60]
    case BadgeVariant.WARNING:
      return theme.colors.gray[30]
    case BadgeVariant.INFO:
      return theme.colors.blue[40]
    case BadgeVariant.ERROR:
      return theme.colors.primary[170]
    case BadgeVariant.NAV:
      return theme.colors.common.navBadge
    default:
      return theme.colors.gray[30]
  }
}

export interface BadgeProps extends React.HTMLAttributes<HTMLElement>, StyleOnlyProps {
  children: React.ReactChild | React.ReactChild[]
}
const Badge = styled.span.withConfig<BadgeProps>({
  shouldForwardProp: (prop, defaultValidator) => {
    return !['variant'].includes(prop) && defaultValidator(prop)
  },
})`
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.gray[102]};
  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${applyOpacity(theme.colors.gray[100], 5)}`};
  padding: 2px 6px;
  line-height: 1;
  background-color: ${({ theme, variant = BadgeVariant.DEFAULT }) => getBadgeBackgroundColor(theme, variant)};
  white-space: nowrap;
`

export default Badge
