import { injectCompanyToURL } from '@helpers'

import Api from '../api'
import {
  API_CREATE_PAYMENT_METHOD_NONCE_URL,
  API_SUBSCRIPTION_DETAILS_URL,
  API_SUBSCRIPTION_HISTORY_URL,
  API_SUBSCRIPTION_PLAN_URL,
  API_SUBSCRIPTION_URL,
} from './urls'

export function fetchSubscriptionPlans(companyId: Company['id']) {
  return Api.get(injectCompanyToURL(API_SUBSCRIPTION_PLAN_URL, companyId))
}

export function applyPromocode(companyId: Company['id'], data: { promo_code: string }) {
  return Api.post(injectCompanyToURL(API_SUBSCRIPTION_PLAN_URL, companyId), data)
}

export function createSubscription(
  companyId: Company['id'],
  data: {
    plan_id: number
    promo_code: string | undefined
    nonce: unknown
  }
) {
  return Api.post(injectCompanyToURL(API_SUBSCRIPTION_URL, companyId), data)
}

export function updateSubscription(
  companyId: Company['id'],
  {
    id,
    ...payload
  }: {
    id: string
    new_plan_id: number
    new_plan_price: Decimal
    trial: boolean
  }
) {
  return Api.patch(
    injectCompanyToURL(API_SUBSCRIPTION_DETAILS_URL, companyId).replace('{subscription_id}', id),
    payload
  )
}

export function fetchSubscriptionHistory(companyId: Company['id']) {
  return Api.get(injectCompanyToURL(API_SUBSCRIPTION_HISTORY_URL, companyId))
}

// braintree
export function createPaymentMethodNonce(companyId: Company['id'], data: { nonce: unknown }) {
  return Api.post(injectCompanyToURL(API_CREATE_PAYMENT_METHOD_NONCE_URL, companyId), data)
}
