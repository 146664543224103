export enum FiltersStateKey {
  BANK_TRANSACTIONS = 'bankTransactions',
  DOKUMENT_LIST = 'dokumentList',
  EXPENSE_LIST = 'expenseList',
  INCOME_LIST = 'incomeList',
  MONTHLY_STATEMENT = 'monthlyStatement',
  PAID_THROUGHS = 'paidThroughs',
  PARTNER_LIST = 'partnerList',
  PAYMENT_ORDERS = 'paymentOrders',
  PAYMENT_TRANSACTIONS = 'paymentTransactions',
  QUARANTINE_LIST = 'quarantineList',
  SALARY_LIST = 'salaryList',
  TAX_LIST = 'taxList',
}

export const DATE_FILTER_KEYS = {
  ACCOUNTING_PERIOD: 'accounting_period',
  CREATED_AT: 'created_at',
  DUE_AT: 'due_at',
  FINANCIAL_FULFILLMENT: 'financial_fulfillment',
  FULFILLED_AT: 'fulfilled_at',
  ISSUED_AT: 'issued_at',
  VALUE_DATE: 'value_date',
  CURRENT_MONTH: 'title', // tárgyhó
  PAYMENT_DATE: 'payment_date',
  EXPORTED_AT: 'exported_at',
  VAT_PERIOD: 'vat_period',
} as const
