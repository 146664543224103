import { AxiosResponse } from 'axios'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { getActiveCompanyId, getErrorMessage } from '@helpers'

import { SettingsProviderCompany } from '@contexts/SettingsDialogProvider/types'

import { BackendOnboardingInvitationStatus } from '@oldComponents/pages/OnboardingInvitationPage/OnboardingInvitationPage'
import { OnboardingData } from '@oldComponents/pages/OnboardingPage/types'

import actions from './actions'
import * as api from './api'

function* connectKsziSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<{ company_id: Company['id'] }>) {
  try {
    const response: AxiosResponse<{ income_count?: number; expense_count?: number }> = yield call(
      api.connectKszi,
      payload
    )
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* fetchOnboardingInviteApprovalsSaga() {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse = yield call(api.getOnboardingInvitationMembersList, companyId)
    yield put(actions.fetchOnboardingInviteApprovals.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchOnboardingInviteApprovals.failure(errorMsg))
  }
}

function* declineOnboardingInviteSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<{ company: SettingsProviderCompany; data: { uuid: string } }>) {
  try {
    const companyId = payload.company.id
    const uuid = payload.data.uuid
    yield call(api.declineOnboardingInvitation, companyId, uuid)
    yield put(actions.declineOnboardingInvite.success(uuid))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.declineOnboardingInvite.failure())
    yield call(reject, errorMsg)
  }
}

function* confirmOnboardingSaga({ meta: { resolve, reject } }: AsyncSagaAction<void>) {
  try {
    const response: AxiosResponse<OnboardingData> = yield call(api.confirmOnboarding)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* getOnboardingInstructionsSaga({ meta: { resolve, reject } }: AsyncSagaAction<void>) {
  try {
    const response: AxiosResponse<OnboardingData> = yield call(api.getOnboardingInstructions)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* getOnboardingInvitationStatusSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<string>) {
  try {
    const response: AxiosResponse<BackendOnboardingInvitationStatus> = yield call(
      api.getOnboardingInvitationStatus,
      payload
    )
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// watcher Saga
export default function* commonSaga() {
  yield takeLatest(actions.fetchOnboardingInviteApprovals.REQUEST, fetchOnboardingInviteApprovalsSaga)
  yield takeLatest(actions.declineOnboardingInvite.REQUEST, declineOnboardingInviteSaga)
  yield takeLatest(actions.connectKszi.REQUEST, connectKsziSaga)
  yield takeLatest(actions.confirmOnboarding.REQUEST, confirmOnboardingSaga)
  yield takeLatest(actions.getOnboardingInstructions.REQUEST, getOnboardingInstructionsSaga)
  yield takeLatest(actions.getOnboardingInvitationStatus.REQUEST, getOnboardingInvitationStatusSaga)
}
