import React from 'react'

import { FormControl, FormHelperText, InputLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { useController } from 'react-hook-form'
import styled from 'styled-components'

import { ButtonBaseProps } from '../Buttons'
import { Typography } from '../Typography'

import { formStyles } from '@styles'
import { SelectableCardButton } from './styles'

const useStyles = makeStyles(formStyles)

const CardsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
`

interface ReactHookFormSelectCardFieldProps {
  className?: string
  disabled?: boolean
  name: string
  options: Array<{ label: ButtonBaseProps['children']; value: number | string | boolean }>
  isLabelHighlighted?: boolean
  label?: React.ReactNode
  required?: boolean
}

export function ReactHookFormSelectCardField({
  className,
  disabled,
  name,
  options,
  isLabelHighlighted,
  label,
  required,
}: ReactHookFormSelectCardFieldProps) {
  const {
    field: { onChange, value },
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({ name })
  const classes = useStyles()

  const hasError = !!error
  const isFieldDisabled = disabled || isSubmitting

  return (
    <FormControl
      className={cx(className, 'form-control', {
        'form-control-error': hasError,
      })}
      disabled={isFieldDisabled}
      error={hasError}
      fullWidth
      margin="normal"
      required={required}
    >
      {label && (
        <InputLabel
          className={cx(classes.bootstrapFormLabel, { [classes.highlightedLabel]: isLabelHighlighted })}
          htmlFor={name}
          shrink
        >
          {label}
        </InputLabel>
      )}
      <CardsGrid className={classes.selectInput}>
        {options.map((option, index) => (
          <SelectableCardButton
            $isSelected={value === option.value}
            data-testid={`${name}-option-${option.value}`}
            disabled={isFieldDisabled}
            key={`${name}-option-${index}`}
            onClick={() => onChange(option.value)}
          >
            <Typography tag="span" size="navigation-md" color={value === option.value ? 'gray-80' : 'gray-70'}>
              {option.label}
            </Typography>
          </SelectableCardButton>
        ))}
      </CardsGrid>
      {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
    </FormControl>
  )
}
