import React from 'react'

import __uniqueId from 'lodash/uniqueId'
import moment from 'moment'
import { Field, Form } from 'react-final-form'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'
import * as Yup from 'yup'

import { createAriaAttributes, makeValidate } from '@helpers'

import { Button, RffSubmitButton } from '@components/ui/Buttons'
import { CustomDialog, CustomDialogActions, CustomDialogBody, CustomDialogHeader } from '@components/ui/Dialogs'
import { FormError } from '@components/ui/FormElements'
import { PrimaryInheritLink } from '@components/ui/Links'
import { Typography } from '@components/ui/Typography'
import { FirstColumn, FormGrid2Column, SecondColumn } from '@components/ui/Wrappers'
import { DateInput, RenderTextField } from '@oldComponents/ui/form'

import { FIELD_SUBSCRIPTION, LINKS } from '@constants'

import { CancelButtonMessage, CreateButtonMessage, formErrorMessages, globalMessages } from '@messages'

const StyledCustomDialogHeader = styled(CustomDialogHeader)`
  max-width: 596px;

  p {
    margin-top: 20px;
  }
`

const FormDialogBody = styled(CustomDialogBody)`
  padding: 0;
  max-width: 596px;

  ${FormGrid2Column} {
    padding: 40px;
  }
`

const FormDialogActions = styled(CustomDialogActions)`
  flex-direction: column;
  align-items: center;
  justify-content: unset;
`

const ButtonsContainerDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const messages = defineMessages({
  startDateLabel: {
    id: 'dialogs.companyInactivePeriod.label.startDate',
    defaultMessage: 'Kezdete',
  },
  endDateLabel: {
    id: 'dialogs.companyInactivePeriod.label.endDate',
    defaultMessage: 'Vége',
  },
})

type CompanyInactivePeriodEditorFormValues = {
  endDate: string
  startDate: string
}

interface CompanyInactivePeriodEditorDialogProps {
  open: boolean
  onClose: VoidFunction
  initialValues: CompanyInactivePeriodEditorFormValues
  isEdit: boolean
  onSubmit: AsyncFunction<CompanyInactivePeriodEditorFormValues, unknown>
}

export function CompanyInactivePeriodEditorDialog({
  open,
  onClose,
  initialValues,
  isEdit,
  onSubmit,
}: CompanyInactivePeriodEditorDialogProps) {
  const { formatMessage } = useIntl()
  const ariaIdPrefix = __uniqueId('company-inactive-period-editor-dialog')
  const aria = createAriaAttributes(ariaIdPrefix)

  const yupValidatorRef = React.useRef(
    makeValidate(
      Yup.object().shape({
        startDate: Yup.string()
          .required(formatMessage(formErrorMessages.required))
          .test(
            'validDate',
            formatMessage(formErrorMessages.invalidDate),
            value => !value || moment(value, 'YYYY-MM-DD', true).isValid()
          ),
        endDate: Yup.string()
          .nullable()
          .when(['startDate'], ([startDate], schema) => {
            if (startDate && moment(startDate, 'YYYY-MM-DD', true).isValid()) {
              return schema.test(
                'validEndDate',
                formatMessage(formErrorMessages.invalidEndDate),
                function testValue(value) {
                  return moment(value, 'YYYY-MM-DD', true).isAfter(moment(startDate, 'YYYY-MM-DD', true))
                }
              )
            }

            return schema.test(
              'validDate',
              formatMessage(formErrorMessages.invalidDate),
              value => !value || moment(value, 'YYYY-MM-DD', true).isValid()
            )
          }),
      })
    )
  )

  return (
    <CustomDialog
      ariaIdPrefix={ariaIdPrefix}
      onClose={onClose}
      open={open}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    >
      <StyledCustomDialogHeader
        title={
          <FormattedMessage id="dialogs.companyInactivePeriod.title" defaultMessage="Szünetelt időszak megadása" />
        }
        borderless
      >
        <Typography align="center">
          <FormattedMessage
            id="dialogs.companyInactivePeriod.description"
            defaultMessage="Kérjük, kiemelten figyelj és pontosan add meg a szüneteltetés első és utolsó napját! (Amennyiben még tart, akkor a végdátumot ne add meg!) További információt <link>itt olvashatsz</link>."
            values={{
              link: function PrimaryTextLink(chunks: string) {
                return (
                  <PrimaryInheritLink
                    href={LINKS.kataBevallasInactivePeriodsInfo}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {chunks}
                  </PrimaryInheritLink>
                )
              },
            }}
          />
        </Typography>
      </StyledCustomDialogHeader>
      <FormDialogBody>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          validate={yupValidatorRef.current}
          render={({ handleSubmit, submitting, hasValidationErrors }) => (
            <form
              data-testid="company-inactive-period-form"
              onSubmit={handleSubmit}
              autoComplete="off"
              noValidate
              id={aria.describedby}
            >
              <FormGrid2Column>
                <FirstColumn>
                  <Field
                    component={RenderTextField}
                    disabled={submitting}
                    label={formatMessage(messages.startDateLabel)}
                    name="startDate"
                    subscription={FIELD_SUBSCRIPTION}
                    InputProps={{
                      placeholder: formatMessage(globalMessages.dateFieldPlaceholder),
                      inputComponent: DateInput,
                    }}
                    required
                  />
                </FirstColumn>
                <SecondColumn>
                  <Field
                    component={RenderTextField}
                    disabled={submitting}
                    label={formatMessage(messages.endDateLabel)}
                    name="endDate"
                    subscription={FIELD_SUBSCRIPTION}
                    InputProps={{
                      placeholder: formatMessage(globalMessages.dateFieldPlaceholder),
                      inputComponent: DateInput,
                    }}
                  />
                </SecondColumn>
              </FormGrid2Column>
              <FormDialogActions borderless>
                <FormError />
                <ButtonsContainerDiv>
                  <Button onClick={onClose} variant="primaryText" type="button">
                    {CancelButtonMessage}
                  </Button>
                  <RffSubmitButton variant="primaryContained" disabled={hasValidationErrors}>
                    {isEdit ? formatMessage(globalMessages.addButtonText) : CreateButtonMessage}
                  </RffSubmitButton>
                </ButtonsContainerDiv>
              </FormDialogActions>
            </form>
          )}
        />
      </FormDialogBody>
    </CustomDialog>
  )
}
