interface ReducerState {
  categoryTypeId: Nullable<number>
  data: number[]
  loading: boolean
}

export const initialState: ReducerState = {
  categoryTypeId: null,
  data: [],
  loading: false,
}

export enum Actions {
  CLEAR = 'CLEAR',
  REQUEST = 'REQUEST',
  SET_RECOMMENDATIONS = 'SET_RECOMMENDATIONS',
}

type ReducerAction =
  | { type: Actions.REQUEST }
  | { type: Actions.CLEAR }
  | { type: Actions.SET_RECOMMENDATIONS; payload: { results: number[]; categoryTypeId: number } }

export function reducer(state: ReducerState, action: ReducerAction): ReducerState {
  switch (action.type) {
    case Actions.REQUEST:
      return {
        ...state,
        loading: true,
      }

    case Actions.SET_RECOMMENDATIONS:
      return {
        categoryTypeId: action.payload.categoryTypeId,
        data: action.payload.results,
        loading: false,
      }

    case Actions.CLEAR:
      return {
        ...state,
        categoryTypeId: null,
      }

    default:
      return state
  }
}
