import { V2_COMPANY_ROUTE } from '@constants'

export const API_DOKUMENTS_LIST_URL = `${V2_COMPANY_ROUTE}documents/`
export const API_CREATE_WEBLINK_URL = `${V2_COMPANY_ROUTE}documents/create`
export const API_BULK_REMOVE_DOKUMENTS_URL = `${V2_COMPANY_ROUTE}documents/delete`
export const API_BULK_DOWNLOAD_DOKUMENTS_URL = `${V2_COMPANY_ROUTE}documents/download`
export const API_BULK_UPLOAD_DOKUMENTS_URL = `${V2_COMPANY_ROUTE}documents/upload-multiple`
export const API_BULK_ATTACH_DOKUMENTS_URL = `${V2_COMPANY_ROUTE}documents/attach`
export const API_DETACH_DOKUMENT_URL = `${V2_COMPANY_ROUTE}documents/detach`
export const API_DOKUMENT_DETAILS_URL = `${V2_COMPANY_ROUTE}documents/{document_id}`
export const API_DOKUMENT_TYPE_LIST_URL = `${V2_COMPANY_ROUTE}documents/document-type`
export const API_DOKUMENT_DETAILS_UPDATE_URL = `${API_DOKUMENT_DETAILS_URL}/update`
export const API_DOKUMENT_TYPE_CREATE_URL = `${API_DOKUMENT_TYPE_LIST_URL}/create`
export const API_DOKUMENT_USER_SAW_URL = `${V2_COMPANY_ROUTE}documents/{document_id}/user-saw`
export const API_DOKUMENTS_STATUS_CHECK_URL = `${V2_COMPANY_ROUTE}documents/status-check`
export const API_DOKUMENTS_BACKGROUND_ACTION_URL = `${V2_COMPANY_ROUTE}documents/actions`
export const API_DOKUMENTS_CONNECTIONS_URL = `${V2_COMPANY_ROUTE}documents/{document_id}/connections`
export const API_DOKUMENTS_HAS_CONNECTIONS_URL = `${V2_COMPANY_ROUTE}documents/has-connections`

//* last filing number
export const API_FILING_NUMBER_URL = `${V2_COMPANY_ROUTE}document-filing-number/`
