import React from 'react'

import __unionBy from 'lodash/unionBy'
import { SortingRule } from 'react-table'

import { CommonAxiosPayload } from '@services/types'

import { cancellablePromise, getOrderingParam, getRowId, parseApiErrorMessage } from '@helpers'

import { SimpleTableOnSelectChangeProp } from '@components/tables'

import { ATTATCH_SEARCH_ROWS_PER_PAGE } from '@constants'

import { useCancellablePromiseRef } from './useCancellablePromiseRef'
import { useNotVisibleSelectionCount } from './useNotVisibleSelectionCount'
import { PageChangePayload } from './useTableControls'

export interface MultiAttachTableProps<ListData> {
  companyId: number
  defaultSelectedItems?: SelectedItemProps[]
  filters: { search: string; searchFields: string[] }
  loadData: AsyncFunction<
    {
      search: string
      searchFields: string[]
      pageSize: number
      ordering: string
    },
    { data: ListData[]; next: BackendCallableLink; previous: BackendCallableLink }
  >
  loadDataByUrl: AsyncFunction<
    CommonAxiosPayload,
    { results: ListData[]; next: BackendCallableLink; previous: BackendCallableLink }
  >
  onSelect: (items: ListData[]) => void
  selectedItems: ListData[]
}

/**
 * Custom hook to manage the state and controls for an attach table.
 *
 * @template ListData - The type of the list data items.
 * @template AttachTableRow - The type of the attach table row.
 *
 * @param {Object} params - The parameters for the hook.
 * @param {SelectedItemProps[]} params.defaultSelectedItems - The default selected items.
 * @param {Object} params.filters - Filters to apply when loading data.
 * @param {Function} params.loadData - Function to load data with given parameters.
 * @param {Function} params.loadDataByUrl - Function to load data by URL.
 * @param {Function} params.onSelect - Callback function when an item is selected.
 * @param {ListData[]} params.selectedItems - The currently selected items.
 * @param {string} rowDataAccessor - The accessor for the row data in the table row.
 * @param {string} defaultOrderBy - The default column to order by.
 *
 * @returns {Array} - Returns an array containing:
 *   - state: The current state of the table data, loading status, and pagination URLs.
 *   - useTableProps: The props to pass to the SimpleTable component.
 *   - onSortByChange: Function to handle sorting changes.
 *   - onSelectChange: Function to handle selection changes.
 *   - onPageChange: Function to handle page changes.
 */
export function useAttachTableControls<ListData extends SelectedItemProps, AttachTableRow extends object>(
  {
    defaultSelectedItems,
    filters,
    loadData,
    loadDataByUrl,
    onSelect,
    selectedItems,
  }: Pick<
    MultiAttachTableProps<ListData>,
    'loadData' | 'loadDataByUrl' | 'filters' | 'onSelect' | 'selectedItems' | 'defaultSelectedItems'
  >,
  rowDataAccessor: keyof AttachTableRow,
  defaultOrderBy: string
) {
  const cPromiseRef = useCancellablePromiseRef()
  const [state, setState] = React.useState<{
    data: ListData[]
    error: Nullable<string>
    loading: boolean
    nextPageUrl: Nullable<string>
    previousPageUrl: Nullable<string>
  }>({
    data: [],
    error: null,
    loading: false,
    nextPageUrl: null,
    previousPageUrl: null,
  })
  const [orderState, setOrderState] = React.useState<{ order: OrderOptions; orderBy: string }>({
    order: 'desc',
    orderBy: defaultOrderBy,
  })

  const onSortByChange = React.useCallback((payload: SortingRule<Record<string, unknown>>[]) => {
    const { id, desc } = payload[0]

    setOrderState({
      order: desc ? 'desc' : 'asc',
      orderBy: id,
    })
  }, [])

  const onSelectChange: SimpleTableOnSelectChangeProp<AttachTableRow> = React.useCallback(
    ({ selectedRowIds = {} }: { selectedRowIds?: Record<ItemIdType, boolean> }) => {
      onSelect(__unionBy(state.data, selectedItems, 'id').filter(({ id }) => selectedRowIds[id]))
    },
    [onSelect, state.data, selectedItems]
  )

  const onPageChange = React.useCallback(
    async ({ url }: PageChangePayload) => {
      try {
        setState(state => ({ ...state, loading: true }))
        cPromiseRef.current = cancellablePromise(loadDataByUrl({ method: 'get', url }))
        const { data, next, previous } = await cPromiseRef.current.promise
        setState(state => ({ ...state, data, nextPageUrl: next, previousPageUrl: previous, loading: false }))
      } catch (error) {
        const errorMsg = parseApiErrorMessage(error)
        if (errorMsg) {
          setState(state => ({ ...state, loading: false, error: errorMsg }))
        }
      }
    },
    [cPromiseRef, loadDataByUrl]
  )

  React.useEffect(() => {
    async function fetchData() {
      const params = {
        pageSize: ATTATCH_SEARCH_ROWS_PER_PAGE,
        ordering: getOrderingParam(orderState),
        ...filters,
      }
      try {
        setState(state => ({ ...state, loading: true }))
        cPromiseRef.current = cancellablePromise(loadData(params))
        const { data, next, previous } = await cPromiseRef.current.promise
        setState(state => ({ ...state, data, nextPageUrl: next, previousPageUrl: previous, loading: false }))
      } catch (error) {
        const errorMsg = parseApiErrorMessage(error)
        if (errorMsg) {
          setState(state => ({ ...state, loading: false, error: errorMsg }))
        }
      }
    }
    fetchData()
  }, [cPromiseRef, filters, loadData, orderState])

  //* Table props
  const useTableProps = React.useMemo(() => {
    const hiddenColumns = [rowDataAccessor]

    return {
      initialState: {
        hiddenColumns,
        sortBy: [{ id: orderState.orderBy, desc: orderState.order === 'desc' }],
      },
      getRowId,
      defaultSelectedRowIds: defaultSelectedItems?.reduce(
        (acc: Record<ItemIdType, boolean>, { id }: SelectedItemProps) => ({ ...acc, [id]: true }),
        {} as Record<ItemIdType, boolean>
      ),
      selectedRowIds: selectedItems.reduce(
        (acc: Record<ItemIdType, boolean>, { id }: ListData) => ({ ...acc, [id]: true }),
        {} as Record<ItemIdType, boolean>
      ),
      hasMorePage: Boolean(state.nextPageUrl || state.previousPageUrl),
      disableAllSelected: true, // turn off the default select all
    }
  }, [
    defaultSelectedItems,
    orderState.order,
    orderState.orderBy,
    rowDataAccessor,
    selectedItems,
    state.nextPageUrl,
    state.previousPageUrl,
  ])

  //* non visible selection control
  const notVisibleSelectedNum = useNotVisibleSelectionCount(selectedItems, state.data)

  const handleNotificationClear = React.useCallback(() => {
    const dataIds = state.data.map(obj => obj.id)

    onSelect(selectedItems.filter(sData => dataIds.includes(sData.id)))
  }, [onSelect, selectedItems, state.data])

  return [
    state,
    useTableProps,
    onSortByChange,
    onSelectChange,
    onPageChange,
    notVisibleSelectedNum,
    handleNotificationClear,
  ] as const
}
