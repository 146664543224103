import React from 'react'

import { CircularProgress } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { StrongTextValues } from '@helpers/translation'

import { Typography } from '@components/ui'
import AsyncFetch from '@oldComponents/AsyncActionTriggers'

const PagingContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  gap: 10px;
  text-align: right;
  flex-shrink: 0;
`

const CounterDisplayDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.common.pageBackground};
  display: flex;
  align-items: flex-start;
  border-radius: 4px;
  gap: 10px;
  padding: 4px 12px;
`

interface PagingCounterProps {
  detailsPaging: ControlsStore['detailsPaging']
  pageSize: number
  paramId: string | undefined
}

/**
 * Displays the current position of the invoice viewer in the invoice list.
 * It uses the `usePagingCounter` hook to get the current position and condition to show the counter.
 */
function PurePagingCounter({
  detailsPaging: { config, loading, page, totalCount },
  pageSize,
  paramId,
}: PagingCounterProps) {
  if (!config || !paramId) {
    return null
  }

  const item = config.listMap[paramId]

  if (!item) {
    return null
  }

  // Calculate current position in the list
  const currentPosition = (page - 1) * pageSize + item.position

  return (
    <PagingContainer>
      {totalCount === 0 && <AsyncFetch mode={['expenseCountV2']} />}
      <Typography color="gray-80" size="400-sm">
        <FormattedMessage
          id="artifactViewer.paging"
          defaultMessage={'<b>Itt jársz:</b> '}
          values={{ ...StrongTextValues }}
        />
      </Typography>
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        <CounterDisplayDiv>
          <Typography color="gray-80" size="400-sm">{`${currentPosition} / ${totalCount}`}</Typography>
        </CounterDisplayDiv>
      )}
    </PagingContainer>
  )
}

export const ExpensePagingCounter = connect((state: Store) => ({
  detailsPaging: state.controls.detailsPaging,
  pageSize: state.expense.list.pageSize,
}))(PurePagingCounter)

ExpensePagingCounter.displayName = 'ExpensePagingCounter'
