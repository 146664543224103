import React from 'react'

import { useIntl } from 'react-intl'
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format'

import { TextFieldBehavior, TextFieldBehaviorProps } from './TextFieldBehavior'

import messages from '@components/forms/messages'

//* local helper to format date string
function formatDate(value: string) {
  if (!value) return value
  const numericStringValue = value.replace(/[^\d]/g, '')
  if (numericStringValue.length < 5) {
    return numericStringValue
  }
  if (numericStringValue.length < 7) {
    return `${numericStringValue.substring(0, 4)}-${numericStringValue.substring(4)}`
  }
  return `${numericStringValue.substring(0, 4)}-${numericStringValue.substring(4, 6)}-${numericStringValue.substring(
    6,
    8
  )}`
}

export interface ReactHookFormDateInputProps
  extends Omit<NumberFormatProps<TextFieldBehaviorProps>, 'onChange' | 'value'> {
  name: Required<NumberFormatProps<TextFieldBehaviorProps>['name']>
  onChange: (value: Required<NumberFormatProps<TextFieldBehaviorProps>['value']>) => void
  value: Required<NumberFormatProps<TextFieldBehaviorProps>['value']>
}

export function ReactHookFormDateInput({
  allowEmptyFormatting = false,
  onChange,
  value,
  ...rest
}: ReactHookFormDateInputProps) {
  const { formatMessage } = useIntl()

  const handleValueChange = React.useCallback(
    ({ value, formattedValue }: NumberFormatValues) => {
      onChange(value ? formattedValue : value)
    },
    [onChange]
  )

  return (
    <NumberFormat
      {...rest}
      allowEmptyFormatting={allowEmptyFormatting}
      autoComplete="off"
      customInput={TextFieldBehavior}
      format={formatDate}
      isNumericString
      onValueChange={handleValueChange}
      placeholder={formatMessage(messages.dateFieldPlaceholder)}
      type="tel"
      value={value ?? ''}
    />
  )
}
