import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { applyOpacity } from '@helpers/styled-helpers'

import { ButtonBase } from './ButtonBase'

const ToggleWrapperDiv = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.gray[30]};
  border-radius: 100px;
  box-shadow: inset 0px 0px 2px ${({ theme }) => applyOpacity(theme.colors.gray[100], 10)};
  display: grid;
  overflow: hidden;
  padding: 2px;
  height: 30px;
  grid-template-columns: repeat(var(--number-of-buttons, 2), minmax(0, 1fr));

  ${ButtonBase} {
    min-width: 60px;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    position: relative;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.2;
    padding: 0 20px;
    color: ${({ theme }) => theme.colors.gray[175]};

    &[aria-pressed='true']:after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      top: 0;
      bottom: 0;
      left: 6px;
      margin: auto;
      background-color: ${({ theme }) => theme.colors.primary[50]};
      border-radius: 50%;
      transition: background-color 250ms ease-in-out;
    }

    &:not(:disabled) {
      &:hover,
      &:active,
      &:focus {
        color: ${({ theme }) => theme.colors.primary[50]};
      }

      &[aria-pressed='true'] {
        color: ${({ theme }) => theme.colors.primary[50]};

        &:hover,
        &:active,
        &:focus {
          color: ${({ theme }) => theme.colors.primary[170]};

          &:after {
            background-color: ${({ theme }) => theme.colors.primary[170]};
          }
        }

        &:after {
          background-color: ${({ theme }) => theme.colors.primary[50]};
        }
      }
    }

    &:first-of-type {
      grid-column: 1/2;
    }

    &:last-of-type {
      grid-column: 2/3;
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.gray[40]};
      &[aria-pressed='true']:after {
        background-color: ${({ theme }) => theme.colors.gray[40]};
      }
    }
  }

  /* ACTIVE BUTTON BACKGROUND */
  &:before {
    background-color: ${({ theme }) => theme.colors.gray[0]};
    box-shadow: 0px 0px 2px ${({ theme }) => applyOpacity(theme.colors.gray[100], 35)};
    border-radius: 30px;
    content: '';
    width: var(--button-width, 60px);
    height: calc(100% - 4px);
    position: absolute;
    top: 2px;
    left: var(--left);
    transition: transform 300ms ease-in-out;
    transform: translateX(var(--background-translate, 0));
  }
`

type ToggleButtonOptions<Value> = FixedLengthArray<{ value: Value; label: StringOrMessage }, 0 | 1 | 2>

interface ToggleButtonProps<Value> {
  className?: string
  disabled?: boolean
  onToggle: VoidFunction
  options: ToggleButtonOptions<Value>
  value: Value
}

/**
 * Toggle button component with primary and secondary value.
 *
 * NOTE: this will only use the first two children
 *
 * @param {ToggleButtonProps} {
 *   value,
 *   disabled = false,
 *   onToggle,
 *   options,
 * }
 */
export function ToggleButton<Value extends string>({
  className,
  disabled = false,
  onToggle,
  options,
  value: currentValue,
}: ToggleButtonProps<Value>) {
  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation()
    onToggle()
  }

  return (
    <ToggleWrapperDiv
      className={className}
      role="group"
      style={{
        '--left': '2px',
        '--number-of-buttons': options.length,
        '--button-width': options.length === 1 ? 'calc(100% - 4px)' : 'calc(50% - 2px)',
        '--background-translate': options.length === 2 && currentValue === options[1]?.value ? '100%' : 0,
      }}
    >
      {options.map(({ value, label }) => (
        <ButtonBase
          aria-pressed={value === currentValue}
          disabled={disabled}
          key={value}
          type="button"
          value={value}
          {...(value !== currentValue && { onClick: handleClick })}
        >
          {label}
        </ButtonBase>
      ))}
    </ToggleWrapperDiv>
  )
}

ToggleButton.propTypes = {
  disabled: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
    }).isRequired
  ).isRequired,
  value: PropTypes.string.isRequired,
}
