import {
  CancelableProcessListener,
  CancelableProcessListenerProps,
  ProcessListener,
  ProcessListenerProps,
} from '@helpers/process'

import { expenseStopRecognition } from '../expense/api'
import { backgroundJob, backgroundJobEmail } from './api'

export const JOB_STATUS_CREATED = 10
const JOB_STATUS_PROCESSING = 20
export const JOB_STATUS_FINISHED = 30
const JOB_STATUS_ERROR = 40

//! background job process w/o sending data via email when max tries reached or time is expired
export const config: ProcessListenerProps = {
  api: backgroundJob,
  responseHandler(response, resolve, reject, setProcess) {
    switch (response.data.status) {
      case JOB_STATUS_FINISHED:
        resolve(response)
        break
      case JOB_STATUS_ERROR:
        // TODO: check this when errors object change
        reject(response.data.errors.message)
        break
      case JOB_STATUS_PROCESSING:
      default:
        setProcess()
    }
  },
  interval: 6,
  delay: 2,
  maxTries: 5,
  calculateInterval(tries, interval, delay) {
    return (tries * interval + delay) * 1000
  },
}

//! background job process w/ sending data via email when max tries reached or time is expired
// NOTE: every background job process is useing this (2022.03.09)
export const emailConfig: ProcessListenerProps = {
  api: backgroundJob,
  emailApi: backgroundJobEmail,
  responseHandler(response, resolve, reject, setProcess) {
    // notify user on FE
    if (response.data.send_email) {
      resolve(response)
    } else {
      switch (response.data.status) {
        case JOB_STATUS_FINISHED:
          resolve(response)
          break
        case JOB_STATUS_ERROR:
          // TODO: check this when errors object change
          reject(response.data.errors.message)
          break
        case JOB_STATUS_PROCESSING:
        default:
          setProcess()
      }
    }
  },
  interval: 5,
  delay: 1,
  maxTries: 6, // 1, 3, 5, 5, 5, 5 [24sec] - trigger emailApi when reached
  calculateInterval(tries, interval, delay) {
    if (tries < 2) {
      return (2 * tries + 1) * delay * 1000
    }
    return interval * 1000
  },
}

export const BackgroundPartnerEmailProcess = new ProcessListener(emailConfig)
export const BackgroundDownloadEmailProcess = new ProcessListener(emailConfig)

// CANCELABLE EXPENSE UPLOAD
const extendedConfig: CancelableProcessListenerProps = {
  api: config.api,
  cancelApi: expenseStopRecognition,
  responseHandler: config.responseHandler,
  interval: 4,
  delay: 4,
  // maxTries: 5,
  calculateInterval(tries, interval, delay) {
    // after 5 tries: [0,1,2,3,4] ~ 1 min
    if (tries > 4) {
      return 15000 // 15sec
    }
    return (tries * interval + delay) * 1000
  },
}

export const BackgroundExpenseUploadProcess = new CancelableProcessListener(extendedConfig)

// MERGE PARTNERS
const mergeConfig: ProcessListenerProps = {
  api: backgroundJob,
  responseHandler(response, resolve, reject, setProcess) {
    switch (response.data.status) {
      case JOB_STATUS_FINISHED:
        resolve(response)
        break
      case JOB_STATUS_ERROR:
        // TODO: check this when errors object change
        reject(response.data.errors.message)
        break
      case JOB_STATUS_PROCESSING:
      default:
        setProcess()
    }
  },
  interval: 15,
  delay: 5,
  maxTries: 10,
  calculateInterval(tries, interval, delay) {
    return (tries ? interval : delay) * 1000
  },
}
export const BackgroundPartnerMergeProcess = new ProcessListener(mergeConfig)
