import { serviceActionsGenerator } from '../serviceGenerator'

const actionTypes = [
  '!FETCH_REPORTS',
  '!FETCH_WORKOUT_REPORT',
  '!FETCH_WORKOUT_DETAILS_REPORT',
  '!CREATE_PAYEE_WORKFLOW',
  '!FETCH_PARTNER_DETAILS',
  '!FETCH_VAT_POSITION_REPORT',
  '!FETCH_BANK_TRANSACTIONS_REPORT',
  '!FETCH_AI_CHAT_DETAILS',
] as const

export default serviceActionsGenerator('reports', actionTypes)
