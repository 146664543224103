import React from 'react'

import { Button, WarningText } from '@components/ui'

import { DeleteConfirmDialogActionsProps } from '../types'

import { CancelButtonMessage } from '@messages'
import { ButtonsContainer, StyledCustomDialogActions } from './styles'

export function DeleteConfirmDialogActions({ error, children, onClose }: DeleteConfirmDialogActionsProps) {
  return (
    <StyledCustomDialogActions borderless>
      {error && <WarningText>{error}</WarningText>}
      <ButtonsContainer>
        <Button variant="secondaryContained" onClick={onClose}>
          {CancelButtonMessage}
        </Button>
        {children}
      </ButtonsContainer>
    </StyledCustomDialogActions>
  )
}
