// based on the package: https://www.npmjs.com/package/redux-saga-actions

import __camelCase from 'lodash/camelCase'

const SINGLE_STATUS_LIST = ['REQUEST'] as const
const MULTI_STATUS_LIST = ['REQUEST', 'SUCCESS', 'FAILURE'] as const

type RemovePrefix<S extends string> = S extends `!${infer Rest}` ? Rest : S

type ActionPayload = any

type PromiseMeta = {
  resolve: (payload: any) => void
  reject: (payload: any) => void
}

type ActionMethod<ReducerType extends string> = (
  payload?: ActionPayload,
  meta?: PromiseMeta
) => {
  type: ReducerType
  payload: ActionPayload
  meta?: PromiseMeta
}

type ReducerType<
  ServiceName extends string,
  ActionType extends string,
  Status extends string
> = `@${ServiceName}/${ActionType}_${Status}`

type SingleAction<ServiceName extends string, CleanType extends string> = {
  [Status in (typeof SINGLE_STATUS_LIST)[number] as Lowercase<Status>]: ActionMethod<
    ReducerType<ServiceName, CleanType, Status>
  >
} & {
  [Status in (typeof SINGLE_STATUS_LIST)[number] as Uppercase<Status>]: ReducerType<ServiceName, CleanType, Status>
}

type MultiAction<ServiceName extends string, ActionType extends string> = {
  [Status in (typeof MULTI_STATUS_LIST)[number] as Lowercase<Status>]: ActionMethod<
    ReducerType<ServiceName, ActionType, Status>
  >
} & {
  [Status in (typeof MULTI_STATUS_LIST)[number] as Uppercase<Status>]: ReducerType<ServiceName, ActionType, Status>
}

type Action<ServiceName extends string, ActionType extends string> = ActionType extends `!${infer TType}`
  ? SingleAction<ServiceName, TType>
  : MultiAction<ServiceName, ActionType>

type ActionCreators<ServiceName extends string, ActionTypes extends ReadonlyArray<string>> = {
  [ActionType in ActionTypes[number] as `${CamelCase<RemovePrefix<ActionType>>}`]: Action<ServiceName, ActionType>
}

/**
 * Function to generate service actions from a list of action types.
 * @param serviceName - The name of the service.
 * @param actionTypes - The list of action types. The action type should start with '!' if it is a single action. (e.g. '!SINGLE_ACTION')
 * @returns actions.
 *
 * @example
 * const actionTypes = ['!SINLE_ACTION', 'MULTI_ACTION'] as const
 * const actions = serviceActionsGenerator('example', actionTypes)
 * actions.singleAction = {
 *   request: (payload?: any, meta?: any) => ({ type: '@example/SINLE_ACTION_REQUEST', payload, meta }),
 *   REQUEST: '@example/SINLE_ACTION_REQUEST',
 * }
 * actions.multiAction = {
 *   request: (payload?: any, meta?: any) => ({ type: '@example/MULTI_ACTION_REQUEST', payload, meta }),
 *   REQUEST: '@example/MULTI_ACTION_REQUEST',
 *   success: (payload?: any, meta?: any) => ({ type: '@example/MULTI_ACTION_SUCCESS', payload, meta }),
 *   SUCCESS: '@example/MULTI_ACTION_SUCCESS',
 *   failure: (payload?: any, meta?: any) => ({ type: '@example/MULTI_ACTION_FAILURE', payload, meta }),
 *   FAILURE: '@example/MULTI_ACTION_FAILURE',
 * }
 */
export function serviceActionsGenerator<
  ServiceName extends string,
  ActionTypes extends ReadonlyArray<ActionType>,
  ActionType extends string
>(serviceName: ServiceName, actionTypes: ActionTypes): ActionCreators<ServiceName, ActionTypes> {
  return actionTypes.reduce((actions, actionType) => {
    const cleanType = actionType.replace('!', '') as RemovePrefix<ActionType>
    const camelCasedType = __camelCase(cleanType) as keyof ActionCreators<ServiceName, ActionTypes>
    const isSingle = actionType.startsWith('!')
    const statusList = isSingle ? SINGLE_STATUS_LIST : MULTI_STATUS_LIST

    let action = {} as ActionCreators<ServiceName, ActionTypes>[keyof ActionCreators<ServiceName, ActionTypes>]
    for (const status of statusList) {
      const lowerStatus = status.toLowerCase() as Lowercase<typeof status>
      const upperStatus = status.toUpperCase() as Uppercase<typeof status>
      const reducerType = `@${serviceName}/${cleanType}_${status}` as ReducerType<
        ServiceName,
        RemovePrefix<ActionType>,
        typeof status
      >
      action = {
        ...action,
        [lowerStatus]: (payload?: ActionPayload, meta?: PromiseMeta) => ({
          type: reducerType,
          payload,
          meta,
        }),
        [upperStatus]: reducerType,
      }
    }

    actions[camelCasedType] = action
    return actions
  }, {} as ActionCreators<ServiceName, ActionTypes>)
}
