import { serviceActionsGenerator } from '../serviceGenerator'

const actionTypes = [
  '!CONFIRM_ONBOARDING',
  '!CONNECT_KSZI',
  '!GET_ONBOARDING_INSTRUCTIONS',
  '!GET_ONBOARDING_INVITATION_STATUS',
  'DECLINE_ONBOARDING_INVITE',
  'FETCH_ONBOARDING_INVITE_APPROVALS',
] as const

export default serviceActionsGenerator('onboarding', actionTypes)
