import React from 'react'

import { useWatch } from 'react-hook-form'
import { connect } from 'react-redux'

import { expenseActions } from '@services'

import { bindActionToPromise, cancellablePromise, parseApiErrorMessage } from '@helpers'

import { useCancellablePromiseRef } from '@hooks/useCancellablePromiseRef'

import { InvoiceType } from '@constants'

import { LedgerNumberField, LedgerNumberFieldProps } from './LedgerNumberField'
import { Actions, initialState, reducer } from './reducer'

type FieldValue = Nullable<number> | undefined

type FieldKey<FieldType> = FieldType extends InvoiceType.EXPENSE
  ? `assignments.${number}.expense_type`
  : `assignments.${number}.revenue_type`

type FormValues<FieldType> = FieldType extends InvoiceType.EXPENSE
  ? { assignments: Array<{ expense_type: FieldValue }> }
  : { assignments: Array<{ revenue_type: FieldValue }> }

interface AssignmentLedgerNumberFieldProps
  extends Pick<LedgerNumberFieldProps, 'disabled' | 'highlighted' | 'isLabelHighlighted' | 'label'> {
  assignmentPrefix: `assignments.${number}.`
  fetchLedgerNumberRecommendations: AsyncFunction<number, number[]>
  invoiceType: InvoiceType
}

function PureAssignmentLedgerNumberField({
  assignmentPrefix,
  fetchLedgerNumberRecommendations,
  invoiceType,
  ...rest
}: AssignmentLedgerNumberFieldProps) {
  const [{ categoryTypeId, ...recommendations }, dispatch] = React.useReducer(reducer, initialState)
  const cPromiseRef = useCancellablePromiseRef<number[]>()

  const categoryTypeFieldValue = useWatch<FormValues<typeof invoiceType>, FieldKey<typeof invoiceType>>({
    name: `${assignmentPrefix}${invoiceType}_type` as FieldKey<typeof invoiceType>,
  })

  const loadRecommendations = React.useCallback(
    async (categoryTypeId: number) => {
      dispatch({ type: Actions.REQUEST })
      try {
        cPromiseRef.current = cancellablePromise(fetchLedgerNumberRecommendations(categoryTypeId))
        const results = await cPromiseRef.current.promise
        // store recommendations and categoryTypeId to prevent multiple fetch for the same id
        dispatch({ type: Actions.SET_RECOMMENDATIONS, payload: { results, categoryTypeId } })
      } catch (error) {
        const errorMessage = parseApiErrorMessage(error)
        if (errorMessage) {
          // silent error
          dispatch({ type: Actions.SET_RECOMMENDATIONS, payload: { results: [], categoryTypeId } })
        }
      }
    },
    [cPromiseRef, fetchLedgerNumberRecommendations]
  )

  React.useEffect(() => {
    if (categoryTypeId && categoryTypeFieldValue && categoryTypeFieldValue !== categoryTypeId) {
      // fetch new recommendations on next focus event when category type field value changes
      dispatch({ type: Actions.CLEAR })
    }
  }, [categoryTypeId, categoryTypeFieldValue])

  const onFocusHandler = React.useCallback(() => {
    if (categoryTypeFieldValue && !categoryTypeId) {
      loadRecommendations(categoryTypeFieldValue)
    }
  }, [categoryTypeFieldValue, categoryTypeId, loadRecommendations])

  return (
    <LedgerNumberField
      {...rest}
      fieldPrefix={`${assignmentPrefix}ledger_number`}
      onFocus={onFocusHandler}
      recommendations={recommendations}
    />
  )
}

// NOTE: currently this field is only available in expense details form
export const AssignmentLedgerNumberField = connect(null, dispatch => ({
  fetchLedgerNumberRecommendations: bindActionToPromise(
    dispatch,
    expenseActions.fetchLedgerNumberRecommendations.request
  ),
}))(PureAssignmentLedgerNumberField)

AssignmentLedgerNumberField.displayName = 'AssignmentLedgerNumberField'
