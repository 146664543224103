import { AxiosResponse } from 'axios'
import * as yup from 'yup'

import { getErrorMessage } from './service'

type PromiseResolveFunction = (payload: Pick<AxiosResponse, 'data'>) => void
type PromiseRejectFunction = (payload: string[] | StringOrMessage) => void

type ResponseHandlerFucntion<Response> = (
  response: AxiosResponse<Response>,
  resolve: PromiseResolveFunction,
  reject: PromiseRejectFunction,
  setProcess: VoidFunction
) => void

type CalculateIntervalFunction = (tries: number, interval: number, delay: number) => number

const isOptionalFunctionSchema = yup
  .mixed()
  .test('is-function', 'must be a function', value => !value || typeof value === 'function')

const validationSchema = yup.object().shape({
  api: isOptionalFunctionSchema.required(),
  calculateInterval: isOptionalFunctionSchema,
  cancelApi: isOptionalFunctionSchema,
  delay: yup.number().positive().integer().optional(),
  emailApi: isOptionalFunctionSchema,
  interval: yup.number().positive().integer().optional(),
  maxTries: yup.number().positive().integer().optional(),
  responseHandler: isOptionalFunctionSchema.required(),
})

export function validateProps<Props>(props: Props) {
  try {
    validationSchema.validateSync(props, { abortEarly: false })
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      const propsErrors = err.inner.map(error => ({ key: error.path, msg: error.message }))
      throw new Error(
        `ProcessListener has invalid properties: ${propsErrors.map(err => `\n - "${err.key}": ${err.msg}`).join(',')}`
      )
    }
    throw err
  }
}

export interface ProcessListenerProps<Payload = BackgroundJobPayload, Results = any, EmailResults = any> {
  api: AsyncFunction<Payload, AxiosResponse<Results>>
  emailApi?: AsyncFunction<Payload, AxiosResponse<EmailResults>>
  responseHandler: ResponseHandlerFucntion<Results | EmailResults>
  interval?: number
  delay?: number
  maxTries?: number
  calculateInterval?: CalculateIntervalFunction
}

export class ProcessListener<Payload = BackgroundJobPayload, Results = any, EmailResults = any> {
  private tries = 0
  private processTimeout: Nullable<NodeJS.Timeout> = null
  private payload!: Payload
  private resolve!: PromiseResolveFunction
  private reject!: PromiseRejectFunction

  private api: AsyncFunction<Payload, AxiosResponse<Results>>
  private emailApi?: AsyncFunction<Payload, AxiosResponse<EmailResults>>
  private responseHandler: ResponseHandlerFucntion<Results | EmailResults>
  private interval: number
  private delay: number
  private maxTries: number
  private calculateInterval: CalculateIntervalFunction

  constructor(props: ProcessListenerProps<Payload, Results, EmailResults>) {
    const {
      api,
      emailApi,
      responseHandler,
      interval = 5,
      delay = 10,
      maxTries = 7,
      calculateInterval = (_tries: number, _interval: number, _delay: number) => {
        if (_tries < 1) {
          return _delay * 1000
        }
        return Math.pow(2, _tries - 1) * _interval * 1000
      },
    } = props

    validateProps(props)

    this.api = api
    this.emailApi = emailApi
    this.responseHandler = responseHandler
    this.interval = interval
    this.delay = delay
    this.maxTries = maxTries
    this.calculateInterval = calculateInterval
  }

  public start = (_payload: Payload) => {
    this.tries = 0
    this.payload = _payload

    return new Promise((_resolve, _reject) => {
      this.resolve = _resolve
      this.reject = _reject
      this.setProcess()
    })
  }

  public stop = () => {
    if (this.processTimeout) {
      clearTimeout(this.processTimeout)
    }
  }

  private run = () => {
    if (this.tries >= this.maxTries) {
      this.stop()

      if (this.emailApi) {
        this.emailApi(this.payload)
          .then(response => {
            this.responseHandler(response, this.resolve, this.reject, this.setProcess)
          })
          .catch(err => {
            const errorMessage = getErrorMessage(err)
            this.reject(errorMessage)
          })
      } else {
        this.reject('__process_error_maxTries__')
      }
      return
    }

    this.tries++

    this.api(this.payload)
      .then(response => {
        this.responseHandler(response, this.resolve, this.reject, this.setProcess)
      })
      .catch(err => {
        this.stop()
        const errorMessage = getErrorMessage(err)
        this.reject(errorMessage)
      })
  }

  private setProcess = () => {
    this.processTimeout = setTimeout(() => {
      this.run()
    }, this.calculateInterval(this.tries, this.interval, this.delay))
  }
}

interface CancelableBackgroundJobPayload extends BackgroundJobPayload {
  invoice_id: ItemIdType
}

export interface CancelableProcessListenerProps<
  Payload extends CancelableBackgroundJobPayload = CancelableBackgroundJobPayload,
  Results = any,
  CancelResults = any
> extends Omit<ProcessListenerProps<Payload, Results>, 'maxTries'> {
  cancelApi: AsyncFunction<Payload, AxiosResponse<CancelResults>>
}

export class CancelableProcessListener<
  Payload extends CancelableBackgroundJobPayload = CancelableBackgroundJobPayload,
  Results = any,
  CancelResults = any
> {
  private tries = 0
  private processTimeout: Nullable<NodeJS.Timeout> = null
  private payload!: Payload
  private resolve!: PromiseResolveFunction
  private reject!: PromiseRejectFunction

  private api: AsyncFunction<Payload, AxiosResponse<Results>>
  private cancelApi: AsyncFunction<Payload, AxiosResponse<CancelResults>>
  private responseHandler: ResponseHandlerFucntion<Results | CancelResults>
  private interval: number
  private delay: number
  private calculateInterval: CalculateIntervalFunction

  constructor(props: CancelableProcessListenerProps<Payload, Results, CancelResults>) {
    const {
      api,
      cancelApi,
      responseHandler,
      interval = 5,
      delay = 10,
      calculateInterval = (_tries: number, _interval: number, _delay: number) => {
        if (_tries < 1) {
          return _delay * 1000
        }
        return Math.pow(2, _tries - 1) * _interval * 1000
      },
    } = props

    validateProps(props)

    this.api = api
    this.cancelApi = cancelApi
    this.responseHandler = responseHandler
    this.interval = interval
    this.delay = delay
    this.calculateInterval = calculateInterval
  }

  public start = (_payload: Payload) => {
    this.tries = 0
    this.payload = _payload

    return new Promise((_resolve, _reject) => {
      this.resolve = _resolve
      this.reject = _reject
      this.setProcess()
    })
  }

  public cancel = () => {
    this.stop()

    this.cancelApi(this.payload)
      .then(() => {
        this.resolve({ data: { results: { id: this.payload.invoice_id } } })
      })
      .catch(err => {
        const errorMessage = getErrorMessage(err)
        this.reject(errorMessage)
      })
  }

  public stop = () => {
    if (this.processTimeout) {
      clearTimeout(this.processTimeout)
    }
  }

  private run = () => {
    this.tries++

    this.api(this.payload)
      .then(response => {
        this.responseHandler(response, this.resolve, this.reject, this.setProcess)
      })
      .catch(err => {
        this.stop()
        const errorMessage = getErrorMessage(err)
        this.reject(errorMessage)
      })
  }

  private setProcess = () => {
    this.processTimeout = setTimeout(() => {
      this.run()
    }, this.calculateInterval(this.tries, this.interval, this.delay))
  }
}
