import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'

import { ConfirmDialog, ConfirmDialogProps } from './ConfirmDialog'

import { ConfirmDialogTitleMessage } from '@messages'

interface DeleteConfirmDialogProps extends Omit<ConfirmDialogProps, 'onSubmit' | 'submitButtonText' | 'title'> {
  onDelete: VoidFunction
}

export function DeleteConfirmDialog({ onDelete, ...props }: DeleteConfirmDialogProps) {
  return (
    <ConfirmDialog
      {...props}
      onSubmit={onDelete}
      submitButtonText={<FormattedMessage id="dialogs.confirm.buttons.deleteConfirm" defaultMessage="Igen, töröljük" />}
      title={ConfirmDialogTitleMessage}
    />
  )
}

DeleteConfirmDialog.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node.isRequired, PropTypes.arrayOf(PropTypes.node.isRequired)]).isRequired,
  id: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}
