import { injectCompanyToURL } from '@helpers'

import Api from '../api'
import { API_BACKGROUND_JOB_EMAIL_URL, API_BACKGROUND_JOB_URL } from './urls'

export function backgroundJob({ company_id, id }: BackgroundJobPayload) {
  return Api.get(injectCompanyToURL(API_BACKGROUND_JOB_URL, company_id).replace('{job_id}', String(id)))
}

export function backgroundJobEmail({ company_id, id }: BackgroundJobPayload) {
  return Api.post(injectCompanyToURL(API_BACKGROUND_JOB_EMAIL_URL, company_id).replace('{job_id}', String(id)))
}
