import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'

import { Badge, BadgeVariant } from '@components/ui/Badges'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 275,
  },
  partner: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: 700,
  },
  badge: {
    marginLeft: 4,
  },
})

interface ExpiredBadgeProps {
  children: React.ReactNode
  expired: boolean
}

export function ExpiredBadge({ children, expired }: ExpiredBadgeProps) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.partner}>{children}</div>
      {expired && (
        <Badge className={classes.badge} variant={BadgeVariant.ERROR}>
          <FormattedMessage id="badges.expired" defaultMessage="Lejárt" />
        </Badge>
      )}
    </div>
  )
}

ExpiredBadge.propTypes = {
  children: PropTypes.node.isRequired,
  expired: PropTypes.bool.isRequired,
}
