import { AxiosResponse } from 'axios'
import { Location, NavigateFunction } from 'react-router-dom'
import { all, call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import {
  expenseListWorkerRunner,
  FilterOptions,
  parseFiltersFromUrlWorkerRunner,
  SyncFiltersPayload,
} from '@webWorkers'
import {
  BackgroundDownloadEmailProcess,
  BackgroundExpenseUploadProcess,
  JOB_STATUS_CREATED,
  JOB_STATUS_FINISHED,
} from '@services/background/process'
import { callUrl } from '@services/common/api'
import { BackgroundJobResponse, CommonAxiosPayload, isBackgroundJobResponse } from '@services/types'

import {
  AMPLITUDE_EVENTS,
  downloadFileWithURL,
  generateBackgroundProcessActionPayload,
  generateDownloadInitAPIPayload,
  getActiveCompanyId,
  getCursorFromUrl,
  getErrorMessage,
  getExpenseListFiltersFromStore,
  getFormErrors,
  getUrlFilterOptionsFromStore,
  isAdvancedApprovalEnabled,
  sendAmplitudeData,
  transformSnakeCaseObjectToCamelCase,
} from '@helpers'

import { PageChangePayload } from '@hooks'

import { InvoiceDownloadRequestParams } from '@components/dialogs/InvoiceDownloadDialog'
import { SyncFiltersConfig } from '@components/filters/types'
import { TaggingSubmitPayload } from '@oldComponents/Tagging/TaggingDialog'

import {
  BackgroundProcessActions,
  FiltersStateKey,
  InvoiceDownloadFlow,
  PageChangeDirection,
  TagsDataTypes,
  TYPING_INTERVAL,
} from '@constants'

import { FeaturePermissons, isPlanPermissionEnabled } from '@permissions'

import { fetchCompanyMembers as fetchCompanyMembersApi } from '../auth/api'
import dashboardActions from '../dashboard/actions'
import { bulkTagging as bulkTaggingApi, fetchTags as fetchTagsApi } from '../dashboard/api'
import filtersActions from '../filters/actions'
import quarantineActions from '../quarantine/actions'
import actions from './actions'
import * as api from './api'
import { AccountingExportBackgroundAction, DownloadBackgroundAction, ExportBackgroundAction } from './backgroundActions'
import {
  BackendExpenseListByPagingResults,
  ExpenseApproveResults,
  ExpenseDeleteDuplicationRequestPayload,
  ExpenseDetailsActionResults,
  ExpenseFilingResults,
  ExpenseUploadBackgroundJobResponse,
  ExpenseUploadPayload,
  ExpenseUploadResponse,
  isUpdatePayload,
  UpdateExpenseDetailsFormValues,
} from './types'

// single approve
function* expenseApproveSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<CommonAxiosPayload<unknown>>) {
  try {
    const response: AxiosResponse<{ meta: ExpenseApproveResults }> = yield call(callUrl, payload)
    yield put(actions.expenseApprove.success(response.data.meta))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// bulk approve
function* bulkApproveSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<BackgroundActionRequestPayload>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const filters: ApiPageParams = yield select(getExpenseListFiltersFromStore)
    const apiPayload = generateBackgroundProcessActionPayload(
      BackgroundProcessActions.TOGGLE_APPROVE_EXPENSES,
      payload,
      filters
    )
    const response: AxiosResponse = yield call(api.expensesBackgroundAction, companyId, apiPayload)
    yield put(actions.bulkApprove.success())
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* createJobNumberSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<string>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<{ name: string }> = yield call(api.createJobNumber, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* searchJobNumbersSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<string>) {
  try {
    yield delay(TYPING_INTERVAL)
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<Array<{ name: string }>> = yield call(api.searchJobNumbers, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// BULK REMOVE
function* bulkDeleteV2Saga({ payload: { ids }, meta: { resolve, reject } }: AsyncSagaAction<BulkRemoveActionPayload>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<BackgroundActionResponse> = yield call(api.expensesBackgroundAction, companyId, {
      action: BackgroundProcessActions.DELETE_EXPENSES,
      ids,
    })
    // every selected items are removed clear selection
    if (response.data.status === JOB_STATUS_FINISHED) {
      // only trigger selection clear when no rejected items
      yield put(actions.bulkDeleteV2.success())
    }
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// export register
function* startBulkExportV2Saga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<BackgroundActionRequestPayload>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const filters: ApiPageParams = yield select(getExpenseListFiltersFromStore)
    const apiPayload = generateBackgroundProcessActionPayload(
      BackgroundProcessActions.EXPORT_EXPENSES,
      payload,
      filters
    )
    const response: BackgroundActionResponse = yield call(ExportBackgroundAction.start, companyId, apiPayload)
    yield call(resolve, response)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* stopBulkExportV2Saga() {
  yield call(ExportBackgroundAction.stop)
}

//* advanced accounting
function* callAccountingCompleteToggleSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<string>) {
  try {
    const response: AxiosResponse<ExpenseDetailsActionResults> = yield call(api.callUrlByPostMethod, payload)
    yield put(actions.callAccountingCompleteToggle.success(response.data))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* callUnlockAccountingSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<string>) {
  try {
    const response: AxiosResponse<ExpenseDetailsActionResults> = yield call(api.callUrlByPostMethod, payload)
    yield put(actions.callUnlockAccounting.success(response.data))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* callExcludeFromAccountingSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<string>) {
  try {
    const response: AxiosResponse<ExpenseDetailsActionResults> = yield call(api.callUrlByPostMethod, payload)
    yield put(actions.callExcludeFromAccountingToggle.success(response.data))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// advanced accounting export
function* startBulkAccountingExportV2Saga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<BackgroundActionRequestPayload>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const filters: ApiPageParams = yield select(getExpenseListFiltersFromStore)
    const apiPayload = generateBackgroundProcessActionPayload(
      BackgroundProcessActions.ACCOUNTING_EXPORT_EXPENSES,
      payload,
      filters
    )
    const response: BackgroundActionResponse = yield call(AccountingExportBackgroundAction.start, companyId, apiPayload)
    yield call(resolve, response)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* stopBulkAccountingExportV2Saga() {
  yield call(AccountingExportBackgroundAction.stop)
}

// DOWNLOAD
function* initDownloadV2Saga({ payload, meta: { resolve, reject } }: AsyncSagaAction<BackgroundActionRequestPayload>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const filters: ApiPageParams = yield select(getExpenseListFiltersFromStore)
    const apiPayload = generateDownloadInitAPIPayload(payload, filters)
    const response: AxiosResponse = yield call(api.initDownloadV2, companyId, apiPayload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* startBulkDownloadV2Saga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<BackgroundActionRequestPayload>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const filters: ApiPageParams = yield select(getExpenseListFiltersFromStore)
    const apiPayload = generateBackgroundProcessActionPayload(
      BackgroundProcessActions.DOWNLOAD_EXPENSES,
      payload,
      filters
    )
    const response: BackgroundActionResponse = yield call(DownloadBackgroundAction.start, companyId, apiPayload)
    yield call(resolve, response)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* stopBulkDownloadV2Saga() {
  yield call(DownloadBackgroundAction.stop)
}

// filing
function* bulkFilingV2Saga({ payload, meta: { resolve, reject } }: AsyncSagaAction<BackgroundActionRequestPayload>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const filters: ApiPageParams = yield select(getExpenseListFiltersFromStore)
    const apiPayload = generateBackgroundProcessActionPayload(BackgroundProcessActions.FILE_EXPENSES, payload, filters)
    const response: AxiosResponse<BackgroundActionResponse<ExpenseFilingResults>> = yield call(
      api.expensesBackgroundAction,
      companyId,
      apiPayload
    )
    // call failure when need to update list, call success when need to update items in store only
    if (payload.isAllSelected) {
      yield put(actions.bulkFilingV2.failure())
    } else {
      yield put(actions.bulkFilingV2.success(response.data.results))
    }
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* filingSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<BackgroundActionRequestPayload>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const apiPayload = generateBackgroundProcessActionPayload(BackgroundProcessActions.FILE_EXPENSES, payload)
    const response: AxiosResponse<BackgroundActionResponse<ExpenseFilingResults>> = yield call(
      api.expensesBackgroundAction,
      companyId,
      apiPayload
    )
    yield put(actions.expenseFiling.success(response.data.results))
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* fetchLastFilingNumberSaga({ meta: { resolve, reject } }: AsyncSagaAction<void>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<{ last: string }> = yield call(api.fetchLastFilingNumber, companyId)
    yield call(resolve, response.data.last)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// CATEGORIZATION
function* bulkCategorizationV2Saga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<BackgroundActionRequestPayload>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    yield call(api.bulkCategorization, companyId, payload)
    yield put(actions.bulkCategorizationV2.success())
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// BULK TAGGING
function* bulkTaggingV2Saga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<{ data_type: TagsDataTypes; ids: ItemIdType[] } & TaggingSubmitPayload>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    yield call(bulkTaggingApi, companyId, payload)
    yield put(actions.bulkTaggingV2.success())
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// BULK ACCOUNTING
function* bulkAccountingV2Saga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<BackgroundActionRequestPayload>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const filters: ApiPageParams = yield select(getExpenseListFiltersFromStore)
    const apiPayload = generateBackgroundProcessActionPayload(
      BackgroundProcessActions.TOGGLE_BOOK_EXPENSES,
      payload,
      filters
    )
    const response: AxiosResponse = yield call(api.expensesBackgroundAction, companyId, apiPayload)
    if (response.data.status === JOB_STATUS_FINISHED) {
      yield put(actions.bulkAccountingV2.success())
    }
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// BULK SYNC INVOICE ARTIFACT
function* bulkSyncV2Saga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<
  {
    action: BackgroundProcessActions
  } & BackgroundActionRequestPayload
>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const filters: ApiPageParams = yield select(getExpenseListFiltersFromStore)
    const apiPayload = generateBackgroundProcessActionPayload(payload.action, payload, filters)
    yield call(api.expensesBackgroundAction, companyId, apiPayload)
    yield put(actions.bulkSyncV2.success())
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// DUPLICATION
function* initDuplicationSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<{ expenseId: number; partnerId?: number }>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse = yield call(api.initDuplication, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* deleteExpenseDuplicationSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<ExpenseDeleteDuplicationRequestPayload>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    yield call(api.deleteExpenseDuplication, companyId, payload)
    yield put(actions.deleteExpenseDuplication.success(payload))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// used in duplication modal
function* deleteExpenseFromListSaga({
  payload: { needToUpdateList, ...apiPayload },
  meta: { resolve, reject },
}: AsyncSagaAction<{ expenseId: number; source: ExpenseDeleteSource; needToUpdateList: 'expense' | 'quarantine' }>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    yield call(api.removeExpense, companyId, apiPayload)
    // only need this when merge called from list view
    if (needToUpdateList === 'expense') {
      yield put(quarantineActions.triggerExpenseUpdateV2.request())
    }
    if (needToUpdateList === 'quarantine') {
      yield put(quarantineActions.triggerQuarantineUpdateV2.request())
    }
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// DUPLICATED EXPENSE
function* checkExpenseDuplicationSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<{ expenseId: number; partnerId: number; invoiceNumber: string }>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse = yield call(api.checkExpenseDuplication, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// EVENTS LOG
function* fetchEventsLogSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<{ documentId: number }>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse = yield call(api.fetchEventsLog, companyId, payload.documentId)
    yield call(resolve, response.data.map(transformSnakeCaseObjectToCamelCase))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// DETAILS
function* fetchExpenseDetailsSaga({ payload }: SagaAction<number>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<ExpenseDetailsBackendValues> = yield call(api.fetchExpenseDetails, companyId, payload)
    // --amplitude tracking start
    yield call(sendAmplitudeData, AMPLITUDE_EVENTS.VIEW_EXPENSE)
    // --amplitude tracking end
    yield put(actions.fetchExpenseDetails.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchExpenseDetails.failure(errorMsg))
  }
}

function* updateExpenseSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<UpdateExpenseDetailsFormValues>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<ExpenseDetailsBackendValues> = yield call(api.updateExpense, companyId, payload)
    // if partner is not exists in payload skip bulkInvoiceUpdateCheck
    // and return null as bulkResponseData
    let bulkResponseData: Nullable<{ count: number }> = null
    if (payload.partner_id) {
      const isEnabled: boolean = yield select(isPlanPermissionEnabled, FeaturePermissons.DETAILS_BULK_UPDATE)

      if (isEnabled) {
        const bulkResponse: AxiosResponse<{ count: number }> = yield call(
          api.bulkInvoiceUpdateCheck,
          companyId,
          payload
        )
        bulkResponseData = bulkResponse.data
      }
    }

    yield put(actions.updateExpense.success(response.data))
    yield call(resolve, {
      response: response.data,
      bulkResponse: bulkResponseData,
    })
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield put(actions.updateExpense.failure())
    yield call(reject, formErrors)
  }
}

// create invoice by form
function* createExpenseSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<ExpenseDetailsFormInitialValues>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse = yield call(api.createExpense, companyId, payload)
    yield call(resolve, { response: response.data })
    // --amplitude tracking start
    yield call(sendAmplitudeData, AMPLITUDE_EVENTS.NEW_EXPENSE_CREATED, {
      from_scan: false,
    })
    // --amplitude tracking end
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield call(reject, formErrors)
  }
}

function* removeExpenseSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<{ expenseId: number; source: ExpenseDeleteSource }>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    yield call(api.removeExpense, companyId, payload)
    yield put(actions.removeExpense.success(payload))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// recommendation
function* updateExpenseWithRecommendationSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<Record<string, unknown> & { company: number }>) {
  try {
    const response: AxiosResponse = yield call(api.updateExpenseWithRecommendation, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// CUSTOM VALIDATOR
function* invoiceNumberCheckSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<Record<string, unknown>>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse = yield call(api.invoiceNumberCheck, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// user viewed new invoice
function* userViewExpenseSaga({ payload }: SagaAction<number>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    yield call(api.userViewExpense, companyId, payload)
  } catch (error) {
    // do nothing
  }
}

// PROCESSES
// create or update with classify is always a background job task
function* handleBackgroundJob(response: AxiosResponse<ExpenseUploadResponse>, companyId: number) {
  const { status, metadata, results } = response.data as unknown as ExpenseUploadBackgroundJobResponse

  if (status === 10) {
    const bgJobResponse: AxiosResponse<BackgroundJobResponse<{ id: number }>> = yield call(
      BackgroundExpenseUploadProcess.start,
      {
        id: response.data.id,
        company_id: companyId,
        invoice_id: metadata.invoice_id,
      }
    )

    return bgJobResponse.data.results
  }

  return results
}

function* uploadExpenseSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<ExpenseUploadPayload>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    // can return a background job response or a regular response (simple update w/o classify)
    const response: AxiosResponse<ExpenseUploadResponse> = yield call(api.uploadExpense, companyId, payload)
    let results: ExpenseUploadResponse = response.data

    if (isUpdatePayload(payload)) {
      //* update expense with re-upload artifact
      // check if need to classify
      if (payload.need_classify) {
        results = yield call(handleBackgroundJob, response, companyId)
      }

      // finish process - get expense_id from backgroundJob response
      // backgroundJob success | response.data.results.id where "id" is an "expense_id"
      const detailsResponse: AxiosResponse<ExpenseDetailsBackendValues> = yield call(
        api.fetchExpenseDetails,
        companyId,
        payload.invoice
      )
      yield put(actions.uploadExpense.success(detailsResponse.data))
    } else {
      //* create expense with file upload
      results = yield call(handleBackgroundJob, response, companyId)

      // amplitude tracking
      yield call(sendAmplitudeData, AMPLITUDE_EVENTS.NEW_EXPENSE_CREATED, {
        from_scan: true,
      })
    }

    yield call(resolve, results)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.uploadExpense.failure())
    yield call(reject, errorMsg)
  }
}

function* cancelUploadExpenseSaga() {
  yield call(BackgroundExpenseUploadProcess.cancel)
}

function* abortUploadExpenseSaga() {
  yield call(BackgroundExpenseUploadProcess.stop)
}

// download invoice artifact
function* downloadExpenseArtifactSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<InvoiceDownloadRequestParams & { id: number }>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<{ path: string } | BackgroundJobResponse> = yield call(
      api.downloadExpenseArtifact,
      companyId,
      payload
    )

    if (isBackgroundJobResponse(response) && payload.download_flow !== InvoiceDownloadFlow.WITHOUT_ATTACHMENTS) {
      // bgJob
      let bgJobResponse: AxiosResponse<BackgroundJobResponse> = response
      if (bgJobResponse.data.status === JOB_STATUS_CREATED) {
        bgJobResponse = yield call(BackgroundDownloadEmailProcess.start, {
          id: response.data.id,
          company_id: companyId,
        })
      }
      // download file when it not resolved by send-in-email cancel
      if (!bgJobResponse.data.send_email) {
        yield call(downloadFileWithURL, bgJobResponse)
      }
    } else {
      // simple download
      yield call(downloadFileWithURL, response)
    }

    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* abortDownloadExpenseArtifactSaga() {
  yield call(BackgroundDownloadEmailProcess.stop)
}

// remove invoice artifact
function* removeExpenseArtifactSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<{ id: ItemIdType }>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    yield call(api.removeExpenseArtifact, companyId, payload)
    yield put(actions.removeExpenseArtifact.success(payload))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// bulk upload
function* uploadMultipleExpenseSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<Record<string, unknown> & { company: number }>) {
  try {
    yield call(api.uploadMultipleExpense, payload)
    // --amplitude tracking start
    yield call(sendAmplitudeData, AMPLITUDE_EVENTS.NEW_MULTI_EXPENSE_CREATED)
    // --amplitude tracking end
    yield call(resolve)
  } catch (error) {
    // do not parse error here, it's handled in the useFileDropzone hook
    yield call(reject, error)
  }
}

// EFFECTS
//* When we do not need to fetch expense types and tags separately from the backend, only the list data (ie. when reordering)
//! Note: we need to think about how to handle multiple users' background changes, if a new tag/expense type is created while the current user does not have it
function* fetchExpenseListV2SimpleSaga() {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const filters: Partial<ExpenseListStoreFilters> = yield select(getExpenseListFiltersFromStore)
    const { expenseTypes, tags }: { expenseTypes: ExpenseTypeData[]; tags: Tag[] } = yield select(
      ({ dashboard: { tags }, expense: { expenseTypes } }) => ({
        expenseTypes: expenseTypes.data,
        tags: tags.data,
      })
    )

    // start fetching expenses
    const listResponse: AxiosResponse<BackendExpenseListByPagingResults> = yield call(
      api.fetchExpenseListV2,
      companyId,
      filters
    )
    //* call worker
    const workerResults: ExpenseListData[] = yield call(expenseListWorkerRunner, {
      expenses: listResponse.data.results,
      expenseTypes,
      tags,
    })

    yield put(
      actions.fetchExpenseListV2.success({
        data: workerResults,
        next: listResponse.data.next,
        previous: listResponse.data.previous,
      })
    )

    // set expense count if there is only one page otherwise we set to 0 and we fetch the count in the PageCounter component
    const count: number = !listResponse.data.next && !listResponse.data.previous ? listResponse.data.results.length : 0
    yield put(actions.fetchExpenseCountV2.success({ count }))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchExpenseListV2.failure(errorMsg))
  }
}

function* fetchExpenseChartsSaga() {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const filters: Partial<ExpenseListStoreFilters> = yield select(getExpenseListFiltersFromStore)

    // start fetching expenses
    const listResponse: AxiosResponse<BackendExpenseChartResult> = yield call(
      api.fetchExpenseCharts,
      companyId,
      filters
    )
    yield put(actions.fetchExpenseCharts.success(listResponse.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchExpenseCharts.failure(errorMsg))
  }
}

// LIST
function* initExpenseListPageLoadSaga({
  payload: { config, filtersStateKey, location, navigate },
  meta: { resolve, reject },
}: AsyncSagaAction<{
  config: SyncFiltersConfig
  filtersStateKey: FiltersStateKey
  location: Location<unknown>
  navigate: NavigateFunction
}>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const isAdvancedApproval: boolean = yield select(isAdvancedApprovalEnabled)
    // gather expense types and tags as we're going to need them for mapping purposes for the list
    const [expenseTypesResponse, tagsResponse, approversResponse]: [
      AxiosResponse<ExpenseTypeData[]>,
      AxiosResponse<Tag[]>,
      AxiosResponse? // TODO type
    ] = yield all([
      call(api.fetchExpenseTypes, companyId),
      call(fetchTagsApi, companyId),
      isAdvancedApproval && call(fetchCompanyMembersApi, companyId), // only need to fetch when feature enabled
    ])

    yield put(actions.fetchExpenseTypes.success(expenseTypesResponse.data))
    yield put(dashboardActions.fetchTags.success(tagsResponse.data))

    if (isAdvancedApproval && approversResponse) {
      yield put(dashboardActions.fetchApproverUsers.success(approversResponse.data.users))
    }

    const filterOptions: FilterOptions = yield select(getUrlFilterOptionsFromStore, filtersStateKey)

    //* >> sync from url to store: call worker
    const { filters, params, validationLevel }: SyncFiltersPayload = yield call(parseFiltersFromUrlWorkerRunner, {
      config,
      filterOptions,
      location,
    })
    yield all([
      put(filtersActions.initExpenseListFiltersFromUrl.request({ filters })),
      put(filtersActions.initExpenseListParamsFromUrl.request(params)),
    ])

    yield call(resolve, validationLevel)
    yield all([put(actions.fetchExpenseListV2.request({ navigate })), put(actions.fetchExpenseCharts.request())])
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
    yield put(actions.fetchExpenseListV2.failure(errorMsg))
  }
}

function* fetchExpenseListV2Saga({ payload: { navigate } }: SagaAction<{ navigate: NavigateFunction }>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    // TODO check filters type
    const filters: Partial<ExpenseListStoreFilters> & { cursor?: string } = yield select(
      getExpenseListFiltersFromStore,
      { withCursor: true }
    )
    const { expenseTypes, tags }: { expenseTypes: ExpenseTypeData[]; tags: Tag[] } = yield select(
      ({ dashboard: { tags }, expense: { expenseTypes } }: Store) => ({
        expenseTypes: expenseTypes.data,
        tags: tags.data,
      })
    )
    // start fetching expenses
    let listResponse: AxiosResponse<BackendExpenseListByPagingResults> = yield call(
      api.fetchExpenseListV2,
      companyId,
      filters
    )

    // drop cursor and refetch list when actual cursor return zero results
    const isCursorDropped = filters.cursor && listResponse.data.results.length === 0
    let previousCursor: Nullable<string> = null
    if (isCursorDropped) {
      if (listResponse.data.previous) {
        // when previous list is exists
        previousCursor = getCursorFromUrl(listResponse.data.previous)
        listResponse = yield call(api.fetchExpenseListByPagingV2, listResponse.data.previous)
      } else {
        // clear cursor when no previous list
        const { cursor, ...newFilters } = filters
        listResponse = yield call(api.fetchExpenseListV2, companyId, newFilters)
      }
    }

    //* call worker
    const workerResults: ExpenseListData[] = yield call(expenseListWorkerRunner, {
      expenses: listResponse.data.results,
      expenseTypes,
      tags,
    })

    yield put(
      actions.fetchExpenseListV2.success({
        data: workerResults,
        isCursorDropped,
        next: listResponse.data.next,
        previous: listResponse.data.previous,
        previousCursor,
      })
    )
    //* << sync from store to url
    const storedFilters: Partial<ExpenseListStoreFilters> = yield select(getExpenseListFiltersFromStore)
    yield put(filtersActions.syncFiltersToUrl.request({ navigate, filters: storedFilters }))

    // set expense count if there is only one page otherwise we set to 0 and we fetch the count in the PageCounter component
    const count: number = !listResponse.data.next && !listResponse.data.previous ? listResponse.data.results.length : 0
    yield put(actions.fetchExpenseCountV2.success({ count }))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchExpenseListV2.failure(errorMsg))
  }
}

function* fetchExpenseListByPagingV2Saga({ payload: { url, direction } }: SagaAction<PageChangePayload>) {
  try {
    const { expenseTypes, tags }: { expenseTypes: ExpenseTypeData[]; tags: Tag[] } = yield select(
      ({ dashboard: { tags }, expense: { expenseTypes } }: Store) => ({
        expenseTypes: expenseTypes.data,
        tags: tags.data,
      })
    )
    const listResponse: AxiosResponse<BackendExpenseListByPagingResults> = yield call(
      api.fetchExpenseListByPagingV2,
      url
    )

    //* call worker
    const workerResults: ExpenseListData[] = yield call(expenseListWorkerRunner, {
      expenses: listResponse.data.results,
      expenseTypes,
      tags,
    })

    yield put(
      actions.fetchExpenseListByPagingV2.success({
        data: workerResults,
        direction,
        next: listResponse.data.next,
        previous: listResponse.data.previous,
      })
    )
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchExpenseListByPagingV2.failure(errorMsg))
  }
}

// handle paging on details with v2 data
function* fetchExpenseDetailsByPagingV2Saga({
  payload: { url, isNext },
  meta: { resolve, reject },
}: AsyncSagaAction<{ url: string; isNext: boolean }>) {
  try {
    const listResponse: AxiosResponse<BackendExpenseListByPagingResults> = yield call(
      api.fetchExpenseListByPagingV2,
      url
    )

    // skip worker because do not need to map these values here, do not show this list for user
    const results = listResponse.data.results.map(({ expenseTypeIds, tagIds, simpleTagIds, ...expense }) => ({
      ...expense,
      expenseTypes: [],
      simpleTags: [],
      tags: [],
    }))

    yield put(
      actions.fetchExpenseListByPagingV2.success({
        direction: isNext ? PageChangeDirection.NEXT : PageChangeDirection.PREV,
        data: results,
        next: listResponse.data.next,
        previous: listResponse.data.previous,
      })
    )
    // return the id of the next expense to fetch
    const nextId = isNext ? results[0].id : results[results.length - 1].id
    yield call(resolve, nextId)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* fetchExpenseCountV2Saga() {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const filters: Partial<ExpenseListStoreFilters> = yield select(getExpenseListFiltersFromStore)
    // start fetching expenses
    const countResponse: AxiosResponse = yield call(api.fetchExpenseCountV2, companyId, filters)
    yield put(actions.fetchExpenseCountV2.success({ count: countResponse.data.count }))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchExpenseListV2.failure(errorMsg))
  }
}

// EXPENSE TYPES
function* fetchExpenseTypesSaga({ payload }: SagaAction<number>) {
  try {
    const response: AxiosResponse<ExpenseTypeData[]> = yield call(api.fetchExpenseTypes, payload) // payload == companyId
    yield put(actions.fetchExpenseTypes.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchExpenseTypes.failure(errorMsg))
  }
}

function* createExpenseTypeSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<Record<string, unknown> & { company: number }>) {
  try {
    const response: AxiosResponse<ExpenseTypeData> = yield call(api.createExpenseType, payload) // company exists in payload
    yield put(actions.createExpenseType.success(response.data))
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* fetchLedgerNumberRecommendationsSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<number>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<{ ledgerNumbers: number[] }> = yield call(
      api.fetchLedgerNumberRecommendations,
      companyId,
      payload
    )
    yield call(resolve, response.data.ledgerNumbers)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// watcher Saga
export default function* commonSaga() {
  yield takeLatest(actions.createJobNumber.REQUEST, createJobNumberSaga)
  yield takeLatest(actions.searchJobNumbers.REQUEST, searchJobNumbersSaga)
  // approve
  yield takeLatest(actions.expenseApprove.REQUEST, expenseApproveSaga)
  yield takeLatest(actions.bulkApprove.REQUEST, bulkApproveSaga)
  // delete
  yield takeLatest(actions.bulkDeleteV2.REQUEST, bulkDeleteV2Saga)
  // export
  yield takeLatest(actions.startBulkExportV2.REQUEST, startBulkExportV2Saga)
  yield takeLatest(actions.stopBulkExportV2.REQUEST, stopBulkExportV2Saga)
  // advanced accounting
  yield takeLatest(actions.callAccountingCompleteToggle.REQUEST, callAccountingCompleteToggleSaga)
  yield takeLatest(actions.callUnlockAccounting.REQUEST, callUnlockAccountingSaga)
  yield takeLatest(actions.callExcludeFromAccountingToggle.REQUEST, callExcludeFromAccountingSaga)
  // advanced accounting export
  yield takeLatest(actions.startBulkAccountingExportV2.REQUEST, startBulkAccountingExportV2Saga)
  yield takeLatest(actions.stopBulkAccountingExportV2.REQUEST, stopBulkAccountingExportV2Saga)
  // download
  yield takeLatest(actions.initDownloadV2.REQUEST, initDownloadV2Saga)
  yield takeLatest(actions.startBulkDownloadV2.REQUEST, startBulkDownloadV2Saga)
  yield takeLatest(actions.stopBulkDownloadV2.REQUEST, stopBulkDownloadV2Saga)
  // filing
  yield takeLatest(actions.bulkFilingV2.REQUEST, bulkFilingV2Saga)
  yield takeLatest(actions.expenseFiling.REQUEST, filingSaga)
  yield takeLatest(actions.fetchLastFilingNumber.REQUEST, fetchLastFilingNumberSaga)
  // tagging
  yield takeLatest(actions.bulkTaggingV2.REQUEST, bulkTaggingV2Saga)
  // categorization
  yield takeLatest(actions.bulkCategorizationV2.REQUEST, bulkCategorizationV2Saga)
  // accounting
  yield takeLatest(actions.bulkAccountingV2.REQUEST, bulkAccountingV2Saga)
  // snyc
  yield takeLatest(actions.bulkSyncV2.REQUEST, bulkSyncV2Saga)
  // duplication
  yield takeLatest(actions.initDuplication.REQUEST, initDuplicationSaga)
  yield takeLatest(actions.deleteExpenseDuplication.REQUEST, deleteExpenseDuplicationSaga)
  yield takeLatest(actions.deleteExpenseFromList.REQUEST, deleteExpenseFromListSaga)
  yield takeLatest(actions.checkExpenseDuplication.REQUEST, checkExpenseDuplicationSaga)
  // events log
  yield takeLatest(actions.fetchEventsLog.REQUEST, fetchEventsLogSaga)
  // details
  yield takeLatest(actions.fetchExpenseDetails.REQUEST, fetchExpenseDetailsSaga)
  yield takeLatest(actions.updateExpense.REQUEST, updateExpenseSaga)
  yield takeLatest(actions.createExpense.REQUEST, createExpenseSaga)
  yield takeLatest(actions.removeExpense.REQUEST, removeExpenseSaga)
  // recommendation
  yield takeLatest(actions.updateExpenseWithRecommendation.REQUEST, updateExpenseWithRecommendationSaga)
  // validation engine - custom validator
  yield takeLatest(actions.invoiceNumberCheck.REQUEST, invoiceNumberCheckSaga)
  // user view
  yield takeLatest(actions.userViewExpense.REQUEST, userViewExpenseSaga)
  // processes
  yield takeLatest(actions.uploadExpense.REQUEST, uploadExpenseSaga)
  yield takeLatest(actions.cancelUploadExpenseProcess.REQUEST, cancelUploadExpenseSaga)
  yield takeLatest(actions.abortUploadExpense.REQUEST, abortUploadExpenseSaga)
  yield takeLatest(actions.downloadExpenseArtifact.REQUEST, downloadExpenseArtifactSaga)
  yield takeLatest(actions.abortDownloadExpenseArtifact.REQUEST, abortDownloadExpenseArtifactSaga)
  yield takeLatest(actions.removeExpenseArtifact.REQUEST, removeExpenseArtifactSaga)
  // multi upload
  yield takeLatest(actions.uploadMultipleExpense.REQUEST, uploadMultipleExpenseSaga)
  // effects
  yield takeLatest(
    [
      actions.updateOrderV2.REQUEST,
      actions.updateRowsPerPageV2.REQUEST,
      filtersActions.toggleExpenseListDateFilter.REQUEST,
      filtersActions.updateExpenseListFilters.REQUEST,
      filtersActions.resetExpenseListFilters.REQUEST,
      quarantineActions.triggerExpenseUpdateV2.REQUEST,
      actions.bulkTaggingV2.SUCCESS,
      actions.bulkCategorizationV2.SUCCESS,
      actions.bulkApprove.SUCCESS,
      actions.bulkAccountingV2.SUCCESS,
      actions.triggerExpenseListUpdate.REQUEST,
      actions.bulkFilingV2.FAILURE, //! used as secondary success action in this case
      actions.bulkSyncV2.SUCCESS,
      actions.bulkDeleteV2.SUCCESS,
      actions.resetPagination.REQUEST,
    ],
    fetchExpenseListV2SimpleSaga
  )
  yield takeLatest(
    [
      filtersActions.updateExpenseListFilters.REQUEST,
      filtersActions.resetExpenseListFilters.REQUEST,
      filtersActions.toggleExpenseListDateFilter.REQUEST,
      actions.fetchExpenseCharts.REQUEST,
      quarantineActions.triggerExpenseUpdateV2.REQUEST,
      actions.bulkCategorizationV2.SUCCESS,
      actions.triggerExpenseListUpdate.REQUEST,
      actions.bulkDeleteV2.SUCCESS,
    ],
    fetchExpenseChartsSaga
  )
  // list
  yield takeLatest(actions.initExpenseListPageLoad.REQUEST, initExpenseListPageLoadSaga)
  yield takeLatest(actions.fetchExpenseCountV2.REQUEST, fetchExpenseCountV2Saga)
  yield takeLatest(actions.fetchExpenseListV2.REQUEST, fetchExpenseListV2Saga)
  yield takeLatest(actions.fetchExpenseListByPagingV2.REQUEST, fetchExpenseListByPagingV2Saga)
  yield takeLatest(actions.fetchExpenseDetailsByPagingV2.REQUEST, fetchExpenseDetailsByPagingV2Saga)
  // expense types
  yield takeLatest(actions.fetchExpenseTypes.REQUEST, fetchExpenseTypesSaga)
  yield takeLatest(actions.createExpenseType.REQUEST, createExpenseTypeSaga)
  // ledger number recommendations
  yield takeEvery(actions.fetchLedgerNumberRecommendations.REQUEST, fetchLedgerNumberRecommendationsSaga)
}
