import { serviceActionsGenerator } from '../serviceGenerator'

const actionTypes = [
  '!FETCH_EVENTS_LOG_DATA',
  '!FETCH_JOB_NUMBER_HISTORY',
  '!FETCH_SALARY_GROUP_DETAILS_TO_STATE', // salary editor
  '!FETCH_TAX_GROUP_DETAILS_TO_STATE', // vat editor
  '!GET_COMPANY_CONSENTS',
  '!INIT_TAGGING',
  '!REFRESH_TRANSACTIONS_PAGE',
  '!RESET_MONTHLY_SALARY_EDITOR_NOTIFICATION',
  '!RESET_MONTHLY_TAX_EDITOR_NOTIFICATION',
  '!SELECT_DEFAULT_ACTIVE_TAB',
  '!SET_COMPANY_CONSENT',
  'CREATE_LEDGER_NUMBER',
  'CREATE_MONTHLY_SALARY_V2',
  'CREATE_MONTHLY_TAX_V2',
  'CREATE_TAG',
  'DASHBOARD_STATUS_CHECK',
  'FETCH_APPROVER_USERS',
  'FETCH_ASSIGNMENT_NOTE_RECOMMENDATIONS',
  'FETCH_LEDGER_NUMBERS',
  'FETCH_PAID_THROUGHS',
  'FETCH_TAGS',
  'FETCH_TAX_CODES',
  'FETCH_TAX_GROUPS',
  'INITIALIZE',
  'REMOVE_MONTHLY_SALARY_V2',
  'REMOVE_MONTHLY_TAX_V2',
  'UPDATE_MONTHLY_SALARY_V2',
  'UPDATE_MONTHLY_TAX_V2',
  'UPDATE_PAID_THROUGH_FILTER',
  'UPLOAD_SALARY_AND_TAX_V2', // XML upload
  'USER_VIEW_SALARY_V2',
  'USER_VIEW_TAX_V2',
] as const

export default serviceActionsGenerator('dashboard', actionTypes)
