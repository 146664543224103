import React from 'react'

import { connect } from 'react-redux'

import { EXPENSE_LIST_INITIAL_FILTERS, filtersActions } from '@services'

import { companyHasIntegrationType, isAccountant, isAdvancedAccountingAvailableForUser } from '@helpers'

import { usePageConfig } from '@contexts/PageControllerProvider'
import { usePortal } from '@contexts/PortalProvider'

import { ALL_EXPENSE_SEARCH_FILTERS, CompanyIntegrationProviders, DEFAULT_EXPENSE_SEARCH_FILTERS } from '@constants'

import { FeaturePermissons, isPlanPermissionEnabled } from '@permissions'

import { getExpenseStatusFilterConfig } from './expenseStatusFiltersHelpers'
import { FilterBar, FilterBarBaseProps } from './FilterBar'
import {
  checkFiltersChanged,
  getSelectableFilterProps,
  getStatusFilterDialogProps,
  getStatusFilterProps,
} from './helpers'

import {
  AllFilterOptionsMessage,
  DefaultFilterOptionsMessage,
  ExpenseListPageMessage,
  TagFilterEmptyPlaceholderMessage,
  TagFilterLabelMessage,
} from '@messages'

interface PureExpenseListPageFilterBarProps extends FilterBarBaseProps {
  approverOptions: CommonIdAndNameType[]
  expenseTypeOptions: CommonIdAndNameType[]
  filters: ExpenseListStoreFilters
  hasIntegrationAcounto: boolean
  hasIntegrationSzamlazz: boolean
  hasTagPermission: boolean
  isAccountantUser: boolean
  isAdvancedAccounting: boolean
  tagOptions: Tag[]
  paymentMethodOptions: PaymentMethod[]
  syncSearchInputValue: (value: string) => void
  vatAreaOptions: VatArea[]
}

function PureExpenseListPageFilterBar({
  approverOptions,
  expenseTypeOptions,
  filters,
  filters: {
    customFields,
    dateField,
    expiredDays,
    expiringDays,
    isExpired,
    isExpiring,
    isPaid,
    origin,
    paymentMethod,
    search,
    searchFields,
    vatArea,
    withApprovers,
    withExpenseTypeIds,
    withoutApprovers,
    withoutExpenseTypeIds,
    withoutTagIds,
    withTagIds,
  },
  hasIntegrationAcounto,
  hasIntegrationSzamlazz,
  hasTagPermission,
  isAccountantUser,
  isAdvancedAccounting,
  isDateFilterDisabled,
  paymentMethodOptions,
  resetFilters,
  syncSearchInputValue,
  tagOptions,
  updateFilters,
  vatAreaOptions,
}: PureExpenseListPageFilterBarProps) {
  const { setPortalAnchorEl } = usePortal()
  const pageConfig = usePageConfig()

  const isDateFilterUsed = true
  const paidStatusFilterProps = {
    values: {
      expiredDays,
      expiringDays,
      isExpired,
      isExpiring,
      isPaid,
    },
  }
  const searchFilterProps = {
    search,
    searchFields,
    options: pageConfig.searchFilterProps?.config.options ?? [],
    extraOptions: pageConfig.searchFilterProps?.config.extraOptions ?? [],
    presets: [
      {
        key: 'defaults',
        optionKeys: DEFAULT_EXPENSE_SEARCH_FILTERS,
        label: DefaultFilterOptionsMessage,
      },
      {
        key: 'all',
        optionKeys: ALL_EXPENSE_SEARCH_FILTERS,
        label: AllFilterOptionsMessage,
      },
    ],
  }

  const selectableFilterProps = getSelectableFilterProps({
    values: {
      withTagIds,
    },
    config: [
      {
        keyValue: 'withTagIds' as const,
        options: tagOptions,
        labelText: TagFilterLabelMessage,
        emptyPlaceholder: TagFilterEmptyPlaceholderMessage,
        hasPermission: hasTagPermission,
      },
    ],
  })

  const statusFilterProps = getStatusFilterProps({
    config: getExpenseStatusFilterConfig({
      hasIntegrationAcounto,
      hasIntegrationSzamlazz,
      isAccountantUser,
      isAdvancedAccounting,
    }),
    values: filters,
    width: 421,
  })

  const filterDialogProps: FilterDialogProps<StatusFilterConfig> = {
    isChanged: checkFiltersChanged(filters, EXPENSE_LIST_INITIAL_FILTERS),
    page: ExpenseListPageMessage,
    paidStatusFilterProps,
    searchFilterProps,
  }

  if (pageConfig.dateFilterProps) {
    filterDialogProps['dateFilterProps'] = {
      dateTypeOptions: pageConfig.dateFilterProps.config.options,
      dateTypeValue: dateField,
    }
  }

  if (pageConfig.statusFilterProps) {
    filterDialogProps['statusFilterProps'] = getStatusFilterDialogProps({
      config: pageConfig.statusFilterProps.config,
      values: filters,
    })
  }
  if (pageConfig.categoryFilterProps) {
    filterDialogProps['categoryFilterProps'] = {
      config: {
        ...pageConfig.categoryFilterProps.config,
        options: expenseTypeOptions,
      },
      values: {
        include: withExpenseTypeIds,
        exclude: withoutExpenseTypeIds,
      },
    }
  }
  if (pageConfig.tagFilterProps) {
    filterDialogProps['tagFilterProps'] = {
      config: {
        ...pageConfig.tagFilterProps.config,
        options: tagOptions,
      },
      values: {
        include: withTagIds,
        exclude: withoutTagIds,
      },
    }
  }
  if (pageConfig.approversFilterProps) {
    filterDialogProps['approversFilterProps'] = {
      config: {
        ...pageConfig.approversFilterProps.config,
        options: approverOptions,
      },
      values: {
        include: withApprovers,
        exclude: withoutApprovers,
      },
    }
  }
  if (pageConfig.currencyFilterProps) {
    filterDialogProps['currencyFilterProps'] = {
      // config: pageConfig.currencyFilterProps.config,
      currencyId: filters.currencyId, // TODO need to refact: { config: { name: 'currency', options: currencyOptions }, values: filters.currency }
    }
  }
  if (pageConfig.amountFilterProps) {
    filterDialogProps['amountFilterProps'] = {
      // config: pageConfig.amountFilterProps.config,
      grossAmountMax: filters.grossAmountMax,
      grossAmountMin: filters.grossAmountMin,
    }
  }
  if (pageConfig.originFilterProps) {
    filterDialogProps['originFilterProps'] = {
      config: pageConfig.originFilterProps.config,
      values: origin,
    }
  }
  if (pageConfig.paymentMethodFilterProps) {
    filterDialogProps['paymentMethodFilterProps'] = {
      config: {
        ...pageConfig.paymentMethodFilterProps.config,
        options: paymentMethodOptions,
      },
      values: paymentMethod,
    }
  }
  if (pageConfig.vatAreaFilterProps) {
    filterDialogProps['vatAreaFilterProps'] = {
      config: {
        ...pageConfig.vatAreaFilterProps.config,
        options: vatAreaOptions,
      },
      values: vatArea,
    }
  }
  if (pageConfig.customFieldsFilterProps) {
    filterDialogProps['customFieldsFilterProps'] = {
      config: pageConfig.customFieldsFilterProps.config,
      values: customFields,
    }
  }

  return (
    <FilterBar
      dateField={filters.dateField}
      filterDialogProps={filterDialogProps}
      isDateFilterDisabled={isDateFilterDisabled}
      isDateFilterUsed={isDateFilterUsed}
      paidStatusFilterProps={paidStatusFilterProps}
      resetFilters={resetFilters}
      searchFilterProps={searchFilterProps}
      selectableFilterProps={selectableFilterProps}
      setPortalRef={setPortalAnchorEl}
      statusFilterProps={statusFilterProps}
      syncSearchInputValue={syncSearchInputValue}
      updateFilters={updateFilters}
    />
  )
}

export const ExpenseListPageFilterBar = connect(
  (state: Store) => {
    const {
      dashboard: {
        common: { payment_methods: paymentMethodOptions, vat_areas: vatAreaOptions },
        tags: { data: tagOptions },
        approverUsers,
      },
      expense: {
        expenseTypes: { data: expenseTypeOptions },
      },
      filters: { expenseList, isGlobalDateFilterDisabled },
    } = state
    return {
      approverOptions: approverUsers,
      expenseTypeOptions,
      filters: expenseList,
      hasIntegrationAcounto: companyHasIntegrationType(state, CompanyIntegrationProviders.acounto),
      hasIntegrationSzamlazz: companyHasIntegrationType(state, CompanyIntegrationProviders.szamlazz),
      hasTagPermission: isPlanPermissionEnabled(state, FeaturePermissons.TAG),
      isAccountantUser: isAccountant(state.auth.company.data.role),
      isAdvancedAccounting: isAdvancedAccountingAvailableForUser(state),
      isDateFilterDisabled: isGlobalDateFilterDisabled,
      paymentMethodOptions,
      tagOptions,
      vatAreaOptions,
    }
  },
  {
    resetFilters: filtersActions.resetExpenseListFilters.request,
    syncSearchInputValue: filtersActions.syncQuarantineSearchFilterInput.request,
    updateFilters: filtersActions.updateExpenseListFilters.request,
  }
)(PureExpenseListPageFilterBar)

ExpenseListPageFilterBar.displayName = 'ExpenseListPageFilterBar'
