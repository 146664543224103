import React from 'react'
import PropTypes from 'prop-types'

import * as Sentry from '@sentry/react'

import { CustomErrorBoundaryView } from '@components/ui'

class ErrorBoundary extends React.Component {
  static propTypes = { children: PropTypes.node.isRequired }
  state = { hasError: false, eventId: null }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: any) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render() {
    if (this.state.hasError) {
      return <CustomErrorBoundaryView eventId={this.state.eventId} withIntercom />
    }

    return this.props.children
  }
}

export const DashboardErrorBoundary = Sentry.withErrorBoundary(ErrorBoundary, {
  fallback: <CustomErrorBoundaryView withIntercom />,
})
