import React from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'
import __debounce from 'lodash/debounce'
import __noop from 'lodash/noop'
import { useController, useFormContext, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import { commonActions } from '@services/index'

import {
  bindActionToPromise,
  cancellablePromise,
  formatDate,
  getCurrency,
  getDecimal,
  parseApiErrorMessage,
} from '@helpers'

import { useAlertDispatch } from '@contexts/AlertProvider'

import { useCancellablePromiseRef } from '@hooks/index'

import { ReactHookFormConditionalField } from '@components/ui'
import { ReactHookFormAmountInput } from '@components/ui/FormElements/ReactHookFormAmountInput'
import { ReactHookFormDateInput } from '@components/ui/FormElements/ReactHookFormDateInput'

import { EXCHANGE_RATE_DECIMAL_PLACES } from '@constants'

import { FieldRefreshAdornment } from './FieldRefreshAdornment'

import messages from '@components/forms/messages'

type GenericFormValues = {
  currency: number
  exchange_date: Nullable<string>
  exchange_rate: Decimal
  fulfilled_at: string
}

interface ReactHookFormExchangeRateFieldsProps {
  companyDefaultCurrency: number
  currencyOptions: CommonIdAndNameType[]
  defaultCurrencyId: number
  disabled: boolean
  fetchExchangeRate: AsyncFunction<FetchExchangeRatePayload, FetchExchangeRateResponse>
  invoiceDetailsId?: ItemIdType
  maximumFractionDigits?: number
  minimumFractionDigits?: number
}

function PureReactHookFormExchangeRateFields({
  companyDefaultCurrency,
  currencyOptions,
  defaultCurrencyId,
  disabled,
  fetchExchangeRate,
  invoiceDetailsId,
  maximumFractionDigits = EXCHANGE_RATE_DECIMAL_PLACES,
  minimumFractionDigits = 0,
}: ReactHookFormExchangeRateFieldsProps) {
  const { invoice_id } = useParams<{ invoice_id?: string }>()
  const { formatMessage } = useIntl()
  const { setErrorAlert } = useAlertDispatch()

  //* form controls
  const {
    control,
    formState: {
      defaultValues: {
        currency: initialCurrency,
        exchange_date: initialExchangeDate,
        exchange_rate: initialExchangeRate,
      } = {},
      isSubmitting,
      isValidating,
    },
    getFieldState,
    setValue,
  } = useFormContext<GenericFormValues>()

  const exchangeDateController = useController({ name: 'exchange_date', control })
  const exchangeRateController = useController({ name: 'exchange_rate', control })

  const [fulfilledAt, currency] = useWatch({
    control,
    name: ['fulfilled_at', 'currency'],
  })
  const formInitializedRef = React.useRef(false)

  //* date field controls
  const [loading, setLoading] = React.useState(false)
  const [{ changed, valid, active }, setState] = React.useState({
    active: false,
    changed: false,
    valid: Boolean(exchangeRateController.field.value) && !exchangeDateController.fieldState.invalid,
  })
  const cPromiseRef = useCancellablePromiseRef<FetchExchangeRateResponse>()
  const isFormResetRef = React.useRef(true)
  const updateRef = React.useRef(__noop)
  const initExchangeDateRef = React.useRef(__noop)

  const debouncedFetch = React.useRef(
    __debounce((payload: FetchExchangeRatePayload) => {
      cPromiseRef.current = cancellablePromise(fetchExchangeRate(payload))
      // handle promise
      cPromiseRef.current.promise
        .then(response => {
          setLoading(false)
          setState(state => ({ ...state, changed: false })) // reset exchange_date state
          const decimalValue =
            response.exchange_rate.value === null
              ? ''
              : getDecimal(response.exchange_rate.value, {
                  maximumFractionDigits,
                  minimumFractionDigits,
                })
          setValue('exchange_rate', decimalValue)
        })
        .catch(error => {
          const errorMsg = parseApiErrorMessage(error)
          if (errorMsg) {
            setErrorAlert(errorMsg)
            setLoading(false)
          }
        })
    }, 1000)
  )

  const companyDefaultCurrencyName = React.useMemo(
    () => getCurrency(companyDefaultCurrency, currencyOptions),
    [companyDefaultCurrency, currencyOptions]
  )

  isFormResetRef.current =
    currency === initialCurrency &&
    exchangeDateController.field.value === initialExchangeDate &&
    exchangeDateController.field.value === initialExchangeRate

  updateRef.current = () => {
    setLoading(true)
    const currentCurrencyName = getCurrency(currency, currencyOptions)

    debouncedFetch.current({
      exchange_date: exchangeDateController.field.value as string, // already validated, so it must be filled here
      from_currency: currentCurrencyName,
      to_currency: companyDefaultCurrencyName,
    })
  }

  initExchangeDateRef.current = () => {
    let date = exchangeDateController.field.value
    if (!date) {
      if (fulfilledAt && !getFieldState('fulfilled_at').invalid) {
        date = fulfilledAt
      }
      if (!date) {
        // current date
        date = formatDate()
      }
      setValue('exchange_date', date, { shouldValidate: true }) // validate field
    }
    setState(state => ({ ...state, changed: true }))
  }

  //* INIT exchange_date
  React.useEffect(() => {
    if (!disabled && !exchangeDateController.field.value && active) {
      initExchangeDateRef.current()
    }
  }, [active, currency, disabled, exchangeDateController.field.value])

  // track exchange_date field change and validation
  React.useEffect(() => {
    setState(state => ({ ...state, changed: true, valid: false }))
  }, [exchangeDateController.field.value])

  React.useEffect(() => {
    if (changed && !valid && !isValidating) {
      setState(state => ({
        ...state,
        valid: Boolean(exchangeDateController.field.value) && !exchangeDateController.fieldState.invalid,
      }))
    }
  }, [
    changed,
    exchangeDateController.field.value,
    exchangeDateController.fieldState.invalid,
    getFieldState,
    isValidating,
    valid,
  ])

  //* ON UPDATE: curreny or exchangeDate
  const isExchangeDateFieldChangedAndValidated = changed && valid

  React.useEffect(() => {
    if (!disabled && !isFormResetRef.current && currency && isExchangeDateFieldChangedAndValidated) {
      // user change
      updateRef.current()
    }
  }, [currency, disabled, isExchangeDateFieldChangedAndValidated, exchangeDateController.field.value])

  const handleRefreshClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      if (!disabled && !exchangeDateController.field.value) {
        initExchangeDateRef.current()
      }
      updateRef.current()
    },
    [disabled, exchangeDateController.field.value]
  )

  const isFieldDisabled = disabled || exchangeDateController.formState.isSubmitting

  // reset conditional fields' values when they are not visible
  React.useEffect(() => {
    if (formInitializedRef.current && currency === defaultCurrencyId) {
      setValue('exchange_date', null)
      setValue(
        'exchange_rate',
        getDecimal(1, {
          maximumFractionDigits,
          minimumFractionDigits,
        })
      )
    }
    formInitializedRef.current = true
  }, [currency, defaultCurrencyId, initialCurrency, maximumFractionDigits, minimumFractionDigits, setValue])

  return (
    <ReactHookFormConditionalField
      name="currency"
      condition={currency => {
        const detailsMatchedWithParam = String(invoice_id) === String(invoiceDetailsId)
        return detailsMatchedWithParam && Boolean(currency && currency !== defaultCurrencyId)
      }}
    >
      <Grid item xs={4}>
        <ReactHookFormDateInput
          disabled={disabled || isSubmitting}
          endAdornment={
            <FieldRefreshAdornment disabled={isFieldDisabled} loading={loading} onClick={handleRefreshClick} />
          }
          error={!!exchangeDateController.fieldState.error}
          helperText={exchangeDateController.fieldState.error?.message ?? ''}
          label={formatMessage(messages.exchangeDateLabel)}
          name="exchange_date"
          onBlur={() => setState(state => ({ ...state, active: false }))}
          onChange={exchangeDateController.field.onChange}
          onFocus={() => setState(state => ({ ...state, active: true }))}
          required
          value={exchangeDateController.field.value}
        />
      </Grid>
      <Grid item xs={4}>
        <ReactHookFormAmountInput
          disabled={disabled || isSubmitting}
          error={!!exchangeRateController.fieldState.error}
          helperText={exchangeRateController.fieldState.error?.message ?? ''}
          label={formatMessage(messages.exchangeRateLabel)}
          maximumFractionDigits={EXCHANGE_RATE_DECIMAL_PLACES}
          name="exchange_rate"
          onChange={exchangeRateController.field.onChange}
          required
          value={exchangeRateController.field.value}
        />
      </Grid>
    </ReactHookFormConditionalField>
  )
}

PureReactHookFormExchangeRateFields.propTypes = {
  companyDefaultCurrency: PropTypes.number.isRequired,
  currencyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  defaultCurrencyId: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  fetchExchangeRate: PropTypes.func.isRequired,
  invoiceDetailsId: PropTypes.number,
}

export const ReactHookFormExchangeRateFields = connect(
  (state: Store) => ({
    companyDefaultCurrency: state.auth.company.data.default_currency,
    currencyOptions: state.dashboard.common.currencies,
  }),
  dispatch => ({
    fetchExchangeRate: bindActionToPromise(dispatch, commonActions.fetchExchangeRate.request),
  })
)(PureReactHookFormExchangeRateFields)

ReactHookFormExchangeRateFields.displayName = 'ReactHookFormExchangeRateFields'
