import { EV_COMPANY_TYPE } from './company'

export enum RouteKeys {
  BANK_TRANSACTIONS = 'bankTransactions',
  CASHFLOW = 'cashflow',
  COMPANY = 'company',
  COST = 'cost',
  COST_MULTI_UPLOAD = 'costMultiUpload',
  DOCUMENTS_MULTI_UPLOAD = 'documentsMultiUpload',
  DOKUMENT_LIST = 'dokumentList',
  DOKUMENT = 'dokument',
  DOWNLOADS = 'downloads',
  EXPENSE_LIST = 'expenseList',
  INCOME = 'income',
  INCOME_LIST = 'incomeList',
  INVITE = 'invite',
  LIQUIDITY = 'liquidity',
  LOGIN = 'login',
  MONTHLY_STATEMENT = 'monthlyStatement',
  NO_COMPANY = 'noCompany',
  NO_SUBSCRIPTION = 'noSubscription',
  ONBOARDING = 'onboarding',
  ONBOARDING_CHECK = 'onboardingCheck',
  ONBOARDING_CONNECT = 'onboardingConnect',
  ONBOARDING_INVITATION_STATUS = 'onboardingInvitationStatus',
  ONBOARDING_SUBSCRIPTION = 'onboardingSubscription',
  PAID_THROUGHS = 'paidThroughs',
  PARTNER_LIST = 'partnerList',
  PARTNER = 'partner',
  PAYMENT_ORDERS = 'paymentOrders',
  PROFILE = 'profile',
  QUARANTINE = 'quarantine',
  SALARY_LIST = 'salaryList',
  PULSE = 'pulse',
  RESET_PASSWORD = 'resetPassword',
  RESET_PASSWORD_CONFIRM = 'resetPasswordConfirm',
  ROOT = 'root',
  SELECT_COMPANY = 'selectCompany',
  SIGNUP = 'signup',
  SUBSCRIBE = 'subscribe',
  TRANSACTIONS = 'transactions', // route not exits, but used as constant
  QUARANTINE_LIST = 'quarantineList',
  TAX_LIST = 'taxList',
  CHAT = 'chat',
  PAYMENT_TRANSACTIONS = 'paymentTransactions',
}

export const ROUTES: Record<RouteKeys, string> = {
  [RouteKeys.BANK_TRANSACTIONS]: '/dashboard/:company_id/cash-flow/bank-transactions',
  [RouteKeys.CASHFLOW]: '/dashboard/:company_id/cash-flow',
  [RouteKeys.COMPANY]: '/dashboard/:company_id/company',
  [RouteKeys.COST]: '/dashboard/:company_id/expense',
  [RouteKeys.COST_MULTI_UPLOAD]: '/dashboard/:company_id/expense-multi-upload',
  [RouteKeys.INCOME]: '/dashboard/:company_id/income',
  [RouteKeys.INVITE]: '/invite/:hash',
  [RouteKeys.LIQUIDITY]: '/dashboard/:company_id/liquidity',
  [RouteKeys.LOGIN]: '/login',
  [RouteKeys.MONTHLY_STATEMENT]: '/dashboard/:company_id/monthly-statement',
  [RouteKeys.NO_COMPANY]: '/dashboard/no-company',
  [RouteKeys.ONBOARDING]: '/onboarding',
  [RouteKeys.ONBOARDING_CHECK]: '/onboarding/:company_id/check',
  [RouteKeys.ONBOARDING_CONNECT]: '/onboarding/:company_id/kszi',
  [RouteKeys.ONBOARDING_INVITATION_STATUS]: '/onboarding/:company_id/invitation-status',
  [RouteKeys.ONBOARDING_SUBSCRIPTION]: '/onboarding/:company_id/subscription',
  [RouteKeys.PAID_THROUGHS]: '/dashboard/:company_id/cash-flow/paid-throughs',
  [RouteKeys.PARTNER_LIST]: '/dashboard/:company_id/partners/:partner_type',
  [RouteKeys.PARTNER]: '/dashboard/:company_id/partner',
  [RouteKeys.PAYMENT_ORDERS]: '/dashboard/:company_id/cash-flow/payment-orders',
  [RouteKeys.PROFILE]: '/dashboard/profile',
  [RouteKeys.PULSE]: '/dashboard/:company_id/pulse',
  [RouteKeys.QUARANTINE]: '/dashboard/:company_id/quarantine',
  [RouteKeys.RESET_PASSWORD]: '/reset-password',
  [RouteKeys.RESET_PASSWORD_CONFIRM]: '/reset-password-confirm',
  [RouteKeys.ROOT]: '/dashboard',
  [RouteKeys.SELECT_COMPANY]: '/dashboard/select-company',
  [RouteKeys.SIGNUP]: '/signup',
  [RouteKeys.SUBSCRIBE]: '/dashboard/:company_id/subscribe',
  [RouteKeys.TRANSACTIONS]: '/dashboard/:company_id/transactions',
  // new subscription
  [RouteKeys.NO_SUBSCRIPTION]: '/dashboard/:company_id/no-subscription',
  // downloads
  [RouteKeys.DOWNLOADS]: '/download/:hash',
  [RouteKeys.DOCUMENTS_MULTI_UPLOAD]: '/dashboard/:company_id/documents-multi-upload',
  [RouteKeys.DOKUMENT]: '/dashboard/:company_id/document',
  // v2 transactions routes
  [RouteKeys.DOKUMENT_LIST]: '/dashboard/:company_id/transactions/documents',
  [RouteKeys.EXPENSE_LIST]: '/dashboard/:company_id/transactions/expenses',
  [RouteKeys.INCOME_LIST]: '/dashboard/:company_id/transactions/incomes',
  [RouteKeys.QUARANTINE_LIST]: '/dashboard/:company_id/transactions/quarantine-invoices',
  [RouteKeys.SALARY_LIST]: '/dashboard/:company_id/transactions/salaries',
  [RouteKeys.TAX_LIST]: '/dashboard/:company_id/transactions/taxes',
  // chat
  [RouteKeys.CHAT]: '/dashboard/:company_id/chat',
  // payment transactions
  [RouteKeys.PAYMENT_TRANSACTIONS]: '/dashboard/:company_id/cash-flow/transactions',
}

export const PULSE_REDIRECT_COMPANY_TYPES: Array<StaticEvCompanyType['id']> = [EV_COMPANY_TYPE]
