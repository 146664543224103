import * as amplitude from '@amplitude/analytics-browser'

const ONBOARDING_EVENTS = {
  ONBOARDING_STARTED: 'onboarding_started',
  ONBOARDING_CONTINUED: 'onboarding_continued',
  ONBOARDING_MANUAL_LOGIN: 'onboarding_manual_login',
  ONBOARDING_AUTO_LOGIN: 'onboarding_auto_login',
  ONBOARDING_DONE: 'onboarding_done',
  ONBOARDING_PENDING: 'onboarding_pending',
} as const

const TAXATION_EVENTS = {
  TAXATION_TB_SZOCHO_DOWNLOAD: 'tb_szocho_download',
}

export const AMPLITUDE_EVENTS = {
  LOGIN: 'login',
  REGISTRATION_STARTED: 'registration_started',
  REGISTRATION_SUCCESS: 'registration_success',
  INVITATION_STARTED: 'invitation_started',
  INVITATION_SUCCESS: 'invitation_success',
  NEW_EXPENSE_STARTED: 'new_expense_started',
  NEW_EXPENSE_CREATED: 'new_expense_created',
  NEW_INCOME_STARTED: 'new_income_started',
  NEW_INCOME_CREATED: 'new_income_created',
  VIEW_EXPENSE: 'view_expense',
  VIEW_INCOME: 'view_income',
  VIEW_DOCUMENT: 'view_document',
  PAGE_VIEW: 'page_view',
  NEW_MULTI_EXPENSE_STARTED: 'new_multi_expense_started',
  NEW_MULTI_EXPENSE_CREATED: 'new_multi_expense_created',
  LIQUIDITY_PLANNED_PAYMENT_CHANGE: 'liquidity_planned_payment_change',
  LIQUIDITY_WEEKLY_DATA_SAVE: 'liquidity_weekly_data_save',
  LIQUIDITY_REMOVE_PREDICTION: 'liquidity_remove_prediction',
  LIQUIDITY_REMOVE_SALARY_PREDICTION: 'liquidity_remove_salary_prediction',
  LIQUIDITY_PLANNED_INCOME_CHANGE: 'liquidity_planned_income_change',
  SUBSCRIPTION_CLICKED: 'subscription_clicked',
  SUBSCRIPTION_SUCCESS: 'subscription_success',
  SUBSCRIPTION_CHANGE: 'subscription_change',
  LIQUIDITY_REMOVE_TAX_PREDICTION: 'liquidity_remove_tax_prediction',
  NEW_MULTI_DOCUMENTS_STARTED: 'new_multi_documents_started',
  NEW_MULTI_DOCUMENTS_CREATED: 'new_multi_documents_created',
  NEW_COMPANY_CREATED_CLOSE: 'new_company_created_close',
  NEW_COMPANY_CREATED_EXPENSE: 'new_company_created_expense',
  NEW_COMPANY_CREATED_INTEGRATIONS: 'new_company_created_integrations',
  ...ONBOARDING_EVENTS,
  ...TAXATION_EVENTS,
} as const

/** amplitude */
export function initAmplitude(apiKey: string) {
  amplitude.init(apiKey, { minIdLength: 1 })
}

export function setAmplitudeUser(userId: Nullable<string | number>) {
  amplitude.setUserId(userId == null ? undefined : String(userId))
}

export function setUserProperties({
  company_type,
  industry,
}: Partial<{ company_type: Nullable<string>; industry: Nullable<string> }>) {
  if (!company_type && !industry) return

  const identify = new amplitude.Identify()

  if (company_type) identify.set('company_type', company_type)
  if (industry) identify.set('industry', industry)

  amplitude.identify(identify)
}

export function sendAmplitudeData(
  eventType: (typeof AMPLITUDE_EVENTS)[keyof typeof AMPLITUDE_EVENTS],
  eventProperties?: Record<string, unknown>
) {
  const eventOptions = {
    time: Date.now(),
  }
  amplitude.track(eventType, eventProperties, eventOptions)
}
