import React from 'react'

import { FormattedMessage, useIntl } from 'react-intl'
import * as yup from 'yup'

import {
  AsyncButtonWithIcon,
  CustomDialog,
  CustomDialogHeader,
  ReactHookForm,
  ReactHookFormSubmitButton,
  TrashRegularIcon,
} from '@components/ui'

import { DeleteConfirmDialogActions, DeleteConfirmDialogBody, StyledReactHookFormTextField } from './elements'
import { AdvancedDeleteConfirmDialogProps } from './types'

import { DeletePermanentlyButtonMessage, formErrorMessages, quarantineFormMessages } from '@messages'

const INITIAL_STATE = {
  confirm: '',
}

export function AdvancedDeleteConfirmDialog({
  error,
  forceToConfirm = false,
  id,
  loading,
  onClose,
  onConfirm,
  open,
  testId,
  warningMessage,
  ...rest
}: AdvancedDeleteConfirmDialogProps) {
  const { formatMessage } = useIntl()

  const validationSchema = yup.object({
    confirm: yup
      .string()
      .required(formatMessage(formErrorMessages.required))
      .test('isItOk', formatMessage(quarantineFormMessages.notOK), value => !value || value.toLowerCase() === 'ok'),
  })

  const onSubmitHandler = React.useCallback(async () => {
    await onConfirm()
  }, [onConfirm])

  const isInputVisible = forceToConfirm || warningMessage

  return (
    <CustomDialog id={id} testId={testId} open={open} onClose={onClose}>
      <CustomDialogHeader
        borderless
        title={<FormattedMessage id="dialogs.deleteConfirm.title" defaultMessage="Biztosan törlöd?" />}
      />
      {isInputVisible ? (
        <ReactHookForm
          initialValues={INITIAL_STATE}
          onSubmit={onSubmitHandler}
          skipUnsavedChanges
          validationMode="onSubmit"
          validationSchema={validationSchema}
        >
          <DeleteConfirmDialogBody warningMessage={warningMessage} {...rest}>
            <StyledReactHookFormTextField
              disabled={loading}
              label={formatMessage(quarantineFormMessages.confirmLabel)}
              name="confirm"
            />
          </DeleteConfirmDialogBody>
          <DeleteConfirmDialogActions error={error} onClose={onClose}>
            <ReactHookFormSubmitButton
              buttonText={DeletePermanentlyButtonMessage}
              disabledUntilValid
              icon={<TrashRegularIcon />}
              isCreateOnly
            />
          </DeleteConfirmDialogActions>
        </ReactHookForm>
      ) : (
        <>
          <DeleteConfirmDialogBody {...rest} />
          <DeleteConfirmDialogActions error={error} onClose={onClose}>
            <AsyncButtonWithIcon
              icon={<TrashRegularIcon />}
              loading={loading}
              onClick={onConfirm}
              variant="primaryContained"
            >
              {DeletePermanentlyButtonMessage}
            </AsyncButtonWithIcon>
          </DeleteConfirmDialogActions>
        </>
      )}
    </CustomDialog>
  )
}
