import { changeLanguage, getDefaultLanguage, setDefaultLanguage } from '@helpers'

import actions from './actions'

export const initialState: LocaleStore = { language: getDefaultLanguage() }

function getSafeLanguage(language?: Locale): Locale {
  return language ?? getDefaultLanguage()
}

type LocaleReducerAction =
  | { type: typeof actions.setLocale.REQUEST; payload: Locale }
  | { type: typeof actions.changeLocale.REQUEST; payload: Locale }
  | { type: typeof actions.updateLocale.REQUEST; payload: Locale }

export default function localeReducer(state: LocaleStore = initialState, action: LocaleReducerAction) {
  switch (action.type) {
    case actions.setLocale.REQUEST: {
      const language = getSafeLanguage(action.payload)
      setDefaultLanguage(language)
      return { language: getDefaultLanguage(language) }
    }
    case actions.changeLocale.REQUEST: {
      const language = getSafeLanguage(action.payload)
      changeLanguage(language)
      return { language }
    }
    case actions.updateLocale.REQUEST: {
      const language = getSafeLanguage(action.payload)
      return { language }
    }
    default:
      return state
  }
}
