import React from 'react'
import PropTypes from 'prop-types'

import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format'
import { connect } from 'react-redux'

import { getDecimal } from '@helpers'

import { useNumberFormatSeparators } from '@hooks'

//! React-final-form component

interface AmountInputProps
  extends Omit<
    NumberFormatProps,
    | 'onChange'
    | 'onBlur'
    | 'autoComplete'
    | 'decimalScale'
    | 'fixedDecimalScale'
    | 'getInputRef'
    | 'onValueChange'
    | 'isNumericString'
    | 'thousandSeparator'
    | 'decimalSeparator'
    | 'type'
  > {
  inputRef: NumberFormatProps['getInputRef']
  locale: Locale
  maximumFractionDigits?: number
  minimumFractionDigits?: number
  onBlur?: (value: NumberFormatProps['value']) => void
  onChange: (value: NumberFormatProps['value']) => void
}

function PureAmountInput({
  inputRef,
  locale,
  maximumFractionDigits = 0,
  minimumFractionDigits = 0,
  onBlur,
  onChange,
  ...rest
}: AmountInputProps) {
  const { decimalSeparator, thousandSeparator } = useNumberFormatSeparators(locale)

  const handleValueChange = React.useCallback(
    ({ value, floatValue }: NumberFormatValues) => {
      onChange(
        floatValue === undefined ? value : getDecimal(floatValue, { maximumFractionDigits, minimumFractionDigits })
      )
    },
    [onChange, maximumFractionDigits, minimumFractionDigits]
  )

  return (
    <NumberFormat
      {...rest}
      autoComplete="off"
      decimalScale={maximumFractionDigits}
      decimalSeparator={decimalSeparator}
      fixedDecimalScale={minimumFractionDigits === maximumFractionDigits && minimumFractionDigits !== 0}
      getInputRef={inputRef}
      isNumericString
      onValueChange={handleValueChange} // cast toFixed to be string (avoid propTypes error)
      thousandSeparator={thousandSeparator}
      type="tel"
      {...(onBlur ? { onBlur: () => onBlur(rest.value) } : {})}
    />
  )
}

PureAmountInput.propTypes = {
  allowNegative: PropTypes.bool,
  inputRef: PropTypes.func.isRequired,
  locale: PropTypes.oneOf(['en', 'hu']).isRequired as React.Validator<Locale>,
  maximumFractionDigits: PropTypes.number,
  minimumFractionDigits: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]).isRequired,
}

export const AmountInput = connect(
  (state: Store) => ({
    locale: state.locale.language,
  }),
  {} // important to remove dispatch from props (rest)
)(PureAmountInput)

AmountInput.displayName = 'AmountInput'
