import { serviceActionsGenerator } from '../serviceGenerator'

const actionTypes = [
  '!RESET_BANK_TRANSACTIONS_FILTERS',
  '!RESET_DOKUMENT_FILTERS',
  '!RESET_EXPENSE_LIST_FILTERS',
  '!RESET_INCOME_LIST_FILTERS',
  '!RESET_MONTHLY_STATEMENT_FILTERS',
  '!RESET_PAID_THROUGH_FILTERS',
  '!RESET_PARTNER_LIST_FILTERS',
  '!RESET_PAYMENT_ORDER_FILTERS',
  '!RESET_PAYMENT_TRANSACTIONS_FILTERS',
  '!RESET_QUARANTINE_LIST_FILTERS',
  '!RESET_SALARY_LIST_FILTERS',
  '!RESET_TAX_LIST_FILTERS',
  '!SYNC_EXPENSE_SEARCH_FILTER_INPUT',
  '!SYNC_QUARANTINE_SEARCH_FILTER_INPUT',
  '!TOGGLE_BANK_TRANSACTIONS_DATE_FILTER',
  '!TOGGLE_DOKUMENT_DATE_FILTER',
  '!TOGGLE_EXPENSE_LIST_DATE_FILTER',
  '!TOGGLE_INCOME_LIST_DATE_FILTER',
  '!TOGGLE_PAYMENT_ORDER_DATE_FILTER',
  '!TOGGLE_PAYMENT_TRANSACTIONS_DATE_FILTER',
  '!TOGGLE_QUARANTINE_LIST_DATE_FILTER',
  '!TOGGLE_SALARY_LIST_DATE_FILTER',
  '!TOGGLE_TAX_LIST_DATE_FILTER',
  '!UPDATE_BANK_TRANSACTIONS_FILTERS',
  '!UPDATE_DOKUMENT_FILTERS',
  '!UPDATE_EXPENSE_LIST_FILTERS',
  '!UPDATE_INCOME_LIST_FILTERS',
  '!UPDATE_MONTHLY_STATEMENT_FILTERS',
  '!UPDATE_PAID_THROUGH_FILTERS',
  '!UPDATE_PARTNER_LIST_FILTERS',
  '!UPDATE_PAYMENT_ORDER_FILTERS',
  '!UPDATE_PAYMENT_TRANSACTIONS_FILTERS',
  '!UPDATE_QUARANTINE_LIST_FILTERS',
  '!UPDATE_SALARY_LIST_FILTERS',
  '!UPDATE_TAX_LIST_FILTERS',
  // URL sync filters
  '!INIT_BANK_TRANSACTION_LIST_FILTERS_FROM_URL',
  '!INIT_BANK_TRANSACTION_LIST_PARAMS_FROM_URL',
  '!INIT_DOKUMENT_LIST_FILTERS_FROM_URL',
  '!INIT_DOKUMENT_LIST_PARAMS_FROM_URL',
  '!INIT_EXPENSE_LIST_FILTERS_FROM_URL',
  '!INIT_EXPENSE_LIST_PARAMS_FROM_URL',
  '!INIT_INCOME_LIST_FILTERS_FROM_URL',
  '!INIT_INCOME_LIST_PARAMS_FROM_URL',
  '!INIT_MONTHLY_STATEMENT_FILTERS_FROM_URL',
  '!INIT_PAID_THROUGH_LIST_FILTERS_FROM_URL',
  '!INIT_PAID_THROUGH_LIST_PARAMS_FROM_URL',
  '!INIT_PARTNER_LIST_FILTERS_FROM_URL',
  '!INIT_PAYMENT_ORDER_LIST_FILTERS_FROM_URL',
  '!INIT_PAYMENT_ORDER_LIST_PARAMS_FROM_URL',
  '!INIT_PAYMENT_TRANSACTION_LIST_FILTERS_FROM_URL',
  '!INIT_PAYMENT_TRANSACTION_LIST_PARAMS_FROM_URL',
  '!INIT_QUARANTINE_LIST_FILTERS_FROM_URL',
  '!INIT_QUARANTINE_LIST_PARAMS_FROM_URL',
  '!INIT_SALARY_LIST_FILTERS_FROM_URL',
  '!INIT_SALARY_LIST_PARAMS_FROM_URL',
  '!INIT_TAX_LIST_FILTERS_FROM_URL',
  '!INIT_TAX_LIST_PARAMS_FROM_URL',
  '!SYNC_FILTERS_TO_URL',
] as const

export default serviceActionsGenerator('filters', actionTypes)
