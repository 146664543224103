import { serviceActionsGenerator } from '../serviceGenerator'

const actionTypes = [
  '!INIT_MONTHLY_STATEMENT_PAGE_LOAD',
  '!START_EXPORT',
  '!STOP_EXPORT',
  'FETCH_MONTHLY_STATEMENT_ANALYTICS',
  'REMOVE_EXPENSE_OR_REVENUE_TYPE',
  'UPDATE_EXPENSE_OR_REVENUE_TYPE',
] as const

export default serviceActionsGenerator('statement', actionTypes)
