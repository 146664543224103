import { useIntl } from 'react-intl'
import * as yup from 'yup'

import { formErrorMessages } from '@messages'

// Note: schema type should be the same as type of form values but easier to handle w/o the non-visible fields
export function useTransformPaymentFormValidationSchema(): yup.ObjectSchema<{
  date: string
  paidThroughId: number
  transactions: Array<{ accountNumber: string; amount: string }>
}> {
  const { formatMessage } = useIntl()

  return yup.object({
    date: yup.string().required(formatMessage(formErrorMessages.required)),
    paidThroughId: yup.number().required(formatMessage(formErrorMessages.required)),
    transactions: yup
      .array()
      .of(
        yup.object({
          accountNumber: yup.string().required(formatMessage(formErrorMessages.required)),
          amount: yup.string().required(formatMessage(formErrorMessages.required)),
        })
      )
      .required(),
  })
}

export function useManualValidationSchema(): yup.ObjectSchema<{
  date: string
  paidThroughId: number
  transactions: Array<{ amount: string }>
}> {
  const { formatMessage } = useIntl()

  return yup.object({
    date: yup.string().required(formatMessage(formErrorMessages.required)),
    paidThroughId: yup.number().nullable().required(formatMessage(formErrorMessages.required)),
    transactions: yup
      .array()
      .of(
        yup.object({
          amount: yup.string().required(formatMessage(formErrorMessages.required)),
        })
      )
      .required(),
  })
}

export function useExpressValidationSchema(): yup.ObjectSchema<{
  date: string
  paidThroughId: Nullable<number>
  transactions: Array<{ amount: string }>
}> {
  const { formatMessage } = useIntl()

  return yup.object({
    date: yup.string().required(formatMessage(formErrorMessages.required)),
    paidThroughId: yup.number().defined().nullable(),
    transactions: yup
      .array()
      .of(
        yup.object({
          amount: yup.string().required(formatMessage(formErrorMessages.required)),
        })
      )
      .required(),
  })
}
