import { AxiosResponse } from 'axios'
import { Location, NavigateFunction } from 'react-router-dom'
import { call, delay, put, select, takeLatest } from 'redux-saga/effects'

import { FilterOptions, parseFiltersFromUrlWorkerRunner } from '@webWorkers'
import { BackgroundJobResponse } from '@services/types'

import {
  downloadFileWithURL,
  getActiveCompanyId,
  getErrorMessage,
  getPartnerParamsFromStore,
  getRFFFormErrors,
  getUrlFilterOptionsFromStore,
} from '@helpers'

import { AttachPartnerListData } from '@components/AttachDokuments/elements/AttachDokumentToPartnerSearchView/types'
import { SyncFiltersConfig } from '@components/filters/types'
import { PartnerData, PartnerFormValues } from '@oldComponents/PartnerEditor/types'

import { FiltersStateKey, InvoiceType, TYPING_INTERVAL } from '@constants'

import { BackgroundPartnerEmailProcess, BackgroundPartnerMergeProcess, JOB_STATUS_CREATED } from '../background/process'
import filtersActions from '../filters/actions'
import actions from './actions'
import * as api from './api'

function* initProviderPartnersPageLoadSaga({
  payload: { config, filtersStateKey, location, navigate },
  meta: { resolve, reject },
}: AsyncSagaAction<{
  config: SyncFiltersConfig
  filtersStateKey: FiltersStateKey
  location: Location<unknown>
  navigate: NavigateFunction
}>) {
  try {
    //* >> sync from url to store
    const filterOptions: FilterOptions = yield select(getUrlFilterOptionsFromStore, filtersStateKey)

    //* call worker
    const { filters, validationLevel } = yield call(parseFiltersFromUrlWorkerRunner, {
      config,
      filterOptions,
      location,
    })
    yield put(filtersActions.initPartnerListFiltersFromUrl.request({ filters: { ...filters, type: 'provider' } }))
    //* << sync from store to url
    const storedFilters: PartnerPageParams = yield select(getPartnerParamsFromStore)
    yield put(filtersActions.syncFiltersToUrl.request({ navigate, filters: storedFilters }))
    yield call(resolve, validationLevel)
    yield put(actions.fetchPartners.request())
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
    yield put(actions.fetchPartners.failure(errorMsg))
  }
}

function* initCustomerPartnersPageLoadSaga({
  payload: { config, filtersStateKey, location, navigate },
  meta: { resolve, reject },
}: AsyncSagaAction<{
  config: SyncFiltersConfig
  filtersStateKey: FiltersStateKey
  location: Location<unknown>
  navigate: NavigateFunction
}>) {
  try {
    //* >> sync from url to store
    const filterOptions: FilterOptions = yield select(getUrlFilterOptionsFromStore, filtersStateKey)

    //* call worker
    const { filters, validationLevel } = yield call(parseFiltersFromUrlWorkerRunner, {
      config,
      filterOptions,
      location,
    })
    yield put(filtersActions.initPartnerListFiltersFromUrl.request({ filters: { ...filters, type: 'customer' } }))
    //* << sync from store to url
    const storedFilters: PartnerPageParams = yield select(getPartnerParamsFromStore)
    yield put(filtersActions.syncFiltersToUrl.request({ navigate, filters: storedFilters }))
    yield call(resolve, validationLevel)
    yield put(actions.fetchPartners.request())
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
    yield put(actions.fetchPartners.failure(errorMsg))
  }
}

function* fetchPartnersSaga() {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const params: PartnerPageParams = yield select(getPartnerParamsFromStore, { withType: true, withPage: true })
    const response: AxiosResponse<{ count: number; results: BackendPartnerListResult[] }> = yield call(
      api.fetchPartners,
      companyId,
      params
    )
    yield put(actions.fetchPartners.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchPartners.failure(errorMsg))
  }
}

function* fetchPartnerDetailsSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<ItemIdType>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<PartnerData> = yield call(api.fetchPartnerDetails, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* updatePartnerCalculationBaseSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<{
  calculation_base: CalculationBase
  company: number
  id?: number
  invoiceType: InvoiceType
}>) {
  try {
    yield call(api.updatePartnerCalculationBase, payload)
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* exportPartnersSaga({ meta: { resolve, reject } }: AsyncSagaAction<void>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const { ordering, pageSize, ...params } = yield select(getPartnerParamsFromStore) // cleanup params
    let response: AxiosResponse<BackgroundJobResponse> = yield call(api.exportPartners, companyId, params)

    if (response.data.status === JOB_STATUS_CREATED) {
      const jobId = response.data.id
      response = yield call(BackgroundPartnerEmailProcess.start, {
        id: jobId,
        company_id: companyId,
      })
    }
    // download file when it not resolved by send-in-email cancel
    if (!response.data.send_email) {
      yield call(downloadFileWithURL, response) // download file
    }

    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* abortExportPartnersSaga() {
  yield call(BackgroundPartnerEmailProcess.stop)
}

// PARTNER field search
function* searchPartnersSaga({
  payload,
  meta: { resolve },
}: AsyncSagaAction<
  Partial<{
    name: string
    page: number
    pageSize: number
    ordering: string
    partner: string
    invoice_number: string
    tax_number: string
  }>
>) {
  try {
    yield delay(TYPING_INTERVAL)
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<{ count: number; results: AttachPartnerListData[] }> = yield call(
      api.searchPartners,
      companyId,
      payload
    )
    yield call(resolve, response.data)
  } catch (error) {
    console.error('Search partners failed', error)
    yield call(resolve, []) // resolve with empty results on error
  }
}

function* createPartnerSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<PartnerFormValues>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<PartnerData> = yield call(api.createPartner, companyId, payload)
    yield put(actions.createPartner.success(response.data))
    yield call(resolve, response.data)
  } catch (error) {
    const formErrors = getRFFFormErrors(error)
    yield call(reject, formErrors)
  }
}

function* updatePartnerSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<PartnerFormValues>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<PartnerData> = yield call(api.updatePartner, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const formErrors = getRFFFormErrors(error)
    yield call(reject, formErrors)
  }
}

function* initRemovePartnerSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<number>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<{ count: number }> = yield call(api.getPartnerInvoiceCount, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMessage = getErrorMessage(error)
    yield call(reject, errorMessage)
  }
}

function* removePartnerSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<number>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    yield call(api.removePartner, companyId, payload)
    yield put(actions.removePartner.success())
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* mergeAndRemovePartnerSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<Record<'partnerId' | 'new_partner_id', ItemIdType>>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<BackgroundJobResponse> = yield call(api.mergeAndRemovePartner, companyId, payload)
    // check background process when it is ongoing after request
    if (response.data.status === JOB_STATUS_CREATED) {
      yield call(BackgroundPartnerMergeProcess.start, {
        id: response.data.id,
        company_id: companyId,
      })
    }
    yield put(actions.mergeAndRemovePartner.success())
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// watcher Saga
export default function* commonSaga() {
  yield takeLatest(actions.createPartner.REQUEST, createPartnerSaga)
  yield takeLatest(actions.updatePartner.REQUEST, updatePartnerSaga)
  yield takeLatest(actions.initRemovePartner.REQUEST, initRemovePartnerSaga)
  yield takeLatest(actions.removePartner.REQUEST, removePartnerSaga)
  yield takeLatest(actions.mergeAndRemovePartner.REQUEST, mergeAndRemovePartnerSaga)

  yield takeLatest(actions.initProviderPartnersPageLoad.REQUEST, initProviderPartnersPageLoadSaga)
  yield takeLatest(actions.initCustomerPartnersPageLoad.REQUEST, initCustomerPartnersPageLoadSaga)
  yield takeLatest(actions.fetchPartnerDetails.REQUEST, fetchPartnerDetailsSaga)
  yield takeLatest(actions.updatePartnerCalculationBase.REQUEST, updatePartnerCalculationBaseSaga)

  yield takeLatest(
    [
      actions.fetchPartners.REQUEST,
      filtersActions.updatePartnerListFilters.REQUEST,
      filtersActions.resetPartnerListFilters.REQUEST,
      actions.removePartner.SUCCESS,
      actions.mergeAndRemovePartner.SUCCESS,
      actions.updatePage.REQUEST,
      actions.updateRowsPerPage.REQUEST,
      actions.updateOrder.REQUEST,
    ],
    fetchPartnersSaga
  )
  yield takeLatest(actions.exportPartners.REQUEST, exportPartnersSaga)
  yield takeLatest(actions.abortExportPartners.REQUEST, abortExportPartnersSaga)
  yield takeLatest(actions.searchPartners.REQUEST, searchPartnersSaga)
}
