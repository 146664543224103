import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import * as yup from 'yup'

import { paymentActions } from '@services'

import { applyEmptyFormFieldsAndNullEmptyStrings, bindActionToPromise } from '@helpers'

import { EditorMode } from '@hooks'

import { AsyncFormDialog } from '@components/FormDialogs'

import { BANK_ACCOUNT_NUMBER_REGEX, CITIBANK_ACCOUNT_NUMBER_REGEX, WISE_ACCOUNT_NUMBER_REGEX } from '@constants'

import { PaidThroughEditorFormContent } from './PaidThroughEditorFormContent'
import { PaidThroughDialogVariant, PaidThroughFormValues, PurePaidThroughEditorDialogProps } from './types'

import { formErrorMessages } from '@messages'

/**
 * This is what gets called once the form is successfully submitted to display a success message
 *
 * @param {PaidThroughDetailData} data - the data returned by the API
 * @param {EditorMode} mode - can be `EDIT` or `CREATE`
 */
function onSubmitSuccessText(data: PaidThroughDetailData | null, mode: EditorMode) {
  return (
    <FormattedMessage
      id="editor.paidThrough.success.message"
      defaultMessage="Sikeresen {mode} a(z) {name} pénztárcát. Amennyiben autokassza integrációt is beállítottál a szinkron több napon keresztül is eltarthat, de utána folyamatos lesz."
      values={{
        mode:
          mode === EditorMode.CREATE ? (
            <FormattedMessage id="editor.paidThrough.success.create" defaultMessage="létrehoztad" />
          ) : (
            <FormattedMessage id="editor.paidThrough.success.edit" defaultMessage="módosítottad" />
          ),
        name: <strong>{data?.name}</strong>,
      }}
    />
  )
}

const INITIAL_VALUES: PaidThroughFormValues = {
  account_number: '',
  add_new_balance: false,
  balance: null,
  currency: null,
  is_autokassza: false,
  ledger_number_code: null,
  ledger_number_name: null,
  name: '',
  provider: null,
  paidthrough_type: null,
  value_date: null,
}

// local helper
export function createInitialValues(data?: Partial<PaidThroughDetailData>) {
  return {
    ...INITIAL_VALUES,
    account_number: data?.account_number ?? INITIAL_VALUES.account_number,
    add_new_balance: INITIAL_VALUES.add_new_balance,
    currency: data?.currency?.id ?? INITIAL_VALUES.currency,
    id: data?.id,
    is_autokassza: data?.is_autokassza ?? INITIAL_VALUES.is_autokassza,
    ledger_number_code: data?.ledger_number_code ?? INITIAL_VALUES.ledger_number_code,
    ledger_number_name: data?.ledger_number_name ?? INITIAL_VALUES.ledger_number_name,
    name: data?.name ?? INITIAL_VALUES.name,
    paidthrough_type: data?.paidthrough_type ?? INITIAL_VALUES.paidthrough_type,
    provider: data?.provider ?? INITIAL_VALUES.provider,
  }
}

/**
 * This component handles the editing or creation of a paid through using AsyncFormDialog.
 *
 * @param {PurePaidThroughEditorDialogProps} props - The properties for the component.
 * @param {Function} props.callCreateListItem - Function to call for creating a list item.
 * @param {Function} props.callCreateSelectOption - Function to call for creating a select option.
 * @param {Function} props.callUpdate - Function to call for updating an item.
 * @param {Object} props.editor - Editor configuration object.
 * @param {boolean} props.editor.open - Indicates if the editor dialog is open.
 * @param {EditorMode} props.editor.mode - The mode of the editor, can be `create` or `edit`.
 * @param {number} props.editor.payload - The ID of the paid through item being edited.
 * @param {Object} props.editor.data - Initial data for the editor.
 * @param {Function} props.loadDetails - Function to load the details of a paid through item.
 * @param {Function} props.onClose - Function to call when the dialog is closed.
 * @param {Function} [props.onSubmitSuccess] - Function to call when the form is successfully submitted.
 * @param {PaidThroughDialogVariant} [props.variant=PaidThroughDialogVariant.LIST_ITEM] - The variant of the dialog.
 *
 * @returns {JSX.Element} The rendered component.
 */
export function PurePaidThroughEditorDialog({
  callCreateListItem,
  callCreateSelectOption,
  callUpdate,
  editor: { open, mode, payload: paidThroughId, data: initialData },
  loadDetails,
  onClose,
  onSubmitSuccess,
  variant = PaidThroughDialogVariant.LIST_ITEM,
}: PurePaidThroughEditorDialogProps) {
  const { formatMessage } = useIntl()

  const isEdit = mode === EditorMode.EDIT

  const getValidationSchema = React.useCallback(
    (detailsData: Nullable<PaidThroughDetailData>) => {
      return yup.object().shape({
        paidthrough_type: yup.number().nullable().required(formatMessage(formErrorMessages.required)),
        currency: yup.number().nullable().required(formatMessage(formErrorMessages.required)),
        name: yup.string().required(formatMessage(formErrorMessages.required)),
        provider: yup.number().nullable(),
        account_number: yup
          .string()
          .nullable()
          .when(['provider'], ([provider], schema) => {
            if (provider != null && provider !== '') {
              return schema.required(formatMessage(formErrorMessages.required))
            }

            if (Number(provider) === 1) {
              // citibank
              return schema.matches(CITIBANK_ACCOUNT_NUMBER_REGEX, {
                message: formatMessage(formErrorMessages.invalidBankIdentifier),
                excludeEmptyString: true,
              })
            }

            if (Number(provider) === 17) {
              // wise
              return schema.matches(WISE_ACCOUNT_NUMBER_REGEX, {
                message: formatMessage(formErrorMessages.invalidBankAccountNumber),
                excludeEmptyString: true,
              })
            }

            return schema.matches(BANK_ACCOUNT_NUMBER_REGEX, {
              message: formatMessage(formErrorMessages.invalidBankAccountNumber),
              excludeEmptyString: true,
            })
          }),
        is_autokassza: yup.boolean().required(),
        balance: yup
          .string()
          .nullable()
          // if value_date is set, balance is required
          .test('balance', formatMessage(formErrorMessages.required), function (value) {
            const { value_date } = this.parent
            return value_date && value_date !== '' ? Boolean(value) : true
          })
          .when(['is_autokassza'], ([is_autokassza], schema) => {
            if (is_autokassza && (!isEdit || detailsData?.balances?.length === 0)) {
              return schema.required(formatMessage(formErrorMessages.required))
            }
            return schema
          }),
        value_date: yup
          .string()
          .nullable()
          .test(
            'value_date',
            formatMessage(formErrorMessages.invalidDate),
            value => !value || moment(value, 'YYYY-MM-DD', true).isValid()
          )
          .test(
            'value_date',
            formatMessage(formErrorMessages.invalidFutureDate),
            value => !moment(value, 'YYYY-MM-DD', true).isAfter()
          )
          // if balance is set, value_date is required
          .test('value_date', formatMessage(formErrorMessages.required), function (value) {
            const { balance } = this.parent
            return balance && balance !== '' ? Boolean(value) : true
          })
          .when(['is_autokassza'], ([is_autokassza], schema) => {
            if (is_autokassza && (!isEdit || detailsData?.balances?.length === 0)) {
              return schema.required(formatMessage(formErrorMessages.required))
            }
            return schema
          }),
        add_new_balance: yup.boolean().required(),
        id: yup.number().optional(),
        ledger_number_code: yup.string().nullable(),
        ledger_number_name: yup.string().nullable(),
      })
    },
    [formatMessage, isEdit]
  )

  const dialogTitle = isEdit ? (
    <FormattedMessage id="editor.paidThrough.editDialogTitle" defaultMessage="Pénztárca szerkesztése" />
  ) : (
    <FormattedMessage id="editor.paidThrough.newDialogTitle" defaultMessage="Új pénztárca hozzáadása" />
  )

  async function handleFormSubmit(values: PaidThroughFormValues) {
    // frontend should always send every field, even if it's empty
    const submitValues = applyEmptyFormFieldsAndNullEmptyStrings(values, INITIAL_VALUES, ['balance', 'value_date'])

    if (isEdit) {
      return callUpdate(submitValues)
    }

    if (variant === PaidThroughDialogVariant.SELECT_OPTION) {
      return callCreateSelectOption(submitValues)
    }

    return callCreateListItem(submitValues)
  }

  function onLoadFormData() {
    // it will only run when we do have paidThroughId already
    return loadDetails(paidThroughId as number)
  }

  return (
    <AsyncFormDialog
      createInitialValues={createInitialValues}
      dialogTitle={dialogTitle}
      getValidationSchema={getValidationSchema}
      initialData={initialData}
      mode={mode}
      onClose={onClose}
      onLoad={onLoadFormData}
      open={open}
      onSubmit={handleFormSubmit}
      onSubmitSuccess={onSubmitSuccess}
      onLoadFailureText={
        <FormattedMessage
          id="editor.paidThrough.loadFailure"
          defaultMessage="Hiba történt a pénztárca adatainak lekérése során:"
        />
      }
      onSubmitSuccessText={onSubmitSuccessText}
      skipSuccessResponse={variant === PaidThroughDialogVariant.SELECT_OPTION}
      testId="paid-through-editor-form"
    >
      {({ storedValues }) => {
        return <PaidThroughEditorFormContent detailsData={storedValues} isEdit={isEdit} onClose={onClose} />
      }}
    </AsyncFormDialog>
  )
}

PurePaidThroughEditorDialog.propTypes = {
  callCreateListItem: PropTypes.func.isRequired,
  callCreateSelectOption: PropTypes.func.isRequired,
  callUpdate: PropTypes.func.isRequired,
  editor: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    mode: PropTypes.oneOf(['create', 'edit']).isRequired as React.Validator<EditorMode>,
    payload: PropTypes.number as React.Validator<number>,
    data: PropTypes.shape({ name: PropTypes.string.isRequired }) as React.Validator<{ name: string }>,
  }).isRequired,
  loadDetails: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
}

export const PaidThroughEditorDialog = connect(null, dispatch => ({
  callCreateListItem: bindActionToPromise(dispatch, paymentActions.createPaidThrough.request),
  callCreateSelectOption: bindActionToPromise(dispatch, paymentActions.createPaidThroughOption.request),
  callUpdate: bindActionToPromise(dispatch, paymentActions.updatePaidThrough.request),
  loadDetails: bindActionToPromise(dispatch, paymentActions.fetchPaidThroughDetails.request),
}))(PurePaidThroughEditorDialog)

PaidThroughEditorDialog.displayName = 'PaidThroughEditorDialog'
