import { SyncFiltersPayload } from '@webWorkers'

import { PageChangePayload, UpdateOrderPayload, UpdateRowsPerPagePayload } from '@hooks/useTableControls'

import { AttachDokumentDialogVariant } from '@components/AttachDokuments/constants'
import { AttachmentUploadFormValues } from '@components/AttachmentElements/types'
import { ExpenseDetailsFormValues } from '@components/forms/ExpenseDetailsForm/types'

import attachmentActions from '../attachment/actions'
import authActions from '../auth/actions'
import filtersActions from '../filters/actions'
import quarantineActions from '../quarantine/actions'
import actions from './actions'

export type ExpenseFilingResults = Record<ItemIdType, string>

export type ExpenseDetailsActionResults = Pick<ExpenseDetailsBackendValues, 'status'> & {
  links: TransformObjectToCamelCase<ExpenseDetailsLinks>
}

export type ExpenseApproveResults = TransformObjectToCamelCase<ExpenseDetailsMeta>

type UpdateAttachmentCountPayload = {
  count: number
  documentType: AttachDokumentDialogVariant
}

export type BackendExpenseListByPagingResults = {
  next: BackendCallableLink
  previous: BackendCallableLink
  results: BackendExpenseListResult[]
}

export type ExpenseListByPagingResults = Pick<PageChangePayload, 'direction'> & {
  data: ExpenseListData[]
  next: PageChangePayload
  previous: PageChangePayload
}

export type ExpenseListResults = ExpenseListByPagingResults & {
  isCursorDropped: boolean
  previousCursor: Nullable<string>
}

export type ExpenseDeleteDuplicationRequestPayload = { id: number; invoice: number }

export type UpdateExpenseDetailsFormValues = Required<
  Pick<ExpenseDetailsFormValues, 'partner_id' | 'payment_method' | 'id'>
>

export type ExpenseReducerAction =
  | { type: typeof authActions.selectCompany.REQUEST }
  | { type: typeof filtersActions.initExpenseListParamsFromUrl.REQUEST; payload: SyncFiltersPayload['params'] }
  | { type: typeof actions.bulkFilingV2.SUCCESS; payload: ExpenseFilingResults }
  | { type: typeof actions.expenseFiling.SUCCESS; payload: ExpenseFilingResults }
  | { type: typeof actions.updateExpense.SUCCESS; payload: ExpenseDetailsBackendValues }
  | { type: typeof quarantineActions.updateQuarantineInvoice.SUCCESS; payload: ExpenseDetailsBackendValues }
  | { type: typeof actions.uploadExpense.REQUEST; payload: ExpenseUploadPayload }
  | { type: typeof quarantineActions.uploadQuarantineInvoice.REQUEST }
  | { type: typeof quarantineActions.uploadAcceptedQuarantineInvoice.REQUEST }
  | { type: typeof actions.uploadExpense.SUCCESS; payload: ExpenseDetailsBackendValues }
  | { type: typeof quarantineActions.uploadQuarantineInvoice.SUCCESS; payload: ExpenseDetailsBackendValues }
  | { type: typeof quarantineActions.uploadAcceptedQuarantineInvoice.SUCCESS; payload: ExpenseDetailsBackendValues }
  | { type: typeof actions.uploadExpense.FAILURE }
  | { type: typeof actions.cancelUploadExpenseProcess.REQUEST }
  | { type: typeof actions.abortUploadExpense.REQUEST }
  | { type: typeof quarantineActions.uploadQuarantineInvoice.FAILURE }
  | { type: typeof quarantineActions.uploadAcceptedQuarantineInvoice.FAILURE }
  | { type: typeof actions.fetchExpenseDetails.REQUEST }
  | { type: typeof quarantineActions.fetchQuarantineInvoiceDetails.REQUEST }
  | { type: typeof actions.fetchExpenseDetails.SUCCESS; payload: ExpenseDetailsBackendValues }
  | { type: typeof quarantineActions.fetchQuarantineInvoiceDetails.SUCCESS; payload: ExpenseDetailsBackendValues }
  | { type: typeof actions.fetchExpenseDetails.FAILURE; payload: BackendError }
  | { type: typeof quarantineActions.fetchQuarantineInvoiceDetails.FAILURE; payload: BackendError }
  | { type: typeof actions.removeExpenseArtifact.SUCCESS }
  | { type: typeof actions.bulkTaggingV2.SUCCESS }
  | { type: typeof actions.bulkCategorizationV2.SUCCESS }
  | { type: typeof actions.bulkApprove.SUCCESS }
  | { type: typeof actions.bulkAccountingV2.SUCCESS }
  | { type: typeof actions.bulkFilingV2.FAILURE }
  | { type: typeof actions.clearExpenseDetails.REQUEST }
  | { type: typeof actions.startBulkAccountingExportV2.REQUEST }
  | { type: typeof authActions.selectCompany.SUCCESS }
  | { type: typeof actions.callExcludeFromAccountingToggle.SUCCESS; payload: ExpenseDetailsActionResults }
  | { type: typeof actions.callAccountingCompleteToggle.SUCCESS; payload: ExpenseDetailsActionResults }
  | { type: typeof actions.callUnlockAccounting.SUCCESS; payload: ExpenseDetailsActionResults }
  | { type: typeof actions.expenseApprove.SUCCESS; payload: ExpenseApproveResults }
  | { type: typeof actions.fetchExpenseTypes.REQUEST }
  | { type: typeof actions.fetchExpenseTypes.SUCCESS; payload: ExpenseTypeData[] }
  | { type: typeof actions.fetchExpenseTypes.FAILURE; payload: BackendError }
  | { type: typeof actions.createExpenseType.SUCCESS; payload: ExpenseTypeData }
  | { type: typeof attachmentActions.increaseAttachmentCount.REQUEST; payload: AttachmentUploadFormValues }
  | { type: typeof attachmentActions.decreaseAttachment.REQUEST; payload: AttachmentUploadFormValues }
  | { type: typeof attachmentActions.updateAttachmentCount.REQUEST; payload: UpdateAttachmentCountPayload }
  | { type: typeof actions.fetchExpenseListV2.REQUEST }
  | { type: typeof actions.fetchExpenseListByPagingV2.REQUEST; payload: PageChangePayload }
  | { type: typeof actions.fetchExpenseDetailsByPagingV2.REQUEST; payload: DetailsPageChangePayload }
  | { type: typeof actions.fetchExpenseListV2.SUCCESS; payload: ExpenseListResults }
  | { type: typeof actions.fetchExpenseListByPagingV2.SUCCESS; payload: ExpenseListByPagingResults }
  | { type: typeof actions.fetchExpenseListV2.FAILURE; payload: BackendError }
  | { type: typeof actions.fetchExpenseListByPagingV2.FAILURE; payload: BackendError }
  | { type: typeof actions.updateOrderV2.REQUEST; payload: UpdateOrderPayload }
  | { type: typeof actions.updateRowsPerPageV2.REQUEST; payload: UpdateRowsPerPagePayload }
  | { type: typeof filtersActions.toggleExpenseListDateFilter.REQUEST }
  | { type: typeof filtersActions.updateExpenseListFilters.REQUEST }
  | { type: typeof filtersActions.resetExpenseListFilters.REQUEST }
  | { type: typeof actions.fetchExpenseCharts.REQUEST }
  | { type: typeof actions.fetchExpenseCharts.SUCCESS; payload: BackendExpenseChartResult }
  | { type: typeof actions.fetchExpenseCharts.FAILURE; payload: BackendError }
  | { type: typeof actions.deleteExpenseDuplication.SUCCESS; payload: ExpenseDeleteDuplicationRequestPayload }
  | { type: typeof quarantineActions.triggerExpenseUpdateV2.REQUEST }
  | { type: typeof actions.triggerExpenseListUpdate.REQUEST }
  | { type: typeof actions.resetPagination.REQUEST }
  | { type: typeof actions.clearResetFormAfterUpload.REQUEST }

type ExpenseCreateByUploadPayload = { file: File; need_classify: never }
type ExpenseClassifyPayload = { file: File; need_classify?: boolean }

type ExpenseUpdatePayload = ExpenseClassifyPayload & { invoice: number }

export type ExpenseUploadPayload = ExpenseCreateByUploadPayload | ExpenseUpdatePayload

export function isUpdatePayload(data: ExpenseUploadPayload): data is ExpenseUpdatePayload {
  return Boolean((data as ExpenseUpdatePayload).invoice)
}

type ExpenseUploadBackgroundJobResults = { id: number }

export type ExpenseUploadBackgroundJobResponse = BackgroundActionResponse<ExpenseUploadBackgroundJobResults> & {
  metadata: { invoice_id: number }
}

export type ExpenseUploadResponse = ExpenseUploadBackgroundJobResults &
  Partial<Pick<ExpenseDetailsBackendValues, 'missing'>>
