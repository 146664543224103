export const ADVANCED_ACCOUNTING_CLASS_NAME = 'advanced-accounting'
export const APP_TITLE = 'QUiCK'
export const AUTH_TOKEN_NAME = 'quick_auth_token'
export const ANNOUNCEMENT_COOKIE_PREFIX = 'quick_announcement'

export const BADGE_MASTER = 1
export const BADGE_ASSISTANT = 2

export const CURRENCY_OPTIONS = {
  style: 'currency',
  currency: 'HUF',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
} as { style: 'currency'; currency: string; minimumFractionDigits: number; maximumFractionDigits: number }

const DEFAULT_ROWS_PER_PAGE_OPTIONS = [50, 100, 200]
export const ROWS_PER_PAGE_OPTIONS = process.env.ROWS_PER_PAGE
  ? [Number(process.env.ROWS_PER_PAGE), ...DEFAULT_ROWS_PER_PAGE_OPTIONS]
  : DEFAULT_ROWS_PER_PAGE_OPTIONS

export enum CompanyIntegrationProviders {
  acounto = 40,
  billingo = 20,
  payee = 30,
  szamlazz = 10,
  vasfala = 50,
}

export const CALENDAR_LOCALE_FORMATS = {
  header: {
    hu: 'YYYY. MMMM',
    en: 'MMMM, YYYY',
  },
  input: {
    hu: 'YYYY. MMMM D.',
    en: 'MMMM D, YYYY',
  },
}

export const PAID_NO = 1
export const PAID_YES = 2
export const PAID_INSTALLMENT = 3

export const EMPTY_SALARY_ROW = {
  amount: '',
  name: '',
  simple_tags: [],
  tags: [],
  tax_number: '',
}

export const EMPTY_TAX_ROW = {
  amount: '',
  code: '',
  due_at: '',
  name: '',
  simple_tags: [],
  tags: [],
}

export const QUARANTINE_STATUS = {
  pending: 0,
  accepted: 1,
  rejected: 2,
  merged: 3,
} as const

export enum WarningBarViews {
  PENDING_SZAMLAZZ_SYNC = 'pending-szamlazz-sync',
  PROCESSING = 'processing-invoices',
  DEFAULT = 'no-warning',
}

export enum InvoiceType {
  EXPENSE = 'expense',
  INCOME = 'revenue',
}

export enum PaidThroughType {
  CASH = 1,
  BANK_ACCOUNT = 2,
}

export enum PageChangeDirection {
  NEXT = 'next',
  PREV = 'prev',
}

export const COMPACT_NAVBAR_ID = 'compact-navbar'

export const CHAT_INTERVAL = 5000 // ms

export const EXCHANGE_RATE_DECIMAL_PLACES = 4

export const INVOICE_AMOUNT_INPUT_DECIMAL_PLACES = 2

// A4 paper size: 72dpi (595 x 842 pixels)
export const A4_PAPER_SIZE = {
  width: 595,
  height: 842,
}

export const CHART_PRINT_CONFIG = {
  width: 2 * A4_PAPER_SIZE.width,
  height: 0.5 * A4_PAPER_SIZE.height,
  scale: 1,
}

export const DOC_PREVIEW_CONFIG = {
  maxWidth: 1600,
  dialogHeaderMaxHeight: 85,
  controlsBarHeight: 45,
}

export enum PreviewType {
  ARTIFACT = 'ARTIFACT',
  INVOICE = 'INVOICE',
}

export enum DokumentConnectionType {
  EXPENSES = 'expenses',
  INCOMES = 'incomes',
  PARTNERS = 'partners',
}

export const ERROR_CLEAR_TIMEOUT = 4000
