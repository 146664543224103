import { A4_PAPER_SIZE } from '@constants'

export enum ZoomOrientation {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait',
}

export enum ZoomState {
  RESET = 'reset',
  CHANGE_TO_PORTRAIT = 'to_portrait',
  CHANGE_TO_LANDSCAPE = 'to_landscape',
  ORIGINAL_SIZE = 'original_size',
}

export const POPPER_PROPS = { disablePortal: true, placement: 'top' } as const

export const MINIMUM_RENDERER_SIZE = 0.2 * A4_PAPER_SIZE.width // 20% of A4 paper size ~ 119px
