import React from 'react'
import PropTypes from 'prop-types'

import { CircularProgress, Fade } from '@material-ui/core'
import styled, { css } from 'styled-components'

import { applyOpacity } from '@helpers/styled-helpers'

type OverlayVariants = 'attachmentTable' | 'default' | 'listTableV1' | 'listTableV1NoPaging' | 'listTableV2'

const OVERLAY_TOP_VALUES: Record<OverlayVariants, number> = {
  attachmentTable: 37,
  default: 0,
  listTableV1: 45, // thead height
  listTableV1NoPaging: 45,
  listTableV2: 48,
}

const OVERLAY_BOTTOM_VALUES: Record<OverlayVariants, number> = {
  attachmentTable: 0,
  default: 0,
  listTableV1: 52, // pagination height
  listTableV1NoPaging: 0,
  listTableV2: 52,
}

const LoaderPositionerDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: ${({ theme }) => applyOpacity(theme.colors.gray[0], 80)};
`

const LoadingOverlayWrapperDiv = styled.div.withConfig<{ variant: OverlayVariants }>({
  shouldForwardProp: (prop, defaultValidator) => {
    return !['variant'].includes(prop) && defaultValidator(prop)
  },
})`
  position: relative;
  min-height: 40px;

  ${LoaderPositionerDiv} {
    top: ${({ variant }) => OVERLAY_TOP_VALUES[variant]}px;
    bottom: ${({ variant }) => OVERLAY_BOTTOM_VALUES[variant]}px;
  }

  ${({ variant }) =>
    ['listTableV1', 'listTableV2'].includes(variant) &&
    css`
      overflow-x: auto;
    `}
`

interface ContentWithLoadingOverlayProps {
  children: React.ReactNode
  className?: string
  loading: boolean
  size?: number
  variant?: OverlayVariants
}

//! NOTE "mountOnEnter" and "unmountOnExit" on Fade component is important to avoid performance issue by high CPU usage
const ContentWithLoadingOverlay = React.forwardRef<HTMLDivElement, ContentWithLoadingOverlayProps>(
  ({ children, className, loading, size = 30, variant = 'default' }, ref) => (
    <LoadingOverlayWrapperDiv className={className} variant={variant} ref={ref}>
      {children}
      <Fade in={loading} mountOnEnter unmountOnExit>
        <LoaderPositionerDiv>
          <CircularProgress color="primary" size={size} />
        </LoaderPositionerDiv>
      </Fade>
    </LoadingOverlayWrapperDiv>
  )
)

ContentWithLoadingOverlay.displayName = 'ContentWithLoadingOverlay'

ContentWithLoadingOverlay.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  size: PropTypes.number,
  variant: PropTypes.oneOf([
    'attachmentTable',
    'default',
    'listTableV1',
    'listTableV1NoPaging',
    'listTableV2',
  ]) as React.Validator<OverlayVariants>,
}

export default ContentWithLoadingOverlay
