import __orderBy from 'lodash/orderBy'

import { orderCompanies, transformCamelCaseObjectToSnakeCase } from '@helpers'

import { isUserPermissionsEnabled } from '@permissions'

import { isUserLoggedIn } from '../helpers'
import onboardingActions from '../onboarding/actions'
import subscriptionActions from '../subscription/actions'
import actions from './actions'
import {
  companyWithTokenAndIntegrations,
  orderCompanyMembers,
  serializeAuthMeData,
  serializeCompanyUser,
  serializeCompanyWithUserPermissions,
  updateCompaniesListIfNecessary,
} from './helpers'
import { AuthInitialState, AuthReducerAction } from './types'

// TODO: once `AuthStore` is final this simply needs to be `AuthStore`
export const initialState: AuthInitialState = {
  user: null,
  companies: [], // company-select
  userCompanies: [], // user profile companies list
  company: {
    loading: false,
    data: null,
    error: null,
    fetched: false,
  },
  companyMembers: {
    companyUserTemplate: {} as CompanyUser,
    data: [],
  },
  companyPendingInviteApprovals: [],
  isLoggedIn: isUserLoggedIn(),
  isLogoutRequested: false, // flag to control redirect after successful login (redirectToAfterLogin in router)
  token_expired: false,
  renew_token_inprogress: false, // lock infinite refresh request in middleware
  integrations: [],
  invitation: {
    loading: true,
    data: null,
    error: null,
  },
  companyVats: {
    loading: false,
    fetched: false,
    data: [],
    error: null,
  },
}

export default function reducer(state: AuthInitialState = initialState, action: AuthReducerAction): AuthInitialState {
  switch (action.type) {
    case actions.login.SUCCESS:
    case actions.signup.SUCCESS:
    case actions.acceptInvitation.SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isLogoutRequested: false,
        token_expired: false,
      }

    case actions.loadInvitationDetails.SUCCESS:
      return {
        ...state,
        invitation: {
          ...state.invitation,
          loading: false,
          data: action.payload,
        },
      }
    case actions.loadInvitationDetails.FAILURE:
      return {
        ...state,
        invitation: {
          ...state.invitation,
          loading: false,
          error: action.payload,
        },
      }

    case actions.logout.SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        isLogoutRequested: true, // prevent to remember url after logout
      }

    case actions.renewToken.REQUEST:
      return {
        ...state,
        renew_token_inprogress: true,
      }
    case actions.renewToken.SUCCESS:
      return {
        ...state,
        renew_token_inprogress: false,
      }

    case actions.updateBadgeDisplayedAt.SUCCESS:
    case actions.updatePersonalInfo.SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      }

    case actions.changePreferences.SUCCESS:
      if (!state.user) return state

      return {
        ...state,
        user: {
          ...state.user,
          preferences: {
            ...state.user.preferences,
            ...action.payload,
          },
        },
      }

    case actions.fetchUser.SUCCESS: {
      const { user, companies } = serializeAuthMeData(action.payload)

      return {
        ...state,
        user,
        companies,
      }
    }

    case actions.fetchUserCompanies.SUCCESS: {
      return {
        ...state,
        userCompanies: action.payload,
      }
    }

    case actions.createCompany.SUCCESS: {
      const createdCompany = serializeCompanyWithUserPermissions(action.payload)

      return {
        ...state,
        companies: orderCompanies([createdCompany, ...state.companies]),
      }
    }

    case actions.updateCompany.SUCCESS:
    case actions.updateCompanyField.SUCCESS:
      return {
        ...state,
        companies: updateCompaniesListIfNecessary(state, action.payload),
        company: {
          ...state.company,
          data: serializeCompanyWithUserPermissions(action.payload),
        },
      }

    case actions.removeCompany.SUCCESS:
      return {
        ...state,
        companies: state.companies.filter(o => o.id !== action.payload),
        company: {
          ...state.company,
          data: state.company.data?.id === action.payload ? null : state.company.data,
        },
      }

    //! SUBSCRIPTION changes
    case subscriptionActions.cancelSubscription.SUCCESS: {
      const { companyId, subscription } = action.payload
      return {
        ...state,
        company: {
          ...state.company,
          data:
            state.company.data?.id === companyId
              ? {
                  ...state.company.data,
                  subscription,
                }
              : state.company.data,
        },
      }
    }
    case actions.setActiveSubscription.REQUEST: {
      if (!state.company.data) return state

      return {
        ...state,
        company: {
          ...state.company,
          data: {
            ...state.company.data,
            subscription: action.payload,
          },
        },
      }
    }

    //* reset data when change company
    case actions.selectCompany.REQUEST:
      return {
        ...state,
        company: {
          ...initialState.company,
          fetched: false,
          loading: true,
        },
      }

    case actions.selectCompany.FAILURE:
      return {
        ...state,
        company: {
          ...state.company,
          loading: false,
          fetched: true,
          error: action.payload,
        },
      }

    case actions.selectCompany.SUCCESS:
      return {
        ...state,
        companyVats: {
          ...initialState.companyVats,
        },
        company: {
          ...state.company,
          loading: false,
          fetched: true,
          data: serializeCompanyWithUserPermissions(action.payload),
        },
        companyMembers: {
          ...initialState.companyMembers,
        },
      }

    case actions.fetchCompany.SUCCESS:
      return {
        ...state,
        company: {
          ...state.company,
          data: serializeCompanyWithUserPermissions(action.payload),
        },
      }

    case actions.removeCompanyMembership.SUCCESS:
      return {
        ...state,
        userCompanies: state.userCompanies.filter(userCompany => userCompany.company_user.id !== action.payload),
      }

    case actions.updateNotificationDisplayedAt.SUCCESS: {
      if (!state.user) return state

      return {
        ...state,
        user: {
          ...state.user,
          notifications: state.user.notifications.filter(notif => notif.id !== action.payload.id),
        },
      }
    }

    case actions.fetchCompanyMembers.SUCCESS: {
      if (!state.company.data || !state.user) return state

      const { company_user_template, users } = action.payload
      const hasUserPermissions = isUserPermissionsEnabled(state.company.data, true)
      return {
        ...state,
        companyMembers: {
          data: orderCompanyMembers(users, state.user.id, hasUserPermissions),
          companyUserTemplate: company_user_template,
        },
      }
    }

    case onboardingActions.fetchOnboardingInviteApprovals.SUCCESS:
      return {
        ...state,
        companyPendingInviteApprovals: action.payload,
      }

    case actions.createCompanyMember.SUCCESS:
      return {
        ...state,
        companyMembers: {
          ...state.companyMembers,
          data: [...state.companyMembers.data, action.payload],
        },
      }

    case actions.updateCompanyMember.SUCCESS: {
      // check if updated member is current logged in user
      // update permissions
      const isCurrentUser = state.user?.id === action.payload.user
      if (isCurrentUser) {
        return {
          ...state,
          companyMembers: {
            ...state.companyMembers,
            data: state.companyMembers.data.map((member: CompanyUser) =>
              member.id === action.payload.id ? action.payload : member
            ),
          },
          company: {
            ...state.company,
            data: {
              ...state.company.data,
              ...serializeCompanyUser(action.payload),
            },
          },
        }
      }

      return {
        ...state,
        companyMembers: {
          ...state.companyMembers,
          data: state.companyMembers.data.map((member: CompanyUser) =>
            member.id === action.payload.id ? action.payload : member
          ),
        },
      }
    }

    case actions.removeCompanyMember.SUCCESS:
      return {
        ...state,
        companyMembers: {
          ...state.companyMembers,
          data: state.companyMembers.data.filter((f: CompanyUser) => f.id !== action.payload),
        },
      }

    case onboardingActions.declineOnboardingInvite.SUCCESS:
      return {
        ...state,
        companyPendingInviteApprovals: state.companyPendingInviteApprovals.filter(
          (f: CompanyUserInvitation) => f.id !== action.payload
        ),
      }

    case actions.fetchCompanyVats.REQUEST:
      return {
        ...state,
        companyVats: {
          ...state.companyVats,
          loading: true,
        },
      }

    case actions.fetchCompanyVats.SUCCESS:
      return {
        ...state,
        companyVats: {
          ...state.companyVats,
          loading: false,
          fetched: true,
          data: __orderBy(action.payload, 'percent', 'asc'),
        },
      }

    case actions.fetchCompanyVats.FAILURE:
      return {
        ...state,
        companyVats: {
          ...state.companyVats,
          loading: false,
          fetched: false,
          error: action.payload,
        },
      }

    case actions.createCompanyVat.SUCCESS:
      return {
        ...state,
        companyVats: {
          ...state.companyVats,
          data: [...state.companyVats.data, action.payload],
        },
      }

    case actions.updateCompanyVat.SUCCESS:
      return {
        ...state,
        companyVats: {
          ...state.companyVats,
          data: state.companyVats.data.map(companyVat =>
            companyVat.id === action.payload.id ? action.payload : companyVat
          ),
        },
      }

    case actions.removeCompanyVat.SUCCESS:
      return {
        ...state,
        companyVats: {
          ...state.companyVats,
          data: state.companyVats.data.filter(f => f.id !== action.payload),
        },
      }
    // it is used in company integrations and in liquidity page
    case actions.createIntegrationProvider.SUCCESS: {
      if (!state.company.data) return state

      return {
        ...state,
        company: {
          ...state.company,
          data: companyWithTokenAndIntegrations(state.company.data, action.payload),
        },
      }
    }

    case actions.expiredToken.REQUEST:
      return {
        ...state,
        token_expired: true,
      }

    // custom fields
    case actions.callCustomFieldUrl.SUCCESS:
    case actions.callCustomFieldRemoveUrl.SUCCESS: {
      if (!state.company.data) return state

      return {
        ...state,
        company: {
          ...state.company,
          data: {
            ...state.company.data,
            custom_fields: transformCamelCaseObjectToSnakeCase(action.payload),
          },
        },
      }
    }

    default:
      return state
  }
}
