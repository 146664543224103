import React from 'react'
import PropTypes from 'prop-types'

import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'
import * as yup from 'yup'

import { authActions } from '@services'

import { bindActionToPromise, getDefaultCurrencyId, taxNumberTransform } from '@helpers'

import {
  CustomDialogActions,
  CustomDialogBody,
  ReactHookForm,
  ReactHookFormError,
  ReactHookFormSelectField,
  ReactHookFormSubmitButton,
  ReactHookFormTextField,
} from '@components/ui'

import { CreateBusinessSearchField } from './CreateBusinessSearchField'

import { formErrorMessages, globalMessages } from '@messages'

const StyledCustomDialogBody = styled(CustomDialogBody)`
  & > div:first-child {
    margin-top: 0;
  }
`

const StyledCustomDialogActions = styled(CustomDialogActions)`
  flex-direction: column;
  align-items: center;
`

function useValidationSchema(): yup.ObjectSchema<CreateCompanyFormValues> {
  const { formatMessage } = useIntl()

  return yup.object({
    company_type: yup.string().required(formatMessage(formErrorMessages.required)),
    country: yup.string().required(formatMessage(formErrorMessages.required)),
    default_currency: yup.number().required(formatMessage(formErrorMessages.required)),
    name: yup.string().required(formatMessage(formErrorMessages.required)),
    tax_account_number: yup.string().required(formatMessage(formErrorMessages.required)),
  })
}

interface CreateCompanyFormProps {
  companyTypeOptions: CommonStringIdAndNameType[]
  countryOptions: CommonStringIdAndNameType[]
  currencyOptions: CommonIdAndNameType[]
  defaultCurrencyId: number
  defaultCountry: string
  onSubmit: AsyncFunction<CreateCompanyFormValues, unknown>
  onSubmitSuccess: (payload: Company) => void
}

function PureCreateCompanyForm({
  companyTypeOptions,
  countryOptions,
  currencyOptions,
  defaultCountry,
  defaultCurrencyId,
  onSubmit,
  onSubmitSuccess,
}: CreateCompanyFormProps) {
  const { formatMessage } = useIntl()
  const validationSchema = useValidationSchema()
  const initialValues: CreateCompanyFormValues = {
    company_type: '',
    country: defaultCountry,
    default_currency: defaultCurrencyId,
    name: '',
    tax_account_number: '',
  }
  return (
    <ReactHookForm
      className="create-company-form"
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSubmitSuccess={onSubmitSuccess}
      validationSchema={validationSchema}
    >
      <StyledCustomDialogBody>
        <CreateBusinessSearchField name="name" required />
        <ReactHookFormTextField
          label={formatMessage(globalMessages.formLabelTaxNumber)}
          name="tax_account_number"
          required
          transform={taxNumberTransform}
        />
        <ReactHookFormSelectField
          label={formatMessage(globalMessages.formLabelBusinessType)}
          labelKey="name"
          name="company_type"
          options={companyTypeOptions}
          required
          valueKey="id"
        />
        <ReactHookFormSelectField
          label={formatMessage(globalMessages.formLabelCountry)}
          labelKey="name"
          name="country"
          options={countryOptions}
          required
          valueKey="id"
        />
        <ReactHookFormSelectField
          label={formatMessage(globalMessages.formLabelDefaultCurrency)}
          labelKey="name"
          name="default_currency"
          options={currencyOptions}
          required
          valueKey="id"
        />
      </StyledCustomDialogBody>
      <StyledCustomDialogActions>
        <ReactHookFormError />
        <ReactHookFormSubmitButton buttonClassName="create-company-submit-button" disabledUntilValid isCreateOnly />
      </StyledCustomDialogActions>
    </ReactHookForm>
  )
}

PureCreateCompanyForm.propTypes = {
  companyTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  countryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  currencyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  defaultCountry: PropTypes.string.isRequired,
  defaultCurrencyId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
}

export const CreateCompanyForm = connect(
  (state: Store) => ({
    companyTypeOptions: state.dashboard.common.company_types,
    countryOptions: state.dashboard.common.countries,
    currencyOptions: state.dashboard.common.currencies,
    defaultCurrencyId: getDefaultCurrencyId(state),
    defaultCountry: state.auth.user.default_country,
  }),
  dispatch => ({
    onSubmit: bindActionToPromise(dispatch, authActions.createCompany.request),
  })
)(PureCreateCompanyForm)

CreateCompanyForm.displayName = 'CreateCompanyForm'
