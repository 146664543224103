const LINKS = {
  aamKeretSzamitas: 'https://quickipedia.riport.app/hc/hu/articles/360021383180',
  aamKeretAtlepesFeladataok: 'https://quickipedia.riport.app/hc/hu/articles/360018812992',
  chat: '#', // TODO
  documentAiEULA: 'https://legal.riport.app/adatvedelmi-tajekoztato-3#_luqWA',
  eula: 'https://legal.riport.app/adatvedelmi-tajekoztato-3',
  expressPayment: 'https://quickipedia.riport.app/hc/hu/articles/7254018512924-Expressz-fizetés-funkció',
  defaultWallet:
    'https://quickipedia.riport.app/hc/hu/articles/7254268263196-Fizetettre-%C3%A1ll%C3%ADt%C3%A1s-utal%C3%A1si-csomaggal',
  functionsList: 'https://www.helloquick.riport.app/funkciok',
  fundamentals: 'https://quickipedia.riport.app/hc/hu/articles/6153105598236',
  kataBevallasInactivePeriodsInfo: 'https://quickipedia.riport.app/hc/hu/articles/7333504331292',
  kataBevallasTudascentrum: 'https://quickipedia.riport.app/hc/hu/articles/17330850432412',
  landing: 'https://www.helloquick.riport.app/',
  paidThroughMoreInformation: 'https://quickipedia.riport.app/hc/hu/articles/7013040208924',
  subscriptionChange:
    'https://quickipedia.riport.app/hc/hu/articles/10015528504860-Csomagv%C3%A1lt%C3%A1ssal-kapcsolatos-tudnival%C3%B3k',
  szamlazzAutokassza: 'https://www.szamlazz.hu/egyedi-megoldasok/autokassza',
  szamlazzConnect: 'https://quickipedia.riport.app/hc/hu/articles/5858080562332',
  szamlazzMainPage: 'https://www.szamlazz.hu/szamla/main',
  szamlazzAuth:
    'https://www.szamlazz.hu/szamla/?action=directlogin&targetaction=quicklogin&utm_source=loginform&utm_medium=quick&utm_campaign=quick&utm_id=QUiCK',
  szja22BevallasTudascentrum: 'https://quickipedia.riport.app/hc/hu/articles/8496960920860',
  szja23BevallasTudascentrum:
    'https://quickipedia.riport.app/hc/hu/articles/13571910636188-23SZJA-bevall%C3%A1s-kit%C3%B6lt%C3%A9si-seg%C3%A9dlet-%C3%A1tal%C3%A1nyad%C3%B3z%C3%B3-egy%C3%A9ni-v%C3%A1llalkoz%C3%B3k-r%C3%A9sz%C3%A9re',
  szja24BevallasTudascentrum:
    'https://quickipedia.riport.app/hc/hu/articles/18810799699740-24SZJA-bevall%C3%A1s-kit%C3%B6lt%C3%A9si-seg%C3%A9dlet-%C3%A1tal%C3%A1nyad%C3%B3z%C3%B3-egy%C3%A9ni-v%C3%A1llalkoz%C3%B3k-r%C3%A9sz%C3%A9re',
  tbjSzochoBevallasTudascentrum:
    'https://quickipedia.riport.app/hc/hu/articles/6102402397212-58-as-bevall%C3%A1s-XML-bet%C3%B6lt%C3%A9se-%C3%81NYK-ba',
  tbjSzocho2358ANYK: 'https://quickipedia.riport.app/hc/hu/articles/6102402397212',
  tbjSzocho2358ONYA: 'https://quickipedia.riport.app/hc/hu/articles/6362345616412',
  terms: 'https://legal.riport.app/altalanos-szerzodesi-feltetelek-2',
  noszIntegrationGuide: 'https://quickipedia.riport.app/hc/hu/articles/15927417509532',
}

export default LINKS
