import React from 'react'
import PropTypes from 'prop-types'

import { useController, useFormContext } from 'react-hook-form'

import { ReactHookFormDateInput, ReactHookFormDateInputProps } from './ReactHookFormDateInput'

export function ReactHookFormDateField({
  name,
  disabled,
  ...rest
}: Omit<ReactHookFormDateInputProps, 'error' | 'helperText' | 'onChange' | 'value'>) {
  const { control } = useFormContext()
  const {
    field: { onChange, value },
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({ name, control })

  const isFieldDisabled = disabled || isSubmitting
  return (
    <ReactHookFormDateInput
      {...rest}
      allowEmptyFormatting
      disabled={isFieldDisabled}
      error={!!error}
      helperText={error?.message ?? ''}
      name={name}
      onChange={onChange}
      value={value}
    />
  )
}

ReactHookFormDateField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  highlighted: PropTypes.bool,
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
}
