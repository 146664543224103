import { createDetailsPagingConfig } from '@services/helpers'

import { PageChangeDirection, RouteKeys } from '@constants'

import authActions from '../auth/actions'
import dashboardActions from '../dashboard/actions'
import dokumentsActions from '../dokuments/actions'
import expenseActions from '../expense/actions'
import filtersActions from '../filters/actions'
import incomeActions from '../income/actions'
import paymentActions from '../payment/actions'
import quarantineActions from '../quarantine/actions'
import salaryActions from '../salary/actions'
import taxActions from '../tax/actions'
import actions from './actions'
import { ControlsReducerAction } from './types'

const initialState: ControlsStore = {
  detailsPaging: {
    config: null,
    loading: false,
    page: 1,
    totalCount: 0,
  },
  lists: {
    dokuments: {
      selected: [],
      displayAllSelected: false, // Not used as a feature, but needed for DeleteConfirmDetails in bulk delete feature
      isAllSelected: false, // Not used as a feature, but needed for DeleteConfirmDetails in bulk delete feature
    },
    expense: {
      selected: [],
      displayAllSelected: false,
      isAllSelected: false,
    },
    income: {
      selected: [],
      displayAllSelected: false,
      isAllSelected: false,
    },
    quarantine: {
      selected: [],
      displayAllSelected: false, // Not used as a feature, but needed for DeleteConfirmDetails in bulk delete feature
      isAllSelected: false, // Not used as a feature, but needed for DeleteConfirmDetails in bulk delete feature
    },
    salary: {
      selected: [],
      displayAllSelected: false,
      isAllSelected: false,
    },
    tax: {
      selected: [],
      displayAllSelected: false,
      isAllSelected: false,
    },
  },
  lastVisitedSubPage: {},
}

function updateV2Selection(state: ControlsStore, dataType: SelectableDataTypeProps, selected: SelectedItemProps[]) {
  return {
    ...state,
    lists: {
      ...state.lists,
      [dataType]: {
        ...state.lists[dataType],
        selected,
      },
    },
  }
}

function reducer(state = initialState, action: ControlsReducerAction): ControlsStore {
  switch (action.type) {
    // reset state (eg.: company select change)
    case authActions.selectCompany.REQUEST:
      return { ...initialState }

    //* v2
    case actions.updateListSelect.REQUEST: {
      const { dataType, data } = action.payload
      return {
        ...state,
        lists: {
          ...state.lists,
          [dataType]: {
            ...state.lists[dataType],
            ...data,
          },
        },
      }
    }

    //* RESET SELECTION after successful bulk actions
    //* v2
    case expenseActions.bulkDeleteV2.SUCCESS:
      return {
        ...state,
        lists: {
          ...state.lists,
          expense: { ...initialState.lists.expense },
        },
      }

    case incomeActions.bulkDeleteV2.SUCCESS:
      return {
        ...state,
        lists: {
          ...state.lists,
          income: { ...initialState.lists.income },
        },
      }

    case taxActions.bulkDeleteV2.SUCCESS:
      return {
        ...state,
        lists: {
          ...state.lists,
          tax: { ...initialState.lists.tax },
        },
      }

    case salaryActions.bulkDeleteV2.SUCCESS:
      return {
        ...state,
        lists: {
          ...state.lists,
          salary: { ...initialState.lists.salary },
        },
      }

    case paymentActions.createPaymentV2.SUCCESS: {
      const { transactionType } = action.payload
      return {
        ...state,
        lists: {
          ...state.lists,
          [transactionType]: { ...initialState.lists[transactionType as PaymentTransactionType] },
        },
      }
    }

    case dokumentsActions.bulkAiRecognition.SUCCESS:
      return {
        ...state,
        lists: {
          ...state.lists,
          dokuments: { ...initialState.lists.dokuments },
        },
      }

    //! side effects modify selections - remove unavailable item(s) from selection
    // quarantine update - manual
    // quarantine update - by upload
    case quarantineActions.updateQuarantineInvoice.SUCCESS:
    case quarantineActions.uploadQuarantineInvoice.SUCCESS: {
      // invoice details data
      const { id, missing } = action.payload
      const selectedV2 = state.lists.quarantine.selected
      // when missing false - invoice moved from quarantine list
      if (!missing) {
        return updateV2Selection(
          state,
          'quarantine',
          selectedV2.filter(item => item.id !== id)
        )
      }

      return state
    }

    // expense delete
    case expenseActions.removeExpense.SUCCESS: {
      // paylod: id
      const selectedV2 = state.lists.expense.selected
      if (selectedV2.length) {
        return updateV2Selection(
          state,
          'expense',
          selectedV2.filter(sData => sData.id !== action.payload)
        )
      }
      return state
    }

    // update monthly salary
    case dashboardActions.updateMonthlySalaryV2.SUCCESS: {
      const { salaries } = action.payload
      const selectedV2 = state.lists.salary.selected

      return updateV2Selection(
        state,
        'salary',
        selectedV2.filter(sData => !salaries.some(salary => salary.id === sData.id))
      )
    }

    // removeMonthlySalary - editor dialog (SalaryEditorDialog) - payload: monthly data
    case dashboardActions.removeMonthlySalaryV2.SUCCESS: {
      const { filtered_items } = action.payload
      const selectedV2 = state.lists.salary.selected

      return updateV2Selection(
        state,
        'salary',
        selectedV2.filter(sData => !filtered_items.some(item => item.id === sData.id))
      )
    }

    // update monthly tax
    case dashboardActions.updateMonthlyTaxV2.SUCCESS: {
      const { taxes } = action.payload
      const selectedV2 = state.lists.tax.selected

      return updateV2Selection(
        state,
        'tax',
        selectedV2.filter(tData => !taxes.some(tax => tax.id === tData.id))
      )
    }

    // removeMonthlyTax - editor dialog (SalaryEditorDialog) - payload: monthly data
    case dashboardActions.removeMonthlyTaxV2.SUCCESS: {
      const { filtered_items } = action.payload
      const selectedV2 = state.lists.tax.selected

      return updateV2Selection(
        state,
        'tax',
        selectedV2.filter(sData => !filtered_items.some(item => item.id === sData.id))
      )
    }

    // income delete
    case incomeActions.removeIncome.SUCCESS: {
      // paylod: id
      const selectedV2 = state.lists.income.selected
      if (selectedV2.length) {
        return updateV2Selection(
          state,
          'income',
          selectedV2.filter(sData => sData.id !== action.payload)
        )
      }
      return state
    }

    // other documents bulk delete
    case dokumentsActions.bulkRemoveDokuments.SUCCESS:
    case dokumentsActions.attachDokuments.SUCCESS: {
      return {
        ...state,
        lists: {
          ...state.lists,
          dokuments: { ...initialState.lists.dokuments },
        },
      }
    }

    //* V2 actions
    // bulk quarantine approve v2 - cleanup selection
    case quarantineActions.bulkApproveQuarantineInvoicesV2.SUCCESS: {
      const { isAllSelected, selected } = action.payload
      if (isAllSelected) {
        return state
      }
      return updateV2Selection(
        state,
        'quarantine',
        state.lists.quarantine.selected.filter(({ id }) => !selected.includes(id))
      )
    }

    // bulk quarantine delete v2 - reset selection
    case quarantineActions.bulkRejectQuarantineInvoicesV2.SUCCESS: {
      return {
        ...state,
        lists: {
          ...state.lists,
          quarantine: { ...initialState.lists.quarantine },
        },
      }
    }

    case actions.setLastVisitedSubPage.REQUEST: {
      const { parentRoute, subRoute } = action.payload
      return {
        ...state,
        lastVisitedSubPage: {
          ...state.lastVisitedSubPage,
          [parentRoute]: subRoute,
        },
      }
    }

    //* Details paging controls
    case quarantineActions.fetchQuarantineListV2.SUCCESS:
    case quarantineActions.fetchQuarantineListByPagingV2.SUCCESS:
      return {
        ...state,
        detailsPaging: {
          ...state.detailsPaging,
          config: createDetailsPagingConfig(RouteKeys.QUARANTINE, action.payload.data),
        },
      }

    case expenseActions.fetchExpenseListV2.SUCCESS:
      return {
        ...state,
        detailsPaging: {
          ...state.detailsPaging,
          config: createDetailsPagingConfig(RouteKeys.COST, action.payload.data),
          page: action.payload.previousCursor ? state.detailsPaging.page - 1 : initialState.detailsPaging.page,
        },
      }

    case expenseActions.fetchExpenseListByPagingV2.SUCCESS:
      return {
        ...state,
        detailsPaging: {
          ...state.detailsPaging,
          config: createDetailsPagingConfig(RouteKeys.COST, action.payload.data),
          page:
            action.payload.direction === PageChangeDirection.NEXT
              ? state.detailsPaging.page + 1
              : state.detailsPaging.page - 1,
        },
      }

    case expenseActions.fetchExpenseCountV2.REQUEST:
      return {
        ...state,
        detailsPaging: {
          ...state.detailsPaging,
          loading: true,
        },
      }

    case expenseActions.fetchExpenseCountV2.SUCCESS:
      return {
        ...state,
        detailsPaging: {
          ...state.detailsPaging,
          totalCount: action.payload.count,
          loading: false,
        },
      }

    case expenseActions.updateOrderV2.REQUEST:
    case expenseActions.updateRowsPerPageV2.REQUEST:
    case filtersActions.toggleExpenseListDateFilter.REQUEST:
    case filtersActions.updateExpenseListFilters.REQUEST:
    case filtersActions.resetExpenseListFilters.REQUEST: {
      return {
        ...state,
        detailsPaging: {
          ...state.detailsPaging,
          page: 1,
        },
      }
    }

    case incomeActions.fetchIncomeListV2.SUCCESS:
    case incomeActions.fetchIncomeListByPagingV2.SUCCESS:
      return {
        ...state,
        detailsPaging: {
          ...state.detailsPaging,
          config: createDetailsPagingConfig(RouteKeys.INCOME, action.payload.data),
        },
      }

    case dokumentsActions.fetchDokuments.SUCCESS:
    case dokumentsActions.fetchDokumentsByPaging.SUCCESS:
      return {
        ...state,
        detailsPaging: {
          ...state.detailsPaging,
          config: createDetailsPagingConfig(RouteKeys.DOKUMENT, action.payload.data),
        },
      }

    //* trigger from MERGE action
    case actions.detailsPagingConfigUpdate.REQUEST: {
      const { from_id, to_id } = action.payload
      const { config } = state.detailsPaging

      if (!config) {
        return state
      }

      const removedItem = { ...config.listMap[from_id] }
      removedItem.id = to_id

      if (!removedItem) {
        return state
      }

      const newListMap: Record<string, DetailsPagingMapItem> = {
        ...config.listMap,
        [to_id]: removedItem,
      }

      // replace removed item links with the new item
      if (removedItem.prevItemId) {
        newListMap[removedItem.prevItemId].nextItemId = to_id
      }
      if (removedItem.nextItemId) {
        newListMap[removedItem.nextItemId].prevItemId = to_id
      }
      delete newListMap[from_id]

      return {
        ...state,
        detailsPaging: {
          ...state.detailsPaging,
          config: {
            ...config,
            listMap: newListMap,
          },
        },
      }
    }

    default:
      return state
  }
}

export default reducer
