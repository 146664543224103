import React from 'react'

import styled from 'styled-components'

interface ParentSizeProps {
  children: (rect: { width: number; height: number }) => JSX.Element
}

const ParentSizeWrapper = styled.div`
  width: 100%;
  height: 100%;
`
/**
 * Utility component to get the size of the parent element. Only renders children when the size is measured (isReady=True).
 * @param children - A function that receives the size of the parent element and renders the children.
 * @returns JSX.Element
 *
 * @example
 * ```tsx
 * <ParentSize>
 *  {({ width, height }) => (
 *   <div>
 *     <p>Width: {width}</p>
 *     <p>Height: {height}</p>
 *   </div>
 *  )}
 * </ParentSize>
 * ```
 */
export function ParentSize({ children }: ParentSizeProps) {
  const divRef = React.useRef<HTMLDivElement>(null)
  const [size, setSize] = React.useState({ width: 0, height: 0 })

  React.useEffect(() => {
    if (!divRef.current) {
      return
    }

    function updateSize(entries: ResizeObserverEntry[]) {
      for (const entry of entries) {
        const { width, height } = entry.contentRect
        setSize({ width, height })
      }
    }

    const resizeObserver = new ResizeObserver(updateSize)

    resizeObserver.observe(divRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return <ParentSizeWrapper ref={divRef}>{size.width > 0 && size.height > 0 && children(size)}</ParentSizeWrapper>
}
