import { serviceActionsGenerator } from '../serviceGenerator'

const actionTypes = [
  '!EXPORT_PAYMENT',
  '!FETCH_BANK_TRANSACTIONS_BY_PAGING',
  '!FETCH_PAID_THROUGH_DETAILS',
  '!FETCH_PAID_THROUGHS_BY_PAGING',
  '!FETCH_PAYMENT_TRANSACTIONS_BY_PAGING',
  '!FETCH_PAYMENT',
  '!FETCH_PAYMENTS_BY_PAGING',
  '!INIT_BANK_TRANSACTION_LIST_PAGE_LOAD',
  '!INIT_PAID_THROUGH_LIST_PAGE_LOAD',
  '!INIT_PAYMENT_ORDER_LIST_PAGE_LOAD',
  '!INIT_PAYMENT_TRANSACTION_LIST_PAGE_LOAD',
  '!INITIALIZE_PAYMENT',
  '!LOAD_INVOICE_TRANSACTIONS',
  '!LOAD_PAID_THROUGH_PROVIDER_BALANCE',
  '!RESET_BANK_TRANSACTIONS_PAGINATION',
  '!RESET_PAID_THROUGHS_PAGINATION',
  '!RESET_PAYMENT_ORDERS_PAGINATION',
  '!RESET_PAYMENT_TRANSACTIONS_PAGINATION',
  '!START_BANK_TRANSACTIONS_EXPORT',
  '!START_PAYMENT_TRANSACTIONS_EXPORT',
  '!STOP_BANK_TRANSACTIONS_EXPORT',
  '!STOP_PAYMENT_TRANSACTIONS_EXPORT',
  '!UPDATE_BANK_TRANSACTIONS_ROWS_PER_PAGE',
  '!UPDATE_PAID_THROUGHS_ROWS_PER_PAGE',
  '!UPDATE_PAYMENT_TRANSACTIONS_ROWS_PER_PAGE',
  '!UPDATE_PAYMENTS_ROWS_PER_PAGE',
  'CREATE_PAID_THROUGH_OPTION',
  'CREATE_PAID_THROUGH',
  'CREATE_PAYMENT_V2',
  'FETCH_BANK_TRANSACTIONS',
  'FETCH_PAID_THROUGH_OPTIONS',
  'FETCH_PAID_THROUGHS',
  'FETCH_PAYMENT_TRANSACTIONS',
  'FETCH_PAYMENTS',
  'REMOVE_PAID_THROUGH',
  'REMOVE_PAYMENT_TRANSACTION',
  'REMOVE_PAYMENT',
  'UPDATE_PAID_THROUGH',
  'UPDATE_PAYMENT',
] as const

export default serviceActionsGenerator('payment', actionTypes)
