import Qs from 'qs'

import { injectCompanyToURL } from '@helpers'
import { transformV2ParamsToV1 } from '@services/helpers'

import { PartnerFormValues } from '@oldComponents/PartnerEditor/types'

import { InvoiceType } from '@constants'

import Api from '../api'
import {
  API_PARTNER_CALCULATION_BASE_URL,
  API_PARTNER_DETAILS_URL,
  API_PARTNER_INVOICE_COUNT_URL,
  API_PARTNER_LIST_EXPORT_URL,
  API_PARTNER_LIST_URL,
  API_PARTNER_MERGE_URL,
} from './urls'

export function fetchPartners(companyId: Company['id'], { page, search, ...params }: PartnerPageParams) {
  return Api.get(injectCompanyToURL(API_PARTNER_LIST_URL, companyId), {
    params: {
      ...params,
      name: search, // migrate back to v1
      page: (page ?? 0) + 1, // shift page because mui pagination start with 0 and BE pagination start with 1
    },
    paramsSerializer: {
      serialize(v2Params) {
        return Qs.stringify(transformV2ParamsToV1(v2Params), { indices: false })
      },
    },
  })
}

export function searchPartners(
  companyId: Company['id'],
  {
    page,
    ...params
  }: Partial<{
    name: string
    page: number
    pageSize: number
    ordering: string
    partner: string
    invoice_number: string
    tax_number: string
  }>
) {
  const url = `${injectCompanyToURL(API_PARTNER_LIST_URL, companyId)}dropdown/`
  return Api.get(url, {
    params,
    paramsSerializer: {
      serialize(v2Params) {
        return Qs.stringify(transformV2ParamsToV1(v2Params), { indices: false })
      },
    },
  })
}

export function fetchPartnerDetails(companyId: Company['id'], partnerId: ItemIdType) {
  return Api.get(injectCompanyToURL(API_PARTNER_DETAILS_URL, companyId).replace('{partner_id}', String(partnerId)))
}

export function updatePartner(companyId: Company['id'], { id: partnerId, ...data }: PartnerFormValues) {
  return Api.patch(
    injectCompanyToURL(API_PARTNER_DETAILS_URL, companyId).replace('{partner_id}', String(partnerId)),
    data
  )
}

export function updatePartnerCalculationBase({
  company,
  id,
  ...data
}: {
  calculation_base: CalculationBase
  company: number
  id?: number
  invoiceType: InvoiceType
}) {
  return Api.post(
    injectCompanyToURL(API_PARTNER_CALCULATION_BASE_URL, company).replace('{partner_id}', String(id)),
    data
  )
}

export function createPartner(companyId: Company['id'], data: PartnerFormValues) {
  return Api.post(injectCompanyToURL(API_PARTNER_LIST_URL, companyId), data)
}

export function removePartner(companyId: Company['id'], partnerId: ItemIdType) {
  return Api.delete(injectCompanyToURL(API_PARTNER_DETAILS_URL, companyId).replace('{partner_id}', String(partnerId)))
}

export function exportPartners(companyId: Company['id'], params: Omit<PartnerPageParams, 'ordering' | 'pageSize'>) {
  return Api.post(injectCompanyToURL(API_PARTNER_LIST_EXPORT_URL, companyId), params)
}

export function getPartnerInvoiceCount(companyId: Company['id'], partnerId: ItemIdType) {
  return Api.get(
    injectCompanyToURL(API_PARTNER_INVOICE_COUNT_URL, companyId).replace('{partner_id}', String(partnerId))
  )
}

export function mergeAndRemovePartner(
  companyId: Company['id'],
  { partnerId, ...data }: Record<'partnerId' | 'new_partner_id', ItemIdType>
) {
  return Api.post(injectCompanyToURL(API_PARTNER_MERGE_URL, companyId).replace('{partner_id}', String(partnerId)), data)
}
